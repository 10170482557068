
<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.study template") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0">
        <div
          class="
            d-flex
            flex-column flex-lg-row
            justify-content-lg-start
            my-2 my-lg-0
          "
        >
        <div class="form-group mb-0 w-100">
          <label class="mb-0">{{ $t("navigations.study") }}</label>
          <select
            class="form-select mx-lg-12 my-lg-0"
            placeholder="Search by Status"
            v-model="selectedstudy"
            @change="onChange($event)"
          >
            <option value="All">All</option>
            <option
              v-for="studye in modelstudy"
              :key="studye.studyId"
              :value="studye.studyId"
            >
              {{ studye.studyRef }}
            </option>
          </select>
        </div>
           
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">{{ $t("navigations.study template") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.select") }}</th>
                <th>{{ $t("navigations.form name") }}</th>
                <th>{{ $t("navigations.folder") }}</th>
                <th>{{ $t("navigations.language") }}</th>
                <th>{{ $t("navigations.version") }}</th>
                <th>{{ $t("navigations.created date") }}</th>
                <th>{{ $t("navigations.status") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="template in templateList" :key="template.templateId">
                <td><input
                    type="checkbox"
                    :id="template.templateId"
                    :value="template.templateId"
                    :disabled="selectedstudy == '' || selectedstudy == 'All'"
                    v-model="templatearray"
                    @change.prevent="selecttemplate()"
                    :checked="templatearray.includes(template.templateId)"
                  /></td>
                <td>{{ template.templateName }}</td>
                <td>{{ template.folder }}</td>
                <td>{{ template.language }}</td>
                <td>{{ template.version }}</td>
                <!-- <td>{{template.createdDate == null ? '' : template.createdDate.slice(8, 10) +"/"+template.createdDate.slice(5, 7)+"/"+template.createdDate.slice(0, 4)}}</td> -->
                 <td>{{template.createdDate == null ? '' : filter(template.createdDate)}} </td>
                <td>{{ template.status }}</td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->
          
            <div class="col-lg-12 mx-0 px-0">
              <div class="row mx-0 ">
            
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  justify-content-between
                "
              >
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalitems"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
            <button
            class="btn btn-primary save_btn px-5"
            :disabled="selectedstudy == '' || selectedstudy == 'All'"
            type="submit"
            @click.prevent="addstudytemplate()"
          >
            {{ $t("navigations.save") }}
          </button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
</template>

<script src="./studyTemplate.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.wrapper-content{
  position:relative
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.modelbody{
  overflow: unset !important;
}

.modelbody{
  /* background-color: aquamarine; */
margin:0;
padding: 5%;
}

.modal-container {
  background: #fff;
  width: min(50%,90%);
  border-radius: 4px;
  position: fixed;
  transition: all .3s ease-in-out;
  height:auto !important;
  margin-top:200px;}
  .savebutton{
    padding-right: 0;
    height: 32px;
  }
  .btn:focus {
  outline: none;
  box-shadow: none;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
</style>
