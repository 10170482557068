<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.visit forms") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 pl-0 pr-1">
            <label class="mb-0">{{ $t("navigations.form name") }}</label>
            <input
              type="search"
              v-model="searchdata.templateName"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.date") }}</label>
            <input
              type="date"
              v-model="searchdata.createdDate"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.status") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.status"
              name="account"
            >
              <option value="">  {{ $t("navigations.select") }}</option>
              <option value="New">{{ $t("navigations.new") }}</option>
              <option value="Approved">{{ $t("navigations.approved") }}</option>
              <option value="Retired">{{ $t("navigations.retired") }}</option>
              <option value="Design Complete">{{ $t("navigations.design complete") }}</option>
            </select>
          </div>
          <!-- <span type="button" @click.prevent="filteredLists" -->
          <span
            type="button"
            class="col-12 col-md-2 w-100 save_btn mx-lg-1 align-self-end"
            @click="setsearchdata()"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ $t("navigations.visit forms") }}</h5>
        <div class="d-flex">
          <div
            v-if="rollsprivilages.includes('Visit Templates Create Template')"
            class="position-relative align-self-center mr-2"
            aria-label="Create site"
            tabindex="0"
          >
          <img
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Create site"
            width="17" height="17"
            @click="createTemplate()"
          />
            <div class="tool-tip">{{ $t("navigations.create form") }}</div>
          </div>
          <div class="position-relative cursor-pointer" v-if="templateList.length!=0" @click.prevent="showFilter = !showFilter"><img
              src="../../assets/img/filter.png" width="17" height="17" alt="filter table" />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
        </div>
       
      </div>
      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
          <div class="col-3 px-0" v-on:clickout="dropdownOpen = false">
            <div class=" table-multi-select-dropdown" @click.stop="dropdownOpen = !dropdownOpen;">
              <div class="selected-options">
                <span>Select Columns</span>
              </div>
              <div class="dropdown-arrow"></div>
            </div>
            <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
              <label v-for="(column, index) in getColumns" :key="index">
                <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center" v-if="templateList.length===0">
          No data available
        </div>
        <div v-else class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th v-if="selectedcolumnobj['Template Name']"> {{ $t("navigations.form name") }}</th>
                <th v-if="selectedcolumnobj['Language']">{{ $t("navigations.language") }}</th>
                <th v-if="selectedcolumnobj['Version']">{{ $t("navigations.version") }}</th>
                <th v-if="selectedcolumnobj['Created Date']">{{ $t("navigations.created date") }}</th>
                <th v-if="selectedcolumnobj['Status']">{{ $t("navigations.status") }}</th>
                <th class="text-center">{{ $t("navigations.action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(template, index) in templateList"
                :key="template.templateId"
              >
                <td v-if="selectedcolumnobj['Template Name']">{{ template.templateName }}</td>
                <td v-if="selectedcolumnobj['Language']">{{ template.language }}</td>
                <td v-if="selectedcolumnobj['Version']">{{ template.version }}</td>

                <td v-if="selectedcolumnobj['Created Date']">{{ filter(template.createdDate) }}</td>
                <td v-if="selectedcolumnobj['Status']">{{ template.status }}</td>
                <td
                  class="text-center"
                  v-on:clickout="showSidePopup[index] = false"
                >
                  <div
                    class="position-relative cursor-pointer"
                    @click.prevent="
                      showSidePopup[index] = !showSidePopup[index]
                    "
                  >
                    <i class="fa fa-ellipsis-v"></i>
                  </div>
                  <div class="plan-side-popup-form" v-if="showSidePopup[index]">
                    <div
                      v-if="rollsprivilages.includes('Visit Templates Create New Version')"
                      @click.prevent="
                        createNewVersion(
                          template.templateFormId,
                          template.version,
                          template.sourceTemplateId,
                          template.templateId
                        )
                      "
                    >
                      {{ $t("navigations.create new version") }}
                    </div>
                    <!-- <div @click.prevent="deleteForms(template.templateId)">
                      Delete
                    </div> -->
                    <div
                      v-if="rollsprivilages.includes('Visit Templates Edit')"
                      @click.prevent="
                        editForm(
                          template.templateFormId,
                          template.version,
                          template.sourceTemplateId
                        )
                      "
                    >
                      {{ $t("navigations.edit") }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->

          <div class="col-lg-12 mx-0 px-0">
            <div class="row mx-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination justify-content-end"
              >
                <v-pagination
                  v-model="currentPage"
                  :pages="totalitems"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./formTemplate.js"></script>
<style scoped>
.plan-side-popup-form {
  position: absolute;
  text-align: left;
  right: 115px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(133px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup-form > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup-form > div:hover {
  background-color: #e4eaf9;
}
</style>