<template>
  <div class="modal__upload__wrapper">
    <div class="modal-dialog modal-dialog-width">
      <div class="modal-content">
        <div class="modal-header-version d-flex justify-content-bertween p-3">
          <h5 class="my-0 align-self-center">{{ $t("navigations.add new version") }}</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosed()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow">
          <div class="row">
            <div class="d-flex col-12 col-md-12 pr-md-1 mt-2">
              <div class="col-md-4">
              <label class="mb-0 mr-1" for="version-number"
                >{{ $t("navigations.existing version") }}</label
              >
              </div>
              <!-- {{versionhistoryarray}} -->
              <select v-model="sumofversions" class="form-select col-md-6" >
               <option v-for="versions in versionhistoryarray" :key="versions.version" :value="versions.version"> {{ versions.version }} </option>
              </select>
              <span class="d-block errorClass" v-if="validationmsgs[2] == true">
               {{ $t("navigations.please enter new version") }}
              </span>
            </div>
            <div class="d-flex col-12 col-md-12 pr-md-1 mt-2">
              <div class="col-md-4">
              <label class="mb-0 mr-1" for="version-number"
                >{{ $t("navigations.new version") }}</label
              ><span aria-label="mandatory field" class="error-block">*</span>
              </div>
              <input
                type="text"
                id="version-number"
                v-model="versionnumber"
                class="form-control col-md-6"
                maxlength="30"
              />
              
              
            </div>
            <span class="d-block errorClass" v-if="validationmsgs[0] == true">
               {{ $t("navigations.please enter new version") }}
              </span>
              <span class="d-block errorClass" v-if="validationmsgs[1] == true">
                Version already exist.
              </span>
            
            <!-- <div class="d-flex col-12 col-md-12 pr-md-1 mt-2">
              <div class="col-md-4">
              <label class="mb-0 mr-1" for="version-number"
                >Version history</label
              >
              </div>
              <textarea
                id="changesHistory"
                v-model="historynote"
                class="form-control col-md-6"
                maxlength="250"
              ></textarea>
            </div> -->
          </div>
          <div class="d-flex justify-content-end mt-2 makeitbottom">
            <button
              type="button"
              class="btn btn-primary btn-xs px-4 float-right"
              @click.prevent="savefunction()"
            >
              {{ $t("navigations.create") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { onBeforeMount } from "@vue/runtime-core";

export default {
  name: "VersionPopup",
  props: {
    formid: {
      type: String,
      default: "",
    },
    currentversion: {
      type: String,
      default: "",
    },
    sourceid:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      historynote:"",
      sumofversions:"",
      idtoken:"",
      existingversion:"",
        versionnumber:"",
        validationmsgs: false,
      baseurl: process.env.VUE_APP_Service_URL,
      validationmsgs:[false,false],
      versionhistoryarray: [],
    };
  },
  async beforeMount() {},

  async mounted() {
    this.idtoken=store.getters.getIdToken;
    this.existingversion = this.currentversion;
    this.getversionhistory();
  },
  async unmounted() {},
  methods: {
   async getversionhistory(){
    await axios
      .get(
        `${this.baseurl}/forms/formslibrary/getversionhistory?sourceId=${this.sourceid}`,
        {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          }
        }
      )
      .then((res) => {
        this.versionhistoryarray = res.data;
        this.sumofversions = this.versionhistoryarray[this.versionhistoryarray.length -1].version;

      })
      .catch((err) => {
       console.error(err)
      });
    },
    async onclosed() {
      this.$emit("closedialogebox");
    },
    async savefunction(){
      let check = this.versionhistoryarray.some(item => item["version"].trim() === this.versionnumber.trim());
      if(this.versionnumber.trim() == ""){
        this.validationmsgs[0] = true;
        this.validationmsgs[1] = false;
      }
      else if(check){
        this.validationmsgs[0] = false;
        this.validationmsgs[1] = true;
      }
      else{
        this.validationmsgs[0] = false;
        this.validationmsgs[1] = false;
        store.dispatch("setnewversion", this.versionnumber.trim());
        store.dispatch("setversionhistorynote", this.historynote);
        this.$emit("createnewtempversion");
      }
    },

  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal__upload__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.modal-dialog-width {
  max-width: 700px !important;
  width:500px;
}
.modal-overflow {
  overflow-x: hidden;
  height: 250px;
  overflow-y: auto;
}

.groupupload {
  display: flex;
}

.save_btn {
  background-color: #145faf !important;
  color: white;
}

.errorClass {
  color: red;
  font-size: 13px;
  width: 250px;
  margin-left: 32%;
}
.unvisible {
  display: none;
}

.listfile {
  width: 90%;
}

.imp {
  color: #ff0000;
  font-size: 7px;
}
.error-block {
  color: #ff0000;
}

.modal-header-version {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.breakWord {
  word-break: break-word;
}
.makeitbottom {
    position: absolute;
    bottom: 8px;
    right: 8px;
}
</style>
