<template>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-6 align-self-center">
        <h2>{{ $t("navigations.lab result trends") }}</h2>
      </div>
      <div class="col-lg-2">
        <i class="fa fa-times close" @click="pageclosed()"></i>
      </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-md-12 pl-1 mb-2">
                <historytab
                :historyarray="historykeys"
                @gotohistorylevel="gotohistorylevel"
                @exporttopdf="exporttopdf"
            @exporttoexcell="exporttoexcell"
                />
            </div>
            <div ref="captureArea1" class="col-12 pl-1 mb-2">
                <D3LineChart
                 :data="data"
                 :loader="loader" 
                 :templateId="templateId" 
                 :chartlabel="' Lab Results Trend Over Time'" 
                 @opendrillpopup="drillpopupopened"
                 @drilldowncurrentchart="drilldowncurrentchartforbar" 
                 @exporttoexcell="exporttoexcell"
                />
            </div>
            <div ref="captureArea2" class="col-md-6 mb-2">
                <groupbarchart
                    :availableColumns=subjectKeys
                    :loader="loader"
                    :data="data"
                    :templateId="templateId"
                    :xAxisValue="groupchartkeys['xaxiskey']"
                    yAxisValue=""
                    :groupCategoryValue="groupchartkeys['groupkey']"
                    :fromPage="'labResult'"
                    chartlabel="Comparison of Lab Tests Across Visits"
                    @drilldowncurrentchart="drilldowncurrentchartforbar"
                    @opendrillpopup="drillpopupopened"
                    @setgroupkeys="retaingroupchartvars"
                    @exporttoexcell="exporttoexcell"
                />
          </div>
            <div ref="captureArea3" class="col-12 col-md-6 mb-2  pl-1">
                <stackedbarchart
                xAxisKey="LBTEST"
                yAxisKey="LBORRES"
                :availableColumns="subjectKeys"
                segmentKey="LBSPEC"
                :loader="loader"
                :data="data"
                :templateId="''"
                :fromPage="'statusReport'"
                chartlabel="Lab Test Results by Test and Specimen Type"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"      
                @exporttoexcell="exporttoexcell"       
                />
            </div> 
          <div class="col-lg-12 pl-1 mb-1">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                        <span>Patient Distribution</span>
                    </div>
                    <div class="stat-body-white">
                        <advtable 
                        fileheadding="Lab result trends"
                            :loader="loader" 
                            :data="data" 
                            fromPage="patientRetention"
                            @drilldowncurrentchart="drilldowncurrentchartforbar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <chartdrill
      v-if="drillpopup"
      fileheadding="Lab result trends"
      :drilldata="drilldata"
      :templateId="templateId"
      :fromPage="'adverseEventsPop'"
      @closepopup="onclosed()"
    />
</template>
<script src="./LabResultTrend.js"></script>
<style>
@import "../../custom_lib/AiCharts/charts.css";
.page-break {
  page-break-before: always; /* Ensures new page starts before this element */
}
  
</style>