<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.field privileges") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0">
        <div
          class="d-flex flex-column flex-lg-row justify-content-lg-start my-2 my-lg-0"
        >
          <div class="form-group mb-0 w-100">
            <label for="study" class="mb-0">{{
              $t("navigations.study")
            }}</label>
            <select
              id="study"
              class="form-select mx-lg-12 my-lg-0"
              placeholder="Search by Status"
              v-model="selectedstudy"
              @change="onChange($event)"
            >
              <option selected value="">All</option>
              <option
                v-for="study in modelstudy"
                :key="study.studyId"
                :value="study.studyId"
              >
                {{ study.studyRef }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5>{{ $t("navigations.forms") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{
              $t("navigations.template name")
            }}</th>
                <th>{{
              $t("navigations.language")
            }}</th>
                <th> {{
              $t("navigations.version")
            }}</th>
                <th>{{
              $t("navigations.created date")
            }}</th>
                <th>{{
              $t("navigations.status")
            }}</th>
                <th class="text-center"  v-if="rollsprivilages.includes('Field Privileges Set Privileges')">{{
              $t("navigations.set privileges")
            }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="template in templateList" :key="template.templateId">
                <td>{{ template.templateName }}</td>
                <td>{{ template.language }}</td>
                <td>{{ template.version }}</td>
                <td>
                  {{
                    template.createdDate == null
                      ? ""
                      : filter(template.createdDate)
                  }}
                </td>
                <td>{{ template.status }}</td>
                <td class="text-center">
                  <div
                    v-if="
                      rollsprivilages.includes(
                        'Field Privileges Set Privileges'
                      )
                    "
                  >
                    <router-link
                      :to="
                        '/viewTemplate/fieldprivileges/' + template.templateId
                      "
                    >
                      <img
                        src="../../assets/img/set_privilege.png"
                        width="15"
                        alt="Set privilege"
                      />
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->

          <div class="col-lg-12 mx-0 px-0">
            <div class="row mx-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination justify-content-end"
              >
                <v-pagination
                  v-if="templateValue"
                  v-model="currentPage"
                  :pages="totalitems"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
                <v-pagination
                  v-if="templateStudyValue"
                  v-model="studytempCurrent"
                  :pages="totalpages"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="studyloadPage"
                />
                <!-- <button
                  class="btn btn-primary save_btn px-5"
                  :disabled="selectedstudy == '' || selectedstudy == 'All'"
                  type="submit"
                  @click.prevent="addstudytemplate()"
                >
                  Save
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./field_privileges.js"></script>
<style>
</style>