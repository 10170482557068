/* eslint-disable */
import axios from "axios";
import store from "@/store";
import Modal from "../modal/modal.vue";
import CreateVideoCallSchedulePopUp from "../../components/CreateVideoCallSchedulePopUp/CreateVideoCallSchedulePopUp.vue";
// let testarray= [];
export default {
	name: "trainingMaterials",
	components: {
		Modal,
		CreateVideoCallSchedulePopUp
	},
	props: {
		patientLoginId: {
			type: String,
			default: ""
		}
	},
	beforeRouteLeave(from, next) {
		// If the user is navigating away from the training material page
		if (from.path === `/trainingMaterials/${this.patientLoginId}`) {
			// Redirect the user to a different page
			next(`/patientupdate/${this.patientLoginId}`);
		}
	},
	data() {
		return {
			baseUrl: `${process.env.VUE_APP_Service_URL}/management/document/`,
			baseapi: process.env.VUE_APP_Service_URL,
			showModal:false,
			isLoading:true,
			isVideoLoading:true,
			isDescLoading:true,
			addClass:false,
			checkedValue:false,
			addScrollClass:false,
			showPopup:false,
			studyId:"",
			Id:"",
			idtoken:store.getters.getIdToken,
			versionId:"",
			modelvideoId:"",
			ReferenceId:"",
			documentlist:[],
			videolist:[],
			patientLogin:{},
			date :"",
			time :"",
			CreateVideoCallSchedulePopUp:false,
			userEmail: "",
		};
	},
	mounted() {
		console.clear()
		store.dispatch("setNavbar", false)
		store.dispatch("setPatientNavbar", true)
		document.querySelector("#page-wrapper").style.width = "100%";
		const idtoken = store.getters.getIdToken;
		this.header = {
			headers: {
				Authorization: "Bearer " + idtoken,
				"Content-Type": "application/json",
			},
		}
		this.initializeTrainingInfo();
		
	},
	methods: {
		async initializeTrainingInfo(){
			await this.getPatientloginInfo();
			await this.getPatientById();
			console.log("this.studyId", this.studyId);
			await this.getDescription();
			await this.getDocs();
			await this.getVideoList();
		},
		async getPatientloginInfo() {
			await axios.get(`${this.baseapi}/account-core/user/getencryptedpatientinfo?patientLoginId=${this.patientLoginId}`, this.header)
				.then((res) => {
					console.log("get patient login info", res.data);
					this.patientLogin = res.data;
					this.checkedValue = true ? this.patientLogin.hasTrained == true : this.patientLogin.hasTrained == false;
					this.userEmail = res.data.CreatedUserEmail
				})
				.catch((err) => {
					console.log(" error get patient login", err);
				});
		},
		async getPatientById(){
			await axios.get(`${this.baseapi}/management/patient/getpatientbyid/${this.patientLogin.subjectID}`, this.header)
				.then((res) => {
					console.log("get patient item info", res.data);
					this.patientItem = res.data;
					this.studyId = this.patientItem.studyId;
					store.dispatch("setStudyID",this.studyId);
				})
				.catch((err) => {
					console.log(" error in get patient item", err);
				})
		},
		async getDescription() {
			await axios
				.get(`${this.baseapi}/management/study/getstudydiscription?StudyId=${this.studyId}`,
					{
						headers: {
							Authorization: "Bearer " + this.idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					this.isDescLoading = false
					this.description = res.data
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async getDocs(){
			await axios
				.get(`${this.baseUrl}listdocument?documentTypeId=${this.studyId}`,
					{
						headers: {
							Authorization: "Bearer " + this.idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					this.isVideoLoading = false
					this.documentlist = res.data.results
					if (this.documentlist.length >= 4) {
						this.addScrollClass = true
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async getVideoList() {
			await axios
				.get(`${this.baseapi}/management/video/videolist?parentid=${this.studyId}`,
					{
						headers: {
							Authorization: "Bearer " + this.idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					this.isLoading = false;
					this.videolist = res.data.data
					if (this.videolist.length > 2) {
						this.addClass = true
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async nextPage() {
			await axios
				.put(`${this.baseapi}/account-core/user/updatetrainedstatus`,
					{
						"patientLoginId": this.patientLoginId,
						"trainedStatus": true
					},
					{
						headers: {
							Authorization: "Bearer " + this.idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					this.$router.push(`/consentformlist/${this.patientLoginId}`)
				})
				.catch((err) => {
					console.log(err);
				});
		},
		openModal(type, id, versionid, videoid,referenceId) {
			this.modalType = type
			this.modelvideoId = videoid
			this.versionId = versionid
			this.Id = id
			this.showModal = !this.showModal
			this.ReferenceId=referenceId
		},

		// patient vediocall
		async openInfo() {
			document.body.style.overflowY = "hidden"
			this.showPopup = !this.showPopup;
		},
		async closemodal() {
			document.body.style.overflowY = "auto"
			this.showPopup = false;
			this.date = "";
			this.time = "";
			this.dateAndTime ="";
		},
		async requestVideoCall() {
			if (this.date == "" && this.time == "") {
				alert("Please select date and time")
			}
			else if (this.date == "") {
				alert("Please select date");
			} else if (this.time == "") {
				alert("Please select time");
			}
			else {
				this.dateAndTime = this.date+"T"+this.time;
				const requestBody = {	

					toEmails: this.patientLogin.CreatedUserEmail != 
					null? [this.patientLogin.CreatedUserEmail] : ["tinub@datamatica.uk"],
					fromEmail: this.patientLogin.emailID,
					patientLoginId: this.patientLogin.id,
					time: this.convertToUTCTimeFormat(this.dateAndTime)
				};
				await axios.post(`${this.baseapi}/management/videocall/requestvideocall`, requestBody, this.header)
					.then((res) => {
						console.log("video call requested", res);
						alert("Video call requested successfully.You will be notified through email with video call joining link when your request has been accepted.");
						this.dateAndTime = "";
						this.closemodal();
					})
					.catch((err) => {
						console.log(" error in request video call", err);
					});
			}
		},
		getCurrentDateTime() {
			const now = new Date();
			const year = now.getFullYear();
			const month = String(now.getMonth() + 1).padStart(2, '0');
			const day = String(now.getDate()).padStart(2, '0');
			const hours = String(now.getHours()).padStart(2, '0');
			const minutes = String(now.getMinutes()).padStart(2, '0');

			return `${year}-${month}-${day}T${hours}:${minutes}`;
		},
		getCurrentDate() {
			const today = new Date();
			const year = today.getFullYear();
			let month = today.getMonth() + 1;
			if (month < 10) {
			  month = '0' + month;
			}
			let day = today.getDate();
			if (day < 10) {
			  day = '0' + day;
			}
			return `${year}-${month}-${day}`;
		  },
		convertToUTCTimeFormat(date) {
			const localTime = new Date(date);
			return localTime.toISOString();
		},
		
	}
}
