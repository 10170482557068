<template>
  <div ref="component" class="stat-card-white compminheight">
    <div class="stat-header-white">
      <span>{{$t("navigations.subject distribution")}}</span>
      <div class="d-flex align-items-center" v-if="!loader">
        <select
          v-model="selectedXAxis"
          id="x-axis-select"
          class="graph-select py-0"
          @change="cancelselection"
        >
          <option v-for="option in xAxisOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
        <!-- <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="undobutton()">
              <img  src="../../assets//img/undo.png" alt="Undo block">
          </div> -->
       
        <div v-if="!loader" class="expand-block px-2 mb-1  position-relative">
          <img class="cursor-pointer" @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>{{$t("navigations.data overview")}}</span>
          </div>
        </div> 
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
        <!-- <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative" @click.prevent="showMore=true">
          <img src="../../assets//img/more.png" alt="More block">
          <div class="more-expand" @click="showtable()" v-if="showMore" v-on:clickout="showMore=false">
            <span>Analyze</span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="stat-body-white bar-chart-wrapper d-flex flex-column" :class="{'h-100':loader==true}">
      <div v-if="loader" class="loader"></div>
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <svg ref="chartContainer" v-show="!loader"></svg>
      <div class="tooltip" ref="tooltip"></div>
    </div>
    <div class="legend" v-if="!loader">
      <div class="legend-item">
        <span class="legend-color enrolled"></span>
        {{ labelOne }}
      </div>
      <div class="legend-item">
        <span class="legend-color non-enrolled"></span>
        {{ labelTwo }}
      </div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,updateChart()">
    <div
      class="stat-body-white d-flex flex-column w-100">
    <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>  
      <svg ref="popupchartContainer"></svg>
      </div>
  </enlargerpopup>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"
import html2pdf from 'html2pdf.js';
import * as XLSX from "xlsx"
export default {
  name: "GroupedBarChart",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: "",
    },
    xaxiskeypassed: {
      type: String,
      default: "",
    },
    labelOne: {
      type: String,
      default: "Enrolled",
    },
    labelTwo: {
      type: String,
      default: "Non-enrolled",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    fromPage:{
      type: String,
      default: "",
    }
  },
  components:{
    enlargerpopup
  },
  data() {
    return {
      showVisitPopup: false,
      filterkeyword:"",
      filterarray:[],
      chartdata: [],
      sortdata: [],
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      expandBlock:false,
      showMore:false,
      selectedXAxis: "",
      selectedYAxis: "AIPrediction",
      xAxisOptions: [
        "AGE",
        "GENDER",
        "CHOLESTROL",
        "AVGLUCOSE",
        "CHRONICONDITION",
        "BLOODOXYGEN",
      ],
      chartContainer:null,
      yAxisOptions: ["AIPrediction"],
      bins: [], // Dynamic bins
      genderMap: { 0: "Male", 1: "Female", 2: "Other" },
      colors: { Enrolled: "#62B2FD", "Non-enrolled": "#9BDFC4" },
      selectedGroups: [], // Track selected group
      tooltip:null,
      mouseX:0,
      mouseY:0
    };
  },
  watch: {
    data: {
      handler() {
        this.chartdata = this.data;
        if (this.templateId != "" && this.templateId != null) {
          this.createmapping();
        }
        this.loadQuantitativeAttributes();
        this.createChart();
        this.updateBins();
        this.updateChart();
        this.cancelselection();
        this.filterkeyword = "";
        this.filterarray = []
      },
      deep: true,
    },
    selectedXAxis: {
      handler() {
        if (this.templateId != "" && this.templateId != null) {
          this.createmapping();
        }
        this.updateBins();
        this.updateChart();
      },
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    }
   
  },
  async beforeMount() {
    this.chartdata = this.data;
    this.tooltip=this.$refs.tooltip
    await this.loadQuantitativeAttributes();
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  async mounted() {
    if(this.xaxiskeypassed != ""){
    this.selectedXAxis = this.xaxiskeypassed;
    }
    window.addEventListener('mousemove', this.updateMousePosition)
    console.log("Values are",this.selectedXAxis)
    this.tooltip=this.$refs.tooltip
    this.idtoken = store.getters.getIdToken;
    if (this.templateId != "" && this.templateId != null) {
      await this.createmapping();
    }
    if(this.fromPage=='patientScreen')
    {
      this.selectedXAxis='AGE'
    }
    this.updateBins();
    this.createChart();
  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
        // Create a temporary container
        const pdfContainer = document.createElement('div');
  
        const captureArea1 = this.$refs.component.cloneNode(true);

        pdfContainer.appendChild(captureArea1);
        
        // Optionally style the container for PDF formatting
        pdfContainer.style.width = '100%';
  
        pdfContainer.style.display = 'block';
        // Set the options for html2pdf
        const opt = {
          margin: 0,
          filename: 'combined_chart.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
        };
      
        // Convert the temporary container to PDF
        html2pdf().set(opt).from(pdfContainer).save();
      },
    updateMousePosition(event) {
      if (this.$refs.component) {
      const rect = this.$refs.component.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    }
    },
    undobutton() {
      this.$emit("undobutton");
    },
    showtable(){
      this.$emit("opendrillpopup", this.chartdata);
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
      this.chartdata = this.data;
      this.updateBins();
      this.updateChart();
    },
    async loadQuantitativeAttributes() {
      const sampleData =
        this.chartdata &&
        this.chartdata.length > 0 &&
        this.chartdata[0].features
          ? this.chartdata[0].features
          : {};

      const quantitativeAttributes = Object.keys(sampleData).filter(
        (key) => typeof sampleData[key] === "number"
      );

      this.xAxisOptions = quantitativeAttributes;
      if(this.selectedXAxis == ""){
       
      this.selectedXAxis = quantitativeAttributes[0] || "";
      
      }
    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach((page) => {
            page.field.forEach((field) => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce(
                  (map, option) => {
                    map[option.codevalue] = option.optionname;
                    return map;
                  },
                  {}
                );
              }
            });
          });
          console.log("this.mappings", this.mappings);
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    updateBins() {
      if (!this.selectedXAxis) return;

      const values = this.chartdata
        .map((d) => d.features[this.selectedXAxis])
        .filter((v) => v !== undefined);
      const uniqueValues = Array.from(new Set(values));

      if (uniqueValues.length > 5) {
        const min = d3.min(values);
        const max = d3.max(values);
        const numBins = 5;
        const step = Math.ceil((max - min) / numBins);
        //const step = (max - min) / numBins;
        this.bins = d3.range(min, max, step).concat(max);
      } else {
        this.bins = uniqueValues;
      }
    },
    updateChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupchartContainer : this.$refs.chartContainer;
      if (!this.chartContainer) {
        console.error("SVG not initialized");
        return;
      }
      
      // Clear existing chart
      d3.select(this.chartContainer)
        .selectAll("*")
        .remove();
      // Re-create the chart with new settings
      
      this.createChart();
    },
    async createChart() {
      const margin = { top: 20, right: 30, bottom: 40, left: 40 };
      const barWidth = 40; // Fixed width for each bar
      const groupPadding = 20; // Padding between groups
      const chartHeight = 290;
      const aggregatedData = this.aggregateData(this.chartdata);

      if (aggregatedData.length === 0) {
        console.error("Aggregated data is empty");
        return;
      }

      const subCategories = ["Enrolled", "Non-enrolled"];
      const groupWidth = subCategories.length * barWidth + groupPadding;
      const totalWidth = Math.max(
        800,
        aggregatedData.length * groupWidth + margin.left + margin.right
      );
      const chartWidth = totalWidth - margin.left - margin.right;

      this.xScale0 = d3
        .scaleBand()
        .domain(aggregatedData.map((d) => d.key))
        .range([margin.left, chartWidth + margin.left])
        .padding(0.1);

      this.xScale1 = d3
        .scaleBand()
        .domain(subCategories)
        .range([0, this.xScale0.bandwidth()])
        .padding(0.05);

      this.yScale = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(aggregatedData, (d) => d3.max(d.values, (v) => v.value)) || 0,
        ])
        .nice()
        .range([chartHeight, 0]);
        this.chartContainer = this.expandBlock ? this.$refs.popupchartContainer : this.$refs.chartContainer;
      this.svg = d3
        .select(this.chartContainer)
        .attr("viewBox", `0 -20 ${totalWidth} 400`);


      const barGroups = this.svg
        .append("g")
        .attr("class", "bars")
        .selectAll("g")
        .data(aggregatedData)
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${this.xScale0(d.key)},0)`)
        .attr("style", (d) =>
          this.selectedGroups.includes(d.key) ? "opacity: 50%;" : ""
        ) 
        .on("click", (event, d) => {
          this.bargroupclicked(d);
        });

        barGroups
  .selectAll("rect")
  .data((d) => d.values)
  .enter()
  .append("rect")
  .attr("x", (d) => this.xScale1(d.category))
  .attr("y", (d) => this.yScale(d.value))
  .attr("height", (d) => this.yScale(0) - this.yScale(d.value))
  .attr("width", this.xScale1.bandwidth())
  .attr("fill", (d) => this.colors[d.category])
  .attr("stroke", "none") // Remove border on mouseover
  .on("mousemove", (event, d) => {
    const categoryLabel = d.category === "Enrolled" ? this.labelOne : this.labelTwo;
    d3.select(this.tooltip).style("opacity", 1)
      .html(`Category: ${categoryLabel}<br/>Value: ${d.value}`)
      .style("left", this.mouseX + "px")
      .style("top", this.mouseY + "px");
  })
  .on("mouseout", () => {
    d3.select(this.tooltip).style("opacity", 0);
  })
  .on("click", () => {
    d3.select(this.tooltip).style("opacity", 0);
  });

      barGroups
        .selectAll("text")
        .data((d) => d.values)
        .enter()
        .append("text")
        .attr(
          "x",
          (d) => this.xScale1(d.category) + this.xScale1.bandwidth() / 2
        )
        .attr("y", (d) => this.yScale(d.value) + (d.value > 10 ? 15 : -5)) // Adjusting the position of text inside the bar
        .attr("text-anchor", "middle")
        .attr("fill", "black")
        .style("font-size", "14px") // Increasing font size
        .text((d) => d.value);

      this.svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${chartHeight})`)
        .call(d3.axisBottom(this.xScale0))
        .selectAll("text")
        .style("text-anchor", "end")
        .style("font-size", "18px")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

      this.svg
        .append("g")
        .attr("class", "y-axis")
        .style("font-size", "14px")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(this.yScale));
    },
    bargroupclicked(passedobj) {
      const key = passedobj.key;
      
      // Toggle selection of group
      if (this.selectedGroups.includes(key)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== key);
        let result = this.sortdata.filter(item => !passedobj.totalarray.includes(item));
        this.sortdata = result;
        console.log("key",key);
        let filterarraydeleted = this.filterarray.filter(item => `(${key})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedXAxis}(${this.filterarray})`;
        console.log("passedobj is",this.filterkeyword);
      } else {
        this.selectedGroups.push(key);
        this.filterarray.push(`(${passedobj.key})`)
        this.filterkeyword = `${this.selectedXAxis}(${this.filterarray})`;
        this.sortdata = this.sortdata.concat(passedobj.totalarray);
      }

      // Filter data based on selected groups
     

      this.updateBins();
      this.updateChart();
    },
    async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  console.log("this.selectedGroups", this.selectedGroups);

  this.chartContainer = this.expandBlock ? this.$refs.popupchartContainer : this.$refs.chartContainer;
  // Clear the applied styles on the bars
  d3.select(this.chartContainer)
    .selectAll(".bars g")
    .attr("style", "");

  // Re-render the chart to reset styles
  this.updateChart();
},
    recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
    aggregateData(data) {
      // Filter the valid data
      const validData = data.filter(
        (d) =>
          d.features[this.selectedXAxis] !== undefined &&
          d.features[this.selectedYAxis] !== undefined
      );

      if (!validData.length) {
        return [];
      }

      // Group the data
      const nestedData = d3.group(
        validData,
        (d) => this.getXKey(d.features[this.selectedXAxis]),
        (d) => d.features[this.selectedYAxis]
      );

      // Aggregate the data and convert totalarray to an array of objects
      const aggregatedData = Array.from(nestedData, ([key, values]) => {
        const totalArray = [];
        values.forEach((entries, yKey) => {
          totalArray.push(...entries);
        });

        return {
          key: String(this.getMappedValue(key, this.selectedXAxis)),
          values: [
            {
              category: "Enrolled",
              value: values.get(true) ? values.get(true).length : 0,
            },
            {
              category: "Non-enrolled",
              value: values.get(false) ? values.get(false).length : 0,
            },
          ],
          totalarray: totalArray,
        };
      });

      console.log("Aggregated data:", aggregatedData[0]);

      // Sort the aggregated data
      return aggregatedData.sort((a, b) =>
        String(a.key).localeCompare(String(b.key), undefined, { numeric: true })
      );
    },
    getXKey(value) {
      if (this.bins.length > 5) {
        return this.getBin(value, this.bins);
      }
      return value;
    },
    getBin(value, bins) {
      for (let i = 0; i < bins.length - 1; i++) {
        if (value >= bins[i] && value < bins[i + 1]) {
          return `${bins[i]}-${bins[i + 1]}`;
        }
      }
      return `${bins[bins.length - 1]}+`;
    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    },
  },
};
</script>

<style scoped>
@import "../AiCharts/charts.css";
.selectedbargraph{
  opacity: 50%;
}
.bar-chart-wrapper {
  overflow-x: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar-chart {
  position: relative;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.legend-color.enrolled {
  background-color: #62b2fd;
}

.legend-color.non-enrolled {
  background-color: #9bdfc4;
}

.compminheight {
  min-height: 355px;
}


.breadcrumb {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to force single-line display */
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.breadcrumb-item {
  font-size: 16px; /* Adjust font size for readability */
  margin-right: 15px; /* More space between items */
  color: #007bff;
  text-decoration: none;
  white-space: nowrap; /* Prevent text from wrapping */
}

.breadcrumb-item:last-child {
  color: #6c757d;
  pointer-events: none;
  text-decoration: none;
}

.breadcrumb-item::after {
  content: "/"; /* Separator */
  margin-left: 5px;
  color: #6c757d;
  white-space: nowrap;
}

.breadcrumb-item:last-child::after {
  content: ""; /* Remove separator for the last item */
}
.selected {
  opacity: 50%;
}

.selectdrill{
  width:100%;
}
</style>
