 <template>
  <!-- modal starts -->
  <div class="approver_modal__wrapper1">
    <div class="approver__list__modal">
      <div class="approver__list__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.approver") }}</h4>
        <div class="cursor-pointer">
            <i class="fa fa-times" @click.prevent="closemodall()"></i>
        </div>
      </div>
      <!-- test -->
      <!-- Popup -->
      <div class="position-relative cursor-pointer align-self-center float-right mr-3 py-2" @click="openPopUp()">
        <img
          src="../../assets/img/icons/add.png"
          alt="Add approver"
        >
        <div class="tool-tip--add-approver">{{ $t("navigations.add") }}</div>
      </div>
      <div class="ibox-content">
        <div class="list-group scrollable">
          <ul
            class="sortable-list connectList agile-list ui-sortable"
            v-for="approve in approverList"
            :key="approve.id"
            id="todo"
          >
            <li
              v-if="approve.approvedStatus == 'Approved'"
              class="success-element ui-sortable-handle"
              id="task2"
            >
              <div class="media-body">
                <medium class="float-right"
                  ><span class="badge badge-primary">{{
                    approve.approvedStatus
                  }}</span>
                </medium>
                <strong>{{ approve.approvedByName }}</strong> <br />
                <small class="text-muted">{{
                  filter(approve.assignedDateTime.slice(0, 10))
                }}</small>
                <p class="approver_cmnt_text">
                  {{ approve.comments }}
                </p>
              </div>
            </li>
            <li
              v-if="approve.approvedStatus == 'Rejected'"
              class="danger-element ui-sortable-handle"
              id="task2"
            >
              <div class="media-body">
                <medium class="float-right">
                  <span class="badge badge-danger">{{
                    approve.approvedStatus
                  }}</span>
                </medium>
                <strong>{{ approve.approvedByName }}</strong> <br />
                <small class="text-muted">{{
                  filter(approve.assignedDateTime.slice(0, 10))
                }}</small>
                <p class="approver_cmnt_text">
                  {{ approve.comments }}
                </p>
              </div>
            </li>
            <li
              v-if="approve.approvedStatus == 'Pending'"
              class="warning-element ui-sortable-handle"
              id="task2"
            >
              <div class="media-body">
                <medium class="float-right">
                  <span class="badge badge-warning">{{
                    approve.approvedStatus
                  }}</span>
                </medium>
                <strong>{{ approve.approvedByName }}</strong> <br />
                <small class="text-muted">{{
                  filter(approve.assignedDateTime.slice(0, 10))
                }}</small>
                <p class="approver_cmnt_text">
                  {{ approve.comments }}
                </p>
                <div class="position-relative cursor-pointer align-self-center float-right"  @click="RemoveApprover(approve.approvedByEmail)">
                  <img
                     src="../../assets/img/delete_doc.png"
                    alt="Remove approver"
                  >
                  <div class="tool-tip">{{ $t("navigations.remove") }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
      <div
        v-if="displaySidePopup"
        class="modal-approver"
        id="myModal6"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="model-approver-title d-flex justify-content-end py-1">
          <div class="position-relative align-self-center p-2 cursor-pointer" :class="{disabled:ApproversName.length==0}" @click="saveApprover()">
            <img
              src="../../assets/img/save.png"
              alt="Save approver"
            >
            <div class="tool-tip">{{ $t("navigations.save") }}</div>
          </div>
            <div class="position-relative align-self-center p-2 cursor-pointer" @click="closeApprover()">
            <img
              src="../../assets/img/cancel.png"
              alt="Close modal"
            >
            <div class="tool-tip">{{ $t("navigations.close") }}</div>
          </div>
        </div>
        <div class="modal-approver-list1 scrollable px-4">
          <fieldset>
            <div
              class="form-check1 abc-checkbox pt-1"
              v-for="approvers in approverlistsforPopup"
              :key="approvers.email"
            >
              <input
                class="form-check1-input"
                :id="approvers.email"
                :value="approvers.email"
                type="checkbox"
                @change="fun(approvers.email)"
                :checked="ApproversName.includes(approvers.email)"
              />
              <label class="form-check1-label" for="checkbox1">
                {{ approvers.userName }}
              </label>
            </div>
          </fieldset>              
        </div>
      </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
export default {
  name: "listApprover",
  props: {
    id: {
      type: String,
      default: "",
    },
    versionId: {
      type: String,
      default: "",
    },
    versionNumber: {
      type: String,
      default: "",
    },
    
    patid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displaySidePopup: false,
      ApproversName: [],
      baseapi: process.env.VUE_APP_Service_URL,
      baseurl: process.env.VUE_APP_Service_URL,
      approverlistsforPopup: [],
      approverList: [],
      docVersionNumber: "",
      documentId: "",
      doclist: [],
      SearchData: {
        docName: "",
        FolderId: "",
        documentStorage: "",
        fromDate: "",
        toDate: "",
        pageSize: 10,
        pageNo: 1,
        
        DocName: "",
        
        DocsearchName: "",
        CreatedDate: "",
        UploadedBy: "",
        DocumentTypeId: "",
        pageNo: 1,
        pagesize: 10,
      },
      totalCount: 0,
      totalPage: 1,
      pagesize: 10,
      FolderId: "",
      pageindex: 1,
      SortProperty:"CreatedAt",
      SortOrder:1,
    };
  },
  async mounted() {
    document.body.style.overflowY="hidden"
    console.log("list in approvepopup is");
    console.log(this.id, this.versionId);
    this.defaultdate = localStorage.getItem("dateformat");
  },
  async unmounted() {
    document.body.style.overflowY="auto"
  },
  async beforeMount() {
    this.getApproverById(this.id, this.versionId);
  },

  methods: {
    async closeApprover(){
      this.displaySidePopup=!this.displaySidePopup
    },
    async closemodall() {
      this.$emit("libactive");
      this.ApproversName = [];
      this.getDocuments();
    },
    async updatemodalpop() {     
      this.$emit('listupdate')
      this.$emit('docsumm')
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
      console.log("Reverse is");
      console.log(data);
    },
    async fun(optid) {
      if (this.ApproversName.includes(optid)) {
        for (var i = this.ApproversName.length; i--; ) {
          if (this.ApproversName[i] === optid) {
            this.ApproversName.splice(i, 1);
          }
        }
      } else {
        this.ApproversName.push(optid);
      }
    },
    async saveApprover() {
      console.log("document details", this.documentId);
      const idtoken = store.getters.getIdToken;
      console.log({
        documentId: this.id,
        versionNumber: this.versionNumber,
        approveList: this.ApproversName,
      });
      await axios
        .put(
          `${this.baseurl}/management/document/addapprover`,
          {
            documentId: this.id,
            versionNumber: this.versionNumber,
            approveList: this.ApproversName,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("resulttttttttt", res.data);
          this.docapproverupdate = res.data;
          if(this.ApproversName.length==0)
          {
            return;
          }
          // alert("Approver added successfully");
           alert(this.$t('navigations.approver added successfully'))
          this.approverlistsforPopup.splice(0);
          this.ApproversName = [];
          this.displaySidePopup = false;
        })
        .catch((err) => {
          console.log("error..", err);
        });

      this.getApproverById(this.id, this.versionId);
    },
    async getApproverById(docId, versionId) {
      console.log("in get approver by id ");
      const idtoken = store.getters.getIdToken;
      console.log("heeeeee", docId);
      console.log("heeeeee", versionId);
      await axios
        .get(
          `${this.baseapi}/management/document/getdocumentapprover?documentid=${docId}&versionId=${versionId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.approverList = res.data;
          console.log("Docdownld............", this.approverList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async RemoveApprover(Email) {
      let passdata = {
        createdUserEmail: "string",
        createdUserId: "string",
        createdAt: "2022-08-10T05:59:09.233Z",
        lastModifiedUserEmail: "string",
        lastModifiedUserId: "string",
        lastModifiedAt: "2022-08-10T05:59:09.233Z",
        documentId: this.id,
        approvedByEmail: Email,
        versionNumber: this.versionNumber,
      };
      console.log("remove", Email);
      const idtoken = store.getters.getIdToken;
      console.log("removeId", idtoken);
      console.log({
        documentId: this.id,
        approvedByEmail: Email,
        versionNumber: this.versionNumber,
      });
      if (
        confirm(
          this.$t("navigations.are you sure you want to remove this approver?")
        )
      ) {
        await axios
          .delete(
            `${this.baseapi}/management/document/deleteapprover`,
            {
              data: passdata,
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.remover = res.data;
            alert(this.$t("navigations.approver removed successfully"));
            this.updatemodalpop();
            console.log("Removed............", this.remover);
            this.approverlistsforPopup.splice(0);
            this.ApproversName = [];
            this.displaySidePopup = false;
            this.getApproverById(this.id, this.versionId);
          })
          .catch((err) => {
            alert(this.$t("navigations.document approver is removed"));
            console.log(err);
          });
      }
    },
    async openPopUp(){
      this.displaySidePopup = !this.displaySidePopup;
      this.getreviewers(this.id);
    },
    async getreviewers(docId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/account-core/user/getuserfordocumentapprover?documentId=${docId}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          console.log(res);
         
          this.approverlistsforPopup = res.data;

          this.approverList.forEach((aprvr) => {
              this.approverlistsforPopup.forEach((element) => {
             
              if ( element.emailId == aprvr.approvedByEmail) {
                const index = this.approverlistsforPopup.indexOf(element);
                this.approverlistsforPopup.splice(index, 1);
              }
            });
          });
          console.log("Reviewers", this.approverlistsforPopup);
        })

        .catch((err) => {
          console.log(err);
        });
    },
    async getDocuments() {
      console.log("entererddd......")
      this.doclist = [];
      const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      if (folderId != 0) {
        this.SearchData.FolderId = folderId;
      } else {
        this.SearchData.FolderId = "";
      }
      await axios
        .get(
          ` ${this.baseurl}/management/document/listdocument?documentName=` +
            this.SearchData.DocsearchName +
            "&uploadedDate=" +
            this.SearchData.CreatedDate +
            "&uploadedBy=" +
            this.SearchData.UploadedBy +
            "&documentTypeId=" +
            storageId +
            "&folderId=" +
            this.SearchData.FolderId +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize+
           "&SortProperty=" +
           this.SortProperty+
           "&SortOrder="+
           this.SortOrder,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("resulttttttttt enteredddd......", res.data);
          this.doclist = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPage = parseInt(this.totalCount / this.SearchData.pageSize);
          if (this.totalCount % this.SearchData.pageSize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
  },
};
</script>
<style>
.approver__list__modal{
  width: min(38%,80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-x: hidden;
}
.approver__list__title{
  background-color: var(--pop-up-background);
  color:#ffffff;
  }
  .disabled{
  opacity: 0.5;
  cursor:not-allowed;
}
  .disabled .tool-tip{
    opacity:0;
  }
  .position-relative .tool-tip--add-approver {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 24px;
  top: 3px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip--add-approver {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

</style>