import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "createFormModal",
  components: {
    VPagination,
  },
  props: { studyId: String },
  data() {
    return{
        positionofnewgroup:"Before",
        moofcolumns:"1",
        baseurl: process.env.VUE_APP_Service_URL,
        createdformid:"",
        formName:"",
        idtoken:""
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    this.idtoken=store.getters.getIdToken
    document.body.style.overflow="hidden"
  },
  unmounted(){
    document.body.style.overflow="auto"
  },
  methods:{
  async createrandongroup(){
 let groupdetails={};
 groupdetails.positionofnewgroup = this.positionofnewgroup;
 groupdetails.moofcolumns = this.moofcolumns;
    this.$emit("addnewgroup", groupdetails);
  },
  
}

}