
  /* eslint-disable */
  
  export default {
    name: "videocallpopup",

    props: {
    },
    data() {
      return {

      };
    },

    async mounted() {
    },

    methods: {
      async closepopup(){
        this.$emit('opendvidpopup');
      },
    },

  };
 