<template>
    <!-- modal starts -->
    <div class="modal_wrapper">
      <div class="modal_container">
        <div class="modal_title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">Request Virtual Meeting</h4>
          <div class="tooltip-wrapper pointer" @click.prevent="closepopup()" style="float: right; cursor: pointer;">
              <i class="fa fa-times close-button-popups"></i>
              <span class="custom-tooltip-popups">Close</span>
          </div>
        </div>
        <div class="modal-body py-3">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Email">
                <span class="input-group-append">
                <button type="button" class="save_btn py-1 w-100 ml-1"><i class="fa fa-paper-plane-o px-1"></i>Send</button>
                </span>
              </div>
        </div>
      </div>
       
        </div>
  </template>
   
  <script lang="js" src="./videocall_popup.js"></script>
  <style scoped>
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
   
  .disabled .tool-tip {
    opacity: 0;
  }
   
  .position-relative .tool-tip--add-approver {
    visibility: hidden;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    right: 24px;
    top: 3px;
    font-size: 0.8rem;
    position: absolute;
    z-index: 1;
    -webkit-transition: visibility 75ms ease-in;
    transition: visibility 75ms ease-in;
  }
   
  .position-relative:hover .tool-tip--add-approver {
    visibility: visible;
    -webkit-transition: visibility 30ms ease-in;
    transition: visibility 30ms ease-in;
  }
   
  .scrollable {
    max-height: 400px;
    overflow-y: auto;
  }
   
  .table th,
  .table td {
    vertical-align: middle;
    text-align: center;
  }
   
  .thead-dark th {
    background-color: #343a40;
    color: white;
  }
   
  .totaltabs {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
   
  .subtab {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-bottom: none;
    background-color: #f9f9f9;
  }
   
  .subtab.activesubtab {
    background-color: #fff;
    border-bottom: 1px solid #fff;
  }
   
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
   
  .chart-container {
    width: 100%;
    height: 400px;
  }
  </style>