import axios from "axios";
import store from "../../store/index";
export default{
    name: "patientenrolment",
    components: {

    },
    props: {
        patientLoginId:{
            type:String,
            default:""
            }
    },
    data(){
        return {
            errors:[],
            validations: [false, false, false, false, false, false, false, false],
            baseurl: process.env.VUE_APP_Service_URL,
            values: {
              emailIdPrimary: "",
              password: "",
              firstName: "",
              lastName:"",
              timeZone:"",
              preferredLanguage:"",         
              patientLoginId: this.patientLoginId,
            },
            newpassword:"",       
            inputType: "password",
            subId:"",
            visibleOn: false,
            patient:"",
            patientmail:""
        }
    }, 
    async mounted(){
        await this.isPatientExist();
        await this.patientDetils();
        store.dispatch("setNavbar",false);
        store.dispatch("setPatientNavbar",true);
        document.querySelector("#page-wrapper").style.width="100%"
        this.subId = this.patientLoginId
    },
    methods: {
        async isPatientExist() {      
            const idtoken = store.getters.getIdToken;    
            await axios
              .get(
                //`https://localhost:5001/user/ispatientexist?patientLoginId=${this.patientLoginId}`,
                `${this.baseurl}/account-core/user/ispatientexist?patientLoginId=${this.patientLoginId}`,         
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {                     
                this.patient = res.data;
                if(this.patient == "User exist")
                {
                  this.$router.push(`/subjectlogin/${this.patientLoginId}`);
                }
                console.log("patient is",this.patient )
              })
              .catch((err) => {
                console.log(err);
              });
        },
        async patientDetils() {      
            const idtoken = store.getters.getIdToken;   
            if(this.patient == "No user found") {
              await axios
              .get(
                `${this.baseurl}/account-core/user/getencryptedpatientinfomail?patientLoginId=${this.patientLoginId}`,         
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {                     
                this.patientmail = res.data;
                this.values.emailIdPrimary = this.patientmail;
                console.log("patient detail is", this.patientmail)
              })
              .catch((err) => {
                console.log(err);
              });
            }
            else if(this.patient == "Online registered"){
              await axios
                      .get(
                          `${this.baseurl}/account-core/user/getencryptedpatientinfo?patientLoginId=${this.patientLoginId}`,
                          {
                              headers: {
                                  Authorization: "Bearer " + idtoken,
                                  "Content-Type": "application/json",
                              },
                          }
                      )
                      .then((res) => {
                          this.patientmail = res.data.emailID;
                          this.setValues(res.data);
                          console.log("patient is", this.values)
                      })
                      .catch((err) => {
                          console.log(err);
                      });
      
            }
           
        },
        async setValues(data){
            this.values = {
              emailIdPrimary: data.emailID ,
              password: "",
              firstName: data.firstName,
              lastName: data.lastName,
              timeZone: data.timeZone,
              preferredLanguage: data.preferedLanguage,         
              patientLoginId: this.patientLoginId,
            }
        },
        async addSubject() {
            console.log("in create", this.values)  
            const idtoken = store.getters.getIdToken;    
            await axios
              .post(
                `${this.baseurl}/account-core/user/createsubject`,
                this.values,
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {           
                store.dispatch("loginuser", res.data);
                const idtoken = store.getters.getIdToken;
                console.log("tokenis",idtoken)
                alert("User created successfully.")
                this.$router.push(`/trainingMaterials/${this.patientLoginId}`);
              })
              .catch((err) => {
                alert("The user already exists");
              });
        },
        async continueClicked() {
            await this.validate();
            await this.validateallpassword();
            console.log("validddddssssss",this.validations)
            if (this.validations.includes(false)) {
              this.errors = this.validations;
            } else {
              this.addSubject();
            }
        },
        async validate() {
            this.values.firstName = this.values.firstName.replace(/ +/g, ' ').trim();
            this.values.lastName = this.values.lastName.replace(/ +/g, ' ').trim();
            this.values.emailIdPrimary= this.values.emailIdPrimary.replace(/ +/g, ' ').trim();     
            this.Validatename(this.values.firstName, 0);
            this.Validatename(this.values.lastName, 1);
            this.ValidatefirstEmail(this.values.emailIdPrimary);     
        },
        async Validatename(name, errindex) {
            console.log("firstnameeeeeeeee",this.values.firstName)
            let letters = /^[A-Za-z\s]*$/;
            if (name.trim() == "") {
              console.log("condition1")
              this.validations[errindex] = false;
      
            }
            else if (!letters.test(name)) {
              console.log("condition2")
              this.validations[errindex] = true;
      
            }
            else {
              console.log("condition3")
              this.validations[errindex] = true;
            }
        },
        async ValidatefirstEmail(mail) {
            if(mail == "")
            {
              this.validations[2] = false;
              this.validations[7] = true;
            }
            else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
              this.validations[7] = true;
              this.validations[2] = true;       
            }
            else {
              this.validations[7] = false;
              this.validations[2] = true;
            }
        },
        async passwordVisible() {
            this.visibleOn = !this.visibleOn;
            if (this.visibleOn == true) {
              this.inputType = "text";
            } else {
              this.inputType = "password";
            }
        },
        async validateallpassword() {
            this.Validatepassword(this.values.password);
            this.Validateconfirmpassword(this.newpassword);
            this.Validatematchpasswords(
              this.newpassword,
              this.values.password
            );
        },  
        async Validatepassword(passwd) {
            
            let reNum = /[0-9]/;
            let reLow = /[a-z]/;
            let reUpp = /[A-Z]/;
            let spcl = /(?=.*[!@#$%^&*])/;
            if (passwd == "" || passwd == null) {
              this.validations[6] = true;
              this.validations[3] = false;
            } else if (
              passwd.length < 8 &&
              !reLow.test(passwd) ||
              !reNum.test(passwd) ||
              !spcl.test(passwd) ||
              !reUpp.test(passwd)
            ) {
              console.log("check",passwd )
              this.validations[3] = true;
      
              this.validations[6] = false;
            } else {
              this.validations[3] = true;
              this.validations[6] = true;
            }
        },    
        async Validateconfirmpassword(passwd) {
            
            if (passwd == "" || passwd == "" || passwd == null) {
              this.validations[4] = false;
            } 
            else {
              this.validations[4] = true;
            }
        }, 
        async Validatematchpasswords(passwd, confirmpaswd) {
            if (
              this.validations[3] == true &&
              this.validations[4] == true &&
              this.validations[6] == true
            ) {
              console.log("validate paswds");
              if (passwd == confirmpaswd) {
                this.validations[5] = true;
                console.log(" paswds ok");
              } else {
                this.validations[5] = false;
                console.log("paswds mismatch");
              }
            } else {
              console.log("final condtn paswds");
              this.validations[5] = true;
            }
        },   
    }
}