<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white">
      <span>{{ computedChartLabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <select
          class="graph-select py-0"
          v-model="selectedXAxis"
          id="attribute-select"
          @change="createHistogram"
        >
          <option
            v-for="option in attributeOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>Data Overview</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white d-flex flex-column" :class="{'h-100': loader}">
      <div v-if="loader" class="loader"></div>
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div v-show="!loader" class="histogram-container">
        
        <div class="histogram-content">
          <svg ref="chartContainer"></svg>
        </div>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,createHistogram()">
    <div
      class="stat-body-white d-flex flex-column col-md-12">
    <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div v-show="!loader" class="histogram-container">
    <div class="histogram-content">
      <svg ref="popupChartContainer"></svg>
    </div>
  </div>
    </div>
</enlargerpopup>
</template>
<script>
import * as d3 from 'd3';
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: 'Histogram',
  components:{
    enlargerpopup
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    xAxisKey: {
      type: String,
      default: 'TRTDUR',
    },
    yAxisKey: {
      type: String,
      default: 'AEYN',
    },
    xAxislabel: {
      type: String,
      default: 'Treatment Duration',
    },
    yAxislabel: {
      type: String,
      default: 'No of Adverse Events',
    },
    chartlabel: {
      type: String,
      default: "Histogram",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    graphwidth: {
      type: Number,
      default: 1200,
    },
    graphheight: {
      type: Number,
      default: 400,
    },
    tableData:{
      type: Array,
      required: true,
      default: () => [],
    }
  },
  computed: {
  computedChartLabel() {
    return this.chartlabel.replace("$$", this.selectedXAxis || "");
  },
},
  data() {
    return {
      showVisitPopup: false,
      expandBlock:false,
      attributeOptions: [],
      selectedXAxis: this.xAxisKey,
      selectedYAxis: this.yAxisKey,
      chartContainer: null,
      tooltip: null,
      resizeObserver: null,
      barselectedarray:[],
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      sorteddata:[],
      chartdata:[],
      mouseX: 0,
      mouseY: 0,
    };
  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      let filename = this.computedChartLabel || "chart_data";
      const opt = {
        margin: 0,
        filename: `${filename}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      if(this.$refs.chartContainer)
      {
        const rect = this.$refs.chartContainer.getBoundingClientRect();

        // Calculate the mouse X and Y positions relative to the component
        this.mouseX = event.clientX - rect.left;
        this.mouseY = event.clientY - rect.top;
      }
      else{
        return
      }
    
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createHistogram();
},
    createHistogram() {
  this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
      this.chartdata=this.data
  // Ensure the chart container is defined and accessible
  if (!this.chartContainer || !this.tooltip) return;

  // Clear any existing content
  d3.select(this.chartContainer).selectAll('*').remove();

  const margin = { top: 30, right: 20, bottom: 70, left: 50 }; // Fixed margins
  const fixedBarWidth = 40; // Fixed width for each bar
  const gapBetweenBars = 10; // Gap between bars
  const dataCount = new Set(this.data.map(d => d[this.selectedXAxis] !== null ? d[this.selectedXAxis] : 'null')).size; // Unique bars including "null"

  // Calculate the total width based on the number of bars, the width of each bar, and the gap between them
  const totalBarsWidth = (dataCount * fixedBarWidth) + ((dataCount - 1) * gapBetweenBars); // Total width of all bars and gaps combined
  const svgWidth = totalBarsWidth + margin.left + margin.right; // SVG width includes the total width of bars and margins

  const height = this.graphheight - margin.top - margin.bottom; // Chart height

  // Append the SVG and a group element with the calculated width
  const svg = d3
    .select(this.chartContainer)
    .attr('width', svgWidth) // Set the total width dynamically
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);
    //chacking if adverse events are true
    if (this.selectedYAxis == 'AEYN') {
    this.sorteddata = this.data.filter(d => d[this.yAxisKey] === "Yes");
    }
    else{
      this.sorteddata = this.data;
    }

  let groupedData;

  // Ensure the data has the property we're looking for
  if (this.sorteddata.length === 0 || !(this.selectedXAxis in this.sorteddata[0])) {
    console.error(`The selected X-axis key "${this.selectedXAxis}" does not exist in the data.`);
    return;
  }

  // Group data by string or number values
  if (typeof this.sorteddata[0][this.selectedXAxis] === 'string') {
    groupedData = d3.rollups(
      this.sorteddata,
      v => v.length, // Count occurrences
      d => d[this.selectedXAxis] !== null ? d[this.selectedXAxis] : 'null' // Replace null with "null"
    );
  } else if (this.selectedYAxis == 'AEYN') {
    groupedData = d3.rollups(
      this.sorteddata,
      v => v.filter(d => d[this.yAxisKey] === "Yes").length,
      d => d[this.selectedXAxis] !== null ? d[this.selectedXAxis] : 'Null' // Replace null with "null"
    );
  } 
  else {
    groupedData = d3.rollups(
      this.sorteddata,
      v => v.length,
      d => d[this.selectedXAxis] !== null ? d[this.selectedXAxis] : 'null' // Replace null with "null"
    )
    console.log(v=>v.length)
  }

  // Sort the grouped data by X-axis value
  groupedData.sort((a, b) => a[0] - b[0]);

  // Define the X-axis scale with a fixed bar width and gap between bars
  const x = d3
    .scaleBand()
    .domain(groupedData.map(d => d[0]))
    .range([0, totalBarsWidth]) // Set range based on the total width of the bars and gaps
    .padding(0.2);
    // No extra padding between bars

  // Define the Y-axis scale
  const y = d3
    .scaleLinear()
    .domain([0, d3.max(groupedData, d => d[1]) || 0])
    .nice()
    .range([height, 0]);

  // Add Y-axis
  svg.append('g').call(d3.axisLeft(y));

    // Add Y-axis label
    svg.append('text')
    .attr('text-anchor', 'middle')
    .attr('transform', 'rotate(-90)')
    .attr('x', -height / 2)
    .attr('y', -margin.left + 10)
    .text(this.yAxislabel);

  // Manually append the X-axis line (for the horizontal line beneath the bars)
  svg.append('line')
    .attr('x1', 0)
    .attr('x2', totalBarsWidth)
    .attr('y1', height) // Position at the bottom of the chart
    .attr('y2', height) // Position at the bottom of the chart
    .attr('stroke', 'black') // X-axis line color
    .attr('stroke-width', 1); // X-axis line thickness

  // Add bars with fixed width and gap
  svg.selectAll('rect')
    .data(groupedData)
    .enter()
    .append('rect')
    .attr('x', (d) => x(d[0]) || 0)
    .attr('y', d => y(d[1]))
    .attr('width', x.bandwidth()) // Fixed width for each bar
    .attr('height', d => height - y(d[1]))
    .attr('fill', '#0dcaf0') // Bar color
    .attr("style", (d) =>
          this.selectedGroups.length!=0?(this.selectedGroups.includes(d[0]) ? "" : "opacity: 50%;"):""
        )
    .on('mouseover', (event, d) => {
      d3.select(this.tooltip)
        .style('opacity', 1)
        .html(`Count: ${d[1]}`)
        .style('left', this.mouseX + "px")
        .style('top',this.mouseY + "px");
    })
    .on('mouseout', () => {
      d3.select(this.tooltip).style('opacity', 0);
    })
    .on('mousemove', event => {
      d3.select(this.tooltip)
      .style("left", this.mouseX + "px")
      .style("top",  this.mouseY + "px");
    })
    .on('click', (event, d) => {
      const filteredData = this.data.filter(item => item[this.selectedXAxis] === d[0] || (item[this.selectedXAxis] === null && d[0] === 'null'));
      // this.drilldowndata(`${this.selectedXAxis}=${d[0]}`, filteredData);
      this.clickbarinchart(filteredData, d[0]);
    });

  // Add the X-axis
  svg.append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(d3.axisBottom(x))
    .selectAll("text") // Select all text labels
    .text(function(d) {
        // Handle null or empty string cases, and truncate the label if needed
        const label = d === null || d === '' ? 'Null' : d;
        return label.length > 13 ? `${label.slice(0, 10)}...` : label;
      })
    
    .style("text-anchor", "end") // Set the anchor to the end for better readability
    .attr("dx", "-.8em") // Shift label horizontally
    .attr("dy", ".15em") // Shift label vertically
    .attr("transform", "rotate(-45)"); // Rotate the text 45 degrees

  // Add bar labels
  svg.selectAll('text.bar-label')
    .data(groupedData)
    .enter()
    .append('text')
    .attr('class', 'bar-label')
    .attr('x', (d) => (x(d[0]) || 0) + x.bandwidth() / 2) // Center label at each bar
    .attr('y', d => y(d[1]) - 5)
    .attr('text-anchor', 'middle')
    .text(d => d[1]);
},
clickbarinchart(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createHistogram();
  },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
    drilldowndata(filteredkeyword, filteredData) {
      const dataToEmit = {
        sortedData: filteredData,
        filterkeyword: filteredkeyword,
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
    extractAttributeOptions() {
      if (this.data.length > 0) {
        this.attributeOptions = Object.keys(this.data[0]).filter((key)=>
          key!='_id' && key!='STUDYID' && key!='USUBJID'
        );
      }
    },
    showtable(){
      this.showMore=false
      this.$emit("opendrillpopup", this.chartdata);
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    this.chartContainer = this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.extractAttributeOptions();
    this.createHistogram();
    
    // Add resize observer to make SVG responsive
    this.resizeObserver = new ResizeObserver(() => this.createHistogram());
    this.resizeObserver.observe(this.$refs.chartContainer);
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.extractAttributeOptions();
        this.cancelselection();
        this.createHistogram();
      },
    },
    selectedXAxis() {
      this.cancelselection();
      this.createHistogram();
    },
    selectedYAxis() {
      this.createHistogram();
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createHistogram();
        });
      }
    }
  },
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";
.histogram-container {
  width: 100%;
  overflow-x: auto; /* This enables the horizontal scrollbar */
  position: relative;
  overflow-y: hidden;
}

.histogram-content {
  width: 2000px; /* Adjust this width as needed to ensure the content fits within the scrollable area */
}

svg {
  font-family: sans-serif;
  font-size: 10px;
}

text.bar-label {
  fill: black;
  font-size: 12px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  
}
.histogram-container {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  position: relative;
  overflow-y: hidden;
}

.histogram-content {
  width: auto; /* Adjust this width to fit the content */
}

</style>
