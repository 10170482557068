import axios from "axios";
import AgoraRTC from "agora-rtc-sdk-ng";
import { markRaw } from "vue";
import store from "../../store/index";
export default {
    name: "videocall",
    props: {
        userId: String,
    },
    data() {
        return {
            gridItems: [],
            client: null,
            localtracks: [null, null],
            APP_ID: "",
            CHANNEL: "",
            TOKEN: "",
            USER_ID: "",
            localUserJoined: false,
            localUserAudioEnabled: true,
            localUserVideoEnabled: true,

            AudioWasPublished: false,
            VideoWasPublished: false,

            baseurl: process.env.VUE_APP_Service_URL,
            idtoken: store.getters.getIdToken,
            headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
            },
        };
    },
    async beforeMount(){
          document.querySelector("#page-wrapper").style.width="100%"
        },
    async mounted() {

        store.dispatch("setNavbar", false)
        await this.getCurrentUserInfo();
        await this.initializeAgora();
    },
    methods: {
        async getCurrentUserInfo() {
            console.log("id ", this.userId);
            await axios
                .get(
                    `${this.baseurl}/management/videocall/joinvideocall?uniqueUserId=${this.userId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        const { agoraUserId, agoraToken, appId, channel } = response.data;
                        this.APP_ID = appId;
                        this.CHANNEL = channel;
                        this.TOKEN = agoraToken;
                        this.USER_ID = agoraUserId;
                    }
                })
                .catch(() => {
                    alert("Join Failed");
                });
        },
        async initializeAgora() {
            AgoraRTC.setLogLevel(5);
            this.client = markRaw(
                AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
            );
        },

        async aRemoteUserJoined(user, mediaType) {
            await this.client.subscribe(user, mediaType);
            if (mediaType === "audio") {
                user.audioTrack.play();
            }
            if (mediaType === "video") {

                if (!this.gridItems.includes(String(user.uid)))
                    this.gridItems.push(String(user.uid));
                this.$nextTick(() => {

                    user.videoTrack.play(String(user.uid));
                });
            }
        },
        async aRemoteUserLeft(user, mediaType) {
            if (mediaType === "audio" && user.audioTrack) {
                user.audioTrack.stop();  // Stop the audio track if it exists
            }

            if (mediaType === "video" && user.videoTrack) {
                user.videoTrack.stop();  // Stop the video track if it exists
                const participantId = String(user.uid);
                this.gridItems = this.gridItems.filter(id => id !== participantId);  // Remove participant from grid
            }
        },
        async joinCall() {
            try {
                const res = await this.client.join(
                    this.APP_ID,
                    this.CHANNEL,
                    this.TOKEN,
                    parseInt(this.USER_ID, 10)
                );
                console.log(res);
                this.client.on("user-published", this.aRemoteUserJoined.bind(this));
                this.client.on("user-left", this.aRemoteUserLeft.bind(this));


                if (!this.gridItems.includes(this.USER_ID))
                    this.gridItems.push(this.USER_ID);

                this.$nextTick(async () => {
                    if (this.localUserVideoEnabled) {
                        await this.CreateVideoTrack(this.USER_ID);
                        await this.PublishVideo();
                        this.VideoWasPublished = true;
                    }
                    if (this.localUserAudioEnabled) {
                        await this.CreateAudioTrack();
                        await this.PublishAudio();
                        this.AudioWasPublished = true;
                    }
                });
                this.localUserJoined = true;
            } catch (error) {
                console.error("Error joining call:", error);
            }
        },


        async CreateAudioTrack() {
            try {
                this.localtracks[0] = await AgoraRTC.createMicrophoneAudioTrack();
                console.log("Audio track created");
            } catch (error) {
                console.error("Error creating audio track:", error);
            }
        },

        async CreateVideoTrack(divId) {
            try {
                this.localtracks[1] = await AgoraRTC.createCameraVideoTrack();
                this.localtracks[1].play(divId);
                console.log("Video track created and playing in div:", divId);
            } catch (error) {
                console.error("Error creating video track:", error);
            }
        },


        async PublishAudio() {
            try {
                await this.client.publish([this.localtracks[0]]);
                console.log("Audio published");
            } catch (error) {
                console.error("Error publishing audio:", error);
            }
        },
        async PublishVideo() {
            try {
                await this.client.publish([this.localtracks[1]]);
                console.log("Video published");
            } catch (error) {
                console.error("Error publishing video:", error);
            }
        },






        muteVideo() {
            if (this.localUserVideoEnabled) {
                if (this.localtracks[1]) { // Check for video track
                    this.localtracks[1].setEnabled(false);
                }
                this.localUserVideoEnabled = false; // Update state
            }
        },

        async unMuteVideo() {
            if (!this.localUserVideoEnabled) {
                if (this.localUserJoined)
                    if (!this.VideoWasPublished) {
                        await this.CreateVideoTrack(this.USER_ID);
                        await this.PublishVideo();
                        this.VideoWasPublished = true;
                    }

                if (this.localUserJoined)
                    if (this.localtracks[1]) {
                        this.localtracks[1].setEnabled(true);
                    }
                this.localUserVideoEnabled = true;
            }
        },

        muteAudio() {
            if (this.localUserAudioEnabled) {
                if (this.localtracks[0]) { 
                    this.localtracks[0].setEnabled(false);
                }
                this.localUserAudioEnabled = false; 
            }
        },

        async unMuteAudio() {
            if (!this.localUserAudioEnabled) {

                if (this.localUserJoined)
                    if (!this.AudioWasPublished) {
                        await this.CreateAudioTrack();
                        await this.PublishAudio();
                        this.AudioWasPublished = true;
                    }


                if (this.localUserJoined)
                    if (this.localtracks[0]) { 
                        this.localtracks[0].setEnabled(true);
                    }
                this.localUserAudioEnabled = true; 
            }
        },

        leaveCall() {
            if (this.localUserJoined) {
                if (this.localtracks[1]) {
                    this.localtracks[1].stop();
                    this.localtracks[1].close();
                    this.localtracks[1] = null;
                }
                if (this.localtracks[0]) {
                    this.localtracks[0].stop();
                    this.localtracks[0].close();
                    this.localtracks[0] = null;
                }
                this.gridItems = [];
                if (this.client)
                    this.client.leave();
                this.localUserJoined = false;
                this.AudioWasPublished = false;
                this.VideoWasPublished = false;
            }
        },
        async startRecording() {
            alert("Not Implemented");
        },
        async shareScreen() {
            alert("Not Implemented");
        },
    },








    computed: {
        gridColumns() {
            const count = this.gridItems.length;
            if (count === 1) return 1;
            if (count === 2) return 2;
            if (count <= 4) return 2;
            return 3;
        },
        gridRows() {
            const count = this.gridItems.length;
            if (count === 1) return 1;
            if (count === 2) return 1;
            if (count <= 4) return 2;
            return Math.ceil(count / 3);
        },
    },
};