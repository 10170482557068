<!-- Study components Visits -->
<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.visits") }} </h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 py-2 px-3">
      <div class="form-group mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="col-12 col-lg-5 my-1 my-lg-0 px-1 pl-lg-0">
            <label class="mb-0"> {{ $t("navigations.visit no") }} </label>
            <div class="form-group mb-0">
              <input
                type="text"
                v-model="searchData.visitNo"
                class="form-control my-lg-0"
              />
            </div>
          </div>
          <div class="col-12 col-lg-5 my-1 my-lg-0 px-1">
            <label class="mb-0"> {{ $t("navigations.visit name") }} </label>
            <div class="form-group mb-0">
              <input
                type="text"
                v-model="searchData.FormName"
                class="form-control my-lg-0"
              />
            </div>
          </div>
          <div class="col-12 col-lg-2 my-1 my-lg-0 align-self-end px-0">
            <span type="button" class="save_btn w-100" @click="searchbtn()">
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>
      <!-- <h5 class="linkstyle hoverstyle createLink" @click="addvisit(0)"> Add Visit </h5> -->
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.visit list") }}</h5>
        <div
        v-if="visitList.length!=0"
          class="position-relative cursor-pointer"
          @click.prevent="showFilter = !showFilter"
        >
          <img
            src="../../assets/img/filter.png"
            width="17"
            height="17"
            alt="filter table"
          />
          <div class="tool-tip">{{ $t("navigations.filter") }}</div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
          <div class="col-3 px-0" v-on:clickout="dropdownOpen = false">
            <div
              class="table-multi-select-dropdown"
              @click.stop="dropdownOpen = !dropdownOpen"
            >
              <div class="selected-options">
                <span>Select Columns</span>
              </div>
              <div class="dropdown-arrow"></div>
            </div>
            <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
              <label v-for="(column, index) in getColumns" :key="index">
                <input
                  type="checkbox"
                  v-model="selectedcolumnobj[column]"
                  :value="true"
                />
                {{ column }}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center" v-if="visitList.length===0">
          No data available
        </div>
        <div v-else class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <!-- <th>Study Ref No. </th> -->
                <th v-if="selectedcolumnobj['Visit No.']">
                  {{ $t("navigations.visit no") }}
                </th>
                <th
                  v-if="selectedcolumnobj['Visit Name']"
                  class="sort_block"
                  @click="SortSelected('visitFormName', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit name") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  v-if="selectedcolumnobj['Visit Type']"
                  class="sort_block"
                  @click="SortSelected('visitType', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit type") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  v-if="selectedcolumnobj['Pre-Visit']"
                  class="sort_block"
                  @click="SortSelected('preVisit', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.pre-visit") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  v-if="selectedcolumnobj['Post-Visit']"
                  class="sort_block"
                  @click="SortSelected('postVisit', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.post-visit") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Visit Period(Days)']">
                  {{ $t("navigations.visit period days") }}
                </th>
                <th v-if="selectedcolumnobj['Visit Period(Weeks)']">
                  {{ $t("navigations.visit period weeks") }}
                </th>
                <th v-if="selectedcolumnobj['Visit Period(Months)']">
                  {{ $t("navigations.visit period months") }}
                </th>
                <th v-if="selectedcolumnobj['Visit Period(Year)']">
                  {{ $t("navigations.visit period year") }}
                </th>
                <th
                  class="text-center"
                  v-if="roleprivileges.includes('Visit Assign Template')"
                >
                  {{ $t("navigations.assign form") }}
                </th>
                <th
                  class="text-center"
                  v-if="roleprivileges.includes('Visit View')"
                >
                  {{ $t("navigations.forms") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="visit in visitList" :key="visit.visitId">
                <td v-if="selectedcolumnobj['Visit No.']">
                  {{ visit.visitNo }}
                </td>
                <td v-if="selectedcolumnobj['Visit Name']">
                  {{ visit.visitFormName }}
                </td>
                <td v-if="selectedcolumnobj['Visit Type']">
                  {{ visit.visitType }}
                </td>
                <td v-if="selectedcolumnobj['Pre-Visit']">
                  {{ visit.preVisit }}
                </td>
                <td v-if="selectedcolumnobj['Post-Visit']">
                  {{ visit.postVisit }}
                </td>
                <td v-if="selectedcolumnobj['Visit Period(Days)']">
                  {{ visit.visitDayPeriod }}
                </td>
                <td v-if="selectedcolumnobj['Visit Period(Weeks)']">
                  {{ visit.visitWeekPeriod }}
                </td>
                <td v-if="selectedcolumnobj['Visit Period(Months)']">
                  {{ visit.visitMonthPeriod }}
                </td>
                <td v-if="selectedcolumnobj['Visit Period(Year)']">
                  {{ visit.visitYearPeriod }}
                </td>
                <!-- <td><a class="editicon" @click="addvisit(visit.visitId)"><i class="fa fa-edit edit-delete"></i></a></td>
                <td><a class="editicon" @click="deleted(visit.visitId)"><i class="fa fa-trash edit-delete"></i></a></td> -->
                <td
                  v-if="roleprivileges.includes('Visit Assign Template')"
                  class="text-center"
                >
                  <i
                    class="fa fa-external-link-square edit-delete"
                    @click.prevent="openModal(visit.visitId)"
                  ></i>
                </td>
                <td
                  v-if="roleprivileges.includes('Visit View')"
                  class="text-center"
                >
                  <i
                    v-if="
                      roleprivileges.includes('Visit View')
                    "
                    class="fa fa-file-text edit-delete"
                    @click.prevent="openFormsModal(visit.visitId)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-3 mx-0" v-if="visitList.length!=0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="dataTables_paginate paging_simple_numbers pagination float-right"
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPageNumber"
                  :pages="totalPages"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal starts -->
  <div class="modal-wrapper-template" v-if="openModalWrapper == true">
    <div class="modal-container-template">
      <div class="modal-title d-flex justify-content-between align-items-center">
        <h4 class="my-0">{{ $t("navigations.assign form") }}</h4>
        <div class="tooltip-wrapper pointer" @click.prevent="closeModal" style="float: right; cursor: pointer;">
          <i class="fa fa-times close-button-popups"></i>
          <span class="custom-tooltip-popups">Close</span>
        </div>
      </div>
      <div class="modal-template-body">
        <!-- test -->
        <div class="form-group row my-lg-2">
          <div class="d-flex justify-content-center">
            <div class="col-lg-10">
              <Multiselect
                v-model="selectedTemplateIds"
                mode="multiple"
                valueProp="templateId"
                placeholder="Select Form"
                label="templateName"
                :options="templatelist"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-template-theme mr-0"
              >
              <template v-slot:option="{ option }">
                {{ option.templateName }} ( {{ option.version }})
              </template>
              </Multiselect>
            </div>
            <div class="col-lg-2 px-0">
                  <button
                    class="save_btn py-1 w-100"
                    @click.prevent="assignFun()"
                  >
                    {{ $t("navigations.assign") }}
                  </button>
            </div>
          </div>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- modal ends -->
  <div class="modal-wrapper-forms">
    <div class="modal-container">
      <div class="titleclass">
        <h4 class="mx-2 mb-0 mt-1">{{ $t("navigations.forms") }}</h4>
        <div class="closebutton tooltip-wrapper pointer mt-1" style="float: right; cursor: pointer;">
          <a style="color: white" class="close-link">
            <i class="fa fa-times close-button-popups"              
              @click.prevent="closeModalForms"></i>
            <span class="custom-tooltip-popups">Close</span>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <!-- test -->
        <div>
          <div class="form-group row my-lg-2">
            <div class="d-flex flex-column flex-lg-row mb-lg-0">
              <div class="col-lg-12">
                <table class="table features-table mb-0">
                  <p v-if="formItems == ''" class="mb-0 text-center">
                   {{ $t("navigations.no forms added") }}
                  </p>
                  <tbody>
                    <tr v-for="forms in formItems" :key="forms.id">
                      <td>{{ forms.templateName }}</td>
                      <td class="text-center">
                        <i
                          class="fa fa-eye edit-delete"
                          @click.prevent="viewForm(forms.template_ID)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
</template>
<script src="./visitComponent.js"></script>
<style scoped>
@import "./modal.css";
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.form-group {
  margin-bottom: 0rem;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.wrapper-content {
  position: relative;
}
.features-table thead th {
  width: 60%;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}

.modal-container {
  background: #fff;
  width: min(30%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}

.headingunderline {
  text-decoration-line: underline;
}
.multiselect-template-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-radius: 0;
  --ms-line-height: 0.8;
  --ms-option-font-size: 0.8rem;
  --ms-font-size: 0.8rem;
}
</style>