/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import VPagination from "@hennge/vue3-pagination";
import Chart from "chart.js";
import moment from "moment";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { getDefaultCompilerOptions } from "typescript";
export default {
    name: "googlefitscreen",
    components: {
        VPagination,
      },
    data(){
        return{
            baseUrl: `${process.env.VUE_APP_Service_URL}/management/`,
            idtoken:store.getters.getIdToken,
            email:"",
            searchEmail:"veenasvijayan04@gmail.com",
            defaultdate:"",
            defaultTime: "",
            searchFilterOptions:"Calories",
            horizontalGraphXaxis:"",
            horizontalGraphYaxis:"",
            barGraphYaxis:"",
            barGraphXaxis:"",
            sndcharts:"",
            pageIndex:1,
            pageSize:10,
            currentPage:1,
            totalPages:1,            
            dataList:[],
            emailSet:[],
            calorieSet:[],
            dates:[],
            filterOptions:[],
            sndoptions: {
              scales: {
                         y: {
                           beginAtZero: true
                            }
                      }
                 },
              options: {
                  scales: {
                            y: {
                              beginAtZero: true
                                }
                          }
             },

            // Chart section starts
            vitalspiechartoptions: {
              chart: {
                width: '100%',
                type: 'pie',
              },
              labels: [],
              responsive: [{
                breakpoint: 480,
                options: {
                  pie: {
                    size: 200
                  },
                  chart: {
                    toolbar: {
                        show: false
                      },
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
            vitalschart: {
              charttype: "bar",
              chartOptions: {
                stroke: {
                    width: 2,
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    id: "vuechart-example",
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                    },
                },
                xaxis: {
                    labels: {
                      rotate: -45
                    },
                    categories: [],
                    title: {
                      // text: "Dates",
                      offsetX: 0,
                      offsetY: 0,
                      style: {
                          color: undefined,
                          fontSize: '12px',
                          fontFamily: 'Roboto, sans-serif',
                          fontWeight: 600,
                          cssClass: 'apexcharts-xaxis-title',
                      },
                  },
                },
                yaxis:{
                  title: {
                    // text: this.searchFilterOptions,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-title',
                    }
                  },
                  tickAmount:5,
                }
                
              },
              series: [
                  {
                    name:this.searchFilterOptions,
                  data: [],
                 },
                ],
                piedataseries: [2, 3, 2, 1]
            },  
            doublebarchart: {
              charttype: 'bar',
              chartOptions: {
                stroke: {
                    width: 2
                        },
                  chart: {
                    height: '100%',
                    width: '100%',
                    id: 'firstchart-example',
                  },
                  dataLabels: {
                  enabled: false,
                },
                  plotOptions: {
                      bar: {
                        horizontal: false,
                      }
                    },
                  xaxis: {
                    categories: [],
                  },
                },
              series: [{
                data: [0, 0, 0, 0 ]
              }],
              dataseries: [],
            },      
          }
            
    },
    async mounted(){
        console.clear()
        this.defaultdate = localStorage.getItem("dateformat");
        this.defaultTime = localStorage.getItem("timeformat");
        await this.getFilteredOptions();       
        await this.getGraphData(this.searchEmail,this.searchFilterOptions)        
        await this.getData(this.searchEmail)        
        this.sndcharts = null;
    },
    methods:{
      filter(data,value) {
          if (value==true) {
              return moment(data).format(this.defaultTime);
          }
          else {
            return moment(data).format(this.defaultdate);
        }
      },
      filterDate(data) {
        if (data) {
          return moment(data).format(this.defaultdate)
        }
    },
      async getFilteredOptions(){
          await axios
          .get(this.baseUrl +"googlefit/list?"+
          "email="+'',
          {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res)=>{            
              res.data.data.map((item)=>{
                this.emailSet.push(item.email)
                this.filterOptions=Object.keys(item)
              })
              this.filterOptions=this.filterOptions.filter((item)=>
                !['email','id','start_Time','end_Time'].includes(item)
              )
              this.filterOptions=this.filterOptions.map(item=>item.replace(/_/g, " ").replace(/\b\w/g, c => c.toUpperCase())
              )
              this.emailSet=[...new Set(this.emailSet)];
              this.searchEmail=this.emailSet[0]
          })
          .catch((err) => {
              console.log(err);
            });
      },
      async getGraphData(value1,value2){
        if(value2!="All" || value2=="")
        {
          value2=value2.replace(/\s+/g,"_").replace(/\b\w/g, c => c.toLowerCase())
        }
        
        if(value1=="All" || value1==""){
         value1="veenasvijayan04@gmail.com"         
        }
        if(value2=="All"|| value2==""){
          value2="calories"
        }
          await axios
          .get(this.baseUrl +"googlefit/list?"+
          "email="+value1
          +"&propName="+value2
          +"&pageindex="+this.pageIndex
          +"&pagesize="+this.pageSize,
          {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res)=>{  
              this.dates=[]
              this.calorieSet=[]              
              res.data.data.forEach((item)=>{
                this.dates.push(this.filterDate(item.end_Time));      
                  this.calorieSet.push(item.value?item.value:0)
              })                  
              console.log("Dates are",this.dates)
              console.log("Calories are",this.calorieSet)
              this.vitalschart.chartOptions =
                { 
                  stroke: {
                  width: 2,
                    },
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        id: "vuechart-example",
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: false,
                        },
                    },
                    xaxis: {
                          name:"Dates",
                          categories: this.dates,
                }}
            if(value2 == "blood_Pressure"){
                this.bpchart(res.data);      
              }                  
            this.vitalschart.series =
                [
                  {
                    name: this.searchFilterOptions,
                    data: this.calorieSet,
                  }
              ]
            
            this.vitalspiechartoptions.labels = this.dates;
            this.vitalschart.piedataseries = this.calorieSet;                          
          })
          .catch((err) => {
              console.error(err);
            });
            
            this.vitalschart.charttype='bar'
      },
      async bpchart(data){       
            this.doublebarchart.series = [{
              'name': "Systolic Blood Pressure",
              'data': []
            },{
              'name': "Diastolic Blood Pressure",
              'data': []
            }]
          data.data.forEach(bpdata =>{
            const myArray = bpdata.value.split("/");
            this.doublebarchart.series[0].data.push(myArray[0]==""?0:parseInt(myArray[0]))
            this.doublebarchart.series[1].data.push(myArray[1]==""?0:parseInt(myArray[1]))
           
          });
          this.doublebarchart.chartOptions.xaxis = {
            'name':"Dates",
            'categories': this.dates,
          };
      },
      async getData(value1,dropdownValue){
        if(value1=="All" || value1==""){
         value1="veenasvijayan04@gmail.com"         
        }
        if(dropdownValue==true){
          this.currentPage = 1
          this.pageIndex=1
          this.pageSize=10
          this.calorieSet=[]
          this.dates=[]
        }
          await axios
          .get(this.baseUrl +"googlefit/list?"+
          "email="+value1
          +"&pageindex="+this.pageIndex
          +"&pagesize="+this.pageSize,
          {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res)=>{            
              this.dates=[]
              this.calorieSet=[]              
                this.dataList=res.data.data
                this.totalPages = parseInt(res.data.total / this.pageSize);
                if (res.data.total % this.pageSize != 0) {
                  this.totalPages = this.totalPages + 1;
                }
                console.log("total pages are",this.totalPages)     
                          
          })
          .catch((err) => {
              console.error(err);
            });
              this.vitalschart.charttype = 'bar';    
      },
      async chartchange(val) {
        if (val == "barhorizondal") {          
        	await this.switchfirstbar(true);  
          this.vitalschart.charttype = "bar";  				         
        } else {         
          await this.switchfirstbar(false)  
          this.vitalschart.charttype =val;    
        }              
      },
      async switchfirstbar(res) {
        this.vitalschart.chartOptions.plotOptions = { bar: { horizontal: res } }     
        this.vitalschart.chartOptions.dataLabels={enabled:res} ;
        this.vitalschart.chartOptions.markers={size:5}
        this.vitalschart.chartOptions.yaxis={tickAmount:10}      
          this.vitalschart.charttype = ""; 
        
        
      },
      loadPage: function (page) {
          this.currentPage = page;
          this.pageIndex = page;
            this.getData(this.searchEmail,false)
            this.getGraphData(this.searchEmail,this.searchFilterOptions)
        },
  }
}