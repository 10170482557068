<template>
    <div class="pie-chart-container">
      <!-- Field Selection for Pie Chart -->
      <div class="field-selection">
        <label for="fieldSelect">Select Field for Pie Chart:</label>
        <select v-model="selectedField" id="fieldSelect" @change="updateChart">
          <option value="" disabled>Select Field</option>
          <option v-for="(column, index) in availableColumns" :key="index" :value="column">
            {{ column }}
          </option>
        </select>
      </div>
  
      <!-- Pie Chart -->
      <div class="chart-wrapper">
        <svg ref="chartContainer"></svg>
      </div>
    </div>
  </template>
  
  <script>
  import * as d3 from 'd3';
  
  export default {
    name: 'PieChart',
    props: {
      data: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        selectedField: '', // Field selected for pie chart
        availableColumns: ["SEX","TRT","SMOKSTAT", "ALCOHSTAT", "COMPLT", "ADHR", "EXFREQ", "DIET", "ALLERGY", "MHSTAT", "SES", "AEYN", "AEONGO", "AESEV", "AESER", "AEDTH", "AELIFE", "AEHOSP", "AEDISAB", "AECONG", "AEMIE", "AEREL"],
      };
    },
    watch: {
      data: {
        handler() {
          this.initializeColumns();
          this.updateChart();
        },
        immediate: true,
        deep: true,
      },
      selectedField() {
        this.updateChart();
      },
    },
    methods: {
      initializeColumns() {
        this.selectedField = this.availableColumns[0];
      },
      updateChart() {
        if (!this.selectedField || !this.data.length) return;
  
        const svg = d3.select(this.$refs.chartContainer);
        svg.selectAll('*').remove(); // Clear any existing content
  
        const width = 400;
        const height = 400;
        const radius = Math.min(width, height) / 2;
  
        const customColors = ["#28C0FF",
            "#FF98BD",
            "#8C82FF",
            "#FFB44F",
            "#57D9A5",
            "#FDAD73"];
        const color = d3.scaleOrdinal().range(customColors.concat(d3.schemeCategory10));
  
        const pie = d3.pie().value(d => d.value);
  
        const arc = d3.arc()
          .innerRadius(0)
          .outerRadius(radius);
  
        const chartData = d3.rollups(
          this.data,
          v => v.length,
          d => d[this.selectedField]
        ).map(([key, value]) => ({ key, value }));
  
        const svgGroup = svg
          .attr('width', width)
          .attr('height', height)
          .append('g')
          .attr('transform', `translate(${width / 2}, ${height / 2})`);
  
        const arcs = svgGroup
          .selectAll('.arc')
          .data(pie(chartData))
          .enter()
          .append('g')
          .attr('class', 'arc');
  
        arcs
          .append('path')
          .attr('d', arc)
          .attr('fill', d => color(d.data.key));
  
        arcs
          .append('text')
          .attr('transform', d => `translate(${arc.centroid(d)})`)
          .attr('dy', '0.35em')
          .text(d => `${d.data.key}: ${d.data.value}`);
      },
    },
    mounted() {
      this.initializeColumns();
      this.updateChart();
    },
  };
  </script>
  
  <style scoped>
  .pie-chart-container {
    width: 100%;
    text-align: center;
  }
  
  .field-selection {
    margin-bottom: 20px;
  }
  
  .chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  svg {
    font-family: sans-serif;
    font-size: 10px;
  }
  </style>
  