<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-6 align-self-center">
            <h2 class="mb-0">{{ $t("navigations.comparative lab results") }}</h2>
        </div>
        <div class="col-lg-2">
            <i class="fa fa-times close my-0" @click="$router.push(`/analyticalDashboard`)"></i>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-12 pl-1 mb-2">
                <historytab :historyarray="historykeys" @gotohistorylevel="gotohistorylevel" @exporttopdf="exporttopdf"
                @exporttoexcell="exporttoexcell"/>
            </div>
            <div ref="captureArea1" class="col-12 col-md-6 pl-1 mb-2">
                <D3LineChart :data="data" :loader="loader" :templateId="templateId" :chartlabel="'Lab Results Over Time'" @drilldowncurrentchart="drilldowncurrentchartforbar" @opendrillpopup="drillpopupopened" @exporttoexcell="exporttoexcell"/>
            </div>
            <div ref="captureArea2" class="col-12 col-md-6 mb-2">
                <piechart
              :loader="loader"
              :data="data"
              :tableData="data"
              :templateId="templateId"
              :fromPage="'descriptiveStatistics'"
              :displayValue="false"
              :xaxiskey="xaxiskeys['piechart']"
              chartlabel="Treatment Group Distribution"
              @setxaxiskey="retainxaxiskey"
              @drilldowncurrentchart="drilldowncurrentchartforbar"
              @opendrillpopup="drillpopupopened"
              @exporttoexcell="exporttoexcell"
            />
            </div>
            <div ref="captureArea3" class="col-12 pl-1 mb-2">
                <groupbarchart :data="data"
                  :availableColumns="arrayofkeys"
                    chartlabel="Average Lab Results by Treatment Group"
                    :fromPage="'descriptiveStatistics'"
                    :xAxisValue="groupchartkeys['xaxiskey']"
                    :groupCategoryValue="groupchartkeys['groupkey']"
                    @opendrillpopup="drillpopupopened"
                    @drilldowncurrentchart="drilldowncurrentchartforbar"
                    @exporttoexcell="exporttoexcell"
                    />
            </div>
            <div class="col-lg-12 pl-1 mb-1">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                        <span>Patient Distribution</span>
                    </div>
                    <div class="stat-body-white">
                        <advtable :loader="loader" fileheadding="Comparative lab results" :data="data" fromPage="patientRetention"
                            @drilldowncurrentchart="drilldowncurrentchartforbar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <chartdrill v-if="drillpopup" fileheadding="Comparative lab results" :drilldata="drilldata" :templateId="templateId" :fromPage="'adverseEventsPop'"
    @closepopup="drillpopup=false" />
</template>
<script src="./comparativeLabResult.js"></script>
