<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{$t('navigations.report dashboard')}}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-3">
        <div class="widget style1 rep-blu-bg" @click.prevent="schedulereport">
          <div class="row">
            <div class="col-10 text-left my-2">
              <span class="font-bold">{{$t('navigations.schedule')}}</span>
            </div>
            <div class="col-2 text-right my-2">
              <img src="../../assets/img/schedule_r.png" alt="Reports of schedule">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="widget style1 rep-grn-bg" @click.prevent="patientreport">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span class="font-bold">{{$t('navigations.subject summary')}}</span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/patient_summary.png" alt="Patient summary">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="widget style1 rep-ylw-bg " @click.prevent="sitereport">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span class="font-bold">{{$t('navigations.site report')}}</span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/site_report.png" alt="Site report">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="widget style1 rep-red-bg" @click.prevent="visitreport">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span class="font-bold">{{$t('navigations.visit summary')}}</span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/visit_summary_ongoing_visits.png" alt="Ongoing visits in visit summary">
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4">
        <div class="widget-head-vert-box text-center p-xl">
          <div class="">
            <img src="../../assets/img/home_visit_analysis.png" alt="Home visit report">
            <h4 class="font-bold no-margins">
              {{$t('navigations.home visit analysis')}}
            </h4>
            <small>{{$t('navigations.visits')}}</small>
          </div>

          <div class="m-1">
            <span>100{{$t('navigations.sites')}}</span> |
            <span>350{{$t('navigations.subjects')}}</span>
          </div>
        </div>
        <div class="widget-head-vert-box text-center p-xl">
          <div class="">
            <img src="../../assets/img/Unscheduled_visits_R.png" alt="Unscheduled visits report">
            <h4 class="font-bold no-margins">
             {{$t('navigations.visit completed out of the window')}}
            </h4>
            <small>{{$t('navigations.visits')}}</small>
          </div>

          <div>
            <span>100 {{$t('navigations.sites')}}</span> |
            <span>350 {{$t('navigations.subjects')}}</span>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-2">
        <div class="widget-head-grn-box text-center p-xl">
          <div class="">
            <img src="../../assets/img/Unscheduled_visits_R.png" alt="Unscheduled visits report">
            <h1 class="m-xs">456</h1>
            <h4 class="font-bold no-margins">
              {{$t('navigations.unscheduled visits')}}
            </h4>
          </div>
        </div>
        <div class="widget-head-grn-box text-center p-xl">
          <div class="">
           <img src="../../assets/img/query_status_report.png" alt="Query status report">
            <h4 class="font-bold mt-3">
              {{$t('navigations.query status report')}}
            </h4>
            <small>{{$t('navigations.amount')}}</small>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="widget-head-ylw-box text-center p-xl">
          <div class="">
            <img src="../../assets/img/referrals_received_r.png" alt="Referrals received report">
            <h1 class="m-xs">47</h1>
            <h4 class="font-bold no-margins">
              {{$t('navigations.total number of referrals received')}}
            </h4>
          </div>
        </div>
        <div class="widget-head-ylw-box text-center p-xl">
          <div class="">
            <img src="../../assets/img/visit_windows.png" alt="Visit window(S) open in next 7 days and 14 days">
            <h1 class="m-xs">47</h1>
            <h4 class="font-bold no-margins">
              {{$t('navigations.visit window(S) open in next 7 days and 14 days')}}
            </h4>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-2">
        <div class="widget-head-red-box text-center p-xl">
          <div class="">
            <img src="../../assets/img/visits_r.png" alt="Visits report">
            <h4 class="font-bold">
              {{$t('navigations.visits')}}
            </h4>
            <small>{{$t('navigations.by code')}}</small>
          </div>
        </div>
        <div class="widget-head-red-box text-center p-xl">
          <div class="">
            <img src="../../assets/img/nurse_list.png" alt="Nurse list">
            <h4 class="font-bold word-break">
              {{$t('navigations.nurse list')}}
            </h4>
            <small>{{$t('navigations.by country')}}</small>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="widget-head-vert-box p-sm style1">
          <div class="row">
            <div class="col-10 text-left my-2">
              <span>{{$t('navigations.query count report')}}</span>
            </div>
            <div class="col-2 text-right my-2">
              <img src="../../assets/img/query_count_report.png" alt="Query count report">
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="widget-head-vert-box p-sm style1">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span> {{$t('navigations.custom visit code')}}</span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/custom_visit_code.png" alt="Custom visit code">
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="widget-head-vert-box p-sm style1">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span> {{$t('navigations.unscheduled visits count report')}}</span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/unscheduled_visit_count.png" alt="Unscheduled visits count report">
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="widget-head-vert-box p-sm style1">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span> {{$t('navigations.visits completed out of window count')}}</span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/visit_completed_window.png" alt="Visits completed out of window count">
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="widget-head-vert-box p-sm style1">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span> {{$t('navigations.planned home care visit count')}} </span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/planned_home_visit_count.png" alt="Planned home visit count">
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-4">
        <div class="widget-head-vert-box p-sm style1">
          <div class="row my-2">
            <div class="col-10 text-left">
              <span> {{$t('navigations.visit schedule')}}</span>
            </div>
            <div class="col-2 text-right">
              <img src="../../assets/img/visit_schedule.png" alt="Visit schedule report">
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script src="./report_dashboard.js"></script>
<style scoped>
.rep-blu-bg {
  background-color: #61acff !important;
  color: #ffffff;
  cursor: pointer;
}
.rep-grn-bg {
  background-color: #40dbbc !important;
  color: #ffffff;
  cursor: pointer;
}

.rep-red-bg {
  background-color: #ff5973 !important;
  color: #ffffff;
  cursor:pointer
}
.rep-ylw-bg {
  background-color: #ffb955 !important;
  color: #ffffff;
  cursor:pointer;
}
.rep-white-bg {
  background-color: #ffffff;
  color: #676a6c;
}
.widget-head-color-box {
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
}
.widget-head-vert-box {
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #e7eaec;
  background-color: #ffffff;
}
.widget-head-vert-box i {
  color: #62acff;
}
.widget-head-vert-box span {
  padding-top: 10px;
}
.widget-head-grn-box {
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #e7eaec;
  background-color: #ffffff;
}
.widget-head-grn-box i {
  color: #40dbbc;
}
.widget-head-ylw-box {
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #e7eaec;
  background-color: #ffffff;
}
.widget-head-ylw-box i {
  color: #ffb955;
}
.widget-head-red-box {
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #e7eaec;
  background-color: #ffffff;
}
.widget-head-red-box i {
  color: #ff5973;
}
.word-break{
  word-break:break-all
}
.horizondalscroll {
  overflow-y: auto;
  max-height: 500px;
}
</style>
