<template>
  <div class="w-100">
    <svg ref="arcChart" :width="width" :height="height"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'ArcTweenChart',
  props: {
    totalNumber: {
      type: Number,
      required: true,
      default: 100,
    },
    passedNumber: {
      type: Number,
      required: true,
      default: 50,
    },
    color1: {
      type: String,
      default: '#1AB394',
    },
    color2: {
      type: String,
      default: '#f44336', 
    },
    labelOne:{
      type: String,
      default: 'Acceptable', 
    },
    labelTwo:{
      type: String,
      default: 'Rejected', 
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  watch: {
    totalNumber() {
      this.createChart();
    },
    passedNumber() {
      this.createChart();
    },
  },
  data() {
    return {
      legendData: [
        { label: this.labelOne, color: this.color1 },
        { label: this.labelTwo, color: this.color2 },
      ],
    };
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const width = this.width;
      const height = this.height;
      const radius = Math.min(width, height) / 2;

      const svg = d3.select(this.$refs.arcChart)
        .attr('width', width + "%")
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height]);

      svg.selectAll('*').remove(); 

      const arc = d3.arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius);

      const pie = d3.pie()
        .sort(null)
        .value(d => d.value);

      const acceptablePercentage = (this.passedNumber / this.totalNumber) * 100;
      const rejectablePercentage = 100 - acceptablePercentage;

      const data = [
        { name: this.labelOne, value: this.passedNumber },
        { name: this.labelTwo, value: this.totalNumber - this.passedNumber },
      ];

      const arcs = pie(data);

      const color = d3.scaleOrdinal()
        .domain(data.map(d => d.name))
        .range([this.color1, this.color2]);

      const g = svg.append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

      g.selectAll('path')
        .data(arcs)
        .enter()
        .append('path')
        .attr('fill', d => color(d.data.name))
        .transition()
        .duration(1000)
        .attrTween('d', function (d) {
          const i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

     
      svg.append('text')
        .attr('text-anchor', 'middle')
        .attr('x', -3)
        .attr('y', height / 2 - radius / 2 - 10)
        .style('font-size', '12px')
        .style('fill', '#000')
        .text(`${rejectablePercentage.toFixed(2)}%`);
        

      
      svg.append('text')
        .attr('text-anchor', 'middle')
        .attr('x', 110)
        .attr('y', height / 2 - radius / 2 - 10)
        .style('font-size', '12px')
        .style('fill', '#000')
        .text(`${acceptablePercentage.toFixed(2)}%`);

      const legend = svg.append('g')
        .attr('transform', `translate(${width / 2 - radius + 10}, ${height - radius - 10})`);

      this.legendData.forEach((d, i) => {
        const legendRow = legend.append('g').attr('transform', `translate(${width}, ${i * 20})`);

        legendRow
          .append('rect')
          .attr('width', 12)
          .attr('height', 12)
          .attr('fill', d.color);

        legendRow
          .append('text')
          .attr('x', 20)
          .attr('y', 10)
          .attr('text-anchor', 'start')
          .style('font-size', '12px')
          .text(d.label);
      });

      svg.append('text')
        .attr('text-anchor', 'middle')
        .attr('x', width / 2)
        .attr('y', height / 2 + 10)
        .style('font-size', '14px')
        .style('fill', '#000')
        .text(`${this.passedNumber}/${this.totalNumber}`);
    },
  },
};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
