/* eslint-disable */
import axios from "axios";
import { toHandlers } from "vue";
import VuePdfEmbed from 'vue-pdf-embed'
import 'video.js/dist/video-js.css'
import { VideoPlayer } from '@videojs-player/vue'

// let testarray= [];
export default {
	name: "modal",
    components:{
        VuePdfEmbed,
        VideoPlayer
    },
	props:{
        modaltype:{
            type:String,
            default:""
        },
        modalId:{
            type:String,
            default:""
        },
        videoId:{
          type:String,
          default:""
        },
        modalReferenceId:{
          type:String,
          default:""
        },
        version:{
            type:String,
            default:""
        }
    },
    
	data() {
		return {
			baseUrl: `${process.env.VUE_APP_Service_URL}/management/document/`,
			baseapi: process.env.VUE_APP_Service_URL,
			idtoken:"",
      source:"",
      format: '',
      source: '',
      officeurl: '',
      NewFile:'',
      office: ['xls', 'xlsx', 'doc', 'docx'],
      showLoading:false,
      videosrc:"",
      pdfIframe:"",
      playerOptions: {
        autoplay: false,
        controls: true,
        muted:false,
        fluid:true,
        height:300,
        sources: [],
      },
      videoPlayer: null,
			documentlist:[],
      handlePdfLoaded:false,
      documentData : {},
      fileData : "",
    };
        
    },
    created(){
      if(this.modaltype==="video"){
        this.setVideoSrc();
      }
      else{
        this.showLoading=true       
        this.setDoc()
        this.setfilename()   
        setTimeout(() => {
          this.showLoading =false;
        }, 8000);     
      }
    },
    mounted(){
      document.body.style.overflowY = "hidden"
      window.scrollTo(0, 0);
    },
    unmounted() {
      document.body.style.overflowY = "auto"
    },
    methods:{
      handleRightClick(event) {
        event.preventDefault();
      },
        onPlayerReady(player) {
          this.videoPlayer = player;
        },
        async setfilename() {
          await axios
            .get(`${this.baseapi}/dms/document/getallfiles/` + this.modalReferenceId)
            .then((res) => {
              this.NewFile =
                res.data.documentVersions[
                  res.data.documentVersions.length - 1
                ].userUploadedFileName;
            });
          this.format = this.NewFile.split(".").pop();
         
        },
        async setDoc() {     
          await axios.get(`${this.baseapi}/management/document/getdocumentbyidanonymous?id=${this.modalId}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) =>{
            console.log("get document by id", res.data);
            this.documentData = res.data;
            this.getFile();
          })
          
            // await axios
            //   .get(
            //     `${this.baseUrl}getdoc?docId=${this.modalId}`,
            //     {
            //       headers: {
            //         Authorization: "Bearer " + this.idtoken,
            //         "Content-Type": "application/json",
            //       },
            //     }
            //   )
            //   .then((res) => {
            //     if (res.data != "") {
            //       this.source = `${this.baseUrl}getdoc?docId=${this.modalId}`;
            //     }
            //   })
            //   .catch((err) => {
            //     console.log("error response is", err);
            //   });
          },
          async getFile(){
            const refId = this.documentData.documentReferenceId;
            const versionId = this.documentData.documentVersions.length > 0
                            ? this.documentData.documentVersions[this.documentData.documentVersions.length - 1].documentVersionId : null;
            await axios.get(`${this.baseapi}/dms/document/file/${refId}?versionid=${versionId}`

            ).then((res) => {
              this.fileData = res.data;
              if (res.data != "") {
                this.source =`${this.baseapi}/dms/document/file/${refId}?versionid=${versionId}`;
              }

            })
            .catch(err => {
              console.log("error in getting file ", err)
            })
          },
          async setVideoSrc(){
            await axios
              .get(
                `${this.baseapi}/management/video/videostream?id=${this.videoId}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                this.videosrc=res.data;
              })
              .catch((err) => {
                console.log("error response is", err);
              });
          },
    }
}