/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: "patientupdate",

    components: {
        VPagination,
    },
    props: {
        patientLoginId: {

            type: String,

            default: ""

        }
    },
    data() {
        return {
            errors: [],
            validations: [true, true],
            baseurl: process.env.VUE_APP_Service_URL,
            values: {
                emailID: "",
                password: "",
                firstName: "",
                lastName: "",
                timeZone: "",
                preferedLanguage: "",
            },
            newpassword: "",
            inputType: "password",
            subId: "",
            visibleOn: false,
            patient: "",
            isDisabled: true,
            lock:false
        }
    },
    async mounted() {
        await this.isSigned();
        await this.patientDetails();
        console.log("loginidis", this.patientLoginId)
        store.dispatch("setNavbar", false)
        store.dispatch("setPatientNavbar", true)
        document.querySelector("#page-wrapper").style.width = "100%"
        this.subId = this.patientLoginId
        console.log("patlogin id issssss", this.subId)
    },

    methods: {
        toggleDisabled() {
            //this.isDisabled = !this.isDisabled;
            if (this.isDisabled) {
                // Enable the fields and change the button text to 'Save'
                this.isDisabled = false;
                // Update button text to 'Save'
                // You can change the button text by updating a variable, for example:
                // this.buttonText = 'Save';
            } else {
                // Perform save operation
                this.updateSubject();

                // Disable the fields again and change the button text to 'Update'
                this.isDisabled = true;
                // Update button text to 'Update'
                // You can change the button text by updating the same variable mentioned above.
            }
        },
        async updateSubject() {
            await this.validate();
            this.isDisabled = false;
            if (!this.validations.includes(false)) {
                console.log("in create", this.values)
                const idtoken = store.getters.getIdToken;
                await axios
                    .put(
                        `${this.baseurl}/account-core/user/encryptpatientinfo`,
                        {
                            "patientLoginId": this.patientLoginId,
                            "firstName": this.values.firstName,
                            "lastName": this.values.lastName,
                            "preferedLanguage": this.values.preferedLanguage,
                            "timeZone": this.values.timeZone
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + idtoken,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        alert("Profile updated successfully")
                        this.patientDetails();
                        this.isDisabled = true;
                        console.log("patient is", this.values)
                    })
                    .catch((err) => {
                        alert(err);
                    });
            }

        },
        async patientDetails() {
            const idtoken = store.getters.getIdToken;
            await axios
                .get(
                    `${this.baseurl}/account-core/user/getencryptedpatientinfo?patientLoginId=${this.patientLoginId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.values = res.data;
                    console.log("patient is", this.values)
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async continueClicked() {
            this.$router.push(`/trainingMaterials/${this.patientLoginId}`);
        },
        async isSigned() {

            const idtoken = store.getters.getIdToken;

            await axios.get(

                `${this.baseurl}/account-core/user/getsigneddetails?patientLoginId=${this.patientLoginId}`,

                {

                    headers: {

                        Authorization: "Bearer " + idtoken,

                        "Content-Type": "application/json",

                    }

                })

                .then((res) => {

                    console.log("Form sign is", res.data.status);

                    if (res.data.status != null) {

                        this.lock = true;



                    }
                    else{
                        this.lock = false;
                    }
                    console.log("lock detail is", this.lock);
                })

                .catch((err) => {

                    console.log(err);

                });

        },
        async validate() {
            console.log("validation iw working", this.values.firstName)
            this.values.firstName = this.values.firstName.replace(/ +/g, ' ').trim();
            this.values.lastName = this.values.lastName.replace(/ +/g, ' ').trim();

            this.Validatename(this.values.firstName, 0);
            this.Validatename(this.values.lastName, 1);

        },
        async Validatename(name, errindex) {
            console.log("firstnameeeeeeeee", this.values.firstName)
            let letters = /^[A-Za-z\s]*$/;
            if (name.trim() == "") {
                console.log("condition1")
                this.validations[errindex] = false;

            }
            else if (!letters.test(name)) {
                console.log("condition2")
                this.validations[errindex] = true;

            }
            else {
                console.log("condition3")
                this.validations[errindex] = true;
            }
        },

    },
}