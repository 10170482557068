<template>
    Test
</template>

<script>
export default {
    name: 'App',
};
</script>

<style scoped>
/* Empty style */
</style>
