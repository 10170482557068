<template>
  <div class="import_forms_modal__wrapper">
    <div class="import_forms_modal__container mt-5">
      <div class="modal__title d-flex justify-content-between align-items-center">
        <h4 class="ml-lg-2 py-1">
          {{ $t("navigations.import from form library") }}
        </h4>
        <div
          class="cursor-pointer px-2 py-1"        
        >
          <i class="fa fa-times" title="Close" @click.prevent="closeModal" ></i>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <div class="form-group row my-lg-2">
            <div class="d-flex justify-content-center">
              <div class="col-lg-10 pl-0 pr-1">
                <Multiselect
                  mode="multiple"
                  v-model="importTemplateId"
                  :placeholder="$t('navigations.select form')"
                   :tag-placeholder="$t('navigations.selected')" 
                  :selected-label="$t('navigations.selected')"
                  valueProp="formLibraryId"
                  label="templateName"
                  :options="formlibrarylist"
                  :hideSelected="false"
                  :closeOnSelect="false"
                  class="multiselect-template-theme mr-0"
                >
                  <template v-slot:option="{ option }">
                    {{ option.formLibraryName }} ( {{ option.version }})
                  </template>
                </Multiselect>
              </div>
              <div class="col-lg-2 px-0">
                <button
                  class="save_btn py-1 w-100"
                  type="button"
                  @click.prevent="ImportFun()"
                >
                  {{ $t("navigations.import") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { useI18n } from "vue-i18n";
export default {
  name: "importForms",
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    return { t, locale };
  },
  components: {
    Multiselect,
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      this.studyId = store.getters.getStudyIs;
      await this.getformLibrarydata();
    });
    this.studyId = store.getters.getStudyIs;
    await this.getformLibrarydata();
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      templatelist: [],
      studyId: "",
      importTemplateId: [],
      importvalue: {
        formLibraryIds: [],
        studyId: "",
      },
      formlibrarylist: "",
    };
  },
  methods: {
    async closeModal() {
      this.$emit("closeModal");
      this.$emit("importForms");
    },
    async getformLibrarydata() {
      console.log("template data function");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/formslibrary/listformlibrary?Status=Approved`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("template data is");
          console.log(res.data);
          this.formlibrarylist = (res.data.results || []).sort((a, b) =>
            a.formLibraryName.localeCompare(b.formLibraryName)
          );
        })
        .catch((err) => {
          console.log("error in get template details ", err);
        });
    },
    async ImportFun() {
      this.importvalue.formLibraryIds = [];
      this.importvalue.formLibraryIds = this.importTemplateId;
      if (
        !this.importvalue.formLibraryIds ||
        this.importvalue.formLibraryIds.length < 1
      ) {
        alert(this.$t('navigations.no forms selected'));
      } else {
        this.importvalue.studyId = store.getters.getStudyIs;
        const idtoken = store.getters.getIdToken;
        await axios
          .post(
            `${this.baseurl}/forms/template/copyfromlibrary`,
            this.importvalue,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.importTemplateId = res.data[0];
            alert(this.$t('navigations.form imported successfully'));
            this.closeModal();
            this.$emit("importForms");
          })
          .catch((err) => {
            alert(err.response.data.detail);
          });
      }
    },
  },
};
</script>
  
  <style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "../../assets/savepages.css";
.import_forms_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.import_forms_modal__container {
  position: fixed;
  width: min(35%, 90%);
  border-radius: 4px;
  background: #fff;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}

.cancel_btn {
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
}
.custom-popup {
  text-align: center;
}

.simple-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 33px; /* Adjust the padding to increase the size */
  width: 29%; /* Adjust the width as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 4000; /* Increase the z-index value if needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.simple-wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.multiselect-template-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-radius: 0;
  --ms-line-height: 0.8;
  --ms-option-font-size: 0.8rem;
  --ms-font-size: 0.8rem;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  width: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -18%; /* Position the tooltip above the icon */
  left: -375%;
  margin-left: -30px; /* Use half of the width to center-align */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}
</style>