<template>
  <div>
    <div class="controls">
      <label for="attribute-select">Attribute:</label>
      <select v-model="selectedAttribute" id="attribute-select" @change="updateChart">
        <option v-for="option in attributeOptions" :key="option" :value="option">{{ option }}</option>
      </select>
      <label for="entroll-status-select">Enrolled:</label>
      <select v-model="enstatus" id="entroll-status-select" @change="changeentrollstatus">
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    </div>
    <div class="chart-container">
      <svg ref="chartContainer"></svg>
      <div ref="tooltip" class="tooltip" style="position: absolute; opacity: 0;"></div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";

export default {
  name: 'PieChart',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 550,
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      enstatus: "true",
      entrollstatus: true,
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      selectedAttribute: 'GENDER',
      attributeOptions: [
        'GENDER',
        'KHSM',
        'ALCOHOL',
        'ACT',
        'ML_prediction'
      ],
    };
  },
  watch: {
    data: {
      handler() {
        this.loadQuantitativeAttributes();
        this.createChart();
      },
      deep: true,
    },
    selectedAttribute() {
      this.updateChart();
    },
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;
    await this.createmapping();
    this.createChart();
  },
  methods: {
    async loadQuantitativeAttributes() {
      const sampleData = (this.data && this.data.length > 0 && this.data[0].features) ? this.data[0].features : {};
      const qualitativeAttributes = [];

      Object.keys(sampleData).forEach(key => {
        const uniqueValues = new Set(this.data.map(item => item.features[key])).size;
        if (uniqueValues < 6) {
          qualitativeAttributes.push(key);
        }
      });

      this.attributeOptions = qualitativeAttributes;
      this.selectedAttribute = qualitativeAttributes[0] || '';
    },
    async changeentrollstatus() {
      this.entrollstatus = this.enstatus == "true";
      this.updateChart();
    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    validateData(data) {
      return data.filter(d => d.features[this.selectedAttribute] !== undefined || d[this.selectedAttribute] !== undefined);
    },
    createChart() {
      try {
        const { data } = this;
        if (!Array.isArray(data) || data.length === 0) {
          console.error('Data is invalid or empty:', data);
          return;
        }

        const validData = this.validateData(data);
        if (validData.length === 0) {
          console.error('Valid data is empty');
          return;
        }
        console.log('validated data in new pie ', validData);
        const aggregatedData = d3.rollups(
          validData,
          v => v.length,
          d => {
            const value = d.features['AIPrediction'] !== undefined ? d.features['AIPrediction'] : d['AIPrediction'];
            return this.mappings['AIPrediction'] ? this.mappings['AIPrediction'][value] || value : value;
          },
          d => {
            const value = d.features[this.selectedAttribute] !== undefined ? d.features[this.selectedAttribute] : d[this.selectedAttribute];
            return this.mappings[this.selectedAttribute] ? this.mappings[this.selectedAttribute][value] || value : value;
          }
        );

        const transformedData = aggregatedData.map(([key, values]) => ({
          key,
          value: values.map(([innerKey, count]) => ({ key: innerKey, value: count }))
        }));

        console.log(JSON.stringify(transformedData));
        let actualvalarray = [];
        transformedData.forEach((eacharray) => {
          if (eacharray.key == this.entrollstatus) {
            actualvalarray = eacharray.value;
          }
        });

        if (actualvalarray.length === 0) {
          console.error('Aggregated data is empty');
          return;
        }
        console.log('aggregatedData data in new pie ', JSON.stringify(actualvalarray));
        const radius = 100;
        const width = 928;
        const height = Math.min(width, 500);

        const color = d3.scaleOrdinal()
          .domain(actualvalarray.map(d => d.key))
          .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), actualvalarray.length).reverse());

        const pie = d3.pie()
          .sort(null)
          .value(d => d.value);

        const arc = d3.arc()
          .innerRadius(0)
          .outerRadius(Math.min(width, height) / 2 - 1);

        const labelRadius = arc.outerRadius()() * 0.8;

        const arcLabel = d3.arc()
          .innerRadius(labelRadius)
          .outerRadius(labelRadius);

        const arcs = pie(actualvalarray);

        this.svg = d3.select(this.$refs.chartContainer)
          .attr("width", width)
          .attr("height", height)
          .attr("viewBox", [-width / 2, -height / 2, width, height])
          .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

        this.svg.append("g")
          .attr("stroke", "white")
          .selectAll()
          .data(arcs)
          .join("path")
          .attr("fill", d => color(d.data.key))
          .attr("d", arc)
          .append("title")
          .text(d => `${d.data.key}: ${d.data.value.toLocaleString("en-US")}`);

        this.svg.append("g")
          .attr("text-anchor", "middle")
          .selectAll()
          .data(arcs)
          .join("text")
          .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
          .call(text => text.append("tspan")
            .attr("y", "-0.4em")
            .attr("font-weight", "bold")
            .text(d => d.data.key))
          .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
            .attr("x", 0)
            .attr("y", "0.7em")
            .attr("fill-opacity", 0.7)
            .text(d => d.data.value.toLocaleString("en-US")));

        const legend = this.svg.append("g")
          .attr("transform", `translate(${width / 2 - 120}, ${-height / 2 + 20})`)
          .selectAll("g")
          .data(arcs)
          .enter().append("g")
          .attr("transform", (d, i) => `translate(0, ${i * 20})`);

        legend.append("rect")
          .attr("width", 18)
          .attr("height", 18)
          .attr("fill", d => color(d.data.key));

        legend.append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text(d => `${d.data.key}: ${d.data.value}`);
      } catch (error) {
        console.error('Error creating pie chart:', error);
      }
    },
    updateChart() {
      d3.select(this.$refs.chartContainer).selectAll('*').remove();
      this.svg = null;
      this.createChart();
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
}
.controls {
  margin-bottom: 10px;
}

.controls label {
  margin-right: 5px;
}

.controls select {
  margin-right: 15px;
}
</style>
