<template>
  <div class="wrapper wrapper-content">
    <div class="mb-1 px-3 py-1">
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 deviation py-3">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-8">
              <h3 class="mb-3">
                Overview of what to expect in enrolment process
              </h3>
              <div class="enrolment-step-block p-2 mb-3">
                <img
                  class="px-2"
                  src="../../assets/img/icons/create_account.png"
                  alt="Create account"
                />
                <div>Create your own account</div>
              </div>
              <div class="enrolment-step-block p-2 mb-3">
                <img
                  class="px-2"
                  src="../../assets/img/icons/study_details.png"
                  alt="Study details"
                />
                <div>Go through the study details</div>
              </div>
              <div class="enrolment-step-block p-2">
                <img
                  class="px-2"
                  src="../../assets/img/icons/consent.png"
                  alt="Consent"
                />
                <div>Consent to take part of the study</div>
              </div>
              <p class="mt-2">
                * It is important to understand that agreeing to participate
                does not guarantee immediate enrollment. Additional screening
                measures may be implemented subsequent to providing consent.
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-8 row py-3">
              <h3 class="mb-3">Create Your Account</h3>
              <p>
                Creating an account enables you to conveniently view your study
                details on this platform and on any device you use.
              </p>

              <div class="col-lg-6">
                <label class="col-form-label"
                  >{{ $t("navigations.first name")
                  }}<sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <input
                  type="text"
                  maxlength="20"
                  class="form-control border-left-important"
                  v-model="values.firstName"
                />
                <span class="errormsg" v-if="errors[0] == false">
                  {{ $t("navigations.please enter first name") }}</span
                >
              </div>
              <div class="col-lg-6">
                <label class="col-form-label"
                  >{{ $t("navigations.last name")
                  }}<sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <input
                  type="text"
                  maxlength="20"
                  class="form-control border-left-important"
                  v-model="values.lastName"
                />
                <span class="errormsg" v-if="errors[1] == false">
                  {{ $t("navigations.please enter last name") }}</span
                >
              </div>

              <div class="col-lg-12">
                <label class="col-form-label">{{
                  $t("navigations.email")
                }}</label>
                <input
                  type="text"
                  class="form-control border-left-important"
                  v-model="values.emailIdPrimary"
                  maxlength="50"
                  disabled
                />
                <span class="errormsg" v-if="errors[2] == false">
                  {{ $t("navigations.please enter email id") }}</span
                >
                <span class="errormsg" v-if="errors[7] == false">
                  Please enter valid email id</span
                >
              </div>

              <div class="col-lg-6">
                <label class="col-form-label"
                  >Password <sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <div class="position-relative">
                  <input
                    :type="inputType"
                    maxlength="20"
                    class="form-control position-relative border-left-important"
                    v-model="values.password"
                  />
                  <i
                    class="password-visible cursor-pointer position-absolute"
                    :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                    @click.prevent="passwordVisible()"
                  ></i>
                </div>
                <div class="errormsg" v-if="errors[3] == false">
                  Please enter a password
                </div>
              </div>

              <div class="col-lg-6">
                <div class="position-relative">
                  <label class="col-form-label"
                    >Confirm Password<sup
                      ><i class="fa fa-asterisk imp"></i></sup
                  ></label>
                  <input
                    :type="inputType"
                    maxlength="20"
                    class="form-control border-left-important"
                    v-model="newpassword"
                  />

                  <i
                    class="password-visible cursor-pointer position-absolute"
                    :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                    @click.prevent="passwordVisible()"
                  ></i>
                </div>
                <div class="errormsg" v-if="errors[4] == false">
                  Please enter confirm password
                </div>
                <span class="errormsg" v-if="errors[5] == false"
                  >Password and confirm password mismatch!</span
                >
              </div>

              <span class="errormsg" v-if="errors[6] == false"
                >Passwords must contain at least eight characters, <br />
                including uppercase, lowercase letters, special characters and
                numbers.</span
              >

              <div class="col-lg-12">
                <label class="col-form-label">Time Zone</label>
                <select
                  class="form-select selectforms"
                  valueProp="VisitTypeName"
                  v-model="values.timeZone"
                >
                  <option selected value="">Select time zone</option>
                  <option value="Pacific/Midway">
                    (GMT-11:00) Midway Island, Samoa
                  </option>
                  <option value="America/Adak">
                    (GMT-10:00) Hawaii-Aleutian
                  </option>
                  <option value="Etc/GMT+10">(GMT-10:00) Hawaii</option>
                  <option value="Pacific/Marquesas">
                    (GMT-09:30) Marquesas Islands
                  </option>
                  <option value="Pacific/Gambier">
                    (GMT-09:00) Gambier Islands
                  </option>
                  <option value="America/Anchorage">(GMT-09:00) Alaska</option>
                  <option value="America/Ensenada">
                    (GMT-08:00) Tijuana, Baja California
                  </option>
                  <option value="Etc/GMT+8">
                    (GMT-08:00) Pitcairn Islands
                  </option>
                  <option value="America/Los_Angeles">
                    (GMT-08:00) Pacific Time (US & Canada)
                  </option>
                  <option value="America/Denver">
                    (GMT-07:00) Mountain Time (US & Canada)
                  </option>
                  <option value="America/Chihuahua">
                    (GMT-07:00) Chihuahua, La Paz, Mazatlan
                  </option>
                  <option value="America/Dawson_Creek">
                    (GMT-07:00) Arizona
                  </option>
                  <option value="America/Belize">
                    (GMT-06:00) Saskatchewan, Central America
                  </option>
                  <option value="America/Cancun">
                    (GMT-06:00) Guadalajara, Mexico City, Monterrey
                  </option>
                  <option value="Chile/EasterIsland">
                    (GMT-06:00) Easter Island
                  </option>
                  <option value="America/Chicago">
                    (GMT-06:00) Central Time (US & Canada)
                  </option>
                  <option value="America/New_York">
                    (GMT-05:00) Eastern Time (US & Canada)
                  </option>
                  <option value="America/Havana">(GMT-05:00) Cuba</option>
                  <option value="America/Bogota">
                    (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                  </option>
                  <option value="America/Caracas">(GMT-04:30) Caracas</option>
                  <option value="America/Santiago">(GMT-04:00) Santiago</option>
                  <option value="America/La_Paz">(GMT-04:00) La Paz</option>
                  <option value="Atlantic/Stanley">
                    (GMT-04:00) Faukland Islands
                  </option>
                  <option value="America/Campo_Grande">
                    (GMT-04:00) Brazil
                  </option>
                  <option value="America/Goose_Bay">
                    (GMT-04:00) Atlantic Time (Goose Bay)
                  </option>
                  <option value="America/Glace_Bay">
                    (GMT-04:00) Atlantic Time (Canada)
                  </option>
                  <option value="America/St_Johns">
                    (GMT-03:30) Newfoundland
                  </option>
                  <option value="America/Araguaina">(GMT-03:00) UTC-3</option>
                  <option value="America/Montevideo">
                    (GMT-03:00) Montevideo
                  </option>
                  <option value="America/Miquelon">
                    (GMT-03:00) Miquelon, St. Pierre
                  </option>
                  <option value="America/Godthab">(GMT-03:00) Greenland</option>
                  <option value="America/Argentina/Buenos_Aires">
                    (GMT-03:00) Buenos Aires
                  </option>
                  <option value="America/Sao_Paulo">
                    (GMT-03:00) Brasilia
                  </option>
                  <option value="America/Noronha">
                    (GMT-02:00) Mid-Atlantic
                  </option>
                  <option value="Atlantic/Cape_Verde">
                    (GMT-01:00) Cape Verde Is.
                  </option>
                  <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                  <option value="Europe/Belfast">
                    (GMT) Greenwich Mean Time : Belfast
                  </option>
                  <option value="Europe/Dublin">
                    (GMT) Greenwich Mean Time : Dublin
                  </option>
                  <option value="Europe/Lisbon">
                    (GMT) Greenwich Mean Time : Lisbon
                  </option>
                  <option value="Europe/London">
                    (GMT) Greenwich Mean Time : London
                  </option>
                  <option value="Africa/Abidjan">
                    (GMT) Monrovia, Reykjavik
                  </option>
                  <option value="Europe/Amsterdam">
                    (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna
                  </option>
                  <option value="Europe/Belgrade">
                    (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana,
                    Prague
                  </option>
                  <option value="Europe/Brussels">
                    (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                  </option>
                  <option value="Africa/Algiers">
                    (GMT+01:00) West Central Africa
                  </option>
                  <option value="Africa/Windhoek">(GMT+01:00) Windhoek</option>
                  <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                  <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                  <option value="Asia/Gaza">(GMT+02:00) Gaza</option>
                  <option value="Africa/Blantyre">
                    (GMT+02:00) Harare, Pretoria
                  </option>
                  <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                  <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                  <option value="Asia/Damascus">(GMT+02:00) Syria</option>
                  <option value="Europe/Moscow">
                    (GMT+03:00) Moscow, St. Petersburg, Volgograd
                  </option>
                  <option value="Africa/Addis_Ababa">
                    (GMT+03:00) Nairobi
                  </option>
                  <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                  <option value="Asia/Dubai">
                    (GMT+04:00) Abu Dhabi, Muscat
                  </option>
                  <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                  <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                  <option value="Asia/Yekaterinburg">
                    (GMT+05:00) Ekaterinburg
                  </option>
                  <option value="Asia/Tashkent">(GMT+05:00) Tashkent</option>
                  <option value="Asia/Kolkata">
                    (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                  </option>
                  <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                  <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                  <option value="Asia/Novosibirsk">
                    (GMT+06:00) Novosibirsk
                  </option>
                  <option value="Asia/Rangoon">
                    (GMT+06:30) Yangon (Rangoon)
                  </option>
                  <option value="Asia/Bangkok">
                    (GMT+07:00) Bangkok, Hanoi, Jakarta
                  </option>
                  <option value="Asia/Krasnoyarsk">
                    (GMT+07:00) Krasnoyarsk
                  </option>
                  <option value="Asia/Hong_Kong">
                    (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                  </option>
                  <option value="Asia/Irkutsk">
                    (GMT+08:00) Irkutsk, Ulaan Bataar
                  </option>
                  <option value="Australia/Perth">(GMT+08:00) Perth</option>
                  <option value="Australia/Eucla">(GMT+08:45) Eucla</option>
                  <option value="Asia/Tokyo">
                    (GMT+09:00) Osaka, Sapporo, Tokyo
                  </option>
                  <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                  <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                  <option value="Australia/Adelaide">
                    (GMT+09:30) Adelaide
                  </option>
                  <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                  <option value="Australia/Brisbane">
                    (GMT+10:00) Brisbane
                  </option>
                  <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                  <option value="Asia/Vladivostok">
                    (GMT+10:00) Vladivostok
                  </option>
                  <option value="Australia/Lord_Howe">
                    (GMT+10:30) Lord Howe Island
                  </option>
                  <option value="Etc/GMT-11">
                    (GMT+11:00) Solomon Is., New Caledonia
                  </option>
                  <option value="Asia/Magadan">(GMT+11:00) Magadan</option>
                  <option value="Pacific/Norfolk">
                    (GMT+11:30) Norfolk Island
                  </option>
                  <option value="Asia/Anadyr">
                    (GMT+12:00) Anadyr, Kamchatka
                  </option>
                  <option value="Pacific/Auckland">
                    (GMT+12:00) Auckland, Wellington
                  </option>
                  <option value="Etc/GMT-12">
                    (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                  </option>
                  <option value="Pacific/Chatham">
                    (GMT+12:45) Chatham Islands
                  </option>
                  <option value="Pacific/Tongatapu">
                    (GMT+13:00) Nuku'alofa
                  </option>
                  <option value="Pacific/Kiritimati">
                    (GMT+14:00) Kiritimati
                  </option>
                </select>
              </div>

              <div class="col-lg-12 mb-3">
                <label class="col-form-label">Language</label>
                <select
                  class="form-select selectforms"
                  valueProp="VisitTypeName"
                  v-model="values.preferredLanguage"
                >
                  <option selected value="">Select language</option>
                  <option value="AF">Afrikaans</option>
                  <option value="SQ">Albanian</option>
                  <option value="AR">Arabic</option>
                  <option value="HY">Armenian</option>
                  <option value="EU">Basque</option>
                  <option value="BN">Bengali</option>
                  <option value="BG">Bulgarian</option>
                  <option value="CA">Catalan</option>
                  <option value="KM">Cambodian</option>
                  <option value="ZH">Chinese (Mandarin)</option>
                  <option value="HR">Croatian</option>
                  <option value="CS">Czech</option>
                  <option value="DA">Danish</option>
                  <option value="NL">Dutch</option>
                  <option value="EN">English</option>
                  <option value="ET">Estonian</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finnish</option>
                  <option value="FR">French</option>
                  <option value="KA">Georgian</option>
                  <option value="DE">German</option>
                  <option value="EL">Greek</option>
                  <option value="GU">Gujarati</option>
                  <option value="HE">Hebrew</option>
                  <option value="HI">Hindi</option>
                  <option value="HU">Hungarian</option>
                  <option value="IS">Icelandic</option>
                  <option value="ID">Indonesian</option>
                  <option value="GA">Irish</option>
                  <option value="IT">Italian</option>
                  <option value="JA">Japanese</option>
                  <option value="JW">Javanese</option>
                  <option value="KO">Korean</option>
                  <option value="LA">Latin</option>
                  <option value="LV">Latvian</option>
                  <option value="LT">Lithuanian</option>
                  <option value="MK">Macedonian</option>
                  <option value="MS">Malay</option>
                  <option value="ML">Malayalam</option>
                  <option value="MT">Maltese</option>
                  <option value="MI">Maori</option>
                  <option value="MR">Marathi</option>
                  <option value="MN">Mongolian</option>
                  <option value="NE">Nepali</option>
                  <option value="NO">Norwegian</option>
                  <option value="FA">Persian</option>
                  <option value="PL">Polish</option>
                  <option value="PT">Portuguese</option>
                  <option value="PA">Punjabi</option>
                  <option value="QU">Quechua</option>
                  <option value="RO">Romanian</option>
                  <option value="RU">Russian</option>
                  <option value="SM">Samoan</option>
                  <option value="SR">Serbian</option>
                  <option value="SK">Slovak</option>
                  <option value="SL">Slovenian</option>
                  <option value="ES">Spanish</option>
                  <option value="SW">Swahili</option>
                  <option value="SV">Swedish</option>
                  <option value="TA">Tamil</option>
                  <option value="TT">Tatar</option>
                  <option value="TE">Telugu</option>
                  <option value="TH">Thai</option>
                  <option value="BO">Tibetan</option>
                  <option value="TO">Tonga</option>
                  <option value="TR">Turkish</option>
                  <option value="UK">Ukrainian</option>
                  <option value="UR">Urdu</option>
                  <option value="UZ">Uzbek</option>
                  <option value="VI">Vietnamese</option>
                  <option value="CY">Welsh</option>
                  <option value="XH">Xhosa</option>
                </select>
              </div>

              <div class="col-md-12">
                <button
                  type="button"
                  class="w-100 save_btn py-2 continue"
                  @click.prevent="continueClicked()"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./PatientEnrollment.js"></script>
<style>
.password-visible {
  right: 20px;
  bottom: 8px;
}
.enrolment-step-block {
  display: flex;
  font-size: clamp(0.5rem, 12vw + 1rem, 1rem);
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.enrolment-step-block img {
  height: fit-content;
}

.active-block {
  background-color: #d9d9d9;
}

.border-left-important {
  border-left: 2px solid #ff6565 !important;
}
.enrollmentdivition {
  border-left: 4px solid white;
  border-radius: 50%;
  height: 100%;
}
.deviation {
  border-right: none;
}
@media (min-width: 768px) {
  .deviation {
    border-right: 1px solid #ccc;
  }
}

.pswrdblock {
  margin-right: 5px;
  margin-top: 33px;
}
.continue {
  padding: initial;
}
</style>