<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.data export") }}</h2>
    </div>
    <!-- <div class="col-lg-2">
            <i class="fa fa-times close my-0" @click="onclosed()"></i>
        </div> -->
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content py-0">
      <div class="form-group row mb-0">
        <div class="col-lg-3 mb-1">
          <label class="col-form-label"
            >{{ $t("navigations.study") }}
            <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <select
            class="form-control setheight"
            name="account"
            v-model="selectedStudy"
            @change="listSites()"
          >
            <option value="" selected>-- Select --</option>
            <option
              v-for="study in studyList"
              :key="study.studyId"
              :value="study.studyId"
            >
              {{ study.studyName }}
            </option>
          </select>
        </div>
        <div class="col-lg-3 mb-1">
          <label class="col-form-label">{{ $t("navigations.site") }}</label>
          <select
            class="form-control"
            name="account"
            v-model="selectedSite"
            @change="SiteChanged()"
          >
            <option value="" selected>{{ $t("navigations.select") }}</option>
            <option
              v-for="site in siteList"
              :key="site.siteID"
              :value="site.siteID"
            >
              {{ site.siteCode }}
            </option>
          </select>
        </div>
        <div class="col-lg-3 mb-1">
          <label class="col-form-label">{{ $t("navigations.subject") }}</label>
          <select
            class="form-control"
            name="account"
            v-model="selectedSubject"
            :disabled="disablePatient"
          >
            <option value="" selected>{{ $t("navigations.select") }}</option>
            <option
              v-for="subject in subjectList"
              :key="subject.patientId"
              :value="subject.patientId"
            >
              {{ subject.subjectId }}
            </option>
          </select>
        </div>      
        <div class="col-lg-3 mb-1">
          <label class="col-form-label">{{ $t("navigations.files") }}</label>
          <input
            type="text"
            class="form-control"
            placeholder=""
            v-model="fileName"
          />
        </div>
        <div
          class="col-lg-12 my-3 text-end"
          v-if="rollsprivilages.includes('Data Export View')"
        >
        <button
            class="save_btn py-1 px-4 mr-2"
            type="submit"
            @click.prevent="exportToCsv()"
          >
            {{ $t("navigations.sdtm") }}
          </button>
          <button
            class="save_btn py-1 px-4"
            type="submit"
            @click.prevent="exportData()"
          >
            {{ $t("navigations.dataset-JSON") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="wrapper wrapper-content">
      <div class="ibox-content mb-1 px-3 py-2">
        <div class="form-group row mb-1">
          <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
            <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
              <label class="mb-0">{{ $t("navigations.from") }}</label>
              <input
                type="date"
                class="form-control"
                v-model="search.fromDate"
              />
            </div>
            <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
              <label class="mb-0"> {{ $t("navigations.to") }}</label>
              <input type="date" class="form-control" v-model="search.toDate" />
            </div>
            <span
              type="button"
              class="col-sm-12 col-lg-2 save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
              @click="SearchDate()"
            >
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>

      <div class="ibox-body">
        <div class="ibox-title style_2 d-flex justify-content-between">
          <h5 class="mt-1">{{ $t("navigations.export audit list") }}</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th
                    class="sort_block"
                    @click="SortSelected('exportedBy', 1)"
                    id="1"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.exported by") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th
                    class="sort_block"
                    @click="SortSelected('exportedAt', 2)"
                    id="2"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.date") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th
                    class="sort_block"
                    @click="SortSelected('exportedAt', 3)"
                    id="3"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.time") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th
                    class="sort_block"
                    @click="SortSelected('exportType', 6)"
                    id="6"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.export type") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th
                    class="sort_block"
                    @click="SortSelected('exportZipName', 4)"
                    id="4"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.file name") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th
                    class="sort_block"
                    @click="SortSelected('status', 5)"
                    id="5"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.status") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <!-- <th class="text-center">{{$t('navigations.download') }}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="audit in aduitList"
                  :key="audit.id"
                  :value="audit.id"
                >
                  <td>{{ audit.exportedBy }}</td>
                  <td>{{ filter(audit.exportedAt) }}</td>
                  <td>{{ formatTime(audit.exportedAt) }}</td>
                  <td>{{ audit.exportType }}</td>
                  <td>{{ audit.exportZipName }}</td>
                  <td>{{ audit.status }}</td>
                  <!-- <td class="text-center">
                    <i
                        class="fa fa-download edit-delete"
                      ></i>
                </td> -->
                </tr>
              </tbody>
            </table>
            <!------ pagination -------->
            <div class="row mx-0">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="dataTables_paginate paging_simple_numbers pagination float-right"
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="currentPage"
                      :pages="totalItems"
                      :range-size="1"
                      active-color="#618bd7"
                      @update:modelValue="loadPage"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!---Pagination ends-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./DataExport.js">
</script>

<style>
@import "../../assets/savepages.css";
</style>
