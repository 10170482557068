<template>

  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t('navigations.manage role') }}</h2>
    </div>
    <div class="col-lg-2">
        <i class="fa fa-times close my-2" title="Close" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-3 p-2">
      <div class="form-group row my-lg-2">

        <div class="col-lg-12 mb-1">
          <label class="col-form-label"> {{ $t('navigations.role name') }} <sup><i class="fa fa-asterisk required"></i></sup> </label>
          <input type="text" class="form-control" maxlength="100" v-model="values.role_Name" />
          <span class="errorClass" v-if="!errors[0]">{{ $t('navigations.please enter a role name') }}</span>
        </div>

        <div class="col-lg-12 mb-1"> 
          <label class="col-form-label">{{ $t('navigations.role description') }}  </label>
          <input type="text" class="form-control"  maxlength="500"   v-model="values.description"/>
        </div>
           <div class="col-lg-6 my-2 d-flex ">
            <div class="input-group date">
                <div class="i-checkss"><label class="mr-2">{{ $t('navigations.active') }}</label> <input type="checkbox" checked="checked" v-model="roles.active"></div>            
            </div>                       
        </div>

        <div class="col-lg-6 my-2 d-flex justify-content-lg-end createLink">
          <button
            class="btn btn-primary cancel_btn py-2 px-5 mx-3"
            type="submit"
            @click="ClearData()"
          >
            {{ $t('navigations.clear') }}
          </button>
          <button
            class="btn btn-primary save_btn py-2 px-5"
            type="submit"
            @click="SaveOrEditRole()"
          >
            {{ $t('navigations.submit') }}
          </button>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import "@vueform/multiselect/themes/default.css";
export default {
    name: "manageRole",
    props: { roleId: String },

    data() {
        return {
            roles: "",
            baseapi: process.env.VUE_APP_Service_URL,
            baseUrl : `${process.env.VUE_APP_Service_URL}/account-core/roles/`,
            values:{
                role_Id :"",
                role_Name: "",
                description: "",
                active: true,
                //created_by: ""
                },
            validations: [false],
            errors: [true],
            headerValues : "",
            idToken : "",
        }
    },
    async mounted() {
        this.idToken = store.getters.getIdToken;
        this.headerValues = {
            headers: {
                Authorization: "Bearer " + this.idToken,
                "Content-Type": "application/json",
                },
        };     
        if (this.roleId != 0) {
           
            console.log(this.roleId);
            await this.getRoles(this.roleId);
            this.bindData();
        }
    },
    methods: {
        async SaveOrEditRole() {
            if (this.roleId != 0) {
                this.validateIsNull();
                if(this.validations.includes(false)){
                   this.errors = this.validations;
                    console.log("Empty feild occurs...!");
                    console.log(this.roles);
                }
                else{
                    await this.editRole();
                }
              
            }
            else {
                this.getUserName();
                this.validateIsNull();
                if(this.validations.includes(false)){
                    this.errors = this.validations;
                    console.log("Empty feild occurs...!");
                    console.log(this.roles);
                } 
                else{
                   await this.saveRole();
                  //  alert("Patient created successfully.")
                }
            }
     
        },
        async saveRole() {
            console.log("save Role value.....!", this.values);
            await axios.post(this.baseUrl + "createrole",  this.values,this.headerValues)
              .then((res) => {
                console.log(res);
                alert(this.$t('navigations.role added successfully'));
                this.$router.push(`/roleList`);
              })
              .catch((err) => {
                console.log("add err reponse: ",err.response.data);
                if(err.response.data.detail =="RoleName already exist"){
                           alert(this.$t('navigations.role name already exists'))
                }
                else{
                  alert(err.response.data.detail)
                }
               
              });
            

        },
        async editRole() {
            console.log("in edit Role");
            this.values.role_Id = this.roles.id;
            this.values.active = this.roles.active;
            console.log("senting value .........", this.values);
            await axios.put(this.baseUrl + "update",this.values,this.headerValues)
              .then((res) => {
                console.log(res);
                alert(this.$t('navigations.role updated successfully'));
                this.$router.push(`/roleList`);
              })
              .catch((err) => {
                console.log("update err reponse: ",err.response);
                alert(err.response.data.detail);
              });
        },
        ClearData() {
            console.log("form cleared");
             this.values={
                role_Id :"",
                role_Name: "",
                description: "",
                active: true,
               // created_by: ""
                }
        },
        async onclosed() {
            this.$router.push(`/roleList`);
        },
        validateIsNull() {
             // this.values.role_Name = this.values.role_Name.replace(/^\s*/, "");
             this.values.role_Name = this.values.role_Name.replace(/ +/g, ' ').trim();
             this.values.description = this.values.description.replace(/ +/g, ' ').trim();
            if (this.values.role_Name != "") {
                this.validations[0] = true;
            }
            else{
                this.validations[0] = false;
            }
        },
        async getRoles(roleId){
            await axios.get(`${this.baseapi}/account-core/roles/getbyid?guid=`+roleId, this.headerValues)
             .then(res =>{
                     console.log("Get roles by id ",res.data)
                    this.roles = res.data} )
            .catch(err => console.log(err));
        },
        bindData(){
          console.log("bind data")
          console.log("values" , this.values);
          console.log("roles" , this.roles);
            this.values.role_Id = this.roles.id;
            this.values.role_Name = this.roles.role_Name;
            this.values.description = this.roles.description;
          //  this.values.created_by = this.roles.created_by;
            this.values.active = this.roles.active;
        },
        async jwtDecrypt(token) {
          const base64Url = token.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
        return JSON.parse(jsonPayload);
        },
        async getUserName() {
            const idToken = store.getters.getIdToken;
            const jwtDecodedValue = await this.jwtDecrypt(idToken);
            this.values.created_by = jwtDecodedValue.name;
            console.log("created by", this.values.created_by);
      },
    }
}
</script>


<style>
@import "../../assets/savepages.css";
 </style>