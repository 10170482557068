 <template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{
              $t("navigations.site privilege")
            }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="ibox-content p-2 mb-1">
      <div class="form-group row mb-0">
        <label class="mb-0">{{
              $t("navigations.user")
            }}</label>
        <div
          class="col-lg-6 d-flex flex-column flex-md-row justify-content-around align-items-lg-center my-2 m-lg-0"
        >
          <select
            class="form-select"
            name="account"
            @change="onChange($event)"
            placeholder="select a user"
            v-model="UserScreen.email"
          >
            <option selected value="">All</option>
            <option
              v-for="options in users"
              :value="options.emailIdPrimary"
              :key="options.id"
            >
              {{ options.firstName }}{{ "  " }}{{ options.lastName }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5>{{
              $t("navigations.studies")
            }}</h5>
          </div>
          <div class="scroll_content">
            <div class="ibox-content">
              <div class="list-group scrollable">
                <a
                  class="list-group-item list_link"
                  :class="{ activeTab: selectedStudy == options.studyId }"
                  v-for="options in studies"
                  :key="options.studyId"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  @click="getPrivilegesbyid(options)"
                >
                  {{ options.studyRef }}
                  <i
                    class="fa fa-angle-right float-right"
                    @click.prevent="getPrivilegesbyid(options)"
                  >
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox-body" v-if="studyName != ''">
          <!-- <div >class="ibox collapse" id="collapseOne" -->
          <div class="ibox-title style_2">
            <h5>{{ studyRef }}</h5>
          </div>
          <div class="ibox-content">
            <table
              class="table table-striped table-bordered table-hover dataTables-example dataTable"
            >
              <thead>
                <tr>
                  <th>
                    {{
              $t("navigations.sites")
            }}
                    <button
                      v-if="rollsprivilages.includes('Site Privilege Update')"
                      class="btn1 btn-verify1 float-right ml-1"
                      type="button"
                    >
                      <i class="fa fa-floppy-o"  @click="savebutton()"></i>
                      <span class="tool-tip">{{
              $t("navigations.save")
            }}</span>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="options in siteDisplayList" :key="options.id">
                  <td>
                    <input
                      type="checkbox"
                      :value="options.isChecked"
                      v-model="options.isChecked"
                      :id="options.id"
                    />
                    {{ options.sitecode }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import axios from "axios";
import store from "../../store/index";
export default {
  name: "Site",
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      localurl: process.env.VUE_APP_redirect,
      userHaveSites: false,
      displayModel: {
        id: "",
        sitecode: "",
        isChecked: false,
      },
      allSites: [],
      siteDisplayList: [],
      headerValue: {},
      users: "",
      sites: [],
      studies: "",
      selectedStudy: "",
      selectedUser: "",
      UserScreen: {
        fullName: "",
        email: "",
      },
      toggle: false,
      studyName: "",
      save: "",
      studyRef: "",
      saveModel: {
        userId: "",
        emailId: "",
        study: [],
        createdUserEmail: "",
        createdUserId: "",
        createdAt: "2022-01-19T04:50:09.300Z",
        lastModifiedUserEmail: "",
        lastModifiedUserId: "",
        lastModifiedAt: "2022-01-19T04:50:09.300Z",
      },
      editModel: {
        userId: "",
        study: [],
        createdUserEmail: "",
        createdUserId: "",
        createdAt: "2022-01-19T04:50:09.300Z",
        lastModifiedUserEmail: "",
        lastModifiedUserId: "",
        lastModifiedAt: "2022-01-19T04:50:09.300Z",
      },
      saveStudyModel: {
        studyId: "",
        studyRef: "",
        site: [],
      },
      saveSiteModel: {
        id: "",
        sitecode: "",
      },
      rollsprivilages: "",
    };
  },
  async mounted() {
    this.token = store.getters.getIdToken;
    this.rollsprivilages = await store.getters.getRolesprivilegeData;

    this.headerValue = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json",
      },
    };
    this.getallusers();
  },
  methods: {
    async onChange(event) {
      this.selectedUser = event.target.value;
      console.log(this.selectedUser);
      await this.getUserStudy();
      this.siteDisplayList = [];
      this.studyName = "";
    },
    async savebutton(save) {
      if (this.userHaveSites) {
        this.createSaveModel();
        this.editModel.study = this.saveModel.study;
        this.editModel.userId = this.saveModel.userId;
        console.log(this.editModel);
        await axios
          .put(
            `${this.baseurl}/management/site/privilegeupdate`,
            this.editModel,
            this.headerValue
          )
          .then((response) => {
            console.log("edit success");
            // alert("User site privilege updated successfully");
               alert(this.$t('navigations.user site privilege updated successfully'))
          })
          .catch((err) => {
            console.log("failed to edit");
          });
        this.editModel.study = [];
        this.saveModel.study = [];
        this.saveStudyModel.site = [];
      } else {
        this.createSaveModel();
        console.log(this.saveModel);
        await axios
          .post(
            `${this.baseurl}/management/site/privilege`,
            this.saveModel,
            this.headerValue
          )
          .then((response) => {
            console.log("saved successfully");
            alert("User site privilege added successfully");
          })
          .catch((err) => {
            console.log("failed to save");
          });
        this.editModel.study = [];
        this.saveModel.study = [];
        this.saveStudyModel.site = [];
      }
    },
    async mapAllSitestoCheckedSite() {
      this.siteDisplayList = [];
      this.sites = this.userHaveSites ? this.sites : [];
      this.allSites.forEach((v) => {
        var ElementPresent = false;
        let xyz = {
          id: String,
          sitecode: String,
          isChecked: Boolean,
        };
        this.sites.forEach((element) => {
          if (element.id == v.siteID) {
            ElementPresent = true;
          }
        });

        if (ElementPresent) {
          xyz.id = v.siteID;
          xyz.sitecode = v.siteCode;
          xyz.isChecked = true;
          this.siteDisplayList.push(xyz);
        } else {
          xyz.id = v.siteID;
          xyz.sitecode = v.siteCode;
          xyz.isChecked = false;
          this.siteDisplayList.push(xyz);
        }
      });
    },
    createSaveModel() {
      console.log("create save model");
      this.siteDisplayList.forEach((element) => {
        let site = {
          id: String,
          sitecode: String,
        };
        if (element.isChecked) {
          site.id = element.id;
          site.sitecode = element.sitecode;
          this.saveStudyModel.site.push(site);
        }
      });
      this.saveStudyModel.studyId = this.selectedStudy;
      this.saveStudyModel.studyRef = this.studyRef;
      this.saveModel.study.push(this.saveStudyModel);
      this.saveModel.userId = this.selectedUser;
      this.saveModel.emailId = this.selectedUser;
    },

    async getAllSites() {
      console.log("get privileges");
      await axios
        .get(
          `${this.baseurl}/management/site/getall?studyId=${this.selectedStudy}`,
          this.headerValue
        )
        .then((response) => {
          this.allSites = response.data;
          console.log("getallsites");
        })
        .catch((err) => {
          console.log("failed to get all sites");
        });
    },
    async getPrivilegesbyid(study) {
      console.log("get privileges");
      this.selectedStudy = study.studyId;
      this.studyName = study.studyName;
      this.studyRef = study.studyRef;
      await this.loadsites();
      await this.getAllSites().then((s) => {
        this.mapAllSitestoCheckedSite();
      });
      this.toggle = !this.toggle;
    },
    async getallusers() {
      const idtoken = store.getters.getIdToken;

      console.log("get all users");

      await axios

        .get(`${this.baseurl}/account-core/user/getallusers`, {
          headers: {
            Authorization: "Bearer " + idtoken,

            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          this.users = response.data;
          // console.log(this.users);
        })

        .catch((err) => {
          console.log("Have error After user listed");
        });
    },
    async getUserStudy() {
      console.log("get user study");
      // if (this.UserScreen.email == "All") {
      //   this.UserScreen.email = "";
      // }
      this.toggle = false;
      await axios
        .get(
          `${this.baseurl}/management/study/getallstudy?user=${this.selectedUser}`,
          this.headerValue
        )
        .then((response) => {
          this.studies = response.data;
        })
        .catch((err) => {
          console.log("Have error After listed");
        });
      await axios
        .get(
          `${this.baseurl}/management/site/isusersiteprivilegeexist?userid=${this.selectedUser}`,
          this.headerValue
        )
        .then((response) => {
          this.userHaveSites = response.data;
        })
        .catch((err) => {
          console.log("Have error After getting t/f");
        });
    },
    async loadsites() {
      console.log("load sites");
      await axios
        .get(
          `${this.baseurl}/management/site/privilege?userid=${this.selectedUser}&studyId=${this.selectedStudy}`,
          this.headerValue
        )
        .then((response) => {
          this.sites = response.data;
        })
        .catch((err) => {
          console.log("Have error After study listed");
          console.log(err);
        });
    },
  },
};
</script>
   
<style scoped>
/* Create a two-column layout */

@import "../../assets/formpage.css";
@import "../../assets/style.css";
.ibox-title3 {
  background-color: var(--pop-up-background);
  --primary-clr: #618bd7;
  --secondary-clr: #145faf;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 0px;
  color: inherit;
  margin-bottom: 0;
  padding: 9px 90px 3px 15px;
  min-height: 36px;
  position: relative;
  clear: both;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.ibox-title2 {
  background-color: var(--pop-up-background-light);
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: #4a4b4b;
  margin-bottom: 0;
  padding: 9px 90px 3px 15px;
  min-height: 36px;
  position: relative;
  clear: both;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.nav-label {
  color: #4a4b4b;
}
.list-group-item {
  background-color: inherit;
  border: 1px solid #e7eaec;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}
h5 {
  display: inline-block;
  font-size: 0.8rem;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: none;
  color: #ffffff;
}
.btn-verify1 {
  width: 30px;
  height: 30px;
  padding: 3px 0;
  border-radius: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 1.428571429;
  background-color: #1ab394 !important;
  border: 0px solid #bb458f;
}
.btn-verify:hover {
  color: #fff !important;
}
.btn1 {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  transition: color 0.15s;
}
.scrollable {
  overflow-y: scroll;
  height: 200px;
}
.activeTab {
  background-color: #d9e4ff;
}
.float-right .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0; /* Adjusted to align tooltip with the button */
  top: 30px; /* Reduced to bring it closer to the button */
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.float-right:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}


</style>


