<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-6 align-self-center">
            <h2 class="mb-0">{{ $t("navigations.adverse events cross tabulation") }}</h2>
        </div>
        <div class="col-lg-2">
            <i class="fa fa-times close" @click="$router.push(`/analyticalDashboard`)"></i>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-md-12 pl-1 mb-2">
                <historytab :historyarray="historykeys" @gotohistorylevel="gotohistorylevel" @exporttopdf="exporttopdf"
                @exporttoexcell="exporttoexcell"/>
            </div>
            <div ref="captureArea1" class="col-12 col-md-6 mb-2 pl-1">
                <stackedbarchart :availableColumns="arrayofkeys" xAxisKey="TRT" yAxisKey="AESEV"
                    segmentKey="AE CATEGORY" :loader="loader" :data="data" :templateId="''"
                    :fromPage="'descriptiveStatistics'" chartlabel="AE Severity by Treatment"
                    @drilldowncurrentchart="drilldowncurrentchartforbar" @opendrillpopup="drillpopupopened" @exporttoexcell="exporttoexcell"/>
            </div>
            <div ref="captureArea2" class="col-12 col-md-6 mb-2">
                <D3LineChart :data="data"  :loader="loader" :templateId="templateId" :chartlabel="'Impact of Subject Characteristics'" fromPage="adverseEventsCross" @opendrillpopup="drillpopupopened" @drilldowncurrentchart="drilldowncurrentchartforbar" @exporttoexcell="exporttoexcell"/>
            </div>
            <div ref="captureArea3" class="col-md-12 mb-2 pl-1">
            <scatteredploat
              xAxisKey="AGE"
              :yAxisKey="scatteredy"
              xAxislabel="Age"
              :yaxisOptions="arraykeys"
              yAxislabel=""
              :loader="loader"
              :data="data"
              :fromPage="'adverseEventsCross'"
               @drilldowncurrentchart="drilldowncurrentchartforbar"
               @opendrillpopup="drillpopupopened"
               @exporttoexcell="exporttoexcell"
            />
          </div>
            <div class="col-lg-12 pl-1 mb-1">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                        <span>Patient Distribution</span>
                    </div>
                    <div class="stat-body-white">
                        <advtable :loader="loader" fileheadding="Adverse events cross tabulation" :data="data" fromPage="patientRetention"
                            @drilldowncurrentchart="drilldowncurrentchartforbar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <chartdrill v-if="drillpopup" fileheadding="Adverse events cross tabulation" :drilldata="drilldata" :templateId="templateId" :fromPage="'adverseEventsPop'"
    @closepopup="drillpopup=false" />
</template>
<script src="./adverseEventCrossTab.js"></script>
<style scoped>
@import url("../../custom_lib/AiCharts/charts.css");
.page-break {
  page-break-before: always; /* Ensures new page starts before this element */
}
  
</style>