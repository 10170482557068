/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import jwtInterceptor from "../Shared/jwtInterceptor";
// import axios from "axios";
import axios from "axios";
import store from "../../store/index";
export default {
  name: "viewTemplate",
  props: {
    tempId:{
          type: String,
          default: ''
      },
      closedoption:{
        type: String,
        default: 'templatelist'
       }
            },
  async beforeMount() {
    await this.setTempId();
  },
  async mounted(){
    this.getselectedtemplate();
    if(this.closedoption == 'fieldprivileges')
      {
        console.log("If entered")
        this.requiredRole=true
      }
  },
  data() {
    return {
      envvar: process.env.NODE_ENV,
      templateId: "",
      baseurl:process.env.VUE_APP_Service_URL,
      templateName:"",
      requiredRole:false
    };
  },
  methods: {
async setTempId(){
  this.templateId = this.tempId;
},
async onclosed() {
  const StudyId = store.getters.getStudyIs;
  if(this.closedoption == "studyVist"){
    await this.$router.push(`/visitlist/${StudyId}`);
  }
  else{
    await this.$router.push(`/${this.closedoption}`);
  }
},
async getselectedtemplate () {
  const idtoken = store.getters.getIdToken;
  console.log("in get form function");
  await axios
    .get(
      `${this.baseurl}/forms/template/get/${this.templateId}?api-version=1.0`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("The list is",res.data);
      this.templateName=res.data.templateName
    })
    .catch((err) => {
      console.log(err);
    });
},

  },
};
