/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
export default {
  name: "fieldprivileges",
  components: {
    VPagination,
  },
  data() {
    return {
      baseUrl: `${process.env.VUE_APP_Service_URL}/forms/template/`,
      baseapi: process.env.VUE_APP_Service_URL,
      defaultdate: "",
      selectedstudy: "",
      stdid: "",
      templateValue: false,
      templateStudyValue: false,
      modelstudy: [],
      templateList: [],
      pageindex: 1,
      totalitems: 0,
      totalpages: 0,
      currentPage: 1,
      studytempIndex: 1,
      studytempCurrent: 1,
      pagesize: 10,
      rollsprivilages: "",
    }
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.rollsprivilages = await store.getters.getRolesprivilegeData;

    this.searchFormName = this.formName;
    this.getstudy();
    this.filter();
    this.GetData()
  },
  methods: {
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate)
      }
    },
    async getstudy() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/study/getallstudy`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res);
          this.modelstudy = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async GetData() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(this.baseUrl + `listtemplate?pageindex=` + this.pageindex + `&pagesize=` + this.pagesize + `&SortOrder=0&Status=Approved&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.totalitems = Math.ceil(res.data.totalCount / 10);
            this.templateList = res.data.results;
            this.templateValue = true
            this.templateStudyValue = false
          } else {
            console.log("no template found");
          }
        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });
    },
    async onChange() {
      // alert(event.target.value);
      const idtoken = store.getters.getIdToken;
      // if(this.selectedstudy=='All')
      // {
      //   this.selectedstudy=''
      // }
      await axios
        .get(`${this.baseUrl}listtemplate?StudyID=${this.selectedstudy}&pageindex=${this.studytempIndex}&pagesize=10&Status=Approved`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log("Event list", res);
          this.templateList = []
          this.templateList = res.data.results
          this.totalpages = Math.ceil(res.data.totalCount / 10);
          this.templateValue = false
          this.templateStudyValue = true
          if (this.stdid == "All") {
            this.GetData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageindex = page;
      this.GetData();
    },
    async studyloadPage(page) {
      alert(page);
      this.studytempCurrent = page;
      this.studytempIndex = page;
      this.onChange();
    }
  }
}