/* eslint-disable */
import "/src/assets/style.css";
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import VPagination from "@hennge/vue3-pagination";
import VueResizable from "vue-resizable";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import createFormModal from "../createFormModal/createForm.vue";
import jwt_decode from "jwt-decode";
import QRCode from "qrcode";
import createnewversion from "../newversion/createnewversion.vue";
import createrepeatedfield from "../Create_repeated_field/createrepeatedfield.vue";
export default {
  name:"mlCriteriaCapture",
  components: {
    Multiselect,
    VPagination,
    VueResizable,
    createFormModal,
    QRCode,
    createnewversion,
    createrepeatedfield
  },
  props: { 
    formid: String,
    templateId:{
      type: String,
      default:""
    } 
  },
  data() {
    const tH = 90;
    const tW = "100%";
    return {
     
    subjectSelection: {
      templateId: null,
      pageNumber: null,
      fieldName: '',
      isRepetedField: false,
      templateFieldProperties : null,
     },
    templateFieldProperties :{
      featureField: false,
      fieldsToConsider: [
        { 
          considerationRate: 0,
          considerableFields: [

          ]
         },
       ],
       dropdownFields: [
        { 
          considerationRate: 0,
          considerableFields: []
         },
       ],
       rangesToConsider : [
        {
          considerationRate: 0,
          min: 0,
          max: 0
        }
       ],
       forBoolean: {
        considerationRate: 0
      }
    },
    

      popuptype:"save",
      repfieldstoedit:[],
      repeatedgroupgroupnos:{},
      temprepeatingarray:[],
      repeatedfieldpopup:false,
      editingOption: null,
      rangeoption: [],
      radioorientation: "vertical",
      cyrrentformid: "",
      currentsourceid: "",
      versionhistoryarray: [],
      isformlocked: false,
      replacetemplatevar: false,
      exisitingvisitsvar: false,
      reviewandapprovalvar: false,
      designcompletepopup: false,
      sourcetemplateidcreate: "",
      newversioncreate: "",
      currentformversion: "",
      newversionformid: "",
      creatversionpopup: false,
      repeatoptionss: "",
      newFieldValue:"",
      calculate: {
        firstfield: "",
        secondfield: "",
        operations: "+",
      },
      presavedrepeatedmodel: "",
      repeatedtemplatename: "",
      repeatedtemplatefieldarray: [],
      calcfieldnamesarray: [],
      repeatedoptions: [],
      repeatingfieldname: "",
      repeatingtype: "text",
      addrepeatedoptfield: false,
      createrepeatedmodel: false,
      currentgrid: [],
      gridoptarray: {},
      optiongroupeditid: "",
      activeTab: "Tab 1",
      highlitedcolumn: "",
      modeloptiontype: "",
      selectedDropdown:[],
      selectedDropdown2:[],
      selectedDropdown3:[],
      dropdownoptions:[],
      selectedmodel: {},
      optiongroups: {},
      imagid: "",
      currentoptions: [],
      qrData:"",
      qrcodecontents: "",
      blankfielddetails: "",
      scaleminval: 0,
      scalemaxval: 0,
      viewtempicon: false,
      templateid: "",
      idtoken: "",
      griddata: [
        {
          Rows: [""],
          Columns: [""],
          Fieldtypes: ["text"],
          Fielddata: {},
          Fieldoptions: {},
        },
      ],
      griditm: "",
      selectedpagegroup: "",
      repeatingdata: [1],
      gridrows: [1],
      gridcolumns: [1],
      gridgeneral: "flex-sm-fill text-sm-center nav-link active",
      gridconfiguration: "flex-sm-fill text-sm-center nav-link",
      gridoption: "general",
      selectedpageid: "",
      qrcodetxt: {},
      width: [tW],
      height: [tH, tH, tH, tH, tH, tH],
      fit: true,
      left: [`calc( 50% - ${tW / 2}px)`],
      top: [`calc(50% - ${tH / 2}px)`],
      maxH: 90,
      minH: 10,
      issaved: false,
      minlength: null,
      maxlength: null,
      minvalue: null,
      maxvalue: null,
      baseurl: process.env.VUE_APP_Service_URL,
      fileuploadurl: process.env.VUE_APP_File_upload_URL,
      update: false,
      version: "",
      currentversion: "",
      columnselected: false,
      selectedgroup: {},
      selectedfield: 0,
      totalgroups: {},
      isedit: false,
      showCreateFormModal: false,
      fieldnamesarray: [],
      tempfieldname: "",
      totalpages: 0,
      selectedFile: "",
      pageno: 1,
      notcollapsed: true,
      checkboxoptions: "",
      checkboxoptionsarray: [],
      radiooptions: "",
      radiovalues: "",
      radiooptionsarray: [],
      collapseDiv: [false],
      showSideModal: [false],
      selectedmenu: "",
      featureValue:false,
      inclusiveLabel:false,
      exclusiveLabel:false,
      considerationRate:false,
      trueConsideration:"",
      falseConsideration:"",
      value: [],
      values: [],
      inputElements: [
        "color",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
        "file",
      ],
      groups: {
        1: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13],
      },
      // options: [
      //   "Required",
      //   "Number",
      //   "alphaneumeric",
      //   "Length range",
      //   "Value range",
      // ],
      options: [
        { value: "Required", label: "Required" },
        { value: "Number", label: "Number" },
        { value: "Alphanumeric", label: "alphaneumeric" },
        { value: "email", label: "Email" },
        { value: "lengthrange", label: "Length range" },
        { value: "valuerange", label: "Value range" },
      ],
      selectedrepeatedtemp: [],
      commonvalidations: ["Required"],
      newtemplate: [],
      templatename: "",
      pagename: "",
      pagearray: [],
      pageobj: {},
      grouparray: {},
      formHeader: "test",
      selectedpage: "",
      grouporderno: "",
      headdingtext: "",
      fieldname: "",
      labeltext: "",
      componentKey: 1,
      type: "",
      showModalll: 0,
      temparray: [],
      totalgroupsforedit: [],
      testtempvar: [],
      listforms: [],
      formname: "",
     // componentModal: false,
      aitoolModal: false,
      pages: {},
      showDragCol: false,
      status: "New",
      dropdownError: false,
      emptyField: [false],
      scaleError: [false, false, false, false],
      newfieldname: "",
      showForm: false,
      showoptmodel: false,
      manageoptoptmodel: false,
      optiongroup: "",
      optiontype: "radio",
      optiongroupopts: "",
      groupoptions: [],
      optiongroupsearch: "",
      modeloptions: [],
      singlemodeloptions: "",
      singlemodelvalues: "",
      selectedrepeatedgroupno: 1,
      repeatedmeasurepopup: false,
      repeatedmeasurelistpopup: true,
      controlSelectError: false,
      newversionViewTemp: false,
      repeatedoptions: [],
      repeatedmeasurevalarray: [],
      temprepeatedmeasureval: {},
      repratmeasureid: new Date(),
      rollsprivilages: "",
      managerepeatfieldmodel: false,
      sourceTemplateId :"",
      oldFieldName : "",
      rangeErrors : [false,false,false,false],
      fieldsConsider:[
        {
            considerationRate: 0,
            considerableFields: [
  
            ]
        }
      ],
      fieldstoConsiderText:[
        {
            considerationRate: 0,
            considerableFields: [
  
            ]
        }
      ]
    };
  },
  beforeRouteEnter(to, from, next) {
    const previousRouteName = from.name;
    next((vm) => {
      vm.previousRouteName = previousRouteName;
    });
  },
  computed: {
    textFields() {
      // Filter fields where type is text
      return this.newtemplate[0].pages[this.pageno - 1].field.filter(
        (field) => field.type === "textbox"
      );
    },
    filteredOptions1() {
      return this.dropdownoptions.filter(
        option => !this.selectedDropdown2.includes(option.codevalue)
      );
    },
    filteredOptions2() {
      return this.dropdownoptions.filter(
        option => !this.selectedDropdown.includes(option.codevalue)
      );
    },
  },
  watch: {
    selectedDropdown(newValue) {
      this.filteredOptions2 = this.dropdownoptions.filter(option => !newValue.some(selected => selected.codevalue === option.codevalue));
    },
    selectedDropdown2(newValue) {
      this.filteredOptions1 = this.dropdownoptions.filter(option => !newValue.some(selected => selected.codevalue === option.codevalue));
    }
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;   
    await this.getrepeatedfieldlist();
    await this.listoptiongroups();
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    this.pagearray = [];
    this.temparray = [];
    const isStudyProfileRoute = localStorage.getItem("isStudyProfileRoute");
    const isFormTemplateRoute = localStorage.getItem("isFormTemplateRoute");
    const isFormCreateTemplateRoute = localStorage.getItem("isFormCreateTemplateRoute");
    if (isStudyProfileRoute === "true") {
      const templateFormId = localStorage.getItem("templateFormId");
      const version = localStorage.getItem("version");
      this.sourceTemplateId = localStorage.getItem("sourceTemplateId");

      await this.createnewversion(templateFormId, version, this.sourceTemplateId);

      localStorage.removeItem("templateFormId");
      localStorage.removeItem("version");
      localStorage.removeItem("sourceTemplateId");
      localStorage.removeItem("isStudyProfileRoute");
      await this.createnewversion(templateFormId, version, this.sourceTemplateId);
    }
    if (isFormTemplateRoute === "true") {
      const templateFormId = localStorage.getItem("templateFormId");
      const version = localStorage.getItem("version");
      this.sourceTemplateId = localStorage.getItem("sourceTemplateId");
      localStorage.removeItem("templateFormId");
      localStorage.removeItem("version");
      localStorage.removeItem("sourceTemplateId");
      localStorage.removeItem("isFormTemplateRoute");
      await this.getPages(templateFormId, this.sourceTemplateId, version, false);
    }
    if (isFormCreateTemplateRoute === "true") {
      const templateFormId = localStorage.getItem("templateFormId");
      const version = localStorage.getItem("version");
      this.sourceTemplateId = localStorage.getItem("sourceTemplateId");
      localStorage.removeItem("templateFormId");
      localStorage.removeItem("version");
      localStorage.removeItem("sourceTemplateId");
      localStorage.removeItem("isFormCreateTemplateRoute");
      this.createForm();
    }

    window.addEventListener("studyIdChanged", async (event) => {
      await this.getForms();
    });
    this.rollsprivilages = store.getters.getRolesprivilegeData;
    this.getForms();
    if (this.previousRouteName.includes("viewform")) {
      this.reloadtemplatefun(isFormTemplateRoute);
    }
    store.dispatch("setCollapse", true);
  },
  async unmounted() {
    store.dispatch("setCollapse", false);
    let pagewrapper = document.querySelector("#page-wrapper");
    pagewrapper.style.width = null;
  },
  methods: {
    handleLabelChange(event,type) {
      if(type == 'checkbox')
        {
          if (event.target.id == 'exclusiveRadio' && event.target.checked==true) {
            this.inclusiveLabel = false;
            this.trueConsideration="100"
            this.falseConsideration="0"
            this.considerationRate=false
   
          } else if (event.target.id == 'inclusiveRadio' && event.target.checked==true) {
            this.exclusiveLabel = false;
            this.trueConsideration="100"
            this.falseConsideration="0"
            this.considerationRate=false
          }
          else{
            this.fieldsConsider = []
            this.trueConsideration=""
            this.falseConsideration=""
            this.considerationRate=true
          }
        }
      if(type=='radio')
        {

        }
    
    },
    getFilteredOptions(index) {
      let options = this.dropdownoptions;
      for (let i = 0; i < this.fieldsConsider.length; i++) {
          if (i !== index) {
              options = options.filter(option => !this.fieldsConsider[i].considerableFields.some(selected => selected.codevalue === option.codevalue));
          }
      }
      return options;
  },
    addItem(type) {  

                  this.templateFieldProperties.fieldsToConsider.push({ considerableFields: [], considerationRate: 0 });    
                
                  
            },
            addropItem() {  
              this.fieldsConsider.push({ considerationRate: 0, considerableFields: []});           
             },
   saverangebutton() {     
              this.templateFieldProperties.rangesToConsider.push({ considerationRate: 0, min: 0, max: 0 });           
             },
            convertToInt(path) {
              const keys = path.split('.');
              let obj = this;
              for (let i = 0; i < keys.length - 1; i++) {
                obj = obj[keys[i]];
              }
              const key = keys[keys.length - 1];
              obj[key] = parseInt(obj[key], 10);
            },
          
    async handleReceivedObject(receivedObject) {
      this.temprepeatingarray=receivedObject;
      this.fieldnamesarray = [];
      this.newtemplate[0].pages.forEach(page => {
        page.field.forEach(field => {
          this.fieldnamesarray.push(field.fieldName.trim().toLowerCase())
          if(field.type == "repeatedmeasure" || field.type == "RepeatingMeasure" || field.type == "repeatingfield"){
            field.repeatingFields.forEach(rField => {
              this.fieldnamesarray.push(rField.fieldName.trim().toLowerCase())
            });
          }
        });
      });
      receivedObject[0].repeatingFields.forEach(rField => {
        if(!this.fieldnamesarray.includes(rField.fieldName.trim().toLowerCase())){
          this.fieldnamesarray.push(rField.fieldName.trim().toLowerCase());
        }
      });
      if(this.popuptype == "save"){
      await this.addfield("repeatingfield");
      }
      else{
        this.editfield();
      }
      this.closerepeatedfield();
    },
    async handleCheckboxChange() {
      if (this.reviewandapprovalvar == true) {
        this.replacetemplatevar = false;
        this.exisitingvisitsvar = false;
      }
    },
    async repeatedmeasuremodal(options) {
      this.repeatedoptions = JSON.parse(options);
      console.log(this.repeatedoptions);
      this.repeatedmeasurepopup = true;
    },
    async closerepeatedmeasuremodal() {
      this.repeatedmeasurepopup = false;
    },
    async repeatedmeasurlist() {
      this.repeatedmeasurelistpopup = true;
    },
    async repeatedmeasurdatapopup() {
      this.repratmeasureid = new Date();
      this.repeatedmeasurelistpopup = false;
    },
    async deleterepeatedmeasure(item) {
      let indexToDelete = this.repeatedmeasurevalarray.indexOf(item);

      if (indexToDelete !== -1) {
        // Remove the element at the found index
        this.repeatedmeasurevalarray.splice(indexToDelete, 1);
      }
    },
    async addrepeatemesureentries() {
      let temprepeatedval = {};
      this.repratmeasureid = new Date();
      this.temprepeatedmeasureval.ID = this.repratmeasureid;
      temprepeatedval = this.temprepeatedmeasureval;
      this.repeatedmeasurevalarray.push({ ...temprepeatedval });
      temprepeatedval = {};
      this.repeatedmeasurdatapopup();
    },
    async addrepeatedmeasure() {
      console.log(this.selectedrepeatedtemp);
      this.addfield("repeatedmeasure");
    },
    async addrepeatedfield() {
      this.selectedrepeatedtemp.forEach(async (eachrepeatedtemp) => {
        console.log(eachrepeatedtemp);
        this.fieldname = eachrepeatedtemp.fieldName;
        this.labeltext = eachrepeatedtemp.fieldName;
        this.currentoptions = eachrepeatedtemp.options;
        let groptemparr = this.temparray[0].pages[this.pageno - 1].Group;
        let lastgroup = groptemparr[groptemparr.length - 1];
        this.addrepeatedonecolumn(lastgroup);
        this.radiooptionsarray = eachrepeatedtemp.options;
        await this.addfield(eachrepeatedtemp.type);
      });
    },
    async getrepeatedfieldlist() {
      await axios
        .get(`${this.baseurl}/forms/templatedesign/listfield`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          this.presavedrepeatedmodel = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async manageoptiongroupmodel() {
      window.scrollTo(0, 0);
      this.manageoptoptmodel = true;
    },
    async managerepeatedfieldmodel() {
      window.scrollTo(0, 0);
      this.managerepeatfieldmodel = true;
    },
    async closemanagerepeatmodel() {
      window.scrollTo(0, 0);
      this.managerepeatfieldmodel = false;
    },
    async gridoptfun(fieldname, gridoptions) {
      console.log("gridoptfun");
      this.gridoptarray[fieldname] = JSON.parse(gridoptions);
    },
    async closemanageoptmodel() {
      window.scrollTo(0, 0);
      this.manageoptoptmodel = false;
      this.optiongroupsearch = "";
      this.listoptiongroups();
    },
    async creatoptiongroup() {
      if (this.optiongroup == "" || this.optiongroup == null) {
        alert("Please enter option group name");
      } else if (this.groupoptions.length == 0) {
        alert("Please enter options");
      } else {
        let optiondata = {
          createdUserEmail: jwt_decode(this.idtoken).email,
          createdUserId: jwt_decode(this.idtoken).user_id,
          createdAt: new Date(),
          lastModifiedUserEmail: jwt_decode(this.idtoken).email,
          lastModifiedUserId: jwt_decode(this.idtoken).user_id,
          lastModifiedAt: new Date(),
          optiongroup: this.optiongroup,
          label: this.optiongroup,
          optiontype: this.optiontype,
          groupoptions: this.groupoptions,
        };
        await axios

          .post(
            `${this.baseurl}/forms/optiongroups/create
          `,
            optiondata,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            alert("Option group added successfully.");
            this.listoptiongroups();
            this.closeoptmodel();
          })
          .catch((err) => {
            console.log("error isssssss", err);
            console.log(err.response.data.messages[0]);
            if (
              err.response.data.messages[0].includes(
                "Option Group name already exists."
              )
            )
              alert("Option group name already exists");
            this.closeoptmodel();
          });
      }
    },
    async addroptiongroupopts() {
      if (
        this.singlemodelvalues.trim() != "" &&
        this.singlemodelvalues.trim() != null &&
        this.optiongroupopts.trim() != "" &&
        this.optiongroupopts.trim() != null
      ) {
        let optgroupobj = {
          optionname: "",
          codevalue: "",
        };
        if (this.optiongroupopts != null && this.optiongroupopts != "") {
          const isNameDuplicate = this.groupoptions.some(
            (option) => option.optionname === this.optiongroupopts
          );
          if (isNameDuplicate) {
            alert("Option group with the same Option name already exists!");
            return;
          }
          const isCodeDuplicate = this.groupoptions.some(
            (option) => option.codevalue === this.singlemodelvalues
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
          optgroupobj.optionname = this.optiongroupopts.trim();
          optgroupobj.codevalue = this.singlemodelvalues.trim();
          this.groupoptions.push(optgroupobj);
        }
        this.optiongroupopts = "";
        this.singlemodelvalues = "";
        optgroupobj = {
          optionname: "",
          codevalue: "",
        };
      }
    },
    async UpdateDropdownoptions() {
      //  Used in both radio and dropdown popups for options
      if (
        this.radiovalues.trim() !== ""  && this.radiovalues.trim() !== null &&
        this.radiooptions.trim() !== "" && this.radiooptions.trim() !== null
      ) {
        if (this.editingOption !== null) {
          if (
            this.radiovalues !== this.editingOption.codevalue ||
            this.radiooptions !== this.editingOption.optionname
          ) {
            const isNameDuplicate = this.radiooptionsarray.some(
              (option) =>
                option.optionname === this.radiooptions.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isNameDuplicate) {
              alert("Option group with the same name already exists!");
              return;
            }

            const isCodeDuplicate = this.radiooptionsarray.some(
              (option) =>
                option.codevalue === this.radiovalues.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isCodeDuplicate) {
              alert("Option group with the same Code value already exists!");
              return;
            }
          }
        } else {
          const isNameDuplicate = this.radiooptionsarray.some(
            (option) => option.optionname === this.radiooptions.trim()
          );
          if (isNameDuplicate) {
            alert("Option group with the same Option name already exists!");
            return;
          }

          const isCodeDuplicate = this.radiooptionsarray.some(
            (option) => option.codevalue === this.radiovalues.trim()
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
        }

        if (this.editingOption !== null) {
          const editedOptionIndex = this.radiooptionsarray.findIndex(
            (option) => option.codevalue === this.editingOption.codevalue
          );
          if (editedOptionIndex !== -1) {
            this.radiooptionsarray[
              editedOptionIndex
            ].codevalue = this.radiovalues;
            this.radiooptionsarray[
              editedOptionIndex
            ].optionname = this.radiooptions;
            this.editingOption = null;

            this.radiovalues = "";
            this.radiooptions = "";
            return;
          }
        }

        const existingIndex = this.radiooptionsarray.findIndex(
          (option) => option.codevalue === this.radiovalues
        );
        if (existingIndex !== -1) {
          if (
            this.radiooptions !==
            this.radiooptionsarray[existingIndex].optionname
          ) {
            this.radiooptionsarray[
              existingIndex
            ].optionname = this.radiooptions;
          }
        } else {
          this.radiooptionsarray.push({
            codevalue: this.radiovalues,
            optionname: this.radiooptions,
          });
        }
      } else {
        alert("Code value and Option name must not be empty.");
      }

      this.radiovalues = "";
      this.radiooptions = "";
    },
    async UpdateOGoptions() {
      //  Used in Option group options update
      if (
        this.singlemodelvalues.trim() !== "" &&
        this.singlemodelvalues.trim() !== null &&
        this.optiongroupopts.trim() !== "" &&
        this.optiongroupopts.trim() !== null
      ) {
        if (this.editingOption !== null) {
          if (
            this.singlemodelvalues !== this.editingOption.codevalue ||
            this.optiongroupopts !== this.editingOption.optionname
          ) {
            const isNameDuplicate = this.groupoptions.some(
              (option) =>
                option.optionname === this.optiongroupopts.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isNameDuplicate) {
              alert("Option group with the same Option name already exists!");
              return;
            }

            const isCodeDuplicate = this.groupoptions.some(
              (option) =>
                option.codevalue === this.singlemodelvalues.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isCodeDuplicate) {
              alert("Option group with the same Code value already exists!");
              return;
            }
          }
        } else {
          const isNameDuplicate = this.groupoptions.some(
            (option) => option.optionname === this.optiongroupopts.trim()
          );
          if (isNameDuplicate) {
            alert("Option group with the same Option name already exists!");
            return;
          }

          const isCodeDuplicate = this.groupoptions.some(
            (option) => option.codevalue === this.singlemodelvalues.trim()
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
        }

        if (this.editingOption !== null) {
          const editedOptionIndex = this.groupoptions.findIndex(
            (option) => option.codevalue === this.editingOption.codevalue
          );
          if (editedOptionIndex !== -1) {
            this.groupoptions[
              editedOptionIndex
            ].codevalue = this.singlemodelvalues;
            this.groupoptions[
              editedOptionIndex
            ].optionname = this.optiongroupopts;
            this.editingOption = null;

            this.singlemodelvalues = "";
            this.optiongroupopts = "";
            return;
          }
        }

        const existingIndex = this.groupoptions.findIndex(
          (option) => option.codevalue === this.singlemodelvalues
        );
        if (existingIndex !== -1) {
          if (
            this.optiongroupopts !== this.groupoptions[existingIndex].optionname
          ) {
            this.groupoptions[existingIndex].optionname = this.optiongroupopts;
          }
        } else {
          this.groupoptions.push({
            codevalue: this.singlemodelvalues,
            optionname: this.optiongroupopts,
          });
        }
      } else {
        alert("Code value and Option name must not be empty.");
      }

      this.singlemodelvalues = "";
      this.optiongroupopts = "";
    },
    async editOptionsfordropdown(codevalue, optionname) {
      //  Used in both radio and dropdown popups for edit click in table(template design)
      this.editingOption = { codevalue, optionname };

      this.radiovalues = codevalue;
      this.radiooptions = optionname;
    },
    async editOGOptions(codevalue, optionname) {
      this.editingOption = { codevalue, optionname };

      this.singlemodelvalues = codevalue;
      this.optiongroupopts = optionname;
    },
    async focusOnEdit() {
      //  Used in both radio and dropdown popups for focusing to the options when edit clicks

      this.$nextTick(() => {
        this.$refs.codeValueInput.focus();
        this.$refs.optionNameInput.focus();
      });
    },
    async cancelEditDropdown() {
      //  Used in both radio and dropdown popups to cancel edit mode
      this.editingOption = null;

      this.radiovalues = "";
      this.radiooptions = "";
      // below used in Option group popup
      this.singlemodelvalues = "";
      this.optiongroupopts = "";
    },
    async deleteoptions(codevalue) {
      // Used in both radio and dropdown popups to delete options in the table
      if (confirm("Are you sure you want to delete this option?")) {
        if(  this.radiooptionsarray.length >1){
          const index = this.radiooptionsarray.findIndex(
            (option) => option.codevalue === codevalue
          );
          if (index !== -1) {
            this.radiooptionsarray.splice(index, 1);
  
            console.log("Option deleted:", codevalue);
            this.radiovalues = "";
            this.radiooptions = "";
          } else {
            console.error("Option not found:", codevalue);
          }
        }
        else{
          alert("Cannot delete all options")
        }
      }
    },
    async deleteOGoptions(codevalue) {
      if (confirm("Are you sure you want to delete this option?")) {
        // Used in Option Group popup to delete options in the table
        const index = this.groupoptions.findIndex(
          (option) => option.codevalue === codevalue
        );
        if (index !== -1) {
          this.groupoptions.splice(index, 1);

          console.log("Option deleted:", codevalue);
          this.singlemodelvalues = "";
          this.optiongroupopts = "";
        } else {
          console.error("Option not found:", codevalue);
        }
      }
    },
    async openoptmodel() {
      window.scrollTo(0, 0);
      this.showoptmodel = true;
    },
    async closeoptmodel() {
      window.scrollTo(0, 0);
      this.optiongroup, (this.optiongroup = "");
      this.singlemodelvalues = "";
      this.optiontype = "";
      this.optiongroupeditid = "";
      this.groupoptions = [];
      this.showoptmodel = false;
      this.optiongroupopts = "";

      this.editingOption = null;
    },
    async clearmodelfields() {
      this.fieldname = "";
      this.labeltext = "";
      this.radiooptionsarray = [];
      this.modeloptiontype = "";
      this.selectedmodel = {};
    },
    async addmodeloptions() {
      if (
        this.singlemodeloptions.trim() != "" &&
        this.singlemodeloptions.trim() !== null &&
        this.singlemodelvalues.trim() != "" &&
        this.singlemodelvalues.trim() != null
      ) {
        const isNameDuplicate = this.selectedmodel.groupoptions.some(
          (option) => option.optionname === this.singlemodeloptions
        );
        if (isNameDuplicate) {
          alert("Option group with the Option name already exists!");
          return;
        }
        const isCodeDuplicate = this.selectedmodel.groupoptions.some(
          (option) => option.codevalue === this.singlemodelvalues
        );
        if (isCodeDuplicate) {
          alert("Option group with the same Code value already exists!");
          return;
        }
        this.currentoptions = {
          optionname: this.singlemodeloptions.trim(),
          codevalue: this.singlemodelvalues.trim(),
        };
        this.selectedmodel.groupoptions.push(this.currentoptions);
        console.log("get value", this.selectedmodel);
        this.singlemodeloptions = "";
        this.singlemodelvalues = "";
      }
    },
    async modelchange() {
      this.modeloptiontype = this.selectedmodel.optiontype;
      this.modeloptions = this.selectedmodel.groupoptions;
    },
    async dropdownmodelchange() {
      this.modeloptiontype = this.selectedmodel.optiontype;
      this.modeloptions = this.selectedmodel.groupoptions;
      this.labeltext = this.selectedmodel.label;
      this.radiovalues = "";
      this.radiooptions = "";
      this.editingOption = null;
      this.radiooptionsarray = [];
      this.selectedmodel.groupoptions.forEach((option) => {
        this.radiooptionsarray.push(option);
      });
    },
    async editOptions(codeval, optname) {
      this.radiovalues = codeval;
      this.radiooptions = optname;
    },
    async radiomodelchange() {
      console.log("this.selectedmodel", this.selectedoptiongroupmodel.label);
      this.labeltext = this.selectedoptiongroupmodel.label;
      this.radiooptionsarray = this.selectedoptiongroupmodel.groupoptions;
    },
    async addmodel() {
      if (this.modeloptiontype === "") {
        this.controlSelectError = true;
      } else {
        this.controlSelectError = false;
        await this.setfieldname();
        this.labeltext = this.selectedmodel.label;
        this.radiooptionsarray = this.selectedmodel.groupoptions;
        this.addfield(this.modeloptiontype);
      }
    },
    async setfieldname() {
      this.fieldname = (Math.floor(Math.random() * 100) + 1).toString();
      if (this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase())) {
        this.setfieldname();
      }
    },
    async viewoptiongroup(id) {
      await axios
        .get(`${this.baseurl}/forms/optiongroups/getoptiongroupbyid/${id}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          this.openeditoptiongroup(res.data, id);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async openeditoptiongroup(data, id) {
      this.optiongroupeditid = id;
      this.optiongroup = data.optiongroup;
      this.optiontype = data.optiontype;
      this.groupoptions = data.groupoptions;
      this.showoptmodel = true;
    },
    async editoptiongroup() {
      if (this.optiongroup == "" || this.optiongroup == null) {
        alert("Please enter option group name");
      } else if (this.groupoptions.length == 0) {
        alert("Please enter options");
      } else {
        let optiondata = {
          createdUserEmail: jwt_decode(this.idtoken).email,
          createdUserId: jwt_decode(this.idtoken).user_id,
          createdAt: new Date(),
          lastModifiedUserEmail: jwt_decode(this.idtoken).email,
          lastModifiedUserId: jwt_decode(this.idtoken).user_id,
          lastModifiedAt: new Date(),
          optiongroup: this.optiongroup,
          label: this.optiongroup,
          optiontype: this.optiontype,
          optiongroupId: this.optiongroupeditid,
          groupoptions: this.groupoptions,
        };
        await axios
          .put(
            `${this.baseurl}/forms/optiongroups/updateoptiongroup`,
            optiondata,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            alert("Option group updated successfully.");
            this.listoptiongroups();
            this.closeoptmodel();
          })
          .catch((err) => {
            console.log("add err response:", err);
            this.closeoptmodel();
          });
      }
    },
    async deleterepeatmodel(id) {
      let data = { id: id };
      if (confirm("Are you sure you want to delete this Repeating model?")) {
        await axios
          .delete(`${this.baseurl}/forms/templatedesign/deletetempfield`, {
            data: {
              id: id,
            },
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.getrepeatedfieldlist();
            alert("Repeating model deleted successfully");
          })
          .catch((err) => {
            alert("Failed due to internal error");
            console.error(err);
          });
      }
    },
    async deleteoptiongroup(id) {
      if (confirm("Are you sure you want to delete this option group?")) {
        await axios
          .delete(
            `${this.baseurl}/forms/optiongroups/deleteoptiongroup?optionGroupId=${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert("Option group deleted successfully");
            this.listoptiongroups();
          })
          .catch((err) => {
            alert("Failed due to internal error");
            console.error(err);
          });
      }
    },
    async searchoptiongroups() {
      await axios
        .get(
          `${this.baseurl}/forms/optiongroups/listoptiongroups?optionname=${this.optiongroupsearch}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.optiongroups = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async listoptiongroups() {
      await axios
        .get(`${this.baseurl}/forms/optiongroups/listoptiongroups`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.optiongroups = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async reloadtemplatefun(isFormTemplateRoute) {
      let currentformid = store.getters.getViewformid;
      let version = store.getters.getversion;
      let sourcetemplateid = store.getters.getsourceTemplateId;
      if (isFormTemplateRoute != "true") {
        this.getPages(currentformid, sourcetemplateid, version, "", false);
      }

      this.collapseDiv[currentformid] = !this.collapseDiv[currentformid];
      if (currentformid) {
        this.viewtempicon = true;
      }
    },
    async griditems() {
      this.griditm = [1, 2, 3, 4];
      return this.griditm;
    },
    async selectconf(val) {
      if (val == "general") {
        this.gridgeneral = "flex-sm-fill text-sm-center nav-link active";
        this.gridconfiguration = "flex-sm-fill text-sm-center nav-link";
      } else if (val == "configuration") {
        this.gridgeneral = "flex-sm-fill text-sm-center nav-link";
        this.gridconfiguration = "flex-sm-fill text-sm-center nav-link active";
      }
      this.gridoption = val;
    },
    async qrcodefun(fieldname, dataurl) {
      try {
        console.log(await QRCode.toDataURL(dataurl));
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err);
      }
    },
    async changepage(id, totalpages, page, sourceid, version, name) {
      await this.getPages(id, sourceid, version, name, false);
      store.dispatch("setsourceTemplateId", sourceid);
      console.log("totalpages", totalpages);
      console.log("page", page);
      console.log("totalpages.indexOf(page)", totalpages.indexOf(page) + 1);
      var elements = document.querySelectorAll(".activeBackground");
      for (var i = 0; i < elements.length; i++) {
        elements[i].classList.remove("activeBackground");
      }
      let changeBackground = document.getElementById(page.pageName + sourceid);
      changeBackground.classList.add("activeBackground");
      this.pageno = totalpages.indexOf(page) + 1;
    },
    async showCollapse(id) {
      console.log("Pages are", this.pages[id]);
      if (this.pages[id] == null || pages[id] == []) {
        alert("No pages found");
      }
    },   
    async getPagesInitially(id, version) {
      this.versionhistoryarray = [];
      this.getversionhistory(id, false);
      this.temparray = [];
      await axios
        .get(
          `${this.baseurl}/forms/templatedesign/getpagesbyid?formId=${id}&version=${version}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.showForm = true;
          this.formname = res.data.formname;
          this.templateid = res.data.templateId;
          this.pages[id] = res.data.pages;
          this.newtemplate[0] = res.data;
          this.assigntemplate();
          this.version = version;
          this.currentversion = this.version;
          this.getForms();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getversionhistory(templateid, firstload) {
      await axios
        .get(
          `${this.baseurl}/forms/template/getversionhistory?sourceId=${templateid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.versionhistoryarray = res.data;
          if (firstload) {
            this.version = this.versionhistoryarray[
              this.versionhistoryarray.length - 1
            ].version;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async handleDropdownChange() {
      this.selectedpageid = "";
      this.getPages(
        this.cyrrentformid,
        this.currentsourceid,
        this.version,
        this.formname,
        true
      );
    },
    async getPages(id, sourceid, version, name, dropdownchange) {
      var elements = document.querySelectorAll(".activeBackground");
      for (var i = 0; i < elements.length; i++) {
        elements[i].classList.remove("activeBackground");
      }
      this.pageno = 1;
      this.version = version;
      this.versionhistoryarray = [];
      this.getversionhistory(sourceid, false);
      this.cyrrentformid = id;
      this.currentsourceid = sourceid;
      if (sourceid != null) {
        store.dispatch("setsourceTemplateId", sourceid);
      }
      if (version != null) {
        store.dispatch("setversion", version);
      }
      if (this.selectedpageid != sourceid) {
        this.selectedpageid = sourceid;
        this.pagearray = [];
        this.temparray = [];
        this.showForm = true;
        await axios
          .get(
            `${this.baseurl}/forms/templatedesign/getpagesbyid?formId=${sourceid}&version=${version}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.formname = res.data.formname;
            this.status = res.data.status;
            this.templateid = res.data.templateId;
            this.pages[sourceid] = res.data.pages;
            this.newtemplate[0] = res.data;
            this.templateid = this.newtemplate[0].templateId;
            this.assigntemplate();
            this.viewtempicon = true;
            this.version = version;
            this.currentversion = version;
            this.isformlocked = res.data.isLock;
          })
          .catch((err) => {
            if (err && id != null) {
              alert("No pages found");
            }
            this.createtemplate();
          });
      }
    },
    async deleteForms(id) {
      if (confirm("Are you sure you want to delete this form?")) {
        await axios
          .delete(
            `${this.baseurl}/forms/templatedesign/deleteform?formId=${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert("Form deleted successfully");
            this.getForms();
            this.getPagesInitially();
            this.showForm = false;
          })
          .catch((err) => {
            alert("Failed due to internal error");
            console.error(err);
          });
      }
    },
    async getForms() {
      let currenttempid = store.getters.getViewtemplateid;
      await axios
        .get(
          `${this.baseurl}/forms/template/listlatesttemplate?StudyID=${store.getters.getStudyIs}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.listforms = res.data.results.sort((a, b) =>
            a.templateName.localeCompare(b.templateName)
          );
          this.listforms.forEach((item) => {
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async incrementrows() {
      if (this.gridrows.length == 0) {
        this.gridrows.push(1);
      } else {
        let lastelement = this.gridrows[this.gridrows.length - 1];
        this.gridrows.push(lastelement + 1);
      }
      this.griddata[0].Rows.push("");
      this.griddata[0].Fieldtypes.push("text");
    },
    async removerowsrows(index) {
      this.griddata[0].Rows.splice(index, 1);
      this.griddata[0].Fieldtypes.splice(index, 1);
      this.gridrows.splice(index, 1);
    },
    async incrementcolumns() {
      if (this.gridcolumns.length == 0) {
        this.gridcolumns.push(1);
      } else {
        let lastelement = this.gridcolumns[this.gridcolumns.length - 1];
        this.gridcolumns.push(lastelement + 1);
      }
      this.griddata[0].Columns.push("");

      // this.griddata[0].Columns.push("");
    },
    async removecolumns(index) {
      this.griddata[0].Columns.splice(index, 1);
      this.gridcolumns.splice(index, 1);
    },
    createForm() {
      this.pagearray = [];
      this.isformlocked = false;
      this.showCreateFormModal = !this.showCreateFormModal;
    },
    eHandler(key, data) {
      this.width[key] = data.width;
      this.height[key] = data.height;
      this.left[key] = data.left;
      this.top[key] = data.top;
    },
    drag(ev, element) {
      this.elementUsed = element;
      ev.dataTransfer.dropEffect = "copy";
      ev.dataTransfer.effectAllowed = "copy";
      ev.dataTransfer.setData("text", ev.target.id);
    },
    drop(ev, element) {
      if (element == "addone") {
        this.addonecolumn();
      }
      if (element == "addtwo") {
        this.addtwocolumn();
      }
      if (element == "addthree") {
        this.addthreecolumn();
      }
      if (element == "addfour") {
        this.addfourcolumn();
      }
      if (element == "addsix") {
        this.addsixcolumn();
      }
      if (element == "header") {
        this.openModeltwo("header");
      }
      if (element == "summary") {
        this.openModeltwo("summary");
      }
      if (element == "subHeader") {
        this.openModeltwo("subHeader");
      }
      if (element == "textbox") {
        this.openModeltwo("textbox");
      }
      if (element == "scales") {
        this.scalemaxval = 0;
        this.scaleminval = 0;
        this.openModeltwo("scales");
      }
      if (element == "dropdown") {
        this.openModeltwo("dropdown");
      }
      if (element == "dropdown") {
        this.openModeltwo("dropdown");
      }
      if (element == "date") {
        this.openModeltwo("date");
      }
      if (element == "time") {
        this.openModeltwo("time");
      }
      if (element == "dateandtime") {
        this.openModeltwo("dateandtime");
      }
      if (element == "numberanddate") {
        this.openModeltwo("numberanddate");
      }
      if (element == "uploadfile") {
        this.openModeltwo("uploadfile");
      }
      if (element == "grid") {
        this.griddata = [
          {
            Rows: [""],
            Columns: [""],
            Fieldtypes: ["text"],
            Fielddata: {},
            Fieldoptions: {},
          },
        ];
        this.gridrows = [1];
        this.gridcolumns = [1];
        this.openModeltwo("grid");
      }
      if (element == "qrcode") {
        this.openModeltwo("qrcode");
      }
      if (element == "year") {
        this.openModeltwo("year");
      }
      if (element == "radio") {
        this.openModeltwo("radio");
      }
      if (element == "checkbox") {
        this.openModeltwo("checkbox");
      }
      if (element == "textarea") {
        this.openModeltwo("textarea");
      }
      if (element == "image") {
        this.onFileChange();
      }
      if (element == "calculation") {
        this.openModeltwo("calculation");
      }
      if (element == "repeatedmodel") {
        this.openModeltwo("repeatedmodel");
      }
      if (element == "repeatedmeasure") {
        this.openModeltwo("repeatedmeasure");
      }if (element == "repeatingfield") {
        this.openModeltwo("repeatingfield");
      }
      if (element == "model") {
        this.clearmodelfields();
        this.openModeltwo("model");
      }
      this.highlitedcolumn = "";
    },
    async onFileChange() {
      if (this.columnselected) {
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        document.getElementById("fileInput").click();
      } else {
        this.adddefaultcolumn();
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        this.qrData = "",
        document.getElementById("fileInput").click();
      }
    },
    async helloButton(event) {
      this.selectedFile = "";
      this.imageData = "";
      console.log("Hello attribute");
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.selectedFile = input.files[0];
        console.log(this.selectedFile);
      }
      await this.submitFile();
      this.openModelcomponent("image");
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("Document", this.selectedFile);
      formData.append("DocumentTypeName", "TemplateImage");
      formData.append("DocumentTitle", "");
      formData.append("DocumentDescription", "");
      formData.append("OrganizationId", "");
      console.log("final data", this.azuredata, this.fileuploadurl);

      await axios
        .post(`${this.fileuploadurl}/create`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          console.log("retreieved data", response);
          this.imagid = `${response.data.data[0]},${response.data.data[1]}`;
          console.log("retreieved imageid", this.imagid);
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    async incrementrows() {
      if (this.gridrows.length == 0) {
        this.gridrows.push(1);
      } else {
        let lastelement = this.gridrows[this.gridrows.length - 1];
        this.gridrows.push(lastelement + 1);
      }
      this.griddata[0].Rows.push("");
      this.griddata[0].Fieldtypes.push("text");
    },
    async incrementcolumns() {
      if (this.gridcolumns.length == 0) {
        this.gridcolumns.push(1);
      } else {
        let lastelement = this.gridcolumns[this.gridcolumns.length - 1];
        this.gridcolumns.push(lastelement + 1);
      }
      this.griddata[0].Columns.push("");
    },
    async onclosed() {
      this.$router.push(`/formlist/${this.formid}`);
    },
    async viewtemplatefun() {
      this.newtemplate[0].templateId;
      store.dispatch("setViewtemplateid", this.newtemplate[0].templateId);
      store.dispatch("setViewformid", this.newtemplate[0].formId);
      store.dispatch("setversion", this.version);
      this.$router.push(`/viewform/${this.templateid}`);
    },
    async dashboard() {
      this.$router.push(`/dashboard`);
    },
    async folder() {
      this.$router.push(`/folder`);
    },
    async template() {
      this.$router.push(`/formlist/${this.formid}`);
      // this.$router.push(`/formlist/a9f05f57-55ed-4f9c-bc68-209dea110bc3`);
    },
    async savetestfun() {
      let cansave = true;
      this.newtemplate[0].pages.forEach((item) => {
        console.log("Item is", item.field);
      });

      if (this.newtemplate[0].pages == 0) {
        cansave = false;
      } else {
        await this.newtemplate[0].pages.forEach((data) => {
          if (data.field.length == 0) {
            cansave = false;
          }
        });
      }
      if (cansave) {
        if (this.version == null || this.version == "") {
          alert("Please enter version");
        } else {
          if (this.currentversion == this.version) {
            this.updatefun("update");
          } else {
            this.savefun("newsave");
          }
        }
      } else {
        alert("Form cannot be saved with empty pages");
      }
    },
    async handleIdFromChild(idFromChild) {
      await this.getPagesInitially(idFromChild, "1.0");
    },
    async newtemplateversion() {
      let newformidd = store.getters.getnewversionformid;
      this.sourcetemplateidcreate = store.getters.getsourceTemplateId;
      this.version = store.getters.getnewversion;
      this.newversioncreate = true;
      this.isformlocked = false;
      this.newversionViewTemp = true;
      this.savefun("newversion");
      this.currentversion = this.version;
      this.getForms();
      this.closecreatenewversion();
    },
    async gettemplatedetails()
    {
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${this.$route.query.templateId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.newtemplate[0].formname = res.data.templateName;
          this.newtemplate[0].pages = res.data.pages;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closecreatenewversion() {
      this.newversionformid = "";
      this.creatversionpopup = false;
    },
    async createnewversion(formid, version, sourceid) {
      console.log("setsourceTemplateId", sourceid);
      console.log("setnewversionformid", formid);
      store.dispatch("setsourceTemplateId", sourceid);
      store.dispatch("setnewversionformid", formid);
      this.newversionformid = formid;
      this.currentformversion = version;
      this.creatversionpopup = true;
    },
    async savefun(typeofform) {
      const idtoken = store.getters.getIdToken;
      const vhistorynote = store.getters.getversionhistorynote;      
      await this.gettemplatedetails()
      this.newtemplate[0].version = this.version;
      this.newtemplate[0].status= "New";
      this.newtemplate[0].language = "English";
      this.newtemplate[0].languageCode = "en";
      this.newtemplate[0].lastModifiedAt = "2023-03-08T04:54:42.752Z";
      this.newtemplate[0].isNewVersion = this.newversioncreate;
      this.newtemplate[0].sourceId = this.sourcetemplateidcreate;
      this.newtemplate[0].studyId = localStorage.getItem("study_id_ref");
      (this.newtemplate[0].versionHistory = vhistorynote),
        await axios
          .post(
            `${this.baseurl}/forms/templatedesign/saveformtemplate`,
            this.newtemplate[0],
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.newtemplate[0].templateId = res.data;
            this.getversionhistory(this.sourcetemplateidcreate, false);
            alert("Form added successfully");
            if (typeofform == "newversion") {
              this.getPagesInitially(this.sourcetemplateidcreate, this.version);
            }
          })
          .catch((err) => {
            console.log("add err response:", err);
          });
      this.newversioncreate = false;
      this.sourcetemplateidcreate = "";
    },
    async updatefun(funval) {
      const idtoken = store.getters.getIdToken;
      // alert("update function");
      this.newtemplate[0].templateId = this.newtemplate[0].templateId;
      this.newtemplate[0].version = this.version.toString();
      await axios
        .put(
          `${this.baseurl}/forms/templatedesign/updateformtemplate`,
          this.newtemplate[0],
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (funval == "update") {
            alert("Template updated successfully.");
          } else if (funval == "addpage") {
            //alert("Page updated successfully.");
          }
        })
        .catch((err) => {
          console.log("add err response:", err);
        });
    },
    async reloadfun() {
      const idtoken = store.getters.getIdToken;
      // console.log("template id is "+ typeof(this.templateid));
      if (this.templateid != 0 && this.templateid != undefined) {
        this.update = true;
        await axios
          .get(
            `${this.baseurl}/forms/templatedesign/getformtemplatedetails?id=${this.templateid}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.newtemplate[0] = res.data;
            this.formname = res.data.formname;
            this.issaved = true;
            this.assigntemplate();
          })
          .catch((err) => {
            console.log("add err response:", err);
          });
      } else {
        this.createtemplate();
      }
    },
    async assigntemplate() {
      if (this.newtemplate[0].pages) {
        await this.reloadtemplate();
        await this.tempvarassign();
      } else {
        this.createtemplate();
      }
    },
    async reloadtemplate() {
      this.fieldnamesarray = [];
      this.newtemplate[0].createdAt = new Date();
      let tempvar = this.newtemplate[0];
      this.version = 1;
      this.currentversion = 1;
      this.testtempvar = [];
      this.testtempvar = [
        {
          pages: [],
          formId: tempvar.formId,
          formname: tempvar.formname,
          language: "english",
          status: "new",
          version: this.version,
          totalPages: tempvar.totalPages,
        },
      ];
      this.totalpages = tempvar.pages.length;
      tempvar.pages.forEach(async (element) => {
        let pageindex = tempvar.pages.indexOf(element) + 1;
        this.pagearray.push(element.pageName);
        let testgrouporderarray = [];
        let pageforedit = {
          pageName: element.pageName,
          pageNo: element.pageNo,
          Group: [],
        };
        if (element.field.length == 0) {
          let emptypages = {
            pageName: element.pageName,
            pageNo: element.pageNo,
            Group: [],
          };

          this.testtempvar[0].pages.push(emptypages);
        } else {
          let pggropno = parseInt(
            element.field[element.field.length - 1].groupOrderNo
          );
          this.totalgroups["page" + pageindex] = pggropno;
          await element.field.forEach((subelement) => {
            if (!testgrouporderarray.includes(subelement.groupOrderNo)) {
              testgrouporderarray.push(subelement.groupOrderNo);
              let newgroup = {
                Groupno: subelement.groupOrderNo,
                components: subelement.groupOrderNo,
                field: [],
              };
              pageforedit.Group.push(newgroup);
            }
          });
          element.field.forEach((subelem) => {
            this.fieldnamesarray.push(subelem.fieldName.trim().toLowerCase());
            if(subelem.type == "repeatingfield"){
              this.repeatedgroupgroupnos[subelem.fieldName] = [];
              if(subelem.repeatingFields.length!=0){
                subelem.repeatingFields.forEach((repeatedgroupelement)=>{
                  this.fieldnamesarray.push(repeatedgroupelement.fieldName.trim().toLowerCase());
                  if(!this.repeatedgroupgroupnos[subelem.fieldName].includes(repeatedgroupelement.groupOrderNo)){
                  this.repeatedgroupgroupnos[subelem.fieldName].push(repeatedgroupelement.groupOrderNo);
                  }
                })
              }
            }
            let fieldinfo = {
              fieldName: subelem.fieldName,
              label: subelem.label,
              fieldno: subelem.fieldno,
              value: subelem.value,
              status: subelem.status,
              validatedBy: subelem.validatedBy,
              validatedAt: subelem.validatedAt,
              comment: subelem.comment,
              repeatingFields: subelem.repeatingFields,
              groupOrderNo: subelem.groupOrderNo,
              type: subelem.type,
              limit: subelem.limit,
              validation: subelem.validation,
              imagePath: subelem.imagePath,
              options: subelem.options,
              required: subelem.required,
              qrData: subelem.qrData
            };
            pageforedit.Group.forEach((groupselect) => {
              if (groupselect.Groupno == subelem.groupOrderNo) {
                fieldinfo.fieldno = groupselect.field.length;
                groupselect.field.push(fieldinfo);
              }
            });
          });
          this.testtempvar[0].pages.push(pageforedit);
        }
      });
    },
    async tempvarassign() {
      this.temparray = this.testtempvar;
      this.testtempvar[0].pages.forEach((item) => {
        item.Group.forEach((groupitem) => {
          groupitem.field.forEach((subitem) => {
            this.calcfieldnamesarray.push(subitem.fieldName.toLowerCase());
          });
        });
      });
    },
    async openModel() {
      window.scrollTo(0, 0);
      this.showModalll = 1;
    },
    async closemodall() {
      // document.body.style.overflow = 'auto';
      this.showModalll = this.showModalll + 1;
    },
    async openModeltwo(val) {
      this.clearInput();
      if(val == "repeatingfield"){
        this.adddefaultcolumn();
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        if(this.temparray.length != 0 && this.temparray[0].pages.length != 0)
        {
          this.createrepeatedfield();        
        }
      }
      else{
      if (this.columnselected) {
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        this.qrData = "";
        this.openModelcomponent(val);
      } else {
        this.adddefaultcolumn();
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        this.qrData = "";
        if(this.temparray.length != 0 && this.temparray[0].pages.length != 0)
        {
          this.openModelcomponent(val);        
        }
      }
    }
    },
    async openModelcomponent(menuval,field) {
      //templateid pageno
      this.newFieldValue=field;    
      this.selectedmenu = menuval;
      this.inclusiveLabel=false
      this.exclusiveLabel=false
      this.featureValue=false
      if(menuval == 'checkbox')
      {
      this.trueConsideration=""
      this.falseConsideration=""      
      this.considerationRate=false      
      }
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
      this.oldFieldName = this.fieldname;

      //this.componentModal = true;

      this.aitoolModal =true;
    },
    async savepredictionbutton (){
      this.subjectSelection.templateId = this.templateid;
      this.subjectSelection.pageNumber = this.pageno;
      this.subjectSelection.fieldName = this.newFieldValue;
      this.subjectSelection.isRepetedField =false;
      this.subjectSelection.templateFieldProperties = this.templateFieldProperties;

      await axios
      .post(`${this.baseurl}/forms/aiproperties/create`, this.subjectSelection, {
        headers: {
          Authorization: "Bearer " + this.idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        alert(this.$t("Subject details created successfully"));
        this.subjectSelection = null;
         document.body.style.overflow = "auto";
         this.aitoolModal = false;
      })
      .catch((err) => {
        console.log(err);
        alert(this.$t(err));
      });
      
     },
     async savepredictions(type){
      if(type=='dropdown'){
        await axios
        .post(`${this.baseurl}/forms/aiproperties/create`, {
          "templateId": this.templateid,
          "pageNumber": this.pageno,
          "fieldName": this.fieldname,
          "isRepetedField": false,
          "templateFieldProperties": {
            "featureField": true,
            "fieldsToConsider": [
              {
                "considerationRate": 100,
                "considerableFields": this.selectedDropdown
              },
              {
                "considerationRate": 0,
                "considerableFields": this.selectedDropdown2
              },
              ...this.fieldsConsider
            ],
            "rangesToConsider": [
              {
                "considerationRate": 100,
                "min": 0,
                "max": 0
              }
            ],
            "forBoolean": {
              "considerationRate": 100
            },
            "possibleNumericRange": {
              "min": 0,
              "max": 0
            }
          }
        }, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          alert(this.$t("Subject details created successfully"));
           document.body.style.overflow = "auto";
           this.aitoolModal = false;
        })
        .catch((err) => {
          console.log(err);
          alert(this.$t(err));
        });
      }
      if(type=='text'){
            
        await axios
        .post(`${this.baseurl}/forms/aiproperties/create`, {
          "templateId": this.templateid,
          "pageNumber": this.pageno,
          "fieldName": this.fieldname,
          "isRepetedField": false,
          "templateFieldProperties": {
            "featureField": true,
            "fieldsToConsider": [
              {
                "considerationRate": this.fieldstoConsiderText[0].considerationRate,
                "considerableFields": this.fieldstoConsiderText[0].considerableFields
              },
              ...this.templateFieldProperties.fieldsToConsider
            ],
            "rangesToConsider": [
              {
                "considerationRate": 100,
                "min": 0,
                "max": 0
              }
            ],
            "forBoolean": {
              "considerationRate": 100
            },
            "possibleNumericRange": {
              "min": 0,
              "max": 0
            }
          }
        }, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          alert(this.$t("Subject details created successfully"));
           document.body.style.overflow = "auto";
           this.aitoolModal = false;
        })
        .catch((err) => {
          console.log(err);
          alert(this.$t(err));
        });
      }
     
     },
    async closebutton (){
      this.rangeErrors = [false,false,false,false];
      document.body.style.overflow = "auto";
      this.aitoolModal = false;
    },
 
    async closemodaltwo() {
      this.rangeErrors = [false,false,false,false];
      this.templateFieldProperties.fieldsToConsider=[]
      this.templateFieldProperties.fieldsToConsider.considerableFields=[]
      document.body.style.overflow = "auto";
    //  this.componentModal = false;
      this.aitoolModal = false;
      this.isedit = false;
      this.columnselected = false;
      this.radiooptions = "";
      this.radiovalues = "";
      this.singlemodeloptions = "";
      this.singlemodelvalues = "";
      this.selectedoptiongroupmodel = "";
      this.selectedmodel = "";
      this.editingOption = null;
      this.maxvalue = "",
      this.minvalue = "";
      this.maxlength = "";
      this.minlength = "";
    },
    async deletecomponent() {
      this.newtemplate[0].pages[this.pageno - 1].field.forEach((element) => {
        if (element.fieldName == this.fieldname) {
          let index = this.newtemplate[0].pages[this.pageno - 1].field.indexOf(
            element
          );
          this.newtemplate[0].pages[this.pageno - 1].field.splice(index, 1);
          //let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
          console.log("new templateeeee.........",this.newtemplate);
          this.temparray[0].pages[this.pageno - 1].Group[this.selectedpagegroup
          ].field[this.selectedfield] = {
            fieldName: this.selectedgroup["page" + this.pageno] + "field2",
            fieldno: this.selectedfield,
            groupOrderNo: this.selectedgroup["page" + this.pageno],
            type: "blankdata",
          };
        }
        this.isedit = false;
      });
      this.deleteapicall();
      this.closemodaltwo();
      this.closebutton();
      await this.removefieldname(this.fieldname);
    },
    async onFileeditchange() {
      document.getElementById("fileInput").click();
    },
    async removefieldname(n) {
      const index = this.fieldnamesarray.indexOf(n);
      if (index > -1) {
        this.fieldnamesarray.splice(index, 1);
      }
    },
    async deleteapicall() {
      let data = {
        createdUserEmail: "string",
        createdUserId: "string",
        createdAt: "2022-06-16T04:19:38.363Z",
        lastModifiedUserEmail: "string",
        lastModifiedUserId: "string",
        lastModifiedAt: "2022-06-16T04:19:38.363Z",
        templateId: this.templateid,
        pageNo: this.pageno,
        fieldName: this.fieldname,
      };
      await axios
        .delete(`${this.baseurl}/forms/templatedesign/deletefield`, {
          data: data,
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          alert("Field deleted successfully.");
          this.columnselected = false;
        })
        .catch((err) => {
          console.log(this.idtoken);
          console.log("add err response:", err);
        });
    },
    // edit field .......................................
    async editfield() {
      this.rangeErrors = [false,false,false,false];
      let repeatingfieldarray = [];
      let validScale = true;
      this.rangeoption = [];
      this.currentoptions = this.radiooptionsarray;
      this.fieldnamesarray = [];
      let formatedFieldname = "";

      const tempFieldName = localStorage.getItem('tempFieldName');
      console.log("template field name ", tempFieldName)
      // fieldnamesarray
      this.newtemplate[0].pages.forEach(page => {
        page.field.forEach(field => {
          formatedFieldname = field.fieldName.trim().toLowerCase()
          if(field.type == "repeatingfield" || field.type == "RepeatingMeasure" || field.type == "repeatedmeasure"){
            if(!this.fieldnamesarray.includes(formatedFieldname)){
              this.fieldnamesarray.push(formatedFieldname);
            }
            field.repeatingFields.forEach(rField => {
              this.fieldnamesarray.push(rField.fieldName.trim().toLowerCase());
            });
          }
          else{
            if(!this.fieldnamesarray.includes(formatedFieldname)){
              this.fieldnamesarray.push(formatedFieldname);
            }
          }
        });
      });

      if (this.type == "textbox") {
        if(this.values.includes("valuerange") || this.values.includes("lengthrange")){
          await this.rangeValidations();

          if(!this.rangeErrors.includes(true)){
         
            this.setvalidations();
            console.log("Set validations.........")
          }
          else{
            validScale = false;
          }
        }
      
      } else if (this.type == "calculation") {
        let calculationdata = JSON.stringify(this.calculate);
        this.currentoptions = [calculationdata];
      } else if (this.type == "repeatingfield") {
        this.newfieldname = this.temprepeatingarray[0].fieldName;
        this.labeltext = this.temprepeatingarray[0].label;
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
        this.repeatedgroupgroupnos[this.fieldname] = [];
        if(repeatingfieldarray.length!=0){
          repeatingfieldarray.forEach((repeatedgroupelement)=>{
          if(!this.repeatedgroupgroupnos[this.fieldname].includes(repeatedgroupelement.groupOrderNo)){
                  this.repeatedgroupgroupnos[this.fieldname].push(repeatedgroupelement.groupOrderNo);
                  }
          })
        }
      }else if (this.type == "scales") {
        this.scaleValidation();
        if (this.scaleError.includes(true)) {
          validScale = false;
        }
        this.rangeoption = [
          {
            min: this.scaleminval.toString(),
            max: this.scalemaxval.toString(),
          },
        ];
      } else if (this.type == "radio") {
        if (this.radioorientation == "horizondal") {
          this.type = "radiohorizondal";
        }
      } else if (this.type == "qrcode") {
        if (this.qrcodecontents == "" || this.qrcodecontents == null) {
          this.emptyField = true;
          validScale = false;
        }
        this.qrData = this.qrcodecontents;
      }
      
      if (this.fieldname.trim() == null || this.fieldname.trim() == "" &&this.type != "repeatingfield") {
          alert("Fieldname cannot be empty");
      } else if ( this.fieldname.trim().toLowerCase() != tempFieldName &&
                  this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase())) {
                    if(this.newfieldname.trim() != this.fieldname.trim())
                    { alert("This field name is already in use"); }
      } else if (this.type == "radio" && this.radiooptionsarray.length == 0) {
          alert("Please enter atleast one option");
      } else if (validScale) {
        this.newtemplate[0].pages[this.pageno - 1].field.forEach((element) => {
          if (element.fieldName == this.tempfieldname) {
            let index = this.newtemplate[0].pages[this.pageno - 1].field.indexOf(element);
            this.newtemplate[0].pages[this.pageno - 1].field[index] = {
              fieldName: this.fieldname.trim(),
              label: this.labeltext,
              value: "",
              status: "",
              validatedBy: "",
              validatedAt: "",
              comment: "",
              groupOrderNo: this.grouporderno,
              repeatingFields: repeatingfieldarray,
              type: this.type,
              limit: this.rangeoption,
              validation: this.values,
              imagePath: this.imagid,
              options: this.currentoptions,
              required: false,
              qrData: this.qrData
            };
            this.temparray[0].pages[this.pageno - 1].Group[this.selectedpagegroup].field[this.selectedfield] = {
              fieldName: this.fieldname.trim(),
              label: this.labeltext,
              fieldno: this.selectedfield,
              value: "",
              status: "",
              validatedBy: "",
              validatedAt: "",
              limit: this.rangeoption,
              repeatingFields: repeatingfieldarray,
              comment: "",
              groupOrderNo: this.grouporderno,
              type: this.type,
              validation: this.values,
              imagePath: this.imagid,
              options: this.currentoptions,
              required: false,
              qrData: this.qrData
            };
            this.componentKey = this.componentKey + 1;
          }
        });
        await this.fieldeditapi(this.type);
        await this.closemodaltwo();
        await this.closebutton();
        localStorage.removeItem('tempFieldName');
      }
      this.fieldnamesarray.push(this.fieldname.trim().toLowerCase());
    },
    async fieldeditapi(type) {
      console.log("Optiosna are", this.currentoptions);
      let repeatingfieldarray = [];
      if (type == "repeatingfield") {
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
      }
      let data = {
        templateId: this.templateid,
        pageNo: this.pageno,
        fieldName: this.fieldname.trim(),
        field: {
          fieldName: this.newfieldname.trim(),
          label: this.labeltext,
          status: "",
          limit: this.rangeoption,
          repeatingFields: repeatingfieldarray,
          groupOrderNo: this.grouporderno,
          type: this.type,
          validation: this.values,
          imagePath: this.imagid,
          options: this.currentoptions,
          qrData: this.qrData
        },
      };
      await axios
        .put(`${this.baseurl}/forms/templatedesign/updatefield`, data, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.handleDropdownChange();
          alert("Template updated successfully.");
        })
        .catch((err) => {
          console.log("update field err response:", err.response.data.detail);
        });
    },
    async resetvalidation(validations) {
      console.log("validations are");
      console.log(validations);
      let valarray = [];
      validations.forEach((val) => {
        if (val.includes("lengthrange")) {
          console.log("lengthrange");
          this.minlength = val.substring(
            val.indexOf("(") + 1,
            val.indexOf(",")
          );
          this.maxlength = val.substring(
            val.indexOf(",") + 1,
            val.indexOf(")")
          );
          valarray.push("lengthrange");
        } else if (val.includes("valuerange")) {
          this.minvalue = val.substring(val.indexOf("(") + 1, val.indexOf(","));
          this.maxvalue = val.substring(val.indexOf(",") + 1, val.indexOf(")"));
          console.log("valuerange");
          valarray.push("valuerange");
        } else {
          console.log("others");
          valarray.push(val);
        }
      });
      this.values = valarray;
    },
    async addgridoptions() {
      // grid value fields adding
      this.griddata[0].Rows.forEach((eachrow) => {
        this.griddata[0].Columns.forEach((eachcolumn) => {
          this.griddata[0].Fielddata[eachrow + eachcolumn] = "";
          this.griddata[0].Fieldoptions[eachrow + eachcolumn] = [];
        });
      });
      console.log("in addgridoptions", this.griddata);
      // grid value field ends
    },
    async inputfieldedit(field, groupno) {
     
      let fieldtype = field.type;
      this.values = [];
        this.scaleError = [false, false, false, false];
        this.emptyField = false;
        if(field.type=='text' || field.type=='textbox'){
          this.fieldstoConsiderText[0].considerableFields[0]=""
          this.fieldstoConsiderText[0].considerationRate=""
          this.templateFieldProperties.fieldsToConsider=[]
        }
        if (field.type == "textbox") {

          await this.resetvalidation(field.validation);
        } else {
          this.values = field.validation;
        }
        if (field.type == "scales") {
          this.scaleminval = parseInt(field.limit[0].min);
          this.scalemaxval = parseInt(field.limit[0].max);
        }
        if (field.type == "radiohorizondal") {
          fieldtype = "radio";
          this.radioorientation = "horizondal";
        }
        if (field.type == "qrcode") {
          this.qrcodecontents = field.qrData;
        }
        if(field.type=='radio')
          {
            this.selectedDropdown=[]
            this.selectedDropdown2=[]
            this.fieldsConsider=[
              {
                  considerationRate: 0,
                  considerableFields: [
        
                  ]
              }
            ]
          }
         if (field.type == "dropdown" || field.type == "radio") {
          this.templateFieldProperties.fieldsToConsider[1]=
            { 
              considerationRate: 0,
              considerableFields: [
    
              ]
             }
          this.templateFieldProperties.fieldsToConsider[2]=
            { 
              considerationRate: 0,
              considerableFields: [
    
              ]
             }
          
        }
        if (field.type == "grid") {
          this.currentgrid = this.gridoptarray[field.fieldName];
          this.gridrows = this.gridoptarray[field.fieldName][0].Rows;
          this.gridcolumns = this.gridoptarray[field.fieldName][0].Columns;
          this.griddata[0].Rows = this.gridoptarray[field.fieldName][0].Rows;
          this.griddata[0].Columns = this.gridoptarray[
            field.fieldName
          ][0].Columns;
        }
        this.selectedgroup["page" + this.pageno] = field.groupOrderNo;
        this.tempfieldname = field.fieldName;
        localStorage.setItem('tempFieldName', field.fieldName.trim().toLowerCase());
        this.openModelcomponent(fieldtype,field.fieldName);
        
        this.fieldname = field.fieldName;
        this.newfieldname = field.fieldName;
        this.labeltext = field.label;
        this.selectedmodel = this.optiongroups.results.find(
          (model) => model.label === this.labeltext
        );
        this.grouporderno = field.groupOrderNo;
        this.radiooptionsarray = field.options;
        if(field.type=='dropdown')
        {
          this.dropdownoptions=this.radiooptionsarray
        }
        this.type = fieldtype;
        this.isedit = true;
        this.selectedfield = field.fieldno;
        this.imagid = field.imagePath;
        this.selectedpagegroup = this.temparray[0].pages[
          this.pageno - 1
        ].Group.indexOf(groupno);
      
    },
    // async Disablemenu() {
    //this.selectedfield = this.temparray[0].pages[this.pageno-1].Group.indexOf(groupno);
    //   await store.dispatch("setSideNavbar", false);
    //   console.log("disable netered");
    // },
    // async Enablemenu() {
    //   await store.dispatch("setSideNavbar", true);
    // },
    async deletepagefun() {
      // alert("deletepage");
      let conformed = confirm("Are you sure you want to delete this page?");
      if (conformed) {
        let indexForRemoval = this.pageno - 1;
        this.newtemplate[0].pages.splice(indexForRemoval, 1);
        this.temparray[0].pages.splice(indexForRemoval, 1);
        let temparraypgno = 1;
        this.temparray[0].pages.forEach((temparrayelement) => {
          temparrayelement.pageNo = temparraypgno;
          temparraypgno++;
        });
        let newtemplatepgno = 1;
        this.newtemplate[0].pages.forEach((newtempelement) => {
          newtempelement.pageNo = newtemplatepgno;
          newtemplatepgno++;
        });
        let i = this.pageno - 1;
        // alert("Pageno" + this.pageno);
        this.pagearray.splice(i, 1);
        if (this.pageno > 1) {
          this.pageno = this.pageno - 1;
        }
        this.totalpages--;
        if (this.totalpages < 1) {
          console.log("cannot save template without pages");
        } else {
          this.updatefun("delete");
        }
        //alert("page deleted successfully");
      }
    },
    async createpage() {
      if (this.pagearray.includes(this.pagename)) {
        // alert("Page name already exist");
        alert(this.$t('navigations.page name already exist'))
      } else if (this.pagename == "") {
        alert("Page name cannot be empty");
      } else {
        this.newtemplate[0].totalPages++;
        this.totalpages = this.newtemplate[0].totalPages;
        console.log("pagearray is ", this.pagearray);
        // let length = this.pagearray.length;
        let length = this.temparray[0].pages.length;
        this.pagearray.push(this.pagename);
        this.pageobj[this.pagename] = length;
        let newpage = {
          pageName: this.pagename,
          pageNo: length + 1,
          field: [],
        };
        this.newtemplate[0].pages[length] = newpage;
        this.temparray[0].pages[length] = {
          pageName: this.pagename,
          pageNo: length + 1,
          Group: [],
        };
        this.totalgroups["page" + (length + 1)] = 0;
        this.pageno = length + 1;
      }
      this.closemodall();
      // }
      this.pagename = "";
    },
    async setvalidations() {
      if (this.values.includes("lengthrange")) {
        let lengthindex = this.values.indexOf("lengthrange");
        this.values[lengthindex] =
          this.values[lengthindex] + `(${this.minlength},${this.maxlength})`;
      }
      if (this.values.includes("valuerange")) {
        let valueindex = this.values.indexOf("valuerange");
        this.values[valueindex] =
          this.values[valueindex] + `(${this.minvalue},${this.maxvalue})`;
      }
    },
    // add field ....................................................
    async addfield(type) {
      this.rangeErrors = [false,false,false,false];
      this.fieldnamesarray = [];
      this.newtemplate[0].pages.forEach(page => {
        page.field.forEach(field => {
          if(field.type == "repeatingfield" || field.type == "RepeatingMeasure" || field.type == "repeatedmeasure"){
            this.fieldnamesarray.push(field.fieldName.toLowerCase());
            field.repeatingFields.forEach(rField => {
               this.fieldnamesarray.push(rField.fieldName.toLowerCase());
            });
          }
          else{
            this.fieldnamesarray.push(field.fieldName.toLowerCase());
          }
        });
      });

      let repeatingfieldarray = [];
      let fieldtyp = type;
      this.rangeoption = [];
      let vald = true;
      this.currentoptions = this.radiooptionsarray;
      if (type == "textbox") {
        if(this.values.includes("valuerange") || this.values.includes("lengthrange")){
          await this.rangeValidations();
        }
        if(!this.rangeErrors.includes(true)){
          this.setvalidations();
        }
        else{
          vald = false;
        }
      }
      if (type == "header" || type == "subHeader") {
        this.fieldname = this.labeltext;
      } else if (type == "scales") {
        this.scaleValidation();
        if (this.scaleError.includes(true)) {
          vald = false;
        }
        // let minval = `Min:${this.scaleminval.toString()}`;
        // let maxval =  `Max:${this.scalemaxval.toString()}`;
        // this.currentoptions = [
        //   `Min:${this.scaleminval}`,
        //   `Max:${this.scalemaxval}`,
        // ];
        this.rangeoption = [
          {
            min: this.scaleminval.toString(),
            max: this.scalemaxval.toString(),
          },
        ];
      } else if (type == "radio" || type == "Radio") {
        if (this.radioorientation == "horizondal") {
          fieldtyp = "radiohorizondal";
        } else {
          fieldtyp = "radio";
        }
      } else if (type == "calculation") {
        let calculationdata = JSON.stringify(this.calculate);
        this.currentoptions = [calculationdata];
        // console.log("griddata = ",currentoptions);
      } else if (type == "repeatingfield") {
        this.fieldname = this.temprepeatingarray[0].fieldName;
        this.labeltext = this.temprepeatingarray[0].label;
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
        this.repeatedgroupgroupnos[this.fieldname] = [];
              if(repeatingfieldarray.length!=0){
                repeatingfieldarray.forEach((repeatedgroupelement)=>{
                  if(!this.repeatedgroupgroupnos[this.fieldname].includes(repeatedgroupelement.groupOrderNo)){
                  this.repeatedgroupgroupnos[this.fieldname].push(repeatedgroupelement.groupOrderNo);
                  }
                })
              }
      } else if (type == "repeatedmeasure") {
        let repeatedmesuredata = JSON.stringify(this.selectedrepeatedtemp);
        this.currentoptions = [repeatedmesuredata];
      } else if (type == "dropdown" || type == "Dropdown") {
        if (this.radiooptionsarray.length == 0) {
          this.dropdownError = true;
          vald = false;
        }
      } else if (type == "grid") {
        await this.addgridoptions();
        let gridoptiondata = JSON.stringify(this.griddata);
        this.currentoptions = [gridoptiondata];
        // console.log("griddata = ",currentoptions);
      } else if (type == "qrcode") {
        if (this.qrcodecontents == "" || this.qrcodecontents == null) {
          this.emptyField = true;
          vald = false;
        }
        this.qrData = this.qrcodecontents;
      }
      if (vald) {
        if (this.fieldname.trim() == null || this.fieldname.trim() == "" &&this.type != "repeatingfield") {
          alert("Fieldname cannot be empty");
        } else if (this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase())) {
          alert("This field name is already in use");
        } else if (
          type == "calculation" &&
          (this.calculate.firstfield == "" || this.calculate.secondfield == "")
        ) {
          alert("Please enter First and Second field in calculation field");
        } else if (type == "radio" && this.radiooptionsarray.length == 0) {
          alert("Please enter atleast one option");
        } else {
          let fielddata = {
            fieldName: this.fieldname.trim(),
            label: this.labeltext,
            value: "",
            status: "",
            validatedBy: "",
            validatedAt: "",
            comment: "",
            groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
            type: fieldtyp,
            limit: this.rangeoption,
            repeatingFields: repeatingfieldarray,
            validation: this.values,
            imagePath: this.imagid,
            options: this.currentoptions,
            required: false,
            qrData: this.qrData
          };
          let tempfielddata = {
            fieldName: this.fieldname.trim(),
            label: this.labeltext,
            fieldno: this.selectedfield,
            value: "",
            status: "",
            validatedBy: "",
            validatedAt: "",
            comment: "",
            limit: this.rangeoption,
            repeatingFields: repeatingfieldarray,
            groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
            type: fieldtyp,
            validation: this.values,
            imagePath: this.imagid,
            options: this.currentoptions,
            required: false,
            qrData: this.qrData
          };
          this.newtemplate[0].pages[this.pageno - 1].field.push(fielddata);
          this.fieldnamesarray.push(this.fieldname.trim().toLowerCase());
          this.temparray[0].pages[this.pageno - 1].Group[this.selectedpagegroup].field[this.selectedfield] = tempfielddata;
          this.columnselected = false;
          this.closemodaltwo();
          this.closebutton();
          this.addfieldapi(fieldtyp);
          this.calculate.firstfield = "";
          this.calculate.secondfield = "";
        }
      }
    },
    async deletegroupfun() {
      let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
      if (isNaN(pagegroup)) {
        alert("Please select a column");
      } else {
        if (this.columnselected != true) {
          alert("Please select a column");
        } else {
          if (this.totalpages > 0) {
            let conformedcolumn = confirm(
              "Are you sure you want to delete this column?"
            );
            if (conformedcolumn) {
              let field = this.temparray[0].pages[this.pageno - 1].Group[
                this.selectedpagegroup
              ].field[this.selectedfield];
              console.log(field);
              if (field.type == "blankdata") {
                this.temparray[0].pages[this.pageno - 1].Group[
                  this.selectedpagegroup
                ].field[this.selectedfield] = "";
              }
            }
          }
        }
      }
    },
    scaleValidation() {
      const RegexCheck = /[^0-9-]/g;
      if (this.scaleminval == this.scalemaxval) {
        this.scaleError[0] = true;
      }
      if (parseInt(this.scaleminval) > parseInt(this.scalemaxval)) {
        this.scaleError[1] = true;
      }
      if (RegexCheck.test(this.scaleminval)) {
        this.scaleError[2] = true;
      }
      if (RegexCheck.test(this.scalemaxval)) {
        this.scaleError[3] = true;
      }
    },
    clearInput() {
      this.scaleError = [false, false, false, false];
      this.emptyField = false;
      this.dropdownError = false;
    },
    async createrepeatedfield() {
      console.log("in repeated field pop up ...", this.fieldnamesarray);
      this.repfieldstoedit = [];
      this.popuptype="save";
      this.type = "repeatingfield"
      this.repeatedfieldpopup = true;
    },
    async createrepeatedfieldpopup() {
      this.createrepeatedmodel = true;
    },
    async closerepeatedfield(){
      this.repeatedfieldpopup = false;
    },
    async editrepeatedmeasure(repfieldstoedit){
      if(!this.isformlocked){
        this.repfieldstoedit = repfieldstoedit;
        this.fieldname = repfieldstoedit.fieldName;
        this.labeltext = repfieldstoedit.label;
        this.grouporderno = repfieldstoedit.groupOrderNo;
        this.popuptype="edit";
        this.type = "repeatingfield"
        this.repeatedfieldpopup = true;
      }
    },
    async closerepeatingmodel() {
      this.addrepeatedoptfield = false;
      this.createrepeatedmodel = false;
    },
    async addrepeatedoptionfield() {
      this.addrepeatedoptfield = !this.addrepeatedoptfield;
    },
    async cancelrepeatedoptionfield() {
      this.addrepeatedoptfield = false;
    },

    async addrepeatedopts() {
      console.log("optionssss", this.repeatedoptions);
      if (!this.repeatedoptions.includes(this.repeatoptionss)) {
        this.repeatedoptions.push(this.repeatoptionss);
      }
      this.repeatedoptions = "";
    },
    async saverepeatedoptions() {
      let repeatinggroupnameexist = false;
      if (this.repeatedtemplatename == "") {
        alert("Please enter repeating group name");
      } else {
        this.presavedrepeatedmodel.forEach((eachrepeatedgroupname) => {
          if (eachrepeatedgroupname.templateName == this.repeatedtemplatename) {
            repeatinggroupnameexist = true;
          }
        });
        if (repeatinggroupnameexist == true) {
          alert("Repeating group name already exist. ");
        } else {
          let savedata = {
            templateName: this.repeatedtemplatename,
            field: this.repeatedtemplatefieldarray,
          };
          await axios
            .post(
              `${this.baseurl}/forms/templatedesign/createtempfield
`,
              savedata,
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )

            .then((response) => {
              console.log("retreieved data", response);
              this.getrepeatedfieldlist();
              this.closeoptcloserepeatingmodelmodel();
              alert("Repeated field created ");
            })
            .catch((err) => {
              console.log(err.data);
              this.closerepeatingmodel();
            });
        }
      }
    },
    async removedrepeatedoptions(index) {
      this.repeatedtemplatefieldarray.splice(index, 1);
    },
    async addrepeatedoptions() {
      let repeatoptarray = this.repeatedoptions;
      let repeatedtemplatefield = {
        fieldName: this.repeatingfieldname,
        type: this.repeatingtype,
        options: repeatoptarray,
      };
      console.log("repeatingfield", repeatedtemplatefield);
      if (!this.repeatedtemplatefieldarray.includes(repeatedtemplatefield)) {
        console.log("repeatingfieldarray", this.repeatedtemplatefieldarray);
        this.repeatedtemplatefieldarray.push(repeatedtemplatefield);
      }
      repeatedtemplatefield = {
        fieldName: "",
        type: "text",
        options: [],
      };
      this.repeatingfieldname = "";
      this.repeatingtype = "text";
      this.repeatedoptions = [];
      console.log(this.repeatedtemplatefieldarray);
      this.addrepeatedoptfield = false;
    },
    async addfieldapi(type) {
      console.log("Optiosna are", this.currentoptions);
      let repeatingfieldarray = [];
      if (type == "repeatingfield") {
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
      }
      
      let data = { 
        TemplateId: this.newtemplate[0].templateId,
        pageNo: this.pageno,
        field: {
          fieldName: this.fieldname.trim(),
          label: this.labeltext,
          status: "",
          repeatingFields: repeatingfieldarray,
          groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
          type: type,
          limit: this.rangeoption,
          validation: this.values,
          imagePath: this.imagid,
          options: this.currentoptions,
          qrData : this.qrData,
        },
      };
      await axios
        .put(`${this.baseurl}/forms/templatedesign/addnewfield`, data, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("add err response:", err);
          if (err.response.data.detail == "PageNo Not Exist !") {
            this.updatefun("addpage");
          }
        });
    },
    async createtemplate() {
      this.newtemplate[0] = {
        templateId: "",
        templateName: this.templatename,
        languageCode: "en",
        totalPages: 0,
        version: "1.0",
        isDeleted: false,
        language: "English",
        folder: "",
        createdUserEmail: store.getters.getEmailIs,
        createdAt: new Date(),
        createdUserId: null,
        lastModifiedUserEmail: null,
        lastModifiedAt: null,
        lastModifiedUserId: null,
        formname: "string",
        formId: this.formid,
        pages: [],
      };
      this.temparray[0] = {
        templateId: "",
        templateName: "",
        totalPages: 1,
        pages: [],
      };
    },
    async designcompletepopupfun() {
      this.designcompletepopup = true;
    },
    async closedesigncompletemodal() {
      this.replacetemplatevar = false;
      this.exisitingvisitsvar = false;
      this.reviewandapprovalvar = false;
      this.designcompletepopup = false;
    },
    async designcomplete() {
      let currentstudy = store.getters.getStudyIs;
      let sourcepage = store.getters.getsourceTemplateId;
      this.emptyField = [];
      this.newtemplate[0].pages.forEach((item) => {
        if (item.field.length == 0) {
          this.emptyField.push(false);
        } else {
          this.emptyField.push(true);
        }
      });
      if (!this.emptyField.includes(true) || this.emptyField.length == 0) {
        // alert("Forms with empty pages cannot be saved");
        alert(this.$t('navigations.forms with empty pages cannot be saved'))
      } else {
        let conformed = confirm(
          "Are you sure you want to change the status to design complete?"
        );
        if (conformed) {
          this.newtemplate[0].status = "Design Complete";
          this.newtemplate[0].templateId = this.templateid;
          this.newtemplate[0].version = this.currentversion;
          if (this.exisitingvisitsvar) {
            await axios
              .get(
                `${this.baseurl}/forms/template/updatevisits?oldTemplateId=${sourcepage}&templateId=${this.newtemplate[0].templateId}&studyId=${currentstudy}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                this.getForms();
              })
              .catch((err) => {
                console.error(err);
              });
          }
          if (this.replacetemplatevar) {
            await axios
              .get(
                `${this.baseurl}/forms/template/updateschedules?oldTemplateId=${sourcepage}&templateId=${this.newtemplate[0].templateId}&studyId=${currentstudy}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                console.log("updateschedules", res.data);
              })
              .catch((err) => {
                console.error(err);
              });
          }

          await axios
            .put(
              `${this.baseurl}/forms/templatedesign/designcomplete?templateid=${
                this.templateid
              }&isLocked=${!this.reviewandapprovalvar}`,
              {},
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              this.isformlocked = !this.reviewandapprovalvar;
              console.log(res);
              this.status = "Design Complete";
              this.closedesigncompletemodal();
              alert("Form design completed successfully.");
              //this.template();
            })
            .catch((err) => {
              console.log("add err response:", err);
              this.closedesigncompletemodal();
            });
        }
      }
    },
    async addradiooptions() {
      if (
        this.radiovalues.trim() != "" &&
        this.radiovalues.trim() != null &&
        this.radiooptions.trim() != "" &&
        this.radiooptions.trim() != null
      ) {
        this.dropdownError = false;
        if (
          this.radiooptions.trim() != "" &&
          this.radiooptions.trim() !== null
        ) {
          const isNameDuplicate = this.radiooptionsarray.some(
            (option) => option.optionname === this.radiooptions.trim()
          );
          if (isNameDuplicate) {
            alert("Option group with the same option name already exists!");
            return;
          }
          const isCodeDuplicate = this.radiooptionsarray.some(
            (option) => option.codevalue === this.radiovalues.trim()
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
          this.currentoptions = {
            optionname: this.radiooptions.trim(),
            codevalue: this.radiovalues.trim(),
          };
          console.log("Optiosn in radio are", this.currentoptions);
          if (!this.radiooptionsarray.includes(this.radiooptions.trim())) {
            this.radiooptionsarray.push(this.currentoptions);
          }
        }
        this.radiooptions = "";
        this.radiovalues = "";
      }
    },
    async expandfun(id) {
      let expitem = document.getElementById(id);
      expitem.classList.toggle("collapse");
    },
    async selectitem(menuval) {
      this.isedit = false;
      this.fieldname = "";
      this.labeltext = "";
      this.grouporderno = "";
      this.values = [];
      this.radiooptionsarray = [];
      if (this.selectedmenu == menuval || this.selectedmenu == "") {
        let expitem = document.getElementById("addvalues");
        expitem.classList.toggle("collapse");
        // alert("selected an item");
        this.selectedmenu = menuval;
      } else {
        this.selectedmenu = menuval;
      }
    },
    async addrepeatedonecolumn(lastgroup) {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
        this.selectspot(inputelem.field[0], lastgroup);
      }
    },
    async adddefaultcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        let groupinfo = {
          Groupno: groupno,
          components: 1,
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
        this.selectspot(inputelem.field[0], inputelem);
      }
    },
    async addonecolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
        // this.newtemplate[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addtwocolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addthreecolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addfourcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field4",
              fieldno: 3,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addsixcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "fiel4",
              fieldno: 3,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field5",
              fieldno: 4,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field6",
              fieldno: 5,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async selectspot(selectedfield, groupno) {
      this.values = [];
      this.radiooptionsarray = [];
      this.radiooptions = "";
      this.columnselected = true;
      this.selectedgroup["page" + this.pageno] = selectedfield.groupOrderNo;
      this.selectedpagegroup = this.temparray[0].pages[
        this.pageno - 1
      ].Group.indexOf(groupno);
      this.selectedfield = selectedfield.fieldno;
      this.blankfielddetails = selectedfield;
      this.highlitedcolumn = selectedfield.fieldName;
    },
    async handleMouseLeave() {
      this.highlitedcolumn = "";
    },
    async onclosed() {
      if (this.previousRouteName == "studyProfile") {
        await this.$router.push(`/${this.previousRouteName}`);
      } else {
        await this.$router.push(`/formTemplates`);
      }
    },
    async rangeValidations(){
      this.rangeErrors = [false,false,false,false];
      if (this.values.includes("lengthrange")) {
        if (this.minlength === null || this.minlength === undefined || this.minlength === "") {
            this.rangeErrors[0] = true;
        }
     
       if(this.maxlength === "" || this.maxlength === null|| this.maxlength === undefined){
          this.rangeErrors[1] = true;
        }
      }
      if(this.values.includes("valuerange")){
        if(this.minvalue === "" || this.minvalue === null|| this.minvalue == undefined){
          this.rangeErrors[2] = true;
        }
        if(this.maxvalue === "" || this.maxvalue === null|| this.maxvalue == undefined){
          this.rangeErrors[3] = true;
        }
      }
    },
    valuesChanged(){
      this.rangeErrors = [false,false,false,false];
    }
  },
};
