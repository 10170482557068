/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import { useStore } from "vuex";
import moment from "moment";
import Chart from "chart.js";
import jwt_decode from "jwt-decode";
import store from "@/store";
// let testarray= [];
export default {
  name: "nursesummary",
  components: {},
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", async (event) => {
      this.defaultdashboard = store.getters.getdefaultdashboard;
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      // await this.loadstudy();
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      this.valnow = await store.getters.getStudyIs;
      this.getcount();
      this.GetFormData();
      this.initializeColumns()
      await this.GetChartData();
      await this.GetqueryChartData();
      this.sndcharts.update();      
    });
    this.defaultdashboard = store.getters.getdefaultdashboard;
    this.sndcharts = null;
    // await this.loadstudy();
    this.roleprivileges = await store.getters.getRolesprivilegeData;
    this.valnow = await store.getters.getStudyIs;
    this.getcount();
    await this.GetFormData();
    this.initializeColumns()
    this.GetChartData();
    this.GetqueryChartData();
  },
  data() {
    return {
      defaultdate: "",
      defaultdashboard: "",
      firstpiechartoptions: {
            'chart': {
              'width': '100%',
              'type': 'pie',
            },
            'labels': [this.$t('navigations.new/unverified forms'), this.$t('navigations.verified forms')],
            'responsive': [{
              'breakpoint': 480,
              'options': {
                pie: {
      size: 200
    },
                'chart': {
                  'toolbar': {
                      'show': false
                    },
                  'width': 200
                },
                'legend': {
                  'position': 'bottom'
                }
              }
            }]
          },
          //horizondalbarchart first starts
          firstbarchart: {
        charttype: 'bar',
      chartOptions: {
        stroke: {
            width: 2
                },
          chart: {
            height: '100%',
            width: '100%',
            id: 'firstchart-example',
          },
          dataLabels: {
          enabled: false,
        },
          plotOptions: {
              bar: {
                horizontal: true,
              }
            },
          xaxis: {
            categories: [],
          },
        },
        series: [{
          data: [0, 0, 0, 0 ]
        }],
        dataseries: [ { "name": "New/Unverified Forms", "data": [ 0, 0, 0, 0, 0, 0, 0 ] }, { "name": "Verified Forms", "data": [ 0, 0, 0, 0, 0, 0, 0 ] } ],
      },
           //horizondalbarchart first ends
      firstchart: {
        charttype: 'line',
      chartOptions: {
        stroke: {
            width: 2
                },
          chart: {
             height: '100%',
              width: '100%',
            id: 'firstchart-example',
          },
          plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
              }
            },
          xaxis: {
            categories: [],
          }
        },
        series: [{
          data: [0, 0, 0, 0 ]
        }],
        dataseries:  [ { "name": "New/Unverified Forms", "data": [ 0, 0, 0, 0, 0, 0, 0 ] }, { "name": "Verified Forms", "data": [ 0, 0, 0, 0, 0, 0, 0 ] } ],
        piechartOptions: '',
        piedataseries: [2,3,2,1]
      },
      secondchart: {
        charttype: 'line',
      chartOptions: {
        stroke: {
            width: 2
                },
          chart: {
            height:"100px",
            toolbar: {
                show: false
              },
            id: 'vuechart-example',
          },
          dataLabels: {
          enabled: false,
        },
          plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
              }
            },
          xaxis: {
            categories: [this.$t('navigations.open'), this.$t('navigations.closed')],
          },
        },
        series: [{
          name: this.$t('navigations.queries'),
          data: [0, 0]
        }],
        dataseries: [{
          name: this.$t('navigations.queries'),
          data: [0, 0]
        }],
        piechartOptions: {
            chart: {
              width: '100%',
              type: 'pie',
            },
            labels: [this.$t('navigations.open'), this.$t('navigations.closed')],
            responsive: [{
              breakpoint: 380,
              options: {
                chart: {
                  toolbar: {
                      show: false
                    },
                  width: 100
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        piedataseries: [2,3]
      },
      activeformcount: "",
      showFilter:false,
      dropdownOpen:false,
      selectedcolumnobj:{},
      patientscountstd: "",
      sitecountstd: "",
      userscountstd: "",
      formcountstd: "",
      querycountstd: "",
      formlists: [],
      pageNumber: 1,
      currentStudy: "",
      userId: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/study/`,
      baseapi: process.env.VUE_APP_Service_URL,
      studies: "",
      valnow: "",
      sndcharts: "",
      secndcharts: "",
      senddatacoppy: "",
      seconsdatacoppy: "",
      roleprivileges:"",
      sndoptions: {
            scales: {
                       y: {
                         beginAtZero: true
                          }
                    }
               },
      options: {
            scales: {
                       y: {
                         beginAtZero: true
                          }
                    }
               },
    };
  },
  methods: {
    async initializeColumns() {
      if (this.formlists.length > 0) {
        const allowedKeys = ['siteId','subjectId','visitName','formName', 'formStatus', 'createdUserName', 'createdAt'];
        this.getColumns= Object.keys(this.formlists[0])
        .filter(column => allowedKeys.includes(column));       
        const fieldLabels = {
          siteId: "Site Code",
          subjectId: "Subject ID",
          visitName: "Visit Name",
          formName: "Form Name",
          formStatus: "Status",
          createdUserName: "Uploaded By",
          createdAt: "Uploaded Date"
        };

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);
         
        this.getColumns=readableData
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
      },
    async setasdefault(){
      // alert("set as default");
        const idtoken = store.getters.getIdToken;
        let userIdfordefault = {
          "userId": this.jwtDecrypt(idtoken).sub,
          "email": jwt_decode(idtoken).email,
          "defaultDashBoard": "nursesummary"
                                };
        await axios
          .put(`${this.baseapi}/account-core/settings/userdashboard`,
          userIdfordefault, 
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            store.dispatch("setdefaultdashboard", "nursesummary");
            alert("Default dashboard updated successfully");
            this.defaultdashboard = store.getters.getdefaultdashboard;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async firstchartchange(val) {
        this.firstchart.charttype = val;
      },
    async secondchartchange(val) {
      if(val == "barhorizondal"){
        await this.switchbar(true);
        this.secondchart.charttype = 'bar';
      }
      else {
        await this.switchbar(false);
        this.secondchart.charttype = val;
      }
    },
    async switchfirstbar(res){
      this.firstchart.chartOptions.plotOptions = {'bar': { 'horizontal': res}};
      this.firstchart.charttype = '';
    },
    async switchbar(res){
      this.secondchart.chartOptions.plotOptions = {'bar': { 'horizontal': res}};
      this.secondchart.charttype = '';
    },
   
    async GetqueryChartData() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/forms/query/getallcounts?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.secondchart.dataseries = [{
            name: this.$t('navigations.queries'),
            data: [
              res.data.openQuries,
              res.data.closedQuries
            ]
          }];
          this.secondchart.piedataseries = this.secondchart.dataseries[0].data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.secondchart.charttype="pie"
    },
    async GetChartData() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/forms/forms/getweeklyformscount?studyId=${currentstudyid}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res);
          // this.sendData.data.labels = res.data.lastweekDates;
          await this.trimdates(res.data.lastweekDates);
          this.firstchart.dataseries = [{
            'name': this.$t('navigations.new/unverified forms'),
            'data': res.data.newformscount
          },{
            'name': this.$t('navigations.verified forms'),
            'data':  res.data.verifiedformscount
          }
        ];
        this.firstbarchart.dataseries = [{
            'name': this.$t('navigations.new/unverified forms'),
            'data': res.data.newformscount
          },{
            'name': this.$t('navigations.verified forms'),
            'data':  res.data.verifiedformscount
          }
        ];
        let newforms = '';
        let verifiedforms = '';
        sumis(res.data.newformscount);
        sumofverifiedis(res.data.verifiedformscount);
        async function sumis(array){
          let sum = 0;
          for (let i = 0; i < array.length; i++) {
        sum += array[i];
        }
          newforms = sum;
        };
        async function sumofverifiedis(array){
          let sum = 0;
          for (let i = 0; i < array.length; i++) {
        sum += array[i];
        }
          verifiedforms = sum;
        };
        this.firstchart.piedataseries =[newforms, verifiedforms];
        })
        .catch((err) => {
          console.log(err);
        });
        this.firstchart.charttype = 'bar';
    },
    async sumofarray(array){
      let sum = 0;
      for (let i = 0; i < array.length; i++) {
    sum += array[i];
    }
    console.log("sum is " + sum);
      return sum;
    },
    async trimdates(arrayofdates) {
      // console.log(arrayofdates);
      let trimmeddates = [];
      await arrayofdates.forEach((element) => {
        trimmeddates.push(this.filter(element.slice(0, 10)));
      });
      // console.log(trimmeddates);
      this.firstchart.chartOptions.xaxis = {
            'categories': trimmeddates,
          };
        this.firstbarchart.chartOptions.xaxis = {
            'categories': trimmeddates,
          };

        // this.firstchart.piechartOptions.labels=  [`verified (${trimmeddates[6]} to ${trimmeddates[0]})`, `Unverified (${trimmeddates[6]} to ${trimmeddates[0]})`];
    },
    jwtDecrypt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getcount() {
      const idtoken = store.getters.getIdToken;
      this.valnow = await store.getters.getStudyIs;
      console.log("current study value is " + this.valnow);
      this.getformcount(this.valnow, idtoken);
      this.getsitecount(this.valnow, idtoken);
      this.getpatientscount(this.valnow, idtoken);
      this.getuserscount(this.valnow, idtoken);
      this.getactiveformcount(this.valnow, idtoken);
      this.getquerycount(this.valnow, idtoken);
      //patientscountstd
    },
    async getpatientscount(currentstudyid, idtoken) {
      let useremail = jwt_decode(idtoken).email;
      await axios
        .get(
          `${this.baseapi}/management/patient/getpatientcountinstudy?StudyId=${currentstudyid}&investigatorEmail=${useremail}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getactiveformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/activecounts?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.activeformcount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getsitecount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/site/getsitescount?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sitecountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getuserscount(studyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/study/getusercountinstudy?StudyId=${studyid}`,
          {
            headers: {
             "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getquerycount(currentstudyid, idtoken) {
      await axios
          .get(
              `${this.baseapi}/forms/query/getcounts?studyId=${currentstudyid}`,
              {
                  headers: {
                      "Access-Control-Allow-Origin": "localhost:8080",
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                  },
              }
          )
          .then((res) => {
              this.querycountstd = res.data;

          })
          .catch((err) => {
              console.log(err);
          });
  },
    async getformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/invformcount?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formcountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async studychange(val) {
      // store.commit(setStudy,val);
      await store.dispatch("setStudyID", val);
      await this.getcount();
      await this.GetFormData();
      await this.GetChartData();
      await this.GetqueryChartData();
    },
    // loadstudy function
    filter(data){
      if(data){
          return moment(data).format(this.defaultdate)
      }
    },
    async viewforms(id) {
      const fromArchive = false
      this.$router.push(`/showform/home/${id}/${fromArchive}`);
    },
    async GetFormData() {
      const idtoken = store.getters.getIdToken;
      const stdid = store.getters.getStudyIs;
      let useremail = jwt_decode(idtoken).email;
      await axios
        .get(`${this.baseapi}/forms/forms/getpaginatedrecentforms?studyId=${stdid}&userEmail=${useremail}`, {
          headers: {
           "Access-Control-Allow-Origin": 'localhost:8080', 
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.results) {
            this.formlists = response.data.results;
            console.log("table data", this.formlists);
          } else {
            console.log("no forms found");
          }
        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });
    },
    // loadstudy function
    async loadstudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      await axios
        .get(`${this.baseapi}/management/study/getallstudy?user=${usermail}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.studies = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.valnow = store.getters.getStudyIs;
    },
  },
};