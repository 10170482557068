/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import auth0 from "auth0-js";
// import Multiselect from "@vueform/multiselect";
var webAuth = new auth0.WebAuth({
  domain: "dev-ikz5y2bz.us.auth0.com",
  clientID: "Ha6TPgiisWCGcn3TcTsFXDJDbSUQSFoX",
  scope: "openid profile",
  responseType: "token id_token",
});
export default {
  name: "App",
  // components: { Multiselect },
  props: { "userid": String },
  mounted() {
    this.selecteduser = this.userid
    this.setRoleList();
    this.retrieveinfo();
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      localurl: process.env.VUE_APP_redirect,
      selecteduser: "",
      values: {
        userName: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        active: true,
        emailIdPrimary: "",
        emailIdSecondary: "",
        phoneNumber: "",
        userType: "",
        role: "",
        addressLineOne: "",
        addressLineTwo: "",
        country: "",
        userId: "",
      },

      roleList: [],
      //multiSelectList: "",
      multiSelectList: [""],
      validations: [false, false, false, false, false, false, false, false,false],
      errors: []
    };
  },
  methods: {
    setmutiselectlist() {

    },
    // disable multiselect
    async RoleListing() {
      await this.roleList.forEach(role => {
        if (role.roleID == this.values.role[0]) {
          this.values.role[0] = role.roleName;
          this.multiSelectList = [role.roleID];
        }


      });


    },
    RoleListingbiId() {
      this.roleList.forEach(role => {
        if (role.roleName == this.values.role[0]) {
          this.values.role[0] = role.roleID;

        }

      });

    },

    async ValidateEmail(mail) {
      if (mail != "") {
        if (mail == this.values.emailIdPrimary) {
          this.validations[3] = false;
        }
        else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
          this.validations[3] = true;
        }
        else {
          this.validations[3] = false;
        }
      }
      else {
        this.validations[3] = true;
      }
    },
    async ValidatefirstEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        this.validations[2] = true;
      }
      else {
        this.validations[2] = false;
      }
    },
    async validPhone(phno) {
      if (phno == "") {
        this.validations[5] = true;
      }
      else if (phno > 999999999 && phno < 10000000000) {
        this.validations[5] = true;
      }
      else {
        this.validations[5] = false;
      }
    },
    async validDob(dob) {

      if (dob === "") {
        dob = null;
      }
      let myDate = new Date(dob);

      let today = new Date();
      if (dob == "") {
        this.validations[7] = true;
      }
      else if (myDate > today) {
        this.validations[7] = false;
      }
      else {
        this.validations[7] = true;
      }
    },
    async Validatename(name, errindex) {
      let letters = /^[A-Za-z\s]*$/;
      if (name.trim() == "") {
        this.validations[errindex] = false;

      }
      else if (!letters.test(name)) {
        this.validations[errindex] = false;

      }
      else {
        this.validations[errindex] = true;
      }
    },
    async ValidateUserName(name, errindex) {
      if (name == null) {
        this.validations[errindex] = false;
      }
      else if(name.length < 5 || name.length > 10){
        this.validations[errindex] = false;
      }
      else {
        this.validations[errindex] = true;
      }
    },
    async validate() {
      this.values.firstName = this.values.firstName.replace(/ +/g, ' ').trim();
      this.values.lastName = this.values.lastName.replace(/ +/g, ' ').trim();
      this.values.userName = this.values.userName == null ? this.values.userName : this.values.userName.replace(/ +/g, ' ').trim();
      this.values.emailIdPrimary= this.values.emailIdPrimary.replace(/ +/g, ' ').trim();
      this.values.emailIdSecondary= this.values.emailIdSecondary.replace(/ +/g, ' ').trim();
      this.values.phoneNumber= this.values.phoneNumber.replace(/ +/g, ' ').trim();
      this.Validatename(this.values.firstName, 0);
      this.Validatename(this.values.lastName, 1);
      this.ValidateUserName(this.values.userName, 8);
      this.ValidatefirstEmail(this.values.emailIdPrimary);
      this.ValidateEmail(this.values.emailIdSecondary);
      this.validPhone(this.values.phoneNumber);

      if (this.values.userType != "") {
        this.validations[4] = true;
      }
      if (this.multiSelectList[0].length < 1) {
        this.validations[6] = false;
      } else {
        this.validations[6] = true;
      }
      this.validDob(this.values.dateOfBirth);
    },
    async addUser() {
      this.values.role = this.multiSelectList;
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      }
      else {
        if (this.selecteduser == 0) {
          this.save();
        }
        else {
          this.edit();
        }

      }
    },
    async setRoleList() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/account-core/roles/rolelist`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {

          this.roleList = res.data;
          this.RoleListing();
          console.log("roles are..", this.roleList);

        })
    },
    async retrieveinfo() {

      if (this.selecteduser != 0) {

        const idtoken = store.getters.getIdToken;
        await axios
          .get(
            //`https://localhost:5001/user/userbyid?id=${this.selecteduser}`,
            `${this.baseurl}/account-core/user/userbyid?id=${this.selecteduser}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {

            if (res.data === "No User Found") {
              alert("Hello");
            }
            res.data.dateOfBirth = res.data.dateOfBirth === null ? "" : res.data.dateOfBirth.slice(0, 10);
            this.values = res.data;
            this.RoleListing();

            console.log("zsddd", this.values)
          })
          .catch((err) => {
            console.log(err);
          });
        let result = await this.roleList.find(obj => {
          return obj.roleName === this.values.role[0];
        })
        this.multiSelectList = [result.roleID];
      }
    },


    async onclosed() {
      this.$router.push(`/userlisting`);
    },
    async clear() {
      if(this.userid=='0')
      {
        this.values.userName= "",
        this.values.firstName= "",
        this.values.lastName= "",
        this.values.dateOfBirth= null,
        this.values.active= true,
        this.values.emailIdPrimary= "",
        this.values.emailIdSecondary= "",
        this.values.phoneNumber= "",
        this.values.userType= "",
        this.multiSelectList[0]=""
        this.values.addressLineOne= "",
        this.values.addressLineTwo= "",
        this.values.country= "",
        this.values.password= ""
      }
      else{
        this.values.userName= "",
        this.values.firstName= "",
        this.values.lastName= "",
        this.values.dateOfBirth= null,
        this.values.active= true,
        this.values.emailIdSecondary= "",
        this.values.phoneNumber= "",
        this.values.userType= "",
        this.multiSelectList[0]="",
        this.values.addressLineOne= "",
        this.values.addressLineTwo= "",
        this.values.country= "",
        this.values.password= ""
      }
      
     
    },

    // save function
    async save() {
      console.log("in create", this.values)

      const idtoken = store.getters.getIdToken;
      await axios
        .post(
          //`https://localhost:5001/user/createuser`,
          `${this.baseurl}/account-core/user/createuser`,
          this.values,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {

          if (res.data.data === "No User Found") {
            alert("Hello");
          }
          this.userList = res.data.data;
          // alert("User created successfully.")
          alert(this.$t('navigations.user created successfully'))
          this.$router.push(`/userlisting`);
        })
        .catch((err) => {
          console.log("errorrrrrrrrrrrrrrr...", err.response.data.detail)
          if(err.response.data.detail == "Username already taken !"){
            alert(this.$t('navigations.username already taken'))
            // alert(err.response.data.detail)
          }
          else if(err.response.data.messages[0].includes("The user already exists.")){
            // alert("User already exists with same email id!");
            alert(this.$t('navigations.user exists with the same email'))
          }
          else{
            alert("Something went wrong!")
          }
        });
    },
    async edit() {

      const idtoken = store.getters.getIdToken;
      if (this.values.dateOfBirth === "") {
        this.values.dateOfBirth = null;
      }
      console.log(this.values);
      await axios
        .put(
          //`https://localhost:5001/user/update`,
          `${this.baseurl}/account-core/user/update`,
          {
            itemId: this.values.id,
            userName: this.values.userName,
            firstName: this.values.firstName,
            lastName: this.values.lastName,
            dateOfBirth: this.values.dateOfBirth,
            active: this.values.active,
            emailIdPrimary: this.values.emailIdPrimary,
            emailIdSecondary: this.values.emailIdSecondary,
            phoneNumber: this.values.phoneNumber,
            userType: this.values.userType,
            role: this.values.role,
            addressLineOne: this.values.addressLineOne,
            addressLineTwo: this.values.addressLineTwo,
            country: this.values.country,
            userId: this.values.userId,
            isVerified: true,
            isPersonalInfoVerified: true
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {

          if (res.data.data === "No User Found") {
            alert("Hello");
          }
          this.userList = res.data.data;
          // alert("User updated successfully.")
          alert(this.$t('navigations.user updated successfully'))
          this.$router.push(`/userlisting`);
        })
        .catch((err) => {
          console.log("errorrrrrrrrrrrrrrr...", err.response.data.detail)
          if(err.response.data.detail == "Username already taken !"){
            alert(err.response.data.detail)
          }
          else if(err.response.data.messages[0].includes("The user already exists.")){
            alert("User already exists with same email id!");
          }
          else{
            alert("Something went wrong!")
          }
        });

    }
  },
};
