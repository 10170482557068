<template>
  <div class="baseinput mainbgchange" :class="{'cursor-pointer':  sourceValue===true}">
    <label class="labelstyle" :class="{'cursor-pointer':  sourceValue===true}">{{label}}<sup v-if="requiredname == 'required' || requiredname == 'Required'"><i class="fa fa-asterisk imp px-1"></i></sup></label>
<input
:disabled="privilege == 'View' || fromarchive == 'true'"
:class="[classname,{'cursor-pointer':  sourceValue===true}]"
:value="modelValue"
:type="inputtype"
@input="$emit('update:modelValue', $event.target.value)"
/>
</div>
</template>

<script>

export default {
props:{
  sourceValue:{
        type: Boolean,
        default: false
    },
    label:{
        type: String,
        default: ''
    },
    modelValue:{
        type:[String, Number],
        default: ''
    },
      inputtype:{
        type: String,
        default: 'text'
      },
      classname:{
        type: String,
        default: 'form-control'
      },
      requiredname:{
        type: String,
        default: ''
      },
      privilege:{
        type: String,
        default: ''
      },
      fromarchive: {
        type: String,
        default: 'false'
      }
}
}
</script>
<style scoped>

.baseinput{
  display: flex;
flex-direction: column;
align-items: flex-start;
background-color:#ffffff;
}
.labelstyle{
  font-size: 14px;
  margin-bottom: 0.1rem !important;
  font-weight: 600;
}
.minwidth{
  min-width: max-content;
}
.errorlist{
  border-width: 2px !important;
  border-color: red !important;
}
.hightlight{
  /* border-color: red !important; */
  border-bottom: 6px solid #f8ac59;
  
}
.imp {
    color: #ff0000;
    font-size: 7px;
}
.borderValidated {
  border:2px solid green!important;
}
.borderIncorrect{
   border:2px solid orange!important;
}
.borderFail{
   border:2px solid red!important;
}
</style>
