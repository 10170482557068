<template>
    <div class="video-doc-modal-wrapper d-flex justify-content-center align-items-center">
      <div class="video-doc-modal" :class="{'d-flex flex-column justify-content-center align-items-center':showLoading}">
        <div v-if="modaltype === 'video'" class="animated fadeIn d-flex flex-column justify-content-end align-items-end">
          <button type="button" class="btn btn-vdo-close" @click.prevent="$emit('closeModal')">
            <img src="../../assets/img/close_icon.png" alt="Close modal" />
          </button>
          <video-player :src="videosrc" ref="videoPlayer" :options="playerOptions" @click.right="handleRightClick($event)" @ready="onPlayerReady"></video-player>
        </div>
        <div v-else class="text-center">
          <div v-if="showLoading">
            <p class="fs-3 fw-bold">Loading...</p> 
          </div>
          <div v-else-if="format == 'JPG' || format == 'JPEG'" class="animated fadeIn d-flex flex-column justify-content-end align-items-end ">
            <button v-if="handlePdfLoaded" type="button" class="btn btn-vdo-close" @click.prevent="$emit('closeModal')">
              <img src="../../assets/img/close_icon.png" alt="Close modal" />
            </button>
            <img :src="source" alt="Italian Trulli" class="imgwidth" />
          </div>
          <div class="animated fadeIn d-flex flex-column justify-content-end align-items-end" v-else-if="format == 'pdf'">
            <button v-if="handlePdfLoaded" type="button" class="btn btn-vdo-close" @click.prevent="$emit('closeModal')">
              <img src="../../assets/img/close_icon.png" alt="Close modal" />
            </button>
            <div class="overflow-y"  @click.right="handleRightClick($event)" v-if="source">
              <vue-pdf-embed :source="source" @loaded="handlePdfLoaded=true"  :scale="2"/>
            </div>    
          </div>
          <div class="animated fadeIn d-flex flex-column justify-content-end align-items-end" v-else-if="office.includes(format)">
            <button v-if="handlePdfLoaded" type="button" class="btn btn-vdo-close" @click.prevent="$emit('closeModal')">
              <img src="../../assets/img/close_icon.png" alt="Close modal" />
            </button>
            <div  @click.right="handleRightClick($event)"   v-if="source">
              <vue-pdf-embed :source="source" @loaded="handlePdfLoaded=true" :scale="2"/>
            </div>         
          </div>
        </div>
      </div>
    </div>
  </template>
  <script src="./modal.js"></script>
  <style>
  .video-js .vjs-big-play-button{
    top:50% !important;
    left:47% !important;
  }
  .overflow-y{
    width:100%;
    height:580px;
    overflow-y: scroll;
  }
  .video-doc-modal-wrapper {
    inset: 0;
    position: fixed;
    transition: cubic-bezier;
    overflow: hidden;
    background: rgb(0 0 0 / 30%);
    z-index: 3000;
  }
  .close-icon {
    fill: #ffffff;
  }
  .video-doc-modal {
    color: #ffffff;
    width: min(1000px, 90%);
  } 
  .loading-image {
    mix-blend-mode: difference;
  }
  .btn-vdo-close {
    color: #000000 !important;
    background-color: #ffffff57 !important;
    border-color: #ffffff57 !important;
    padding:2px 3px 3px 3px  !important;
}
.btn-vdo-close img {
    width:26px !important;
    height:28px !important;
    padding: 0px;
}
  </style>
  