import axios from "axios";
import * as XLSX from "xlsx";
import html2pdf from 'html2pdf.js';
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import barchart from '../../custom_lib/AiCharts/adbarchart.vue';
import stackedbarchart from '../../custom_lib/AiCharts/StackedBarchart.vue';
import scatteredploat from '../../custom_lib/AiCharts/D3ScatterPlot.vue'
import D3LineChart from '../../custom_lib/AiCharts/D3LineChart.vue'
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import groupbarchart from '../../custom_lib/AiCharts/groupbarchart.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
export default{
    name: "labResultTrend",
    components: {
        historytab,
        barchart,
        stackedbarchart,
        scatteredploat,
        D3LineChart,
        advtable,
        groupbarchart,
        chartdrill,
    },
    async mounted(){
        console.log("hiii.....")
        await this.getdata();
    },
    data() {
        return {
            basetestUrl: process.env.VUE_APP_Service_URL,
            data:[],
            historykeys:[],
            drillpopup: false,
            historydata:{},
            drilldata:{},
            loader:true,
            subjectKeys: ["STUDYID", "USUBJID", "SITEID", "VISIT", "VISITNUM", "LBTEST", "LBCAT",
                          "LBSCAT", "LBORRES", "LBSTRESC", "LBSTNRLO", "LBSTNRHI", "LBSTAT", "LBDTC",
                          "LBSPEC", "LBFAST", "LBORNRIND", "LBRFTD", "LBDOSTOT", "LBDOSU", "LBTRT",
                          "LBTRTCD", "LBELTM", "LBENDTC", "LBPOS", "LBRESULTFL", "LBPROTFL", "LBDUPS",
                          "LBCONF", "LBSPCCND", "LBREASND", "LBIND", "LBSTATCD", "LBDUR", "LBPHASE",
                          "LBBLFL", "LBEXCLFL", "LBINCLFL", "LBCRITFL", "LBVALID", "LBVALIDFL", "LBSOURCE",
                          "LBORRESU", "LBPERF", "LBCLSIG", "LBMETHOD", "LBSTNRIND"],
            groupchartkeys:{
                "xaxiskey":"VISITNUM",
                "groupkey":"LBTEST",
            },
        }
    },
    methods: {
        async exporttoexcell() {
            const worksheet = XLSX.utils.json_to_sheet(this.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "Lab Result Trends.xlsx");
          },
        exporttopdf() {
            // Create a temporary container
            const pdfContainer = document.createElement('div');
            const pdfContainer1 = document.createElement('div');
            const pdfContainer2 = document.createElement('div');
            const pdfContainer3 = document.createElement('div');
      
            const captureArea1 = this.$refs.captureArea1.cloneNode(true);
            const captureArea2 = this.$refs.captureArea2.cloneNode(true);
            const captureArea3 = this.$refs.captureArea3.cloneNode(true);
      
            captureArea2.classList.add('page-break');
            captureArea3.classList.add('page-break');
            // Append cloned elements to the container
            pdfContainer1.appendChild(captureArea1);
            pdfContainer2.appendChild(captureArea2);
            pdfContainer3.appendChild(captureArea3);
            
            // Optionally style the container for PDF formatting
           
            pdfContainer1.style.width = '100%';
            pdfContainer2.style.width = '150%';
            pdfContainer3.style.width = '150%';
            pdfContainer.appendChild(pdfContainer1);
            pdfContainer.appendChild(pdfContainer2);
            pdfContainer.appendChild(pdfContainer3);

            pdfContainer.style.display = 'block';
            // Set the options for html2pdf
            const opt = {
              margin: 0,
              filename: 'Lab Result Trends.pdf',
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { scale: 2 },
              jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
            };
          
            // Convert the temporary container to PDF
            html2pdf().set(opt).from(pdfContainer).save();
          },
        async getdata() {
            this.loader = true;
            await axios.get(
              `${this.basetestUrl}/forms-ml/report/getreports?table_name=LabReports`)
              .then((res) => {
                this.data = res.data;
                this.loader = false;
                this.historydata['Home'] = this.data;
                this.historykeys.push('Home');
              })
              .catch((err) => console.log("error in screen list", err));
        },
        async pageclosed(){
            this.$router.push(`/analyticalDashboard`);
        },
        async gotohistorylevel(history){
            this.deleteAfterKeyvaluearrays(history);
            this.deleteAfterKeyarrays(history);
            this.data = this.historydata[history];
        },
        async deleteAfterKeyvaluearrays(selectedKey) {
            const entries = Object.entries(this.historydata); // Convert the object to an array of entries
            const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
          
            // If the key is found, slice the array to keep only the entries up to the selected key
            const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;

            // Convert the array of entries back into an object
            return Object.fromEntries(newEntries);
        },
        async deleteAfterKeyarrays(selectedKey) {
            let index = this.historykeys.indexOf(selectedKey);
            if (index !== -1) {
                // Slice the array up to the element (including the element)
                this.historykeys = this.historykeys.slice(0, index + 1);
            }
        },
        async drilldowncurrentchartforbar(datafromchild){
            const lastkey = this.historykeys[this.historykeys.length - 1];
            if(this.historydata[lastkey].length != datafromchild.sortedData.length){
             this.historykeys.push(datafromchild.filterkeyword);
            this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
            this.data = this.historydata[datafromchild.filterkeyword];
            }
        },
        async onclosed(){
            this.drillpopup = false;
          },
        async drillpopupopened(datafromchild) {
            this.drilldata = datafromchild;
            this.drillpopup = true;
        },
    }
}