/* eslint-disable */
// import createAuth0Client from "@auth0/auth0-spa-js";
import { ref, onMounted, computed } from 'vue'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import store from "../../store/index";
import { router } from "../../router";
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import axios from "axios";
var webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
  scope: "openid profile email offline_access",
  responseType: "token id_token",
  useRefreshTokens: true,
});

export default {
  props: {
    patientLoginId: String,
  },
    async beforeMount(){
      store.dispatch("setNavbar",false)
        store.dispatch("setPatientNavbar",true)
        document.querySelector("#page-wrapper").style.width="100%"
      },
      unmounted() {
       store.dispatch("setNavbar", true);
       store.dispatch("setPatientNavbar",false)
      },
  name: "login",
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const username = ref("");
    const password = ref("");
    const errr = ref("");
    const patientemail = ref("")
    const patientpassword = ref("")
    const result = ("");
    const defaultdate = ref("");
    const hasprivilege = ref(true);
    const inputType=ref("password")
		const visibleOn=ref(false)
    let loadingBlock=ref(false)
    let usersettingsUrl=ref(`${process.env.VUE_APP_Service_URL}/account-core/usersettings`);
    let baseurl = ref(process.env.VUE_APP_Service_URL);
    let userBaseUrl = ref(`${process.env.VUE_APP_Service_URL}/account-core/user/`);
    
    let getNavbarstatus = computed(function () {
        return store.getters.getNavbarstatus;
      });

    async function patientloginscreen() {
      router.push(`/PatientLoginScreen`);
    }

    async function login() {
      if(username.value===''|| password.value==='')
      {
        if(username.value==='')
        {
          errr.value = "Please enter your username";
        }
        else if(password.value==='')
        {
          errr.value = "Please enter your password";
        }
      }
      else{
      return webAuth.client.login(
        {
          realm: "Username-Password-Authentication",
          username: username.value,
          password: password.value,
        },
       
        
          async function (err, authResult) {
            if (err) {
              if (err.description == 'Wrong email or password.') {
                errr.value = "Invalid username or password.";
              }
              if (err.description == "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.") {
                alert(err.description);
              }
              else if (err.description == "please change your password") {
                alert("Your password has been expired. Please reset your password.")
                router.push(`/ResetPassword/${username.value}`);
              }
            }
            else {
              //add new api check call here
              const idtoken = store.getters.getIdToken;
              await axios
                .get(`${userBaseUrl.value}checkispatient?patientLoginId=${props.patientLoginId}&email=${username.value}`,
                  {
                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                    },
                  })
                .then((res) => {
                  console.log(res);
                  if (res.data == true) {
                    errr.value=""
                    loadingBlock.value=true
                    setTimeout(() => {
                      store.dispatch("loginuser", authResult);
                      router.push(`/patientupdate/${props.patientLoginId}`)
                      loginAudit(authResult)
                    }, 2000);
                  }
                  else {
                    alert("Login failed, please check the credentials!!")
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
              
            }
          }
       
        
      );
    }
    }
    /*Tab functionality begins */
    function loginClicked() {
      let login = document.getElementsByClassName("login")[0]
      let loginPatient = document.getElementsByClassName("login-patient")[0]
      let loginTab = document.getElementsByClassName("login-tab-panels")[0]
      let loginTabPatient = document.getElementsByClassName("patient-tab-panels")[0]
      loginTabPatient.style.backgroundColor = "#eaeaea"
      loginTab.style.backgroundColor = "#ffffff"
      loginPatient.style.display = "none"
      login.style.display = "block"
    }
    function patientLogin() {
      let login = document.getElementsByClassName("login")[0]
      let loginTab = document.getElementsByClassName("login-tab-panels")[0]
      let loginTabPatient = document.getElementsByClassName("patient-tab-panels")[0]
      let loginPatient = document.getElementsByClassName("login-patient")[0]
      loginTabPatient.style.backgroundColor = "#ffffff"
      loginTab.style.backgroundColor = "#eaeaea"
      login.style.display = "none"
      loginPatient.style.display = "block"
    }
    async function patientloginClicked() {
      const idtoken = store.getters.getIdToken;
      console.log("in funccccc")
      await axios
        .get(`${this.baseapi}verifypatientlogin?patientemail=${patientemail.value}&patientpassword=${patientpassword.value}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log(res);
          this.result = res.data;
          console.log("resulttttt", this.result);
          if (this.result == true) {
            alert("Patient login success")
            this.$router.push(`/PatientDashboard`);
          }
          else {
            alert("Login failed, please check the credentials!!")
          }
          store.dispatch("setrolesprivileges", 'test test,');
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function statusUpdate() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${baseurl.value}/management/patientschedule/statusupdate`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    /*Tab functionality ends */
    async function getUserSettings(authresult) {
      console.log(usersettingsUrl.value)
      const idtoken = authresult.idToken;
      const emailId = await jwt_decode(idtoken).email;
      await axios
        .get(
          `${usersettingsUrl.value}/getbyid?userId=${emailId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }
          },
        )
        .then((res) => {
            defaultdate.value = res.data.dateFormat;                     
            console.log("default dateformat from api",defaultdate.value);
            if(defaultdate.value=="undefined" || defaultdate.value==null)
            {
              localStorage.setItem("dateformat","DD/MM/YYYY")
            }
            else
            localStorage.setItem("dateformat",defaultdate.value)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function passwordVisible()
      {
        visibleOn.value=!visibleOn.value
        if(visibleOn.value==true){
          inputType.value="text"
        }
        else inputType.value="password"
      } 
    async function setrolesprivileges(data, authresult) {

      console.log("rolll privileges are .............................");
      console.log(data);
      let privilegevalues = [];
      await (data).forEach((screen) => {
        for (let j = 0; j < screen.controls.length; j++) {
          if (!privilegevalues.includes(screen.name + " " + screen.controls[j])) {
            privilegevalues.push(screen.name + " " + screen.controls[j]);
          }
        }
      });
      let userprivilegestring = privilegevalues.join();
      await store.dispatch("setrolesprivileges", `test test ${userprivilegestring}`);
      await gotopages(authresult);
    }

    async function loginAudit(authresult) {
      const token = authresult.idToken;
      const emailId = await jwt_decode(token).email;
      await axios
        .post(`${baseurl.value}/account-core/user/saveloginaudit`,
          {
            "userEmail": emailId,
            "logdetails": [
              {
                "action": "Log In",
                "date": "2022-08-30T09:10:42.634Z",
                "time": "2022-08-30T09:10:42.634Z"
              }
            ]
          }
          ,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log("login audit res", res)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function setdefaultstudy(authresult) {
      await getdefaultlanguage(authresult);
      console.log("default study");
      const idtoken = authresult.idToken;
      //const userId = await jwt_decode(idtoken).sub;
      const userId = await jwt_decode(idtoken).email;
      console.log("userid " + userId);
      await axios
        .get(`${baseurl.value}/management/study/getdefaultstudy?id=` + userId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.statusText == "No Content") {
            console.log("no default study");
          } else {
            console.log("default study" + res.data.studyID);
            store.dispatch("setStudyID", res.data.studyID);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function setuserrole(authresult) {
      console.log(authresult);
      const idtoken = authresult.idToken;
      const userid = (jwt_decode(idtoken).sub).trim();
      await axios
        .get(baseurl.value + "/account-core/user/getuserwithauthid?id=" + userid, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          console.log("rolls are");
          console.log(res.data[0].role);
          store.dispatch("setroles", res.data[0].role);
        
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function googleLogin() {
      console.log("login with google");
      webAuth.authorize(
        {
          connection: "google-oauth2",
          redirectUri: "http://localhost:8080/home",
        },
        function (err, authResult) {
          //do something-------a
          if (err) {
            email
            console.log("err.description");
          } else {
            console.log("authResult");
            store.dispatch("loginuser", authResult);
            // router.push("/home");
          }
        }
      );
    }
    async function getdefaultlanguage(authresult) {
      // alert("default study");
      const idtoken = authresult.idToken;
      const userId = await jwt_decode(idtoken).email;
      await axios
        .get(
          // .get(`${baseurl.value}/management/study/getdefaultstudy?id=` + userId,
          `${baseurl.value}/account-core/usersettings/getbyid?userId=` + userId.trim(),
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("default language", res.data.localLanguage);
          if (res.data.localLanguage) {
            localStorage.setItem("lang", res.data.localLanguage);
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
    function testonmount() {
      store.dispatch("setrolesprivileges", 'test test');
      
    }
    function microsoftLogin() {
      console.log("login with microsof");
      webAuth.authorize(
        {
          connection: "windowslive",
          redirectUri: "http://localhost:8080/home",
        },
        function (err, authResult) {
          //do something
          if (err) {
            console.log(err.description);
          } else {
            console.log(authResult);
            store.dispatch("loginuser", authResult);
            // router.push("/home");
          }
        }
      );
    }

    onMounted(testonmount)
    return {
      login,
      username,
      password,
      errr,
      defaultdate,
      patientemail,
      patientpassword,
      result,
      usersettingsUrl,
      visibleOn,
      inputType,
      getNavbarstatus,
      setuserrole,
      googleLogin,
      microsoftLogin,
      testonmount,
      patientloginscreen,
      loadingBlock,
      loginClicked,
      patientLogin,
      getdefaultlanguage,
      setdefaultstudy,
      patientloginClicked,
      passwordVisible,
      getUserSettings,
      statusUpdate
    };
  },
};
