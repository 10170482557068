<!--eslint-disable @typescript-eslint/explicit-module-boundary-types-->
<template>
  <div class="register-block row bgwhite">
    <div class="col-12 col-md-12 left-block d-flex justify-content-center">
      <div class="register-screen animated fadeInDown height-100 d-flex flex-column justify-content-center">

        <div class="tab-section text-center">
          <h5>Thank you for submitting your study e-consent!</h5>
          <div class="line-sep"><!-- Separator line --></div>
        </div>
        <div>
          <div class="ack pt-4">
            <form role="form" autocomplete="on">
              <div class="form-group">
                Your commitment to participate in this research is greatly appreciated. By providing your consent, you
                have taken a vital step towards advancing medical knowledge and improving patient care. Your involvement
                will help us gather valuable data that can lead to innovative treatments and enhance our understanding of
                the disease or condition under study. We assure you that your personal information will be handled with
                utmost confidentiality and in accordance with ethical guidelines. Your dedication to this study is
                invaluable, and we are grateful for your support in making a positive impact on healthcare.
              </div>
              <div class="form-group"> Thank you!
                <br> Sincerely,<br>
                [Study Team or Organization]
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "patientenrolment",

  components: {
    VPagination,
  },
  props: {
    patientLoginId: {

      type: String,

      default: ""

    }
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("loginidis", this.patientLoginId)
    store.dispatch("setNavbar", false)
    store.dispatch("setPatientNavbar", true)
    document.querySelector("#page-wrapper").style.width = "100%"

  },

  methods: {
  },
}
</script>


<style>
@media (max-width: 768px) {
  .loginscreen.middle-box {
    width: clamp(200px, calc(100vw - 100px), 430px);
  }
}

.img-block img {
  aspect-ratio: 1/1;
}

.register-screen {
  width: clamp(200px, calc(100vw - 100px), 440px);
}

.loading-img {
  mix-blend-mode: lighten;
}

.login-tab-panels {
  border: 1px solid #dcdfe6;
  background-color: #ffffff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: auto !important;
}

.patient-tab-panels {
  border-top-right-radius: 0.25rem;
}

.login-contents {
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.login-image>* {
  width: 100%;
}

.login-contents {
  background-color: #ffffff;
}

.height-100 {
  height: 100dvh;
}

.errmsg {
  font-size: 14px;
  color: red;
}

.form-background {
  margin-left: -15px;
}

.loginheader {
  font-size: 17px;
}

.password-visible-block {
  right: 11px;
  top: 7px;
}

.img-block {
  -webkit-background: linear-gradient(90.25deg, #80e2e7 4.35%, #059f8c 101.12%);
  -moz-background: linear-gradient(90.25deg, #80e2e7 4.35%, #059f8c 101.12%);
  background: linear-gradient(90.25deg, #80e2e7 4.35%, #059f8c 101.12%);
}

.register-block {
  background-color: #f3f3f4;
  color: #676a6c;
  width: 100vw;
}

.left-block {
  color: #676a6c;
}

.right-block {
  color: #676a6c;
}

.points-block {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.1rem;
  list-style-type: disc;
}

.bgwhite {
  background-color: white;
}

.line-sep {
  border: 1px solid var(--secondary-color)!important;
}

.ack {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #6D6D6D;
}
</style>
