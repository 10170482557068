<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Video Call</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content col-lg-12">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="">
        <div class="vdo_control-bar-outer">
          <div class="vdo_control-bar">

            <!-- Join Leave Start -->
            <div class="vdo_control-join-outer" v-if="!localUserJoined">
              <div class="vdo_control-join joincall" @click="joinCall">Join</div>
            </div>
            <div class="vdo_control-join-outer" v-if="localUserJoined">
              <div class="vdo_control-join leavecall" @click="leaveCall">Leave</div>
            </div>
            <!-- Join Leave End -->

            <!-- Audio Start -->
            <div class="vdo_control-icon muteaudio" v-if="localUserAudioEnabled" @click="muteAudio">
              <img src="../../assets/img/mic_on_white.png" alt="Mute Call" />
            </div>
            <div class="vdo_control-icon muteaudio" v-if="!localUserAudioEnabled" @click="unMuteAudio">
              <img src="../../assets/img/mic_off_white.png" alt="Mute Call" />
            </div>
            <!-- Audio End -->


            <!-- Video Start -->
            <div class="vdo_control-icon mutevideo" v-if="localUserVideoEnabled" @click="muteVideo">
              <img src="../../assets/img/video_on_white.png" alt="Turn Camera On" />
            </div>
            <div class="vdo_control-icon mutevideo" v-if="!localUserVideoEnabled" @click="unMuteVideo">
              <img src="../../assets/img/video_off_white.png" alt="Turn Camera Off" />
            </div>
            <!-- Video End -->

            <!-- <div class="vdo_control-icon" @click="startRecording"><img src="../../assets/img/video_off_white.png" alt="Join Call"/></div>
              <div class="vdo_control-icon" @click="shareScreen"><img src="../../assets/img/video_off_white.png" alt="Join Call"/></div> -->
          </div>
        </div>
        <div class="grid-container" :style="{
          gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          gridTemplateRows: `repeat(${gridRows}, 1fr)`,
        }">
          <div v-for="(item, index) in gridItems" :key="index" :id="`${item}`" class="grid-item"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./videocallscript.js">

</script>

<style scoped>
.background-video-call-div {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f73939;
}

.grid-container {
  display: grid;
  gap: 10px;
  padding: 1rem 0rem;
  margin-bottom: 1rem;
  height: calc(100vh - 50px);
}

.grid-item {
  background-color: #ffffff;
  border: 0px solid #a1a1a1;
  border-radius: 0px;
  padding: .1rem;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-strip {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: #3508ff4d;
  margin-top: auto;
}

button {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #e0e0e0;
}

.vdo_container {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  color: white;
}

/* Video Grid */
.vdo_video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

/* Video Box */
.vdo_video-box {
  background-color: #666;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: white;
}

/* Active Border */
.vdo_active {
  border: 3px solid #ffd700;
}

/* Username Label */
.vdo_username {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 14px;
}

/* Control Bar */
.vdo_control-bar {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  background: linear-gradient(#c3d1e3, #eef4fc);
}

.vdo_control-bar-outer {
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 12px;
  background: linear-gradient(#eef4fc, #c3d1e3);
}


/* Control Icons */
.vdo_control-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.vdo_control-join {
  border-radius: 10px;
  width: 112px;
  height: 32px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.vdo_control-join-outer {
  border-radius: 10px;
  width: 120px;
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  background: linear-gradient(#eef4fc, #c3d1e3);
}

.joincall {
  background: linear-gradient(#028b70, #1ab394);
}

.leavecall {
  background: linear-gradient(#b93333, #ff5151);
}

.muteaudio {
  background-color: #3c3c3c;
}

.mutevideo {
  background-color: #3c3c3c;
}

.vdo_control-icon:hover {
  background-color: #777;
}

.vdo_control-join:hover {
  background-color: #777;
}

.joincall:hover {
  background: linear-gradient(#01bd97, #4cffdb);
}

.leavecall:hover {
  background: linear-gradient(#bb0101, #ff0000);
}

.vdo_end-call {
  background-color: #e74c3c;
}

.vdo_video-on {
  background-color: #3498db;
}
</style>
