<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.sites") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 pl-lg-0 px-1">
            <label class="mb-0"> {{ $t("navigations.site code") }}</label>
            <input v-model="this.SiteCode" type="text" class="form-control my-md-2 my-lg-0" />
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0"> {{ $t("navigations.site name") }}</label>
            <input v-model="this.SiteName" type="text" class="form-control my-md-2 my-lg-0" />
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0"> {{ $t("navigations.status") }}</label>
            <select class="form-select my-md-2 my-lg-0" v-model="Status">
              <option value="All">{{ $t("navigations.all") }}</option>
              <option value="Closed">{{ $t("navigations.closed") }}</option>
              <option value="Completed">{{ $t("navigations.completed") }}</option>
              <option value="Enrolled">{{ $t("navigations.enrolled") }}</option>
              <option value="New">{{ $t("navigations.new") }}</option>
              <option value="Open">{{ $t("navigations.open") }}</option>
              <option value="Terminated">{{ $t("navigations.terminated") }}</option>
            </select>
          </div>
          <span @click="search()" class="
              col-sm-12 col-lg-2
              save_btn
              my-1 my-lg-0
              mx-lg-1
              align-self-end
            ">
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.site list") }}</h5>
        <div class="d-flex">
          <div class="position-relative align-self-center" :class="{'mr-3':returnList.length!=0}"
            v-if="!roleprivileges || roleprivileges.includes('Sites Create Site')" aria-label="Create site"
            tabindex="0">
            <img class="cursor-pointer" src="../../assets/img/create.png" width="17" height="17" alt="Create site"
              @click="editCreateSite(0)" />
            <div class="tool-tip">{{ $t("navigations.create site") }}</div>
          </div>
          <div class="position-relative cursor-pointer" v-if="returnList.length!=0" @click.prevent="showFilter = !showFilter"><img
              src="../../assets/img/filter.png" width="17" height="17" alt="filter table" />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
        </div>

      </div>
      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
          <div class="col-3 px-0" v-on:clickout="dropdownOpen = false">
            <div class=" table-multi-select-dropdown" @click.stop="dropdownOpen = !dropdownOpen;">
              <div class="selected-options">
                <span>{{ $t("navigations.select columns") }}</span>
              </div>
              <div class="dropdown-arrow"></div>
            </div>
            <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
              <label v-for="(column, index) in getColumns" :key="index">
                <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
              </label>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <div class="text-center" v-if="returnList.length===0">
            No data available
          </div>
          <table v-else class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <!-- <th> {{ $t('navigations.study') }}</th> -->
                <th v-if="selectedcolumnobj['Site Code']">
                  {{ $t("navigations.site code") }}
                </th>
                <th v-if="selectedcolumnobj['Site Name']" class="sort_block" @click="SortSelected('siteName', 2)" id="2">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.site name") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Status']" class="sort_block" @click="SortSelected('status', 3)" id="3">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Contact Name']" class="sort_block" @click="SortSelected('siteContactName', 4)" id="4">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.contact name") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Contact Email']" class="sort_block" @click="SortSelected('siteContactEmail', 5)" id="5">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.contact email") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['No. of Subjects']">
                  {{ $t("navigations.number of subjects") }}
                </th>
                <th v-if="selectedcolumnobj['Active']" class="text-center">{{ $t("navigations.active") }}</th>
                <th class="text-center" v-if="
                  (!roleprivileges || roleprivileges.includes('Sites Edit'))
                ">
                  {{ $t("navigations.edit") }}
                </th>
                <th class="text-center" v-if="
                  (!roleprivileges || roleprivileges.includes('Sites Action')) 
                ">
                  {{ $t("navigations.action") }}
                </th>
                <th class="text-center" v-if="roleprivileges.includes('Audit Trail Sites')">
                  {{ $t("navigations.audit trail") }}
                </th>
                <th class="text-center" v-if="
                  (!roleprivileges || roleprivileges.includes('Sites Delete'))
                ">
                  {{ $t("navigations.delete") }}
                </th>

                <th v-if="roleprivileges.includes('Sites Manage Folder & Document')" class="text-center">
                  {{ $t("navigations.document") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in returnList" :key="item.siteID">
                <!-- <td>{{ item.studyRef }}</td> -->
                <td v-if="selectedcolumnobj['Site Code']">{{ item.siteCode }}</td>
                <td v-if="selectedcolumnobj['Site Name']">{{ item.siteName }}</td>
                <td v-if="selectedcolumnobj['Status']">{{ item.status }}</td>
                <td v-if="selectedcolumnobj['Contact Name']">{{ item.siteContactName }}</td>
                <td v-if="selectedcolumnobj['Contact Email']">{{ item.siteContactEmail }}</td>
                <td v-if="selectedcolumnobj['No. of Subjects']">{{ item.numberOfPatient }}</td>
                <td v-if="selectedcolumnobj['Active']" class="text-center">
                  <div class="i-checks">
                    <input type="checkbox" v-model="item.active" onclick="return false" />
                  </div>
                </td>
                <td class="text-center" v-if="
                  (!roleprivileges || roleprivileges.includes('Sites Edit'))
                ">
                  <i class="fa fa-edit edit-delete" style="color: #a13b7c" @click="editCreateSite(item.siteID)"></i>
                </td>
                <td class="text-center" v-if="
                  !roleprivileges || roleprivileges.includes('Sites Action')
                ">
                  <i v-if="item.status != 'Terminated' && item.status != 'Close'" class="fa fa-plus-circle edit-delete"
                    style="color: #a13b7c" @click="openModel(item.siteID, item.status)"></i>
                </td>
                <td v-if="roleprivileges.includes('Audit Trail Sites')" class="text-center">
                  <i class="fa fa-eye edit-delete" @click="openModelaudit(item.siteID)"></i>
                </td>
                <td class="text-center" v-if="
                  !roleprivileges || roleprivileges.includes('Sites Delete')
                ">
                  <i class="fa fa-trash edit-delete" style="color: #a13b7c" @click="deletepopup(item.siteID)"></i>
                </td>
                <td v-if="roleprivileges.includes('Sites Manage Folder & Document')" class="text-center">
                  <i @click="folderList(item.siteID, item.siteCode)" class="fa fa-folder edit-delete">
                  </i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="returnList.length!=0" class="row mt-1 mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              ">
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination v-model="currentPage" :pages="totalPage" :range-size="1" active-color="#618bd7"
                  @update:modelValue="loadPage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="audit__history__modal__wrapper" v-if="audithistortpopup == true">
      <div class="audit__history__modal__container">
        <div class="
            audit__history__modal__title
            d-flex
            justify-content-between
            py-2
            px-3
          ">
          <h4 class="my-0">{{ $t("navigations.audit trail") }}</h4>
          <div class="cursor-pointer">
            <i class="fa fa-times" title="Close" @click.prevent="closehistorymodal()"></i>
          </div>
        </div>
        <!-- test -->

        <!-- Popup -->
        <div class="audit__history__modal__contents p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.field name") }}</strong>
                  </th>
                  <th>
                    <strong>{{ $t("navigations.old value") }}</strong>
                  </th>
                  <th>
                    <strong>{{ $t("navigations.new value") }}</strong>
                  </th>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.site code") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.SiteCode }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.SiteCode }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.site name") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.SiteName }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.SiteName }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.site contact name") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.SiteContactName }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.SiteContactName }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.site contact email") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.SiteContactEmail }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.SiteContactEmail }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.number of subjects") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.NumberOfPatient }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.NumberOfPatient }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.site notes") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.SiteNotes }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.SiteNotes }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
                <tr>
                  <th>
                    <strong>{{ $t("navigations.active") }}</strong>
                  </th>
                  <td v-if="auditHistoryLists.oldValue">
                    {{ auditHistoryLists.oldValue.Active }}
                  </td>
                  <td v-else>{{}}</td>
                  <td v-if="auditHistoryLists.newValue">
                    {{ auditHistoryLists.newValue.Active }}
                  </td>
                  <td v-else>{{}}</td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal starts -->
  <div class="modal__action__wrapper" v-if="modalAction">
    <div class="modal__action__container">
      <div class="container-title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.action") }}</h4>
        <div class="tooltip-wrapper pointer" @click.prevent="closemodall()" style="float: right; cursor: pointer;">
              <i class="fa fa-times close-button-popups"></i>
              <span class="custom-tooltip-popups">Close</span>
          </div>
      </div>
      <!-- test -->
      <!-- Popup -->
      <div class="container-content">
        <div class="d-flex justify-content-center h-100">
          <div class="position-relative align-self-center p-2 cursor-pointer" v-if="selectStatus == 'New'"
            @click="UpdateStatus(ActionId, 'Open')">
            <img src="../../assets/img/site_open.png" alt="Open site status" />
            <div class="tool-tip">{{ $t("navigations.open") }}</div>
          </div>
          <div class="position-relative align-self-center p-2 cursor-pointer" v-if="
            selectStatus == 'New' ||
            selectStatus == 'Open' ||
            selectStatus == 'Enrolled'
          " @click="UpdateStatusCompleted(ActionId, 'Completed')">
            <img src="../../assets/img/site_completed.png" alt="Completed site status" />
            <div class="tool-tip">{{ $t("navigations.complete") }}</div>
          </div>
          <div class="position-relative align-self-center p-2 cursor-pointer" v-if="
            selectStatus == 'New' ||
            selectStatus == 'Enrolled' ||
            selectStatus == 'Open'
          " @click="UpdateStatusTerminated(ActionId, 'Terminated')">
            <img src="../../assets/img/site_terminate.png" alt="Terminate site status"
              :title="$t('navigations.terminate')" />
            <div class="tool-tip">{{ $t("navigations.terminate") }}</div>
          </div>
          <div class="position-relative align-self-center p-2 cursor-pointer" v-if="selectStatus == 'Completed'"
            @click="UpdateStatusClose(ActionId, 'Close')">
            <img src="../../assets/img/site_close.png" alt="Close site status" :title="$t('navigations.close')" />
            <div class="tool-tip">{{ $t("navigations.close") }}</div>
          </div>
        </div>
      </div>
      <!--  popup-->
    </div>
  </div>
  <!-- Audit trail details modal starts -->
  <div v-if="auditpopup == true" class="audit__details__modal__wrapper">
    <div class="audit__details__modal__container">
      <div class="
          audit__details__modal__title
          d-flex
          justify-content-between
          py-2
          px-3
        ">
        <h4 class="my-0">{{ $t("navigations.audit details") }}</h4>
        <div class="tooltip-wrapper pointer" @click.prevent="closemodallll()" style="float: right; cursor: pointer;">
              <i class="fa fa-times close-button-popups"></i>
              <span class="custom-tooltip-popups">Close</span>
          </div>
      </div>
      <!-- Popup -->
      <div class="audit__details__modal__content p-3" :class="{ heightBig: changeHeight }">
        <div class="table-responsive">
          <table class="table table-hover issue-tracker">
            <tbody>
              <tr v-for="audit in auditLists" :key="audit.studyId" class="cursor-pointer">
                <td @click="openModelauditOps(audit.id)">
                  <span class="d-block"><strong> {{ audit.loggedInUserName }}</strong></span>
                  <small>{{ filter(audit.createdAt) }}</small>
                </td>
                <td v-if="audit.operation == 'Update'" @click="openModelauditOps(audit.id)">
                  <span class="badge badge-primary">{{ audit.operation }}</span>
                </td>
                <td v-if="audit.operation == 'Delete'" @click="openModelauditOps(audit.id)">
                  <span class="badge badge-danger">{{ audit.operation }}</span>
                </td>
                <td v-if="audit.operation == 'Insert'" @click="openModelauditOps(audit.id)">
                  <span class="badge badge-warning-light">{{
                    audit.operation
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!--  popup -->
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import VPagination from "@hennge/vue3-pagination";
import moment from "moment";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "siteList",
  components: {
    VPagination,
  },
  data() {
    return {
      audithistortpopup: false,
      auditpopup: false,
      modalAction: false,
      showFilter: false,
      dropdownOpen: false,
      selectedcolumnobj:{},
      pp: 0,
      roleprivileges: "",
      currentPage: 1,
      ItemID: "",
      AuditId: "",
      totalPage: 1,
      searchSiteCode: "",
      searchSiteName: "",
      searchStatus: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/site/`,
      baseapi: process.env.VUE_APP_Service_URL,
      idToken: "",
      searchValue: "",
      auditHistoryLists: [],
      auditLists: [],
      returnList: [],
      site: [],
      totalCount: 0,
      StudyID: "",
      SiteCode: "",
      SiteName: "",
      Status: "All",
      pageindex: 1,
      pagesize: 10,
      SortProperty: "createdAt",
      SortOrder: 1,
      Actions: "",
      ActionId: "",
      selectStatus: "",
      siteCode: "",
    };
  },
  watch: {
    returnList: {
      handler() {
        this.initializeColumns()
      },
      immediate: true,
      deep: true,
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      this.idToken = store.getters.getIdToken;
      this.setroleprivileges();
      this.getAndSetData();
    });
    await this.setroleprivileges();
    this.idToken = store.getters.getIdToken;
    await this.getAndSetData();
    this.defaultdate = localStorage.getItem("dateformat");
  },
  methods: {
    initializeColumns() {
      if (this.returnList.length > 0) {
        const allowedKeys = [         
          "siteCode",
          "siteName",
          "status",     
          "siteContactName",     
          "siteContactEmail",          
          "numberOfPatient",
          "active"
        ]
        this.getColumns= Object.keys(this.returnList[0])
        .filter(column => allowedKeys.includes(column));   
        const fieldLabels = {
          siteCode: "Site Code",
          siteName: "Site Name",
          status: "Status",
          siteContactName: "Contact Name",
          siteContactEmail: "Contact Email",          
          numberOfPatient: "No. of Subjects",       
          active:"Active"
        };

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);
         
        this.getColumns=readableData
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
       
      }
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      console.log(this.roleprivileges);
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async closehistorymodal() {
      console.log("close modalssssssssssssss");
      this.audithistortpopup = false;
    },
    openModelaudit(sitesID) {
      document.body.style.overflowY = "hidden";
      this.auditpopup = true;
      this.itemID = sitesID;
      console.log("ITEM ID Issssssssssssssssss...", this.itemID);
      //this.getDocumentHistory(this.documentId);
      //const modalWrapper = document.querySelector(".modal-wrapper");
      //modalWrapper.classList.add("active");
      this.getAuditdetails(this.itemID);
    },
    async closemodallll() {
      console.log("close modal");
      document.body.style.overflowY = "auto";
      this.auditpopup = false;
    },
    async folderList(id, siteCode) {
      await store.dispatch("setStorageId", id);
      await store.dispatch("setStorageCode", siteCode);
      const storageId = store.getters.getStorageId;
      const storageCode = store.getters.getStorageCode;
      console.log("wowwwwwww", storageId);
      this.$router.push(`/folder/${id}/${siteCode}`);
    },
    openModelauditOps(audID) {
      this.audithistortpopup = true;
      this.AuditId = audID;
      this.getAuditHistory();
    },
    async openModel(siteID, status) {
      document.body.style.overflowY = "hidden";
      this.ActionId = siteID;
      this.selectStatus = status;
      this.modalAction = !this.modalAction;
      popup.classList.add("active");
    },
    async closemodall() {
      document.body.style.overflowY = "auto";
      this.modalAction = !this.modalAction;
      this.getAndSetData();
    },

    UpdateStatusCompleted(siteid, val) {
      const idtoken = store.getters.getIdToken;
      if (
        confirm(
          this.$t("navigations.are you sure you want to complete this site?")
        )
      ) {
        axios
          .put(
            `${this.baseapi}/management/site/sitestatus?siteId=` +
            siteid +
            `&status=` +
            val,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data == "Site Completed Successfully") {
              alert(this.$t("navigations.site completed successfully"));
            } else if (res.data == "Not all visits completed") {
              alert(this.$t("navigations.not all visits completed"));
            }
          })
          .catch((err) => {
            console.log("err", err);
            {
              alert(this.$t("navigations.something went wrong"));
            }
          });
      }
      this.closemodall();
      this.getAndSetData();
    },
    UpdateStatusTerminated(siteid, val) {
      console.log("Terminated method");
      const idtoken = store.getters.getIdToken;
      if (
        confirm(
          this.$t("navigations.are you sure you want to terminate this site?")
        )
      ) {
        axios
          .put(
            `${this.baseapi}/management/site/sitestatus?siteId=` +
            siteid +
            `&status=` +
            val,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            alert(this.$t("navigations.site terminated successfully"));
            console.log("site status changed successfully...!");
          })
          .catch((err) => {
            console.log(err);
            console.log("error in change site status...!");
          });
      }
      this.closemodall();
      this.getAndSetData();
    },
    async UpdateStatusClose(siteid, val) {
      console.log("Close method");
      const idtoken = store.getters.getIdToken;
      if (
        confirm(
          this.$t("navigations.are you sure you want to close this site?")
        )
      ) {
        await axios
          .put(
            `${this.baseapi}/management/site/changesitestatus?siteId=` +
            siteid +
            `&status=` +
            val,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            console.log("site status changed successfully...!");
          })
          .catch((err) => {
            console.log(err);
            console.log("error in change site status...!");
          });
      }
      this.getAndSetData();
      this.closemodall();
    },
    async UpdateStatus(siteid, val) {
      console.log("vallllllllllllllllll", val);
      console.log("openmethod");
      const idtoken = store.getters.getIdToken;
      if (
        confirm(this.$t("navigations.are you sure you want to open this site?"))
      ) {
        await axios
          .put(
            `${this.baseapi}/management/site/changesitestatus?siteId=` +
            siteid +
            `&status=` +
            val,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            console.log("site status changed successfully...!");
          })
          .catch((err) => {
            console.log(err);
            console.log("error in change site status...!");
          });
      }
      this.getAndSetData();
      this.closemodall();
    },
    async deletepopup(id) {
      if (
        confirm(
          this.$t("navigations.are you sure you want to delete this site?")
        )
      ) {
        await axios
          .get(
            `${this.baseapi}/management/patient/getpatientcount?Siteid=${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.idToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("*************");
            console.log(res);
            if (res.data < 1) {
              //this.withdrawPatientUnderSite(id);
              axios
                .delete(this.baseUrl + "delete?SiteID=" + id, {
                  headers: {
                    Authorization: "Bearer " + this.idToken,
                    "Content-Type": "application/json",
                  },
                })
                .then(() => {
                  this.search();
                  alert(this.$t("navigations.deleted successfully"));
                });
            } else {
              alert(
                this.$t(
                  "navigations.this site contains 1 or more patients, sites with patients cannot be deleted"
                )
              );
            }
          });

      }

    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getAndSetData();
    },
    async search() {
      this.setSearchData();
      this.pageindex = 1;
      this.currentPage = 1;
      this.getAndSetData();
    },
    setSearchData() {

      this.searchSiteCode = this.SiteCode;
      this.searchSiteName = this.SiteName;
      this.searchStatus = (this.Status === "All") ? "" : this.Status;
      this.totalPage = parseInt(this.totalCount / this.pagesize);

    },
    //search data items are passed as parameters
    async getAndSetData() {
      const currentstudy = store.getters.getStudyIs;
      console.log(currentstudy);
      await axios
        .get(
          this.baseUrl +
          "searchlist?StudyID=" +
          currentstudy +
          "&pageindex=" +
          this.pageindex +
          "&pagesize=" +
          this.pagesize +
          "&SortProperty=" +
          this.SortProperty +
          "&SortOrder=" +
          this.SortOrder +
          "&SiteCode=" +
          this.searchSiteCode +
          "&SiteName=" +
          this.searchSiteName +
          "&Status=" +
          this.searchStatus,

          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.returnList = res.data.results;
          this.siteCode = this.returnList.siteCode;
          this.totalCount = res.data.totalCount;
          this.totalPage = parseInt(this.totalCount / this.pagesize);
          if (this.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getnextpage() {
      console.log("NextPage");
      console.log(this.totalCount);
      console.log(this.totalCount / 10 > this.pageindex);
      if (this.totalCount / 10 > this.pageindex) {
        this.pageindex++;
        await this.getAndSetData();
      }
    },
    async getpreviouspage() {
      if (this.pageindex > 1) {
        console.log("PreviousPage");
        this.pageindex--;
        await this.getAndSetData();
      }
    },
    async withdrawPatientUnderSite(siteId) {
      await axios
        .put(
          this.baseUrl + `/patient/withdrawpatientundersite?SiteId=` + siteId,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Patients withdrawned", res);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    editCreateSite(id) {
      this.$router.push(`/managesite/${id}`);
    },
    myCallback() {
      console.log("call back.........");
      this.pageindex = this.page;
      this.getAndSetData();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.getAndSetData();
    },
    async getAuditdetails() {
      //this.ItemID = this.patientId;
      console.log("item id is", this.itemID);
      await axios
        .get(
          `${this.baseapi}/management/audit/getaudits?AuditTable=Management&ItemID=${this.itemID}&Sort=Desc`
        )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.auditLists = res.data.data;

          console.log("Audit Trial list is", this.auditLists);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
          console.log(res);
        });
    },
    async getAuditHistory() {
      console.log("audit Id is...", this.AuditId);
      await axios
        .get(
          `${this.baseapi}/management/audit/getaudit?AuditId=${this.AuditId}&auditTable=Management`
        )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.auditHistoryLists = res.data;

          console.log("Audit History List issssss", this.auditHistoryLists);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
          console.log(res);
        });
    },
  },
};
</script>

<style>
@import "../../assets/bootstrap.css";
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "./modal.css";

/*pagination styles*/
.pagination {
  margin: 0;
}

.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}

.modal__action__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}

.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}

.form-control {
  padding: 0.2rem 0.75rem !important;
}

.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}

.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}

.modal__action__container {
  position: fixed;
  width: min(300px, 90%);
  height: 100px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-x: hidden;
}

.text-center1 {
  text-align: center !important;
}

.btn-outline1 {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.btn-success1 {
  color: #fff;
  border-color: var(--secondary-clr);
}

.btn-xs1 {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}

.btn1 {
  border-radius: 3px;
  font-size: inherit;
  padding: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.container-title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}

.container-content {
  height: calc(100% - 2.5rem);
}

.scrollable3 {
  overflow-y: scroll;
  height: 250px;
}

.audit__details__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.audit__details__modal__container {
  width: min(90%, 365px);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}

.audit__details__modal__title,
.audit__history__modal__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}

.audit__details__modal__content {
  overflow-y: scroll;
  height: 250px;
}

.audit__history__modal__contents1 {
  overflow-y: scroll;
  height: 313px;
}

.audit__history__modal__wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 4000;
}

.audit__history__modal__container {
  width: min(45%, 90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}

.headingunderline {
  text-decoration-line: underline;
}
</style>