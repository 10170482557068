<template>
  <div ref="chart" class="chart-container"></div>
</template>

<script>
import * as d3 from 'd3';
import axios from "axios";
export default {
  name: 'SurvivalChart',
  props: {
    analysisdata: Object
  },
  watch: {
    analysisdata: {
      handler() {
        this.createChart();
      },
      immediate: true
    }
  },
    mounted() {
      window.removeEventListener('resize', this.createChart);
      this.createChart();
    },
    beforeUnmount() {
    window.removeEventListener('resize', this.createChart);
  },
    methods: {
      createChart() {
        

        if (!this.analysisdata || !this.analysisdata["coef"]) {
          console.log('No data available for chart');
          return;
        }
        const data = this.analysisdata.coef;
        const container = this.$refs.chart;
        d3.select(container).selectAll("*").remove();
        console.log('Creating chart with data:', data);

        const margin = { top: 20, right: 30, bottom: 40, left: 90 };
        const width = container.clientWidth - margin.left - margin.right;
        const height = 300 - margin.top - margin.bottom;

        const svg = d3
          .select(this.$refs.chart)
          .append('svg')
          .attr("viewBox", `0 0 ${width + margin.left + margin.right + 100} ${height + margin.top + margin.bottom+100}`)
          .attr("preserveAspectRatio", "xMidYMid meet")
          .append('g')
          .attr('transform', `translate(${margin.left+100},${margin.top})`);

        const formattedData = Object.entries(data).map(([key, value]) => ({
          attribute: key,
          value: parseFloat(value) 
        })).filter(d => !isNaN(d.value));

        const x = d3
          .scaleLinear()
          .domain([
            d3.min(formattedData, d => d.value) * 1.1,
            d3.max(formattedData, d => d.value) * 1.1
          ])
          .range([0, width]);

        const y = d3
          .scaleBand()
          .range([0, height])
          .domain(formattedData.map(d => d.attribute))
          .padding(0.1);

        // Append bars
        svg
          .selectAll('.bar')
          .data(formattedData)
          .enter()
          .append('rect')
          .attr('class', 'bar')
          .attr('x', d => (d.value < 0 ? x(d.value) : x(0)))
          .attr('y', d => y(d.attribute))
          .attr('width', d => Math.abs(x(d.value) - x(0)))
          .attr('height', y.bandwidth())
          .attr('fill', d => (d.value < 0 ? '#69b3a2' : '#ff6347'));

        // Add values inside bars
        svg
          .selectAll('.label')
          .data(formattedData)
          .enter()
          .append('text')
          .attr('class', 'label')
          .attr('x', d => (d.value < 0 ? x(d.value) - 5 : x(d.value) + 5))
          .attr('y', d => y(d.attribute) + y.bandwidth() / 2 + 5)
          .attr('text-anchor', d => (d.value < 0 ? 'end' : 'start'))
          .attr('fill', 'black')
          .text(d => d.value.toFixed(2));

        // Add x-axis
        svg
          .append('g')
          .attr('transform', `translate(0,${height})`)
          .call(d3.axisBottom(x));

        // Add y-axis
        svg.append('g').call(d3.axisLeft(y));
        // Add x-axis label
        svg.append("text")
        .attr("class", "x-axis-label")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom + 10) // Position below the x-axis
        .attr("text-anchor", "middle")
        .text("Coefficient ");

        console.log('Chart created successfully');
      }
    }
  };
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}

rect {
  transition: fill 0.3s ease;
}
rect:hover {
  fill: #ff6347;
}
</style>
