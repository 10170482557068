<template>
    <div class="stat-card-white d-flex align-items-center">
      <div class="drilldown-path-block p-2">
        <span>{{$t("navigations.drilldown path")}}:</span>
      </div>
      <div class="d-flex justify-content-between align-items-center flex-grow-1">
        <nav aria-label="breadcrumb" class="ml-2">
          <ul class="custom-breadcrumb d-flex flex-wrap">
            <li 
              v-for="(history, index) in historyarray" 
              :key="index" 
              class="custom-breadcrumb-item"
              aria-current="page"
            >
              <template v-if="index === historyarray.length - 1">
                <span class="linkclass" @click.prevent="gotolevel(history)">{{ history }}</span>
              </template>
              <template v-else>
                <span  class="linkclass" @click.prevent="gotolevel(history)">{{ history }}</span>
                <span class="separator">/</span>
              </template>
            </li>
          </ul>
        </nav>
        <div class="text-center mr-2" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont mx-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="showVisitPopup = false,exportPDF()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </template>
  <script>
  /* eslint-disable */
  import axios from "axios";
  import moment from "moment";
  import * as d3 from "d3";
   
  export default {
    name: "historytab",
    props: {
      historyarray: {
        type: Object,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {
        showVisitPopup: false
      };
    },
    async mounted() {
    },
    methods: {
      gotolevel(history){
        this.$emit("gotohistorylevel", history);
      },
      exporttoexcell(){
        this.$emit("exporttoexcell");
      },
      exportPDF(){
        this.$emit("exporttopdf");
      },
    },

  };
  </script>
   
   <style>
   @import "../../custom_lib/AiCharts/charts.css";
   .plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(124px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}
.biggerfont {
  font-size: 16px;
}
   </style>