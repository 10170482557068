<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2
        v-if="
          $store.getters.getsourceUrl == false ||
          $store.getters.getsourceUrl == 'false'
        "
      >
      {{ currentselectedformname }}
      </h2>
      <h2
        v-if="
          $store.getters.getsourceUrl == true ||
          $store.getters.getsourceUrl == 'true'
        "
      >
        {{ $t("Fill form") }}
      </h2>
    </div>
  </div>

  <div class="wrapper wrapper-content">
    <div
      class="ibox-content d-flex"
      v-if="showDiv"
      :class="{ 'height-offset': showDiv, 'text-center': showDiv }"
    >
      <div class="empty-study-block align-items-center">
        <img
          src="../../assets/img/folder_empty.png"
          width="100"
          alt="Empty folder List"
        />
        <h4 class="my-1">{{ $t("navigations.no visits found") }}</h4>
        <span class="fs-6 text-muted my-1">{{ $t("navigations.create your first visit") }}</span>
        <button
          class="save_btn px-3 py-1"
          @click.prevent="$router.push(`/addvisit/0`)"
        >
          <span class="pr-1"
            ><img
              src="../../assets/img/plus_icon.png"
              alt="Add subject" /></span
          >{{ $t("navigations.new visit") }}
        </button>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-3 col-12 pr-0">

        <div class="ibox-content minheight">
          <div
            class="panel-group mb-2"
          >
            <div class="panel panel-defaultconcent">
              <div               
                class="panel-heading fontblack d-flex justify-content-between align-items-center py-1"
              >
                <div class="d-flex align-items-center">

                  <h5 class="panel-title cursor-pointer" tabindex="0">
                     Consent Forms
                  </h5>
                </div>
              </div>
              <span >
                <span >
                  <div
                    v-for="(visits,index) in concentdata"
                    :key="visits.tempId"
                    class="pannelwhite panel-collapse border-bottom px-3 py-2"                    
                  >
                    <div class="template-body cursor-pointer" @click.prevent="expandfun(visits,index,true,false)">
                      <div class="template-list px-6 mb-0">
                        <span>
                        {{ visits.templateName }}</span
                        >
                      </div>
                    </div>
                  </div>
                </span>
              </span>
              <div
              @click="showconsentform()"
                class="panel-heading cursor-pointer fontblack d-flex justify-content-between align-items-center py-1"
              >
                <div  class="d-flex align-items-center">

                  <h5 class="panel-title " tabindex="0">
                    e-Signature
                  </h5>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <div class="ibox-title style_2 d-flex justify-content-between">
          <h6 class="align-self-center"> {{ currentselectedformname }}</h6>
          <div
            class="col-sm-12 col-lg-2 d-flex flex-lg-row align-items-center justify-content-lg-end mx-lg-0 my-lg-0"
            v-if="
              $store.getters.getsourceUrl == true ||
              $store.getters.getsourceUrl == 'true'
            "
          >
         
          </div>

        </div>
        
        <div class="ibox-content">
          <div class="ibox-sign mb-2" v-if="signStatus == `Signed`&&(
              $store.getters.getsourceUrl == true ||
              $store.getters.getsourceUrl == 'true')
            ">
    </div>
          <span

          >
            <div v-if="!consentformvisible && isedittrue" class="minheight col-12">
              <consentform 
              :formsesid = "currentformid" 
              :esignvisitId = "esignvisitId"
              :esignsiteId="esignsiteId"
              :esignsubjectId="esignsubjectId"
              :esignstudyId="esignstudyId"
              :key="currentformid"
              :patientLoginId="patientLoginId" 
              :requiredsubmit = 'true' 
              :closedoption = "whenclosed"
              @saveClicked="currentIndex===concentdata.length-1? expandfun(concentdata[currentIndex],currentIndex,false,false):
              expandfun(concentdata[currentIndex+1],currentIndex+1,false)"
              :isPatientVisit=$store.getters.getsourceUrl 
              type: Boolean, 
              default: false />
            </div>
            <div v-if="consentformvisible && !disableInput" class="minheight col-12">
              <h6>By signing below, I have confirmed: </h6>
              <div class="form-check">
                  <input class="form-check-input" type="checkbox"  v-model="isConsent"  value="" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    I acknowledge that I have read and comprehended both consent form and the study’s description. I am willingly and voluntarily signing this form as evidence of my decision to participate in this study. I will receive a signed and dated copy of this consent form for my records. By signing this form, I provide consent for the use and disclosure of my health information as stated within the form. 
                  </label>
                  <div class="col-12 col-lg-6 my-2">
          <label class="col-form-label">Initials </label>
          <input
            type="text"
            class="form-control" v-model="Initials" 
            maxlength="2" style="text-transform: uppercase"
          />
          <label class="col-form-label">Email ID </label>
          <input
            type="text" v-model="email" 
            class="form-control border-left-important"
            maxlength="50"
          />
          <span class="errormsg" v-if="errors[0] == false"
            >Please enter your email ID</span
          >
          <label class="col-form-label">Password </label>
          <input
            type="password" v-model="password"  
            class="form-control border-left-important"
            maxlength="50"
          />
          <span class="errormsg" v-if="errors[1] == false"
            >{{$t("navigations.please enter your password")}}</span
          >
          <div role="button" tabindex="0" @keyup.enter="saveSignData()" class="save_btn col-lg-12 py-2 mt-4"  @click.prevent="saveSignData()">Sign</div>
        </div>
</div>
            </div>
            <div v-if="consentformvisible && disableInput" class="minheight col-12">
             
              <h1 class="consentheader">{{consentdata}}</h1>
              <div :class="{'green-underline': consentdata === 'Consented', 'red-underline': consentdata !== 'Consented'}"></div>
              <!-- <h6>By signing below, I have confirmed: </h6> -->
              <div class="form-check">
  <input class="form-check-input" type="checkbox"  v-model="isConsent" :disabled="disableInput"  value="" >
  <label class="form-label" >
    I acknowledge that I have read and comprehended both consent form and the study’s description. I am willingly and voluntarily signing this form as evidence of my decision to participate in this study. I will receive a signed and dated copy of this consent form for my records. By signing this form, I provide consent for the use and disclosure of my health information as stated within the form. 
  </label>
              </div>
            <div class="col-12 col-lg-6 my-2">
              <div class="row">
              <label class="col-form-label">{{ signinitials }}</label>
              </div>
              <div class="row">
              <label class="col-form-label ">{{ signedBy }}</label>
              </div>
              <div class="row">
              <label class="col-form-label ">Date :{{ filter(signdate) }}</label>
              </div>
              
            </div>
           
            </div>
            <div v-if="!consentformvisible && !isedittrue" class="minheight col-12">
              <consentformtemplate
                :selectedpageno="selectedpageno"
                :key="templateId"
                :templateid="templateId"
                :stdyid="esignstudyId"
                :visitids="esignvisitId"
                :issavefun="true"
                :linkpatientId="esignsubjectId"
                :linksiteId="esignsiteId"
                :isPatientVisit="true"
                :schedulesId="sheduleId"
                v-on:child-event="parentFunction"
                @saveClicked="currentIndex===concentdata.length-1? expandfun(concentdata[currentIndex],currentIndex,false,false):
              expandfun(concentdata[currentIndex+1],currentIndex+1,false)"
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <signaturePopUp
    v-if="signpopUp == true"
    :status="signatuestatus"
    :formId="signatureformId"
    :modalText="modalStatus"
    @signatureclosemodel="signpopUp = false"
  >
  </signaturePopUp>
  <div class="patient__login__modal__wrapper" v-if="showPopup == true">
    <div class="patient__login__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0 align-self-center">{{ $t("navigations.online monitoring") }}</h4>
        <span
          type="button"
          class="align-self-center"
          data-dismiss="modal"
          @click="closemodal()"
          aria-hidden="true"
          ><i class="fa fa-times modal-close align-self-center"></i>
        </span>
      </div>
      <div class="d-flex justify-content-between p-4">
        <input
          @change="OnUserTypeOnEmailField()"
          class="btn-outline btn-default w-100 align-self-center py-2 px-3 mr-3"
          type="email"
          placeholder="Email"
          v-model="remoteEmail"
        />

        <button
          @click="OnUserClickedInvite()"
          :disabled="inviteButtonstats"
          class="btn-outline btn-default align-self-center py-2 px-3"
        >
          ✔ {{ $t("navigations.invite") }}
        </button>
      </div>
    </div>
  </div>
  <!-- <floatable-video-call v-if="showVideoCall == true" @close="showVideoCall = false" :id="tokenId"> </floatable-video-call> -->
</template>
<script src="./consentFormList.js"></script>
<style scoped>
@import "./modal.css";
@import "../../assets/style.css";
@import "../../assets/formpage.css";

.form-group {
  margin-bottom: 0rem;
}
.popUp-background:hover {
  background-color: #dddddd;
}
.minheight {
  height: 400px;
  overflow: auto;
}

.pagination {
  margin: 0;
}

.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}

.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}

.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}

.wrapper-content {
  position: relative;
}

.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}

.page-links {
  border-radius: 0px;
}

.modelbody {
  overflow: unset !important;
}

/* .titleclass{
background-color:#6198fc;
}
.titlestyle {
    background-color: #6198fc;
} */
.modelbody {
  background: #fff;
  /* background-color: aquamarine; */
  margin: 0;
  padding: 5%;
}

.modal-container {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}

.headingunderline {
  text-decoration-line: underline;
}

.nextbtn {
  height: 30px;
  margin-top: 1%;
}

.patient__login__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}

.patient__login__modal__container {
  position: fixed;
  width: min(500px, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}

.modal__title {
  background-color: #059f8c;
  color: white;
}
.rounded-block {
  width: 10px;
  height: 10px;
  border-radius: 100vw;
}
.badge-grn {
  background-color: #1ab394;
  color: #fff;
}
.badge-genre {
  background-color: #b6bdbf;
  color: #ffffff;
}
.rounded-block .pointer {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  bottom: -40px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.rounded-block:hover .pointer {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.panel-defaultconcent{
  background-color: #d9d9d9;
  color: black;
}
.fontblack{
  color: black !important;
}
.pannelwhite{
  background-color:white;
}
.border-left-important {
  border-left: 2px solid #ff6565;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.consentheader {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.underline {
  height: 3px;
  width: 100%;
  background-color: green;
  margin-bottom: 10px;
}
.green-underline {
  border-bottom: 2px solid green;
  margin-bottom: 10px;
}

.red-underline {
  border-bottom: 2px solid red;
  margin-bottom: 10px;
}
</style>
