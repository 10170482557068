import store from "../../store/index";
import axios from "axios";
import Multiselect from "@vueform/multiselect";
import jwt_decode from "jwt-decode";

export default {
  props: {
    patientid: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
      email: {
        type: String,
        required: true
      },
      isFromTrainingMaterials: {
        type: Boolean,
        default: false
      }
  },
  name: "CreateVideoCallSchedulePopUp",
  components: {
    Multiselect,
  },
  async mounted() {
    if (["FillForm", "SubjectList", "ViewForm", "BlankForm","EditFrom"].includes(this.fromPage)) {
      await this.getUserbySubject();
    }
    await this.GetUserbyStudy();
  },
  computed: {
    combinedStartDateTime() {
      if (this.data.startDate && this.data.startTime) {
        const dateTimeString = `${this.data.startDate}T${this.data.startTime}:00`;
        const date = new Date(dateTimeString);
        return date.toISOString();
      }
      return "";
    },
    combinedEndDateTime() {
      if (this.data.endDate && this.data.endTime) {
        const dateTimeString = `${this.data.endDate}T${this.data.endTime}:00`;
        const date = new Date(dateTimeString);
        return date.toISOString();
      }
      return "";
    },
    attendeeOptions() {
      const subjectUser = this.Email ? [{ email: this.Email }] : [];
      return [...subjectUser, ...this.UserList];
    }
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      UserList: [],
      Email: "",
      today: new Date().toISOString().substr(0, 10),
      data: {
        users: this.isFromTrainingMaterials ? [this.email] : [],
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        startTime: "",
        endTime: "",
        requestFor: null,
        sendDefaultEmails: true,
        emailTemplate: null,
      },
      shouldShowAttendee: false,
      validations: [false, false, false, false, false, false],
      errors: [],
    };
  },
  methods: {
    async closeModal() {
      this.$emit("closeModal");
      
    },
    async GetUserbyStudy() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      let useremail = jwt_decode(idtoken).email;
      try {
        const res = await axios.get(
          `${this.baseurl}/account-core/user/getstudybasedusers?studyid=${currentstudy}&sortProperty=email&IsDes=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        this.UserList = res.data.entities.filter(user => user.email !== useremail);
      } catch (err) {
        console.log(err);
      }
    },
    async getUserbySubject() {
      const idtoken = store.getters.getIdToken;
      try {
        const res = await axios.get(
          `${this.baseurl}/management/patient/getpatientemailwithid?subjectId=${this.patientid}&sortProperty=&IsDes=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        this.Email = res.data;
        this.data.users.push(this.Email);
      } catch (err) {
        console.log(err);
      }
    },
    async Validation() {
      this.errors[0] = this.data.users.length === 0;
      this.errors[1] = !this.data.startDate;
      this.errors[2] = !this.data.endDate;
      this.errors[3] = !this.data.startTime;
      this.errors[4] = !this.data.endTime;
      const startDate = new Date(this.data.startDate);
      const endDate = new Date(this.data.endDate);

      if (startDate < endDate) {
        this.errors[5] = false;
      } else if (startDate > endDate) {
        this.errors[5] = true;
      } else {
        this.errors[5] = false;
      }

      if (this.data.startDate === this.data.endDate && this.data.endTime && this.data.startTime) {
        const startTime = new Date(`1970-01-01T${this.data.startTime}:00`);
        const endTime = new Date(`1970-01-01T${this.data.endTime}:00`);

        this.errors[6] = startTime >= endTime;
      } else {
        this.errors[6] = false;
      }
      const currentDateTime = new Date();
      const startDateTime = new Date(`${this.data.startDate}T${this.data.startTime}`);
      const endDateTime = new Date(`${this.data.endDate}T${this.data.endTime}`);
    
     
      if (startDateTime < currentDateTime) {
        this.errors[7] = 'Start time is in the past';
      } else if (endDateTime < currentDateTime) {
        this.errors[8] = 'End time is in the past';
      } else {
        this.errors[7] = false;
        this.errors[8] = false;
      }
    },
    // async Schedule() {
    //   this.Validation();
    //   if (!this.errors.includes(true)) {
    //     const scheduleData = {
    //       users: this.data.users,
    //       startTime: this.combinedStartDateTime,
    //       endTime: this.combinedEndDateTime,
    //       requestFor: this.isFromTrainingMaterials ? "E-consent support" : null,
    //       sendDefaultEmails: this.data.sendDefaultEmails,
    //       emailTemplate: null,
    //     };

    //     try {
    //       const idtoken = store.getters.getIdToken;
    //       if(!isFromTrainingMaterials){}
         
    //       await axios.post(
    //         `${this.baseurl}/management/videocall/schedulecall`,
    //         scheduleData,
    //         {
    //           headers: {
    //             Authorization: "Bearer " + idtoken,
    //             "Content-Type": "application/json",
    //           },
    //         }
    //       );
    //       alert("Video call scheduled successfully.");
    //       this.closeModal();
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // },
    async Schedule() {
      this.Validation();
      if (!this.errors.includes(true)) {
        const scheduleData = {
          users: this.data.users,
          startTime: this.combinedStartDateTime,
          endTime: this.combinedEndDateTime,
          requestFor: this.isFromTrainingMaterials ? "E-consent support" : null,
          sendDefaultEmails: this.data.sendDefaultEmails,
          emailTemplate: null,
          title:this.data.requestFor
        };
    
        try {
          const idtoken = store.getters.getIdToken;
          
          if (!this.isFromTrainingMaterials) {
            await axios.post(
              `${this.baseurl}/management/videocall/schedulecall`,
              scheduleData,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            );
            alert("Video call scheduled successfully.");
          } else {
            await axios.post(
              `${this.baseurl}/management/videocall/requestpatientvideocall`,
              scheduleData,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            );
            alert("Video call request has been successfully submitted. Once an investigator approves your request, you will receive a confirmation email with video call joining link.");
          }
          
         
          this.closeModal();
          
        } catch (err) {
          console.log(err.response.data.errors.DomainValidations[0]);
          if (err.response && err.response.data && err.response.data.errors.DomainValidations[0] === "Invalid start time") {
            alert("The selected time is no longer available. Please choose an upcoming time for the video call.");
          } else {
            console.log(err);
            alert("An error occurred while scheduling the video call.");
          }
          console.log(err);
        }
      }
    }
    
    
  },
};
