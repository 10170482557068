/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import VPagination from "@hennge/vue3-pagination";
import Multiselect from "@vueform/multiselect";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "visitList",
  components: {
    VPagination,
    Multiselect
  },
  data() {
    return {
      roleprivileges: "",
      selectedvisitid: "",
      showFilter: false,
      dropdownOpen: false,
      selectedcolumnobj:{},
      selectedTemplateIds:[],
      templateNameArray:[],
      templatelist: [],
      baseapi: process.env.VUE_APP_Service_URL,
      studyid: "",
      currentPageSize: 0,
      currentPageNumber: 0,
      visitList: [],
      totalItems: 0,
      totalCount: 0,
      totalPages: 0,
      formsArray:[],
      searchData: {
        pagesize: 10,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      requestData: {
        pagesize: 10,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      formItems:[],
      openModalWrapper:false,
      pageNumbers: [],
      visitides: [],
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      this.setstudy();
      console.log("from mount")
      this.currentPageSize = 10;
      this.currentPageNumber = 1;
      this.requestData.pagesize = 10;
      this.requestData.pageindex = 1;
      this.searchAndGetData();
      this.gettemplatedata();
    });
    await this.setroleprivileges();
    await this.setstudy();
    this.currentPageSize = 10;
    this.currentPageNumber = 1;
    this.requestData.pagesize = 10;
    this.requestData.pageindex = 1;
    console.log("pas data", this.requestData)
    this.searchAndGetData();
    await this.gettemplatedata();

  },
  // computed: {
  //   sortedArray() {
  //     function compare(a, b) {
  //       if (a.visitNo < b.visitNo)
  //         return -1;
  //       else if (a.visitNo > b.visitNo)
  //         return 1;
  //       else
  //         return 0;
  //     }
  //     return this.visitList.sort(compare);
  //   }
  // },
  computed: {
    selectedmodeltemplate() {
      return this.selectedTemplateIds.map(id => {
        return this.templatelist.find(template => template.templateId === id);
      });
    }
  },
  watch: {
    visitList: {
      handler() {
        this.initializeColumns()
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    initializeColumns() {
      if (this.visitList.length > 0) {
        const allowedKeys = [
        "visitNo" ,
        "visitFormName" ,
        "visitType" ,
        "preVisit" ,
        "postVisit" ,
        "visitDayPeriod" ,
        "visitWeekPeriod" ,
        "visitMonthPeriod" ,
        "visitYearPeriod",
        ]
        this.getColumns= Object.keys(this.visitList[0])
        .filter(column => allowedKeys.includes(column));       
        const fieldLabels = {
          visitNo: "Visit No.",
          visitFormName: "Visit Name",
          visitType: "Visit Type",
          preVisit: "Pre-Visit",
          postVisit: "Post-Visit",
          visitDayPeriod: "Visit Period(Days)",
          visitWeekPeriod: "Visit Period(Weeks)",
          visitMonthPeriod: "Visit Period(Months)",
          visitYearPeriod:"Visit Period(Year)",
        };

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);
         
        this.getColumns=readableData
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});
      }
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged', sortProp);
      console.log(this.requestData.SortOrder)
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        if (this.requestData.SortOrder == 0) {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.requestData.pageindex = 1;
      this.currentPageNumber = 1;
      this.requestData.SortProperty = sortProp;
      this.requestData.SortOrder = this.requestData.SortOrder == 0 ? 1 : 0;
      this.searchAndGetData();
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    async assignFun() {
      if (this.selectedTemplateIds.length==0) {
        alert(this.$t('navigations.please select a form'))
      }
      else {
        const idtoken = store.getters.getIdToken;
        this.templateNameArray=[]
        console.log("Value is",this.selectedmodeltemplate)
        this.selectedmodeltemplate.forEach((item,index)=>{
          this.formsArray[index]={
            "template_ID": "",
            "templateName": "",
            "version": ""
          },
          this.formsArray[index].template_ID=item.templateId
          this.formsArray[index].templateName=item.templateName
          this.formsArray[index].version=item.version
        })
        await axios
          .put(`${this.baseapi}/management/visit/assigntemplate`,
            {
              "visitID": this.selectedvisitid,
              "forms": this.formsArray

            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert(this.$t('navigations.template assigned successfully'));
            this.selectedmodeltemplate = []
            this.formsArray=[]
            this.selectedTemplateIds=[]
          })
          .catch((err) => {
            console.log("error");
            console.log(err);
          });
        this.closeModal();
        this.search();
      }
    },
    async gettemplatedata() {
      const idtoken = store.getters.getIdToken;
      console.log("template data function");
      await axios
        .get(`${this.baseapi}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyid}&SortProperty=templateName&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("template data is");
          console.log(res.data);
          this.templatelist = res.data.results.sort((a, b) => {
            return a.templateName.localeCompare(b.templateName);
          });
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
        });
    },
    async getAllForms(visitId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/getallforms?studyId=${this.studyid}&visitId=${visitId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formItems = res.data[0];
          console.log("form items...", this.formItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async viewForm(id) {
      this.$router.push(`/viewTemplate/visitlist/${id}`);
    },
    async openModal(visitid) {
      this.selectedvisitid = visitid;
      this.openModalWrapper=true
      await this.gettemplatedata();
      await this.getAllForms(visitid);
      if(this.formItems != null){
        this.selectedTemplateIds = this.formItems.map(item => item.template_ID);
        console.log("Selected templates....",this.selectedTemplateIds)
      }
    },
    openFormsModal(visitid) {
      this.selectedvisitid = visitid;
      this.getAllForms(visitid);
      const modalWrapper = document.querySelector('.modal-wrapper-forms');
      modalWrapper.classList.add('active');
    },
    closeModal() {
      this.selectedTemplateIds = [];
      this.openModalWrapper=false
    },
    closeModalForms() {
      const modalWrapper = document.querySelector('.modal-wrapper-forms');
      modalWrapper.classList.remove('active');
    },
    async setstudy() {
      this.studyid = store.getters.getStudyIs;
      await store.dispatch("setVisitStudyID", this.studyid);
    },
    async addvisit(id) {
      this.$router.push(`/addvisit/${id}`);
    },
    async search() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      console.log(this.requestData);
      this.currentPageNumber = 1;
      this.requestData.pageindex = 1;
      await this.searchAndGetData();
    },
    //function used for search 
    async searchbtn() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      this.requestData.pageindex = 1;
      this.currentPageNumber = 1;

      const pIndex = Number(this.requestData.pageindex);
      const pSize = Number(this.requestData.pagesize);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/listvisit?VisitName=${this.requestData.FormName}&VisitNo=${this.requestData.visitNo}&StudyId=${this.studyid}&pageindex=${pIndex}&pagesize=${pSize}&SortProperty=${this.requestData.SortProperty}&SortOrder=${this.requestData.SortOrder}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("respp", res.data);
          if (res.data.data === "No Visit Found") {
            alert("No Visits Were Found");
            this.visitList = [];
          } else {
            this.visitList = res.data.results;
            this.totalItems = res.data.visitCont;
            this.totalCount = res.data.totalCount;
            console.log("total count is", this.totalCount)
            this.totalPages = Math.ceil(res.data.totalCount / 10);
            // if ((this.totalCount % pSize) != 0) {
            //   this.totalPages = this.totalPages + 1
            // }
            console.log("this.totalPages = " + this.totalPages);
          }

          this.totalItems = res.data.userCont;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleted(visitId) {
      this.visitides.push(visitId);
      const idtoken = store.getters.getIdToken;
      console.log("visitId", visitId);
      console.log("Enter mounted");
      console.log("itsVisditId", this.visitides);
      if (confirm("Do you really want to delete this visit?")) {
        await axios
          .delete(
            `${this.baseapi}/management/visit`, { data: this.visitides },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.userList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.visitides = [];
      this.searchAndGetData();
    },
    async onPageClick(event) {
      this.requestData.pageindex = event;
      this.searchAndGetData();
    },
    //function to retrieve data by pagination click
    async searchAndGetData() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      const pIndex = Number(this.requestData.pageindex);
      const pSize = Number(this.requestData.pagesize);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/listvisit?VisitName=${this.requestData.FormName}&VisitNo=${this.requestData.visitNo}&StudyId=${this.studyid}&pageindex=${pIndex}&pagesize=${pSize}&SortProperty=${this.requestData.SortProperty}&SortOrder=${this.requestData.SortOrder}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("respp", res.data);
          if (res.data.data === "No Visit Found") {
            alert("No Visits Were Found");
            this.visitList = [];
          } else {
            this.visitList = res.data.results;
            this.totalItems = res.data.visitCont;
            this.totalCount = res.data.totalCount;
            console.log("total count is", this.totalCount)
            this.totalPages = Math.ceil(res.data.totalCount / 10);
            // if ((this.totalCount % pSize) != 0) {
            //   this.totalPages = this.totalPages + 1
            // }
            console.log("this.totalPages = " + this.totalPages);
          }

          this.totalItems = res.data.userCont;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadPage: function (page) {
      console.log("pAGE.....", page)
      this.currentPageNumber = page;
      this.requestData.pageindex = page;
      this.searchAndGetData();

    }
  }
}

