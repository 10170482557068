<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{ $t("navigations.google fit") }}</h2>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="ibox-content mb-1 px-3 py-2">
            <div class="form-group row mb-0 my-lg-0">
                <div class="
                            d-flex
                            flex-column flex-lg-row
                            justify-content-lg-start
                            my-2 my-lg-0
                            mb-lg-0
                            ">
                    <div class="col-12  my-1 pl-lg-0 pr-lg-1">
                        <div class="form-group mb-md-0">
                            <label class="mb-0" for="emailid">{{ $t("navigations.email") }}</label>
                            <select class="form-select my-md-2 my-lg-0" name="email" id="emailid"
                                @change.prevent="getData(searchEmail, true), getGraphData(searchEmail, searchFilterOptions)"
                                v-model="searchEmail">
                                <option value="All">{{ $t("navigations.all") }}</option>
                                <option v-for="(email, index) in emailSet" :key="index" :value="email">
                                    {{ email }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pr-0 px-0">
            <div class="ibox-body mb-1">
                <div class="ibox-title style_2 d-flex justify-content-between">
                    <h5 class="mt-1">{{ $t('navigations.vitals') }}</h5>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="form-group d-flex align-items-center mb-md-0 mr-3 w-200">
                            <label class="mb-0 mr-2" for="emailid">{{ $t('navigations.filter') }}</label>
                            <select class="form-select my-md-2 my-lg-0" name="email" id="emailid"
                                @change.prevent="getGraphData(searchEmail, searchFilterOptions)"
                                v-model="searchFilterOptions">
                                <option value="All">{{ $t("navigations.all") }}</option>
                                <option v-for="(item, index) in filterOptions " :key="index">
                                    {{ item }}
                                </option>
                            </select>
                        </div>
                        <div tabindex="0" aria-label="Bar graph" role="button" class="graphbutton mr-2"
                            data-toggle="tooltip" data-placement="top" title="Bar graph"><img
                                src="../../assets/images/bar_clr.png" @keypress="chartchange('bar')"
                                @click.prevent="chartchange('bar')"></div>
                        <div tabindex="0" aria-label="Horizontal bar graph" role="button" class="graphbutton mr-2"
                            data-toggle="tooltip" data-placement="top" title="Horizontal bar graph"><img
                                src="../../assets/images/horiz_bar_clr.png" @keypress="chartchange('barhorizondal')"
                                @click.prevent="chartchange('barhorizondal')"></div>
                        <div tabindex="0" aria-label="Line graph" role="button" class="graphbutton mr-2"
                            data-toggle="tooltip" data-placement="top" title="Line graph"><img
                                src="../../assets/images/Line_clr.png" @keypress="chartchange('line')"
                                @click.prevent="chartchange('line')"></div>
                        <div tabindex="0" aria-label="Pie graph" role="button" class="graphbutton mr-2"
                            data-toggle="tooltip" data-placement="top" title="Pie graph"><img
                                src="../../assets/images/Pie_clr.png" @keypress="chartchange('pie')"
                                @click.prevent="chartchange('pie')" title="Pie graph"></div>
                        <div tabindex="0" aria-label="Scatter graph" role="button" class="graphbutton "
                            data-toggle="tooltip" data-placement="top" title="Scatter graph"><img
                                src="../../assets/images/bubble_clr.png" @keypress="chartchange('scatter')"
                                @click.prevent="chartchange('scatter')"></div>
                    </div>
                </div>
                <div class="ibox-content" v-if="searchFilterOptions == 'Blood Pressure'">
                    <div>
                        <apexchart v-show="vitalschart.charttype != 'pie'" height="300" 
                            :type="vitalschart.charttype" :options="vitalschart.chartOptions" :series="doublebarchart.series">
                        </apexchart>
                        <apexchart v-show="vitalschart.charttype == 'pie'" height="300"
                            :type="vitalschart.charttype" :options="vitalspiechartoptions"
                            :series="doublebarchart.series"></apexchart>
                    </div>
                </div>
                 <div class="ibox-content" v-if="searchFilterOptions != 'Blood Pressure'">
                    <div id="charts-page">
                        <apexchart v-show="vitalschart.charttype != 'pie'" height="300"
                            :type="vitalschart.charttype" :options="vitalschart.chartOptions" :series="vitalschart.series">
                        </apexchart>
                        <apexchart v-show="vitalschart.charttype == 'pie'" height="300"
                            :type="vitalschart.charttype" :options="vitalspiechartoptions"
                            :series="vitalschart.piedataseries"></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-body">
            <div class="ibox-title style_2 d-flex justify-content-between">
                <h5 class="mt-1">{{ $t("navigations.google fit") }}</h5>
            </div>
            <div class="ibox-content">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered dataTables">
                        <thead>
                            <tr>
                                <th>{{ $t("navigations.step count") }}</th>
                                <th>{{ $t("navigations.calories") }}</th>
                                <th>{{ $t("navigations.average weight") }}</th>
                                <th>{{ $t("navigations.average heart rate") }}</th>
                                <th>{{ $t("navigations.average bp") }}</th>
                                <th>{{ $t("navigations.start time") }}<br /><small>({{ defaultTime }})</small></th>
                                <th>{{ $t("navigations.start date") }}</th>
                                <th>{{ $t("navigations.end time") }}<br /><small>({{ defaultTime }})</small></th>
                                <th>{{ $t("navigations.end date") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in dataList" :key="index">
                                <!-- <td>{{ index+ 1 }}</td> -->
                                <td>{{ data.step_Count }}</td>
                                <td>{{ data.calories }}</td>
                                <td>{{ data.weight }}</td>
                                <td>{{ data.heart_Rate }}</td>
                                <td>{{ data.blood_Pressure }}</td>
                                <td>{{ filter(data.start_Time, true) }}</td>
                                <td>{{ filter(data.start_Time, false) }}</td>
                                <td>{{ filter(data.end_Time, true) }}</td>
                                <td>{{ filter(data.end_Time, false) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-1 mx-0">
                    <div class="col-lg-12 mx-0 px-0">
                        <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                            <v-pagination v-model="currentPage" :pages="totalPages" :range-size="1" active-color="#618bd7"
                                @update:modelValue="loadPage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./googleFit.js"></script>
<style>
.w-200{
    width:200px;
}
</style>