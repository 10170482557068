<template>
    <div>
    <div class="add_form_modal_wrapper">
      <div class="add_form_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Add new Row</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="$emit('closeModal')"></i>
          </a>
        </div>
        <div class="row w-100 mx-auto">
          <div class="col-lg-12 py-3">
            <!-- <label for="form-name">Number of columns:</label>
            <select
                    v-model="moofcolumns"
                    class="form-control mr-2 version"
                  >
                    <option value="1" >1</option>
                    <option value="2" >2</option>
                    <option value="3" >3</option>
                    <option value="4" >4</option>
                    <option value="5" >5</option>
                    <option value="6" >6</option>
                  </select> -->
                  <div class="ibox-content-elements">
                    <div
  :class="[
    moofcolumns === '1' ? 'colmatch' : '', 
    'effects widget text-center border-tile elements-tools-bg m-auto mb-2'
  ]"
  @click="moofcolumns = '1'"
>
                      <div class="outerrowimg col-md-12">
                          <div class="innerrowimg col-md-12 m-1"></div>
                      </div>
                      <h5 class="font-bold">One column in a row</h5>
                    </div>
                    <div
  :class="[
    moofcolumns === '2' ? 'colmatch' : '', 
    'effects widget text-center border-tile elements-tools-bg m-auto mb-2'
  ]"
  @click="moofcolumns = '2'"
>
                    <div class="outerrowimg col-md-12 d-flex gap-1">
                          <div class="innerrowimg col-md-6"></div>
                          <div class="innerrowimg col-md-6"></div>
                      </div>
                      <h5 class="font-bold">Two columns in a row</h5>
                    </div>
                    <div
  :class="[
    moofcolumns === '3' ? 'colmatch' : '', 
    'effects widget text-center border-tile elements-tools-bg m-auto mb-2'
  ]"
  @click="moofcolumns = '3'"
>
                    <div class="outerrowimg col-md-12 d-flex gap-1">
                          <div class="innerrowimg col-md-4"></div>
                          <div class="innerrowimg col-md-4"></div>
                          <div class="innerrowimg col-md-4"></div>
                      </div>
                      <h5 class="font-bold">Three columns in a row</h5>
                    </div>
                    <div
  :class="[
    moofcolumns === '4' ? 'colmatch' : '', 
    'effects widget text-center border-tile elements-tools-bg m-auto mb-2'
  ]"
  @click="moofcolumns = '4'"
>
                    <div class="outerrowimg col-md-12 d-flex gap-1">
                          <div class="innerrowimg col-md-3 "></div>
                          <div class="innerrowimg col-md-3 "></div>
                          <div class="innerrowimg col-md-3 "></div>
                          <div class="innerrowimg col-md-3 "></div>
                      </div>
                      <h5 class="font-bold">Four columns in a row</h5>
                    </div>
                    <div
  :class="[
    moofcolumns === '6' ? 'colmatch' : '', 
    'effects widget text-center border-tile elements-tools-bg m-auto mb-2'
  ]"
  @click="moofcolumns = '6'"
>
                    <div class="outerrowimg col-md-12 d-flex gap-1">
                          <div class="innerrowimg col-md-2"></div>
                          <div class="innerrowimg col-md-2"></div>
                          <div class="innerrowimg col-md-2"></div>
                          <div class="innerrowimg col-md-2 "></div>
                          <div class="innerrowimg col-md-2 "></div>
                          <div class="innerrowimg col-md-2 "></div>
                      </div>
                      <h5 class="font-bold">Six columns in a row</h5>
                    </div>
                    <div style="width:100px; margin:auto"
                    >
                    </div>
                  </div>
          </div>
          <!-- <div class="col-lg-12 py-1">
            <label for="form-name">Position of new group:</label>
            <select
                    v-model="positionofnewgroup"
                    class="form-control mr-2 version"
                  >
                    <option value="Before">Before</option>
                    <option value="After" >After</option>
                  </select>
           </div> -->
          <div class="col-lg-12 py-1 mb-2">
            <button type="button" class="btn add_btn d-flex mx-auto py-0" @click.prevent="createrandongroup()">OK</button>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script src="./addnewrandomcolumn.js"></script>

<style scoped>
.add_form_modal_wrapper {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}

.add_form_modal__containers {
  width: min(400px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.add-block-title {
  color: #ffffff;
  background-color: #768DD3;
}
.add_btn{
    color: #ffffff;
    background-color: #768DD3;
}
.add_btn:is(:hover,:focus,:active){
    background-color: #768DD3;
}
.ibox-content-elements h5 {
  font-size: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.border-tile {
    border: solid;
    border-width: 1px;
    border-color: #bdbbbb;
}
.innerrowimg{
    border: solid;
    border-width: 1px;
    border-color: grey;
    height: 17px;
    border-radius: 4px;
    background-color: #f5f5f5;
}
.colmatch{
  background-color:#e6eeff
}
</style>