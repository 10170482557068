<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2> {{$t('navigations.partially filled forms')}}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 pl-0 pr-1">
            <label class="mb-0">{{$t('navigations.site code')}}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.SiteCode"
              name="account"
              @change="getpatients()"
            >
              <option selected value="">{{$t('navigations.all')}}</option>
              <option
                v-for="site in sites"
                :key="site.sitetId"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{$t('navigations.subject id')}}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.PatientId"
              name="account"
            >
              <option value="">{{$t('navigations.all')}}</option>
              <option
                v-for="patient in patients"
                :key="patient.patientId"
                :value="patient.patientId"
              >
                {{ patient.subjectId }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{$t('navigations.visit name')}}</label>
            <input
              type="search"
              v-model="searchdata.FormName"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <!-- <span type="button" @click.prevent="filteredLists" -->
            <span type="button" 
            class="col-12 col-md-2 w-100 save_btn mx-lg-1 align-self-end"
            @click="SearchButtonClicked()"
          >
           {{$t('navigations.search')}}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="my-0">{{$t('navigations.partially filled forms')}}</h5>
        <div class="position-relative cursor-pointer" v-if="dataList.length!=0" @click.prevent="showFilter = !showFilter"><img
              src="../../assets/img/filter.png" width="17" height="17" alt="filter table" />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
      </div>
      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
          <div class="col-3 px-0" v-on:clickout="dropdownOpen = false">
            <div class=" table-multi-select-dropdown" @click.stop="dropdownOpen = !dropdownOpen;">
              <div class="selected-options">
                <span>{{ $t("navigations.select columns") }}</span>
              </div>
              <div class="dropdown-arrow"></div>
            </div>
            <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
              <label v-for="(column, index) in getColumns" :key="index">
                <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center" v-if="dataList.length===0">
          No data available
        </div>
        <div v-else class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
               <th v-if="selectedcolumnobj['Site Code']">{{ $t('navigations.site code') }}</th>
                 <th v-if="selectedcolumnobj['Subject ID']">{{ $t('navigations.subject id') }}</th>
                  <th v-if="selectedcolumnobj['Visit Name']" class="sort_block" @click="SortSelected('visitName',3)" id="3">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t('navigations.visit name') }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                  </th>
                  <th v-if="selectedcolumnobj['Form Name']" class="sort_block" @click="SortSelected('formName',8)" id="8">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t('navigations.form name') }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                  </th>
                 <th v-if="selectedcolumnobj['Status']" class="sort_block" @click="SortSelected('FormStatus',4)" id="4">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t('navigations.status') }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                  </th>
                  <th v-if="selectedcolumnobj['Uploaded By']" class="sort_block" @click="SortSelected('createdUserName',5)" id="5">
                    <div class="d-flex flex-row justify-content-between">  
                      {{ $t('navigations.uploaded by') }}            
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th  v-if="selectedcolumnobj['Uploaded Date']" class="sort_block" @click="SortSelected('createdAt',6)" id="6">
                    <div class="d-flex flex-row justify-content-between">              
                      {{ $t('navigations.uploaded date') }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                    <th v-if="selectedcolumnobj['Data Source']" class="sort_block" @click="SortSelected('dataSource',7)" id="7">
                      <div class="d-flex flex-row justify-content-between">   
                        {{$t('navigations.data source')}}           
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                <th class="text-center">{{$t('navigations.view')}}</th>                 
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataList" :key="item.formId">
                <td v-if="selectedcolumnobj['Site Code']">{{ item.siteId }}</td>
                <td v-if="selectedcolumnobj['Subject ID']">{{ item.subjectId }}</td>
                <td v-if="selectedcolumnobj['Visit Name']">{{ item.visitName }}</td>
                <td v-if="selectedcolumnobj['Form Name']">{{ item.formName }}</td>
                <td v-if="selectedcolumnobj['Status']">{{ item.formStatus }}</td>
                <td v-if="selectedcolumnobj['Uploaded By']">{{ item.createdUserName }}</td>
                <td v-if="selectedcolumnobj['Uploaded Date']">{{ filter(item.createdAt) }}</td>
                <td v-if="selectedcolumnobj['Data Source']" >{{ item.dataSource }}</td>
                <td class="text-center">
                  <i
                      class="fa fa-eye editicon"
                      @click.prevent="viewforms(item.formId,item.siteId,item.subjectId)"
                    ></i
                  >
                </td>                 
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
          <div class="row mx-0" v-if="dataList.length!=0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                    v-model="currentPageNumber"
                    :pages="totalLists"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  /> 
                </div>
              </div>
            </div>             
          </div>
      </div>
    </div>
  </div>
</template>


<script src="./ReviewFormList.js" type="text/javascript">
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
</style>