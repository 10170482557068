/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import signaturePopUp from "../signaturePopUp/signaturePopUp.vue"
import consentform from "../../custom_lib/dynamic_forms/consentformlib/consentform.vue"
import consentformtemplate from "../../custom_lib/dynamic_forms/consentformlib/consentformtemplate.vue"
export default {
  name: "consentFormList",
  components: {
    consentform,
    consentformtemplate,
    VPagination,
    signaturePopUp
  },
  props: {
    patientLoginId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // tokenId:"b6945994-b8e8-479d-b5d4-fa0ff2ab2d6a",
      // showVideoCall: false,
      currentselectedformname:"",
      esignvisitId:"",
      esignsiteId:"",
      esignsubjectId:"",
      esignstudyId:"",
      Initials:"",
        status:"",
        email:"",
        password:"",
        isConsent:false,
        disableInput: false,
      signdate:"",
      signinitials:"",
      consentdata:"",
      signedBy:"",
      errors: [],
      validations: [false, false],
      currentIndex:0,
      consentformvisible: false,
      concentdata:[],
      formFound:false,
      signpopUp:false,
      signatuestatus:"",
      signatureformId:"",
      modalStatus:"",
      signdate:"",
      signtime:"",
      signedby:"",
      signStatus:"",
      loggedinUser:"",
      TrueORfalse: false,
      shoeaddeditbtn:false,
      sheduleId: "",
      visitselected: false,
      showPopup: false,
      currentformid: "",
      isedittrue: false,
      selectedpageno: 1,
      pages: {},
      addformvar: false,
      remoteEmail: "",
      inviteButtonstats: true,
      showBlockpopup:false,
      visitidvar: "",
      templateId: "",
      templatelist: {},
      expandfunvat: {},
      showBlock: [false],
      roleprivileges: "",
      selectedvisitid: "",
      selectedmodeltemplate: "",
      baseapi: process.env.VUE_APP_Service_URL,
      studyid: "",
      currentPageSize: 0,
      currentPageNumber: 0,
      visitList: [],
      totalItems: 0,
      totalCount: 0,
      totalPages: 0,
      searchData: {
        pagesize: 10,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      requestData: {
        pagesize: 10,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      pageNumbers: [],
      visitides: [],
      showDiv: false,
      mountedValue: false,
    };
  },
  async beforeMount(){
    store.dispatch("setShowonlyheadder", false);
    store.dispatch("setsourceUrl", true);
  },
  async mounted() {
    store.dispatch("setNavbar", false)
		store.dispatch("setPatientNavbar", true)
		document.querySelector("#page-wrapper").style.width = "100%";
    const idtoken = store.getters.getIdToken;
    window.addEventListener("FormDigitalSignature", (event) => {
      if (event.detail.storage == true) {
        //window.location.reload();
        this.getSignStatus()
      }
    });
    window.addEventListener("studyIdChanged", (event) => {
      const getsourceUrl = store.getters.getsourceUrl;
      const boolgetsourceUrl = store.getters.getsourceUrl
      this.setstudy();
      this.currentPageSize = 10;
      this.currentPageNumber = 1;
      this.requestData.pagesize = 10;
      this.requestData.pageindex = 1;
      if (boolgetsourceUrl == true ||
        getsourceUrl == 'true') {
        this.ScheduleData();
      }
      else {
        this.searchAndGetData();
      }
    });
    const getsourceUrl = await store.getters.getsourceUrl;
    const boolgetsourceUrl = await store.getters.getsourceUrl
    await this.setroleprivileges();
    await this.setstudy();
    this.currentPageSize = 10;
    this.currentPageNumber = 1;
    this.requestData.pagesize = 10;
    this.requestData.pageindex = 1;
    this.mountedValue = true
    if (boolgetsourceUrl == true ||
      getsourceUrl == 'true') {
      await this.ScheduleData();
    }
    else {
      await this.searchAndGetData();
    }
    await this.getconcentdata();
    this.expandfun(this.concentdata[0],0,false,true);
  },
  unmounted() {
    store.dispatch("setsourceUrl", false);
    store.dispatch("setShowonlyheadder", true);
  },
  methods: {
    parentFunction(){
      //empty Function
    },
    async validateIsNull() {
      this.email = this.email.replace(/ +/g, ' ').trim();
      this.password = this.password.replace(/ +/g, ' ').trim();
      if (this.email != "") {
        this.validations[0] = true;
      }
      else{
        this.validations[0] = false;
      }
      if (this.password != "") {
        this.validations[1] = true;
      }
      else{
        this.validations[1] = false;
      }
    

    },
    async saveSignData() {
      await this.validateIsNull();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      }
      else {
        if(this.isConsent==true){
          this.status="Consented";
          await this.saveSignapicall();
        }
        else{
        this.status="Dissented";
        if (confirm("We have noticed that you have not selected to accept the consent. \nAre you sure you want to continue?")) {
          await this.saveSignapicall();
        }
        else{

        }
        }
    
      }
    },
    async saveSignapicall(){
      if(this.Initials !=null){
        this.Initials = this.Initials.toUpperCase();
      }
      const idtoken = store.getters.getIdToken;
      await axios
        .put(`${this.baseapi}/account-core/user/signconsent?patientLoginId=${this.patientLoginId}&Initials=${this.Initials}&status=${this.status}&email=${this.email}&password=${this.password}`,{},
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
         
         this.$router.push(`/acknowledgement`)
        
        })
        .catch((err) => {
          console.log(err);
          var result = err.response.data.errors.DomainValidations[0];
          alert(result);
        });
    },
    async showconsentform(){
    await this.isSigned();
    this.consentformvisible = true;
    this.currentselectedformname = "e-Signature";
    },
    async isSigned(){
      const idtoken = store.getters.getIdToken;
      await axios.get(
        `${this.baseapi}/account-core/user/getsigneddetails?patientLoginId=${this.patientLoginId}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
         if(res.data.status!=null){
          this.signinitials=res.data.initials;
          this.consentdata =  res.data.status=="Consented"? "Consented" :"Dissented";
          this.isConsent = res.data.status=="Consented"? true :false;
          this.signedBy = res.data.signedBy;
          this.signdate = res.data.signedDate;
          this.disableInput= true;
          
         }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async signatureClick(status) {
      this.signpopUp = true;
      this.signatuestatus = status
      this.signatureformId = this.currentformid
      if (status == "Signed") {
        this.modalStatus = "Sign"
      }
      else if (status == "Unsigned") {
        this.modalStatus = "Unsign"
      }
    },
    filter(date) {
      const formattedDate = moment(date).format('DD-MM-YYYY hh:mma')
      return date = formattedDate;
    
    },
    async getSignStatus() {
      console.log("form sign status from viewcomponent page");
      const idtoken = store.getters.getIdToken;
      await axios.get(
        `${this.baseapi}/forms/forms/signstatus?formId=${this.currentformid}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          console.log("Form sign is", res);
          this.signdate = res.data.signedAt;
          this.signtime = moment(this.signdate).format("hh:mm a")
          this.signedby = res.data.signedUserName
          this.signStatus = res.data.signStatus
          this.loggedinUser = res.data.loggedInUser
          console.log("Form sign status is", this.signStatus);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async openInfo() {
      document.body.style.overflowY = "hidden"
      this.showPopup = !this.showPopup;
    },

    // async openInfo() {
    //   this.showVideoCall = true;
    // },

    async closemodal() {
      document.body.style.overflowY = "auto"
      this.showPopup = false;
    },
    OnUserTypeOnEmailField() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.remoteEmail)) {
        this.inviteButtonstats = false;
      } else {
        this.inviteButtonstats = true;
      }
    },
    async OnUserClickedInvite() {
      const idtoken = store.getters.getIdToken;
      const dateTimeNow = new Date().toISOString();
      await axios
        .post(`${this.baseapi}/management/videocall/roomlink?dateTime=${dateTimeNow}`,
          [
            this.remoteEmail
          ],
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          alert("Invited successfully.")
          console.log(res.data);
          this.closemodal()
          this.remoteEmail = null;
          this.inviteButtonstats = true;
        })
        .catch((err) => {
          alert("Something went wrong.")
          console.log(err);
        });
    },

    async ScheduleData() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/patientschedule/getshedules?StudyId=${this.studyid}&PageNo=1&ItemCount=10&sortprop=&isDes=true&PatientID=${this.patientLoginId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.totalCount < 1) {
            this.searchAndGetData();
          }
          else {
            this.visitList = res.data.data;
            this.totalItems = res.data.visitCont;
            this.totalCount = res.data.totalCount;
            console.log("total count is", this.totalCount);
            this.totalPages = parseInt(this.totalCount / pSize);
            if (this.totalCount % pSize != 0) {
              this.totalPages = this.totalPages + 1;
            }
            this.totalItems = res.data.userCont;
          }
        }
        )
        .catch((err) => {
          console.log(err);
        });
    },
    async getconcentdata() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/forms/template/templatedetails?iseConsent=true&patientLoginId=${this.patientLoginId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.concentdata = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async searchAndGetData() {
      this.studyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/listvisit?StudyId=${this.studyid}&VisitType=e-Consent`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("respp", res.data.results);

          this.visitList = res.data.results;
          if (this.visitList.length == 0) {
            this.showDiv = true
          }
          else {
            this.showDiv = false
          }
          this.totalItems = res.data.visitCont;
          this.totalCount = res.data.totalCount;
          console.log("total count is", this.totalCount);
          this.totalPages = parseInt(this.totalCount / pSize);
          if (this.totalCount % pSize != 0) {
            this.totalPages = this.totalPages + 1;
          }

          this.totalItems = res.data.userCont;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createVisit() {
      this.$router.push(`/addvisit/0`)
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged', sortProp);
      console.log(this.requestData.SortOrder)
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        if (this.requestData.SortOrder == 0) {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.requestData.pageindex = 1;
      this.currentPageNumber = 1;
      this.requestData.SortProperty = sortProp;
      this.requestData.SortOrder = this.requestData.SortOrder == 0 ? 1 : 0;
      this.searchAndGetData();
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    async assignFun() {
      if (this.selectedmodeltemplate == "") {
        alert(this.$t('navigations.please select a template'))
      }
      else {
        const idtoken = store.getters.getIdToken;
        await axios
          .put(`${this.baseapi}/management/visit/assigntemplate`,
            {
              "createdUserEmail": "",
              "createdUserId": "",
              "createdAt": new Date(),
              "lastModifiedUserEmail": "",
              "lastModifiedUserId": "",
              "lastModifiedAt": new Date(),
              "visitID": this.selectedvisitid,
              "templateID": this.selectedmodeltemplate,
              "version": "1.0"
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert(this.$t('navigations.template assigned successfully'));
            this.selectedmodeltemplate = ""
            console.log(res.data);
          })
          .catch((err) => {
            console.log("error");
            console.log(err);
          });
        this.closeModal();
        this.search();
      }
    },
    async viewform(id) {
      this.$router.push(`/viewTemplate/visitlist/${id}`);
    },
    openModal(visitid) {
      this.selectedvisitid = visitid;
      const modalWrapper = document.querySelector('.modal-wrapper');
      modalWrapper.classList.add('active');
    },
    async addform() {
      this.$router.push(`/formdesigner/${this.templateId}`);
    },
    async createform() {
      this.$router.push(`/formdesigner/0`);
    },
    closeModal() {
      const modalWrapper = document.querySelector('.modal-wrapper');
      modalWrapper.classList.remove('active');
    },
    async setstudy() {
      this.studyid = store.getters.getStudyIs;
      await store.dispatch("setVisitStudyID", this.studyid);
    },
    async addvisit(id) {
      this.$router.push(`/addvisit/${id}`);
    },
    async search() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      console.log(this.requestData);
      await this.searchAndGetData();
    },
    async settemplate(templateid, pageno, isFormFilled, formisds) {
      if (isFormFilled == true) {
        this.isedittrue = true;
        this.currentformid = formisds;
        this.getSignStatus();
      }
      else {
        this.isedittrue = false;
      }
      this.templateId = templateid;
      this.selectedpageno = pageno;
      store.dispatch("setselectedpageno", pageno);
    },
    async expandfun(visits,index,buttonClicked,initialCall) {
      
      if(this.currentIndex===this.concentdata.length-1 && !buttonClicked && initialCall===false) 
      {
        this.consentformvisible=true 
        this.disableInput=false
        this.currentIndex=0
        this.currentselectedformname = "e-Signature";
      }
      else{
      this.currentIndex=index
      this.esignvisitId = visits.visitId;
      this.esignsiteId = visits.siteId;
      this.esignsubjectId = visits.subjectId;
      this.esignstudyId = visits.studyId;
      this.consentformvisible = false;
      this.templateId = visits.tempId;
      this.currentselectedformname = visits.formName;

      this.visitselected = true;
      if (visits.isFormFilled == true) {
        this.isedittrue = true;
        this.currentformid = visits.formId;
      }
      else {
        this.isedittrue = false;
      }
      await store.dispatch("setvisitId", visits.visitId);
      await store.dispatch("setvisitName", visits.visitFormName);
      this.visitidvar = visits.visitId;
      await this.gettemplates(visits, index);
      this.sheduleId = visits.sheduleId;
      if (this.expandfunvat.hasOwnProperty(visits.visitId)) {
        this.expandfunvat[visits.visitId] = !this.expandfunvat[visits.visitId];
      }
      else {
        this.expandfunvat[visits.visitId] = true;
      }
      }
      this.getconcentdata()
      
      // getSignStatus
    },
    async gettemplates(visits, index) {
      const id = visits.visitId;
      const formfilledstatus = visits.isFormFilled;
      const formids = visits.formId;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/getbyid/${visits.visitId}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {

          if (JSON.stringify(res.data) != '{}') {
            this.shoeaddeditbtn = true;
          }
          else {
            this.shoeaddeditbtn = false;
          }
          this.templatelist[index] = res.data;
          if (this.templatelist[index].template_ID == null) {
            this.templateId = "";
            this.addformvar = false;
            this.mountedValue = false
          }
          else {
            this.mountedValue = true
            this.addformvar = true;

          }
          console.log("visit list and index is ", this.templatelist, index)
          this.settemplate(this.templatelist[0].forms[index].template_ID, 1, formfilledstatus, formids);
          this.getPages(this.templatelist[0].forms[index].template_ID);

        })
        .catch((err) => {
          console.log(err);
        });

    },
    async getPages(id) {
      const idtoken = store.getters.getIdToken;
      // if(this.selectedpageid != id){
      // this.selectedpageid = id;
      await axios
        .get(
          `${this.baseapi}/forms/templatedesign/getpagesbyid?formId=${id}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }
          }
        )
        .then((res) => {
          this.pages[id] = res.data.pages;
          // console.log("page")

        })
        .catch((err) => {
          if (err && id != null) {
            alert("No pages found")
          }
          console.error(err)

        });
      // }
    },
    async deleted(visitId) {
      this.visitides.push(visitId);
      const idtoken = store.getters.getIdToken;
      if (confirm("Do you really want to delete this visit?")) {
        await axios
          .delete(
            `${this.baseapi}/management/visit/delete?id=${visitId}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.userList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.visitides = [];
      this.searchAndGetData();
    },
    async onPageClick(event) {
      this.requestData.pageindex = event;
      this.searchAndGetData();
    },

  }
}

