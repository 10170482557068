<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white">
      <span>{{ chartlabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <!-- <select class="graph-select py-0" v-model="selectedxaxis" id="fieldSelect">
          <option value="" disabled>Select X-Axis Field</option>
          <option v-for="(column, index) in availableColumns" :key="index" :value="column">
            {{ column }}
          </option>
        </select> -->
        <div class="expand-block px-2 mb-1 cursor-pointer position-relative" @click="showtable()">
          <img class="cursor-pointer" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>Data Overview</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white d-flex flex-column" :class="{'h-100': loader}">
      <div class="col-12 col-md-12 ">
        <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
          <label class="ml-2">X-Axis Field</label>
        <select class="graph-select py-0 ml-2 col-md-2" v-model="selectedxaxis" id="fieldSelect">
          <option value="" disabled>Select X-Axis Field</option>
          <option v-for="(column, index) in availableColumns" :key="index" :value="column">
            {{ column }}
          </option>
        </select>
        <label class="ml-2">Y-Axis Field</label>
        <select class="graph-select py-0 ml-2 col-md-2" v-model="selectedyaxis" id="fieldSelect">
          <option value="" disabled>Select Y-Axis Field</option>
          <option v-for="(column, index) in availableColumns" :key="index" :value="column">
            {{ column }}
          </option>
        </select>
        <!-- <label class="ml-2">Value</label>
        <select class="graph-select py-0 ml-2 col-md-2" v-model="selectedvaluekey" id="fieldSelect">
          <option value="" disabled>Select Value</option>
          <option v-for="(column, index) in availableColumns" :key="index" :value="column">
            {{ column }}
          </option>
        </select> -->
        <!-- <label class="ml-2">Statistical method</label>
        <select class="graph-select py-0 ml-2 col-md-2" v-model="selectedstatisticmethod" id="fieldSelect">
          <option value="" disabled>Select Statistical method</option>
          <option value="mean">
            mean
          </option>
          <option value="average">
            average
          </option>
          <option value="median">
            median
          </option>
          <option value="mode">
            mode
          </option>
        </select> -->
      </div>
      </div>
      <div v-if="loader" class="loader"></div>
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div ref="chartContainerWidth" v-show="!loader" class="w-100 overflow-x-auto">
        <svg ref="chartContainer"></svg>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock" @closeModal="expandBlock=false,createHeatmap()">
    <div class="stat-body-white d-flex flex-column">
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div class="histogram-content overflow-x-scroll">
        <svg ref="popupChartContainer"></svg>
      </div>
    </div>
  </enlargerpopup>
</template>
<script>
import * as d3 from 'd3';
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: 'Heatmap',
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    availableColumns: {
      type: Array,
      required: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    xAxisKey: {
      type: String,
      default: 'VISIT',
    },
    yAxisKey: {
      type: String,
      default: 'LBSTNRIND',
    },
    valueKey: {
      type: String,
      default: 'LBORRES', // This will represent the color intensity in the heatmap
    },
    valuestatisticsmethod: {
      type: String,
      default: 'average', 
    },
    chartlabel: {
      type: String,
      default: "Heatmap",
    },
    fromPage: {
      type: String,
      default: "",
    },
    graphwidth: {
      type: Number,
      default: 800,
    },
    graphheight: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      showVisitPopup: false,
      statmethod:"count",
      selectedxaxis: this.xAxisKey,
      selectedyaxis: this.yAxisKey,
      selectedvaluekey: this.valueKey,
      selectedstatisticmethod: this.valuestatisticsmethod,
      chartContainer: null,
      resizeObserver: null,
      tooltip: null,
      expandBlock: false,
      chartdata: [],
      colorScale: null,
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      mouseX: 0,
      mouseY: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.chartContainer) {
        console.error("Component ref is not defined.");
      }
      window.addEventListener('mousemove', this.updateMousePosition);
      this.chartContainer = this.$refs.chartContainer;
      this.tooltip = this.$refs.tooltip;
      this.createHeatmap();
      // Add resize observer to make SVG responsive
      this.resizeObserver = new ResizeObserver(() => this.createHeatmap());
      this.resizeObserver.observe(this.$refs.chartContainer);
    });
  },
  watch: {
    data: {
      handler() {
        this.createHeatmap();
        this.cancelselection();
      },
      deep: true,
    },
    selectedxaxis() {
      this.createHeatmap();
      this.cancelselection();
    },
    selectedyaxis() {
      this.createHeatmap();
      this.cancelselection();
    },
    selectedvaluekey() {
      this.createHeatmap();
      this.cancelselection();
    },
    selectedstatisticmethod() {
      this.createHeatmap();
      this.cancelselection();
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createHeatmap();
        });
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: `${this.chartlabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    updateMousePosition(event) {
      const rect = this.$refs.chartContainer.getBoundingClientRect();
      if (!rect) {
        console.error("Component reference is not defined or not yet mounted.");
        return;
      }
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    showtable() {
      this.$emit("opendrillpopup", this.data);
    },
    /**
     * Preprocess the data to group values by X and Y keys.
     * If the value is numeric, it calculates the average.
     * If the value is a string, it counts the occurrences.
     */
     preprocessData() {
  const groupedData = d3.rollup(
    this.data,
    (v) => {
      if (this.selectedvaluekey === 'count') {
        return v.length; // Simply return the count of occurrences
      }

      const values = v.map(d => d[this.selectedvaluekey]);
      const areNumbers = values.every(val => !isNaN(val) && typeof val !== 'string');

      if (areNumbers) {
        this.statmethod = this.selectedstatisticmethod;
        const numericValues = values.map(Number); // Convert values to numbers

        switch (this.selectedstatisticmethod) {
          case 'mean':
          case 'average': {
            const sum = d3.sum(numericValues);
            const result = sum / numericValues.length;
            return parseFloat(result.toFixed(2));
          }
          case 'median': {
            const median = d3.median(numericValues);
            return parseFloat(median.toFixed(2));
          }
          case 'mode': {
            const frequencyMap = {};
            numericValues.forEach(value => {
              frequencyMap[value] = (frequencyMap[value] || 0) + 1;
            });
            const mode = Object.keys(frequencyMap).reduce((a, b) =>
              frequencyMap[a] > frequencyMap[b] ? a : b
            );
            return parseFloat(+mode.toFixed(2));
          }
          default:
            return null;
        }
      } else {
        this.statmethod = 'count';
        return values.length;
      }
    },
    d => d[this.selectedxaxis],
    d => d[this.selectedyaxis]
  );

  // Convert the grouped map to a flat array for rendering
  const flattenedData = [];
  groupedData.forEach((yGroup, xValue) => {
    yGroup.forEach((value, yValue) => {
      flattenedData.push({
        [this.selectedxaxis]: xValue,
        [this.selectedyaxis]: yValue,
        [this.selectedvaluekey]: value,
      });
    });
  });

  return flattenedData;
},

async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createHeatmap();
},
createHeatmap() {
  const container = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  if (!container || this.data.length === 0) return;

  d3.select(container).selectAll('*').remove(); // Clear any existing content

  const margin = { top: 30, right: 20, bottom: 70, left: 100 };
  const width = this.graphwidth - margin.left - margin.right;
  const height = this.graphheight - margin.top - margin.bottom;

  const svg = d3
    .select(container)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Preprocess the data to aggregate values
  const processedData = this.preprocessData();

  const xLabels = Array.from(new Set(processedData.map(d => d[this.selectedxaxis])));
  const yLabels = Array.from(new Set(processedData.map(d => d[this.selectedyaxis])));

  // Set up scales
  const x = d3.scaleBand().domain(xLabels).range([0, width]).padding(0.05);
  const y = d3.scaleBand().domain(yLabels).range([height, 0]).padding(0.05);

  // Create custom color scale from light blue to light red
  const maxValue = d3.max(processedData, d => +d[this.selectedvaluekey]);
  this.colorScale = d3.scaleLinear()
    .domain([0, maxValue])
    .range(["#79b1ed", "#ff929d"]); // Custom color range

  // Add axes
  svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(x).tickSize(0))
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("transform", "rotate(-45)");

  svg.append("g")
    .call(d3.axisLeft(y).tickSize(0));

  // Create heatmap cells
  svg.selectAll()
    .data(processedData)
    .enter()
    .append("rect")
    .attr("x", d => x(d[this.selectedxaxis]))
    .attr("y", d => y(d[this.selectedyaxis]))
    .attr("width", x.bandwidth())
    .attr("height", y.bandwidth())
    .style("fill", d => this.colorScale(+d[this.selectedvaluekey]))
    .style("stroke", "black")
    .style("opacity", d => {
      // Create a unique key for the block (based on x and y values)
      const blockKey = `${d[this.selectedxaxis]}-${d[this.selectedyaxis]}`;
      // If the block is in selectedGroups, set opacity to 1, else 0.35
      return this.selectedGroups.includes(blockKey) ? 0.35 : 1;
    })
    .on('mouseover', (event, d) => {
      d3.select(this.tooltip)
        .style('opacity', 1)
        .html(`${this.selectedxaxis}: ${d[this.selectedxaxis]}<br>${this.selectedyaxis}: ${d[this.selectedyaxis]}<br>${this.statmethod}: ${d[this.selectedvaluekey]}`)
        .style("background-color", "black")
        .style("left", this.mouseX + "px")
        .style("top", this.mouseY + "px");
    })
    .on('mouseout', () => {
      d3.select(this.tooltip).style('opacity', 0);
    })
    .on('click', (event, d) => {
      // Create a unique key for the block based on x and y values
      const blockKey = `${d[this.selectedxaxis]}-${d[this.selectedyaxis]}`;

      // Filter the original data array to get the subset for the clicked block
      const filteredData = this.data.filter(item => 
        item[this.selectedxaxis] === d[this.selectedxaxis] && 
        item[this.selectedyaxis] === d[this.selectedyaxis]
      );

      // Log the filtered array to the console
      console.log('Data array for clicked block:', filteredData);
      this.clickbarinchart(filteredData, blockKey);  // Pass the blockKey for selection
    });

 // Add values as text in cells
svg.selectAll(".text")
  .data(processedData)
  .enter()
  .append("text")
  .attr("x", d => x(d[this.selectedxaxis]) + x.bandwidth() / 2)
  .attr("y", d => y(d[this.selectedyaxis]) + y.bandwidth() / 2)
  .attr("text-anchor", "middle")
  .attr("dy", ".35em")
  .text(d => {
    // If valueKey is 'count', display the count
    if (this.selectedvaluekey === 'count') {
      return d[this.selectedvaluekey];
    }
    return +d[this.selectedvaluekey];
  });
},
clickbarinchart(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createHeatmap();
  },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
  },
};
</script>

<style scoped>
   @import "../../custom_lib/AiCharts/charts.css";
.heatmap-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
</style>
