/* eslint-disable */
import axios from "axios";
import html2pdf from 'html2pdf.js';
import * as XLSX from "xlsx";
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
import D3LineChart from '../../custom_lib/AiCharts/D3LineChart.vue'
import sunburst from '../../custom_lib/AiCharts/sunburst.vue';
export default {
    name: "treatementEmergentAE",
    components: {
        historytab,
        advtable,
        chartdrill,
        D3LineChart,
        sunburst
    },
    props: {
        templateId: {
            type: String,
            default: ''
        },
        
    },
    data() {
        return {
            graphwidth:850,
            data: [],
            baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/report/",
            datahistory: [],
            historykeys: [],
            historydata: {},
            drilldata: {},
            drilldowndata: [],
            drillpopup: false,
            loader: true,
            scatteredy: ["AESEV"],
            xaxiskeys: {
                "groupbarchart": "",
                "barchart": "",
                "histogram": "",
                "piechart": "AGE",
                "advtable": "",
                "aepiechart": "",
                "historytab": "",
                "chartdrill": ""
            },
            arrayofkeys: [
                "USUBJID","AGE","SEX","BMI","TRT","DOSE","SMOKSTAT","ALCOHSTAT","COMPLT","TRTDUR","BP",
                "HR","LBTEST with LBTESTCD for ALT / AST","LBTEST with LBTESTCD for Creatinine","ADHR","EXFREQ",
                "DIET","ALLERGY","MHSTAT","SES","AEYN","AETERM","AESTDTC","AEONGO","AEENDTC","AESEV","AESER","AEDTH","AELIFE","AEHOSP","AEDISAB","AECONG","AEMIE","AEREL","AEACN","AEACNOTH","AEOUT","EXENDTC(Last Dose)",
                "AERESOLV","AE DURATION","AE FREQUENCY","CMTRT","AE CATEGORY","AE ONSET TIME"
            ],
            arraykeys: [
                "USUBJID","SEX","BMI","TRT","DOSE","SMOKSTAT","ALCOHSTAT","COMPLT","TRTDUR","BP",
                "HR","LBTEST with LBTESTCD for ALT / AST","LBTEST with LBTESTCD for Creatinine","ADHR","EXFREQ",
                "DIET","ALLERGY","MHSTAT","SES","AEYN","AETERM","AESTDTC","AEONGO","AEENDTC","AESEV","AESER","AEDTH","AELIFE","AEHOSP","AEDISAB","AECONG","AEMIE","AEREL","AEACN","AEACNOTH","AEOUT","EXENDTC(Last Dose)",
                "AERESOLV","AE DURATION","AE FREQUENCY","CMTRT","AE CATEGORY","AE ONSET TIME"
            ],
        }
    },
    async mounted() {
        await this.getdata();
    },
    methods: {
        async exporttoexcell() {
            const worksheet = XLSX.utils.json_to_sheet(this.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "Treatment Emergent AE.xlsx");
          },
        exporttopdf() {
            // Create a temporary container
            const pdfContainer = document.createElement('div');
      
            const captureArea1 = this.$refs.captureArea1.cloneNode(true);
            const captureArea2 = this.$refs.captureArea2.cloneNode(true);
      
            captureArea2.classList.add('page-break');
            // Append cloned elements to the container
            pdfContainer.appendChild(captureArea1);
            pdfContainer.appendChild(captureArea2);
            
            // Optionally style the container for PDF formatting
           
            pdfContainer.style.width = '100%';
            pdfContainer.style.display = 'block';
            // Set the options for html2pdf
            const opt = {
              margin: 0,
              filename: 'Treatment Emergent AE.pdf',
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { scale: 2 },
              jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
            };
          
            // Convert the temporary container to PDF
            html2pdf().set(opt).from(pdfContainer).save();
          },
        async drillpopupopened(datafromchild) {
            this.drilldata = datafromchild;
            this.drillpopup = true;
        },
        async drilldowncurrentchart(datafromchild) {
            this.data = datafromchild;
            this.datahistory.push(this.data);
        },
        async drilldowncurrentchartforbar(datafromchild) {
            const lastkey = this.historykeys[this.historykeys.length - 1];
            if (this.historydata[lastkey].length != datafromchild.sortedData.length) {
                this.historykeys.push(datafromchild.filterkeyword);
                this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
                this.data = this.historydata[datafromchild.filterkeyword];
            }
        },
        async gotohistorylevel(history) {
            this.deleteAfterKeyvaluearrays(history);
            this.deleteAfterKeyarrays(history);
            this.data = this.historydata[history];
        },
        async deleteAfterKeyvaluearrays(selectedKey) {
            const entries = Object.entries(this.historydata); // Convert the object to an array of entries
            const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);

            // If the key is found, slice the array to keep only the entries up to the selected key
            const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;

            // Convert the array of entries back into an object
            return Object.fromEntries(newEntries);
        },
        async deleteAfterKeyarrays(selectedKey) {

            let index = this.historykeys.indexOf(selectedKey);

            if (index !== -1) {
                // Slice the array up to the element (including the element)
                this.historykeys = this.historykeys.slice(0, index + 1);
            }
        },
        async retainxaxiskey(xaxiskey) {
            if (xaxiskey != "") {
                this.xaxiskeys["piechart"] = xaxiskey;
            }
        },
        async getdata() {
            this.loader = true;
            await axios.post(
                `${this.baseUrl}getfilteredreports?table_name=AdverseEvents`)
                .then((res) => {
                    this.data = res.data;
                    this.loader = false;
                    this.datahistory.push(this.data);
                    this.historydata['Home'] = this.data;
                    this.historykeys.push('Home');
                })
                .catch((err) => console.log("error in screen list", err));
        },
        async undobutton() {
            if (this.datahistory.length > 1) {
                this.datahistory.pop();
                this.data = this.datahistory[this.datahistory.length - 1];
            }
        },
    }

}