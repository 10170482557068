<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.form transfer") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row"></div>
    <div class="row">
      <div class="ibox-body mb-1 p-2">
        <div class="row my-2 my-lg-0 mb-lg-0">
          <div class="col-lg-5">
            <div class="col-sm">
              <h4>{{ $t("navigations.from") }}:</h4>
              <div class="ibox-contents p-1">
                <div>
                  <label class="mb-0">{{ $t("navigations.site") }}: <strong>{{ sourceSiteClick }}</strong></label>
                </div>
                <div>
                  <label class="mb-0">{{ $t("navigations.subject") }}: <strong>{{ sourcePatientClick }}</strong></label>
                </div>
                <div>
                  <label class="mb-0">{{ $t("navigations.visit") }}: <strong>{{ sourceVisitClick }}</strong></label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="col-sm">
              <h4>{{ $t("navigations.to") }}:</h4>

              <div class="ibox-contents p-1">
                <div>
                  <label class="mb-0">{{ $t("navigations.to") }}: <strong>{{ targetSiteNameClick }}</strong></label>
                </div>
                <div>
                  <label class="mb-0"
                    >{{ $t("navigations.subject") }}: <strong>{{ targetPatientNameClick }}</strong></label
                  >
                </div>
                <div>
                  <label class="mb-0">{{ $t("navigations.visit") }}: <strong>{{ targetVisitNameClick }}</strong></label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-2 align-self-end">
            <span
              type="button"
              class="save_btn py-1 px-4 float-right"
              @click="transferButton()"
            >
              {{ $t("navigations.transfer") }}
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-1 treegrid">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.subject schedule") }}</h5>
          </div>
          <div class="ibox-content horizondalscroll">
            <sourcetree> </sourcetree>
          </div> 
        </div>
      </div>

      <div class="col-lg-6 mb-1 treegridnew">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.subject schedule") }}</h5>
          </div>
          <div class="ibox-content horizondalscroll">
            <targettree> </targettree>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script src="./formtransfer.js"></script>
  <style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.linkstyle {
  cursor: pointer;
}
.ibox-contents {
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: transparent;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}
.treegrid {
  padding-left: initial;
}
.treegridnew {
  padding-right: initial;
}
.horizondalscroll {
  overflow-y: auto;
  max-height: 500px;
}
</style>
  