<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-8">
      <h2 class="">{{ $t("navigations.analytical dashboards") }}</h2>
    </div>
    <div class="col-lg-4">
      <div class="title-action">
        <!-- <router-link to="#" class="btn ip-btn mr-1 align-self-center mb-0"><i class="fa fa-bar-chart-o"></i> Insights Portal</router-link> -->
        <!-- <span
          class="text-end align-self-center"
          v-if="defaultdashboard != 'analyticaldashboard'"
        >
          <button
            type="button"
            class="btn btn-setdefault fontprop"
            @click="setasdefault()"
          >
            {{ $t("navigations.set as default") }}
          </button>
        </span> -->
      </div>
    </div>
    <!-- <div class="col-lg-2">
        <div class="text-end align-self-center" v-if="defaultdashboard != 'analyticaldashboard'">
          <button type="button" class="btn btn-setdefault fontprop" @click="setasdefault()">{{ $t('navigations.set as default')}}</button>
        </div>
        <button type="button" class="btn btn-insights right-sidebar-toggle"><img src="../../assets/img/Insights_Portal.png" alt="Insights Portal"></button>
      </div> -->
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <ul class="nav nav-tabs col-lg-12 mb-2" role="tablist">
        <li>
          <a
            href="#tab-1"
            class="nav-link"
            :class="{ activeTab: refreshFlags['DataAnalytics'] === true }"
            data-toggle="tab"
            @click="handleTabClick('DataAnalytics')"
          
          >
          {{ $t("navigations.clinical trial data analytics") }}
        </a>
        </li>
        <li>
          <a
            href="#tab-2"
            class="nav-link"
            :class="{
              activeTab: refreshFlags['OperationalAnalytics'] === true,
            }"
            data-toggle="tab"
            @click="handleTabClick('OperationalAnalytics')"
          >
           {{ $t("navigations.operational analytics") }}</a
          >
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <!-- <div class="ibox-title style_2">
             <small class="float-right text-muted">Informations</small>
              text
          </div> -->
          <div class="row" v-if="refreshFlags['DataAnalytics']">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/demographicReport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge dm-color align-middle mr-1"
                            >{{ $t("navigations.de") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">{{ $t("navigations.demographics summary") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.interactive dashboard overview") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/aereport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1"
                            >{{ $t("navigations.ae") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">{{ $t("navigations.adverse events summary") }}</h4>
                          <span>
                            <h4>
                              <small
                                >{{ $t("navigations.critical insights: adverse events dashboard") }}</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/subjectstatusreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ss-color align-middle mr-1"
                            >{{ $t("navigations.ss") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.subject status report") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.mortality analysis: summary report") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/deathsummaryreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ds-color align-middle mr-1"
                            >{{ $t("navigations.ds") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">{{ $t("navigations.death summary report") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.mortality analysis: summary report") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labsummaryreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >{{ $t("navigations.ls") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.lab summary report") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.laboratory findings: summary report") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labresultreview">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >{{ $t("navigations.ls") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.lab result review overtime") }}</h4>
                          <span>
                            <h4>
                              <small
                                >{{ $t("navigations.trend analysis: lab results over time") }}</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labdescriptivestatus">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >{{ $t("navigations.ls") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.lab descriptive status") }}</h4>
                          <span>
                            <h4>
                              <small
                                >{{ $t("navigations.lab descriptive status") }}</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/vitalsignreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge vs-color align-middle mr-1"
                            >{{ $t("navigations.vs") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.vital sign result trends") }}</h4>
                          <span>
                            <h4>
                              <small
                                >{{ $t("navigations.health monitoring: vital signs trends") }}</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/vitalsigndescriptivestats">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge vs-color align-middle mr-1"
                            >{{ $t("navigations.vs") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.vital signs descriptive status") }}</h4>
                          <span>
                            <h4>
                              <small
                                >{{ $t("navigations.vital signs descriptive status") }}</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/medicalhistory">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge mh-color align-middle mr-1"
                            >{{ $t("navigations.mh") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.medical history summary") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.mortality analysis: summary report") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/medicalhistorycrosstab">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge mh-color align-middle mr-1"
                            >{{ $t("navigations.mh") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.medical history cross tabulation") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.medical history cross tabulation") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/advereventcrosstabulation">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1"
                            >{{ $t("navigations.ae") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">
                            {{ $t("navigations.adverse events cross tabulation") }}
                            
                          </h4>
                          <span>
                            <h4>
                              <small
                                >{{ $t("navigations.event correlations: ae cross tabulation") }}
                                </small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labResultTrend">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >{{ $t("navigations.lt") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.lab result trends") }}  </h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.lab result trends") }}  </small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/treatmentEmergentAE">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1"
                            >{{ $t("navigations.te") }}</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">
                           {{ $t("navigations.treatment emergent ae") }} 
                          </h4>
                          <span>
                            <h4>
                              <small
                                > {{ $t("navigations.treatment emergent ae") }}</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/ae_OverlayLabsReport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">{{ $t("navigations.ao") }}</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.adverse events overlay labs") }} </h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.adverse events overlay labs") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>

                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/vitalsignsresultovertime">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">{{ $t("navigations.vs") }}</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.vital signs result overtime") }} </h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.vital signs result overtime") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/aeincidencetable">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">{{ $t("navigations.ae") }}</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.ae incidence table") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.ae incidence table") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/exposurereport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">{{ $t("navigations.er") }}</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.exposure data report") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.exposure data report") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
            </div>
            <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/exposureandadverseevents">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">{{ $t("navigations.ea") }}</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">{{ $t("navigations.exposure and adverse events") }}</h4>
                          <span>
                            <h4>
                              <small>{{ $t("navigations.exposure and adverse events") }}</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                 </div>
                 </div>
            </div>
            <div class="col-md-3 dash-widget">
              <h4>{{ $t("navigations.insights portal") }}</h4>
              <div>
                <ul class="list-group">
                  <router-link to="/demographicReport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side dm-color align-middle mr-1"
                        >{{ $t("navigations.de") }}</span
                      >
                      <span> {{ $t("navigations.demographics") }}</span>                      
                    </li>
                 </router-link>
                  <router-link to="/aereport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >{{ $t("navigations.ae") }}</span
                      >
                      <span>{{ $t("navigations.adverse events summary") }}</span>
                    </li>
                 </router-link>
                  <router-link to="/subjectstatusreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ss-color align-middle mr-1"
                        >{{ $t("navigations.ss") }}</span
                      >
                      <span>{{ $t("navigations.subject status report") }}</span>
                    </li>
                 </router-link>
                  <router-link to="/deathsummaryreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ds-color align-middle mr-1"
                        >{{ $t("navigations.ds") }}</span
                      >
                      <span>{{ $t("navigations.death summary report") }}</span>
                    </li>
                 </router-link>
                  <router-link to="/labsummaryreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side lb-color align-middle mr-1"
                        >{{ $t("navigations.ls") }}</span
                      >
                      <span>{{ $t("navigations.lab summary report") }}</span>
                    </li>
                 </router-link>
                  <router-link to="/labresultreview">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side lb-color align-middle mr-1"
                        >{{ $t("navigations.ls") }}</span
                      >
                      <span>{{ $t("navigations.lab result review overtime") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/labdescriptivestatus">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side lb-color align-middle mr-1"
                        >{{ $t("navigations.ls") }}</span
                      >
                      <span>{{ $t("navigations.lab descriptive status") }}</span>
                    </li>
                 </router-link>
                  <router-link to="/vitalsignreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side vs-color align-middle mr-1"
                        >{{ $t("navigations.vs") }}</span
                      >
                      <span>{{ $t("navigations.vital sign result trends") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/vitalsigndescriptivestats">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side vs-color align-middle mr-1"
                        >{{ $t("navigations.vs") }}</span
                      >
                      <span>{{ $t("navigations.vital sign descriptive status") }}</span>
                    </li>
                 </router-link>
                  <router-link to="/medicalhistory">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side mh-color align-middle mr-1"
                        >{{ $t("navigations.mh") }}</span
                      >
                      <span>{{ $t("navigations.medical history summary") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/medicalhistorycrosstab">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side mh-color align-middle mr-1"
                        > {{ $t("navigations.mh") }}</span
                      >
                      <span>{{ $t("navigations.medical history cross tabulation") }}</span>
                    </li>
                 </router-link>
                  <router-link to="/advereventcrosstabulation">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        > {{ $t("navigations.ae") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.adverse events cross tabulation") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/labResultTrend">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        > {{ $t("navigations.lr") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.lab result trends") }}</span>
                    </li>
                  </router-link>
                 <router-link to="/treatmentEmergentAE">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >{{ $t("navigations.ae") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.treatment emergent ae") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/ae_OverlayLabsReport">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >{{ $t("navigations.ao") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.adverse events overlay labs") }} </span>
                    </li>
                  </router-link>
                 <router-link to="/vitalsignsresultovertime">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >{{ $t("navigations.vs") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.vital signs result overtime") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/aeincidencetable">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >{{ $t("navigations.ae") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.ae incidence table") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/priorconcomitantsummary">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >PC{{ $t("navigations.pc") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.prior and concomitant treatment summary") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/comparativelabresult">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >{{ $t("navigations.cl") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.comparative lab results") }}</span>
                    </li>
                </router-link>
                 <router-link to="/exposurereport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >ER</span
                      >
                      <span class="text-wrap">{{ $t("navigations.exposure data report") }}</span>
                    </li>
                 </router-link>
                 <router-link to="/exposureandadverseevents">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >{{ $t("navigations.ea") }}</span
                      >
                      <span class="text-wrap">{{ $t("navigations.exposure and adverse events") }}</span>
                    </li>
                 </router-link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Analytical_Dashboards"></script>
<style scoped>
.activeTab {
  color: #495057;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
</style>