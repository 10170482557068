import axios from "axios";
import Multiselect from "@vueform/multiselect";
import arctween from "../../custom_lib/AiCharts/arctween.vue";
import percebtagechart from "../../custom_lib/AiCharts/percentagechart.vue";
import d3BarchartComparitive from "../../custom_lib/AiCharts/d3BarchartComparitive.vue";
import D3PieChart from '../../custom_lib/AiCharts/D3PieChart.vue';
import sunburst from '../../custom_lib/AiCharts/sunburst.vue';
import groupbarchart from '../../custom_lib/AiCharts/groupbarchartscreening.vue';
import d3table from '../../custom_lib/AiCharts/d3table.vue';
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
import html2pdf from 'html2pdf.js';
import * as XLSX from "xlsx"
export default{
    name: "RetentionDashboard",
    components: {
        Multiselect,
        arctween,
        percebtagechart,
        d3BarchartComparitive,
        D3PieChart,
        sunburst,
        groupbarchart,
        d3table,
        historytab,
        chartdrill
    },
    props: {

    },
    data(){
        return {
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
            totalCount: 0,
            retentionDetails: [],
            retentionCount: 0,
            datahistory:[],
            historykeys:[],
            historydata:{},
            drilldata:{},
            drilldowndata:[],
            searchData: {
                PageNo: 0,
                ItemCount: 0,
                SortBy: "",
                IsDescending: false,
                ML_confidenceMin: 0,
                ML_confidenceMax: 1,
                ML_prediction: null,
            },
            loader:true,
            drillpopup: false
        };
    },
    async mounted(){
        await this.getRetentionCount();
        await this.getPatientRetentionInfo();
        
    },
    methods:{
        async exporttoexcell() {
            const worksheet = XLSX.utils.json_to_sheet(this.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "exported_data.xlsx");
          },
          exporttopdf() {
            // Create a temporary container
            const pdfContainer = document.createElement('div');
            const pdfContainer1 = document.createElement('div');
            const pdfContainer2 = document.createElement('div');
            const pdfContainer3 = document.createElement('div');
            const pdfContainer4 = document.createElement('div');
            const pdfContainer5 = document.createElement('div');
            const pdfContainer6 = document.createElement('div');
      
            const captureArea1 = this.$refs.captureArea1.cloneNode(true);
            const captureArea2 = this.$refs.captureArea2.cloneNode(true);
            const captureArea3 = this.$refs.captureArea3.cloneNode(true);
            const captureArea4 = this.$refs.captureArea4.cloneNode(true);
            const captureArea5 = this.$refs.captureArea5.cloneNode(true);
            const captureArea6 = this.$refs.captureArea6.cloneNode(true);
      
            captureArea2.classList.add('page-break');
            captureArea3.classList.add('page-break');
            captureArea4.classList.add('page-break');
            captureArea5.classList.add('page-break');
            captureArea6.classList.add('page-break');
            // Append cloned elements to the container
            pdfContainer1.appendChild(captureArea1);
            pdfContainer2.appendChild(captureArea2);
            pdfContainer3.appendChild(captureArea3);
            pdfContainer4.appendChild(captureArea4);
            pdfContainer5.appendChild(captureArea5);
            pdfContainer6.appendChild(captureArea6);
            
            // Optionally style the container for PDF formatting
            pdfContainer1.style.width = '100%';
            pdfContainer2.style.width = '100%';
            pdfContainer3.style.width = '200%';
            pdfContainer4.style.width = '200%';
            pdfContainer5.style.width = '150%';
            pdfContainer6.style.width = '100%';
            
            pdfContainer.appendChild(pdfContainer1);
            pdfContainer.appendChild(pdfContainer2);
            pdfContainer.appendChild(pdfContainer3);
            pdfContainer.appendChild(pdfContainer4);
            pdfContainer.appendChild(pdfContainer5);
            pdfContainer.appendChild(pdfContainer6);
      
            pdfContainer.style.display = 'block';
            // Set the options for html2pdf
            const opt = {
              margin: 0,
              filename: 'combined_chart.pdf',
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { scale: 2 },
              jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
            };
          
            // Convert the temporary container to PDF
            html2pdf().set(opt).from(pdfContainer).save();
          },
        async onclosed(){
            this.drillpopup = false;
          },
        async drillpopupopened(datafromchild) {
            this.drilldata = datafromchild;
            this.drillpopup = true;
         },
        async drilldowncurrentchartforbar(datafromchild){
            this.datahistory.push(this.retentionDetails);
            this.historykeys.push(datafromchild.filterkeyword);
            this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
            this.retentionDetails = this.historydata[datafromchild.filterkeyword];
        },
        async undobutton(){
            if(this.datahistory.length > 1){
            this.datahistory.pop();
            this.retentionDetails=this.datahistory[this.datahistory.length-1];
            }
        },
        async gotohistorylevel(history){
            this.deleteAfterKeyvaluearrays(history);
            this.deleteAfterKeyarrays(history);
            this.retentionDetails = this.historydata[history];
        },
        async deleteAfterKeyvaluearrays(selectedKey) {
            const entries = Object.entries(this.historydata); // Convert the object to an array of entries
            const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
          
            // If the key is found, slice the array to keep only the entries up to the selected key
            const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
          
            // Convert the array of entries back into an object
            return Object.fromEntries(newEntries);
          },
          async deleteAfterKeyarrays(selectedKey) {
      
            let index = this.historykeys.indexOf(selectedKey);
      
            if (index !== -1) {
                // Slice the array up to the element (including the element)
                this.historykeys = this.historykeys.slice(0, index + 1);
            }
          },
        async getPatientRetentionInfo() {
            this.loader = true;
            await axios
              .post(`${this.baseUrl}retention/getretentioninfo`, this.searchData)
              .then((res) => {
                this.retentionDetails = res.data.data;
                this.datahistory.push(this.retentionDetails);
                this.historydata['Home'] = this.retentionDetails;
                this.historykeys.push('Home');
                this.totalCount = res.data.count;
                this.loader = false;
              })
              .catch((err) => console.log("error in getting retention result", err));
        },
        async getRetentionCount(){
            await axios.get(`${this.baseUrl}retention/getretentioncounts`)
            .then((res) => {
              this.retentionCount = res.data;
            })
            .catch(err => {
              console.log("error in get retention counts", err)
            });
        },
    }
};