<template>
  
  
      <SurvivalChart />       
    
</template>
  
  <script>
  import SurvivalChart from '../../custom_lib/AiCharts/SurvivalChart.vue';
  export default {
    name: 'survivalanalysis',
    components: {
      SurvivalChart
    },
    data() {
      return {
      };
    }
  };
  </script>
  