<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-10 col-lg-10">
      <h2>{{ $t('navigations.map form') }}</h2>
    </div>
    <div class="col-lg-2">
  <div class="position-relative cursor-pointer">
    <i class="fa fa-times close my-0" @click="onclosed()"></i>
    <span class="tool-tip">
      {{$t('navigations.close')}}
    </span>
  </div>
</div>

  </div>
  <div class="wrapper wrapper-content ">
    <div class="ibox-content px-3 py-2">
      <div class="form-group row mb-0">
        <div class="d-flex flex-column flex-lg-row my-1">
          <div class="form-group mb-0 w-100 pr-1">
            <label class="mb-0">{{ $t('navigations.site code') }}</label> <sup><i class="fa fa-asterisk imp"></i></sup>
            <select class="form-select my-2 my-lg-0 " name="account" v-model="selectedsite"
              @change.prevent="sitechange($event.target.value)">
                <option disabled value="">--Select--</option>
              <option v-for="site in sites" :key="site.siteID" :value="site.siteID">{{ site.siteCode }}</option>
            </select>
          </div>
          <div class="form-group mb-0 w-100 pr-1">
            <label class="mb-0">{{ $t('navigations.subject id') }}</label> <sup><i class="fa fa-asterisk imp"></i></sup>
            <select class="form-select my-2 my-lg-0" name="account" v-model="selectedsubject">
               <option disabled value="">--Select--</option>
              <option v-for="subject in subjects" :key="subject.patientId" :value="subject.patientId">
                {{ subject.subjectId }}</option>
            </select>
          </div>
          <div class="col-12 col-md-2 px-0 px-md-1 align-self-end">
            <span type="button" class="save_btn w-100" @click.prevent="isScheduled(selectedsubject)">
              {{ $t('navigations.map form') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <showForm :formsesid="formsid" :isfieldvalidate=false :isqueryfield=false :requiredsubmit='false' closedoption='displayform' />
</template>

<script src="./mapFormComponents.js"></script>

<style scoped>
@import './style.css';

.searchdiv {
  margin-top: 1rem;
}

.form-select {
  border-radius: 0 !important;
}

.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
</style>
