<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t('navigations.change schedule form') }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="row mb-1">
        <div class="col-lg-4">
          <div class="form-group mb-lg-0">
            <label class="mb-0">{{ $t('navigations.site code') }}</label>
            <select class="form-select" v-model="siteCode" @change="getpatients($event.target.value)">
              <option value="">--{{ $t('navigations.select') }}--</option>
              <option v-for="site in sites"
                :key="site.sitetId"
                :value="site.siteID">{{site.siteCode}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group mb-lg-0">
            <label class="mb-0">{{ $t('navigations.subject id') }}</label>
            <select class="form-select" v-model="subjectID" @change="subjectChanged($event.target.value),displaySearch=true">            
               <option value="">--{{ $t('navigations.select') }}--</option>
              <option v-for="patient in patients"
                :key="patient.patientId"
                :value="patient.patientId">{{ patient.subjectId }}
              </option>
            </select>
          </div>
        </div>        
      </div>
    </div>
    <div class="ibox-content mb-1 px-3 py-2" v-if="displaySearch==true">
      <div class="row mb-1 ">
        <div class="col-lg-5 my-1">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t('navigations.visit no') }}</label>
            <select 
              class="form-control"
              name="visit no" 
              v-model="searchVisitNo"> 
              <option value="" selected>{{ $t("navigations.all") }}</option>
              <option v-for="visit in visitList" 
                      :key="visit.sheduleId" 
                      :value="visit.visitNo" > {{ visit.visitNo }}
              </option>
            </select>
          </div>          
        </div>
        <div class="col-lg-5 my-1">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t('navigations.visit name') }}</label>
            <input v-model="searchVisit" class="form-control" type="text">
          </div>
        </div> 
        <div class="col-lg-2 my-1 py-lg-0 align-self-end">
            <span type="button"
              class="save_btn px-5 mx-lg-1 w-100"
              @click.prevent="getdata()"
            >
              {{ $t('navigations.search') }}
            </span>
          </div>     
      </div>
    </div>
    <div class="ibox-body" v-if="displayTable==true">
       <div class="ibox-title style_2">
          <h5 class="float-left">{{ $t('navigations.change schedule form') }}</h5>
      </div>
      <div class="ibox-content mb-0">
        <div class="table-responsive">
          <table class="table table-bordered dataTables">
            <thead>
              <tr>
                <th>
                  {{ $t('navigations.site code') }} 
                </th>
                <th>
                  {{ $t('navigations.subject id') }}                 
                </th>
                <th >
                    {{ $t('navigations.visit no') }}                                             
                </th>
                <th class="sort_block" @click="SortSelected('visitName',4)" id="4">
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t('navigations.visit name') }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                                  
                </th>
                <th class="sort_block" @click="SortSelected('templateName',9)" id="9">
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t('navigations.form name') }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                                 
                </th>                        
                <th class="sort_block" @click="SortSelected('version',10)" id="10">
                  <div class="d-flex flex-row justify-content-between">      
                    {{ $t('navigations.version') }}        
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('visitType',5)" id="5">
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t('navigations.visit type') }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                                 
                </th>
                <th class="sort_block" @click="SortSelected('visitDate',6)" id="6">
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t('navigations.visit date') }}                   
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                              
                </th>
                <th class="sort_block" @click="SortSelected('investigatorName',8)" id="8">
                  <div class="d-flex flex-row justify-content-between">       
                    {{ $t('navigations.investigator') }}       
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th> 
                <th class="sort_block" @click="SortSelected('visitStatus',7)" id="7">
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t('navigations.visit status') }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                  
                <th v-if="roleprivileges.includes('Change Schedule Template Edit')" class="text-center">{{ $t('navigations.change form') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="schedule in scheduleList" :key="schedule.patientId"  :class="{ bgcolor3: schedule.isUnScheduled }">
                <td>{{schedule.siteCode}}</td>
                <td>{{schedule.patientNo}}</td>
                <td>{{schedule.visitNo}}</td>
                <td>{{schedule.visitName}}</td>
                <td>{{schedule.templateName }}</td>
                <td>{{schedule.version}}</td>
                <td>{{schedule.visitType}}</td>
                <td>{{filter(schedule.visitDate)}}</td>    
                <td>{{schedule.investigatorName}}</td>           
                 <td><span class="badge badge-grn w-100" v-if="schedule.visitStatus=='Completed'">{{schedule.visitStatus}}</span>
                  <span class="badge badge-danger w-100" v-if="schedule.visitStatus=='Overdue'">{{schedule.visitStatus}}</span>
                  <span class="badge badge-yellow w-100" v-if="schedule.visitStatus=='Imminent'">{{schedule.visitStatus}}</span>
                  <span class="badge badge-orage w-100" v-if="schedule.visitStatus=='Pending'">{{schedule.visitStatus}}</span>
                  <span class="badge badge-gray w-100" v-if="schedule.visitStatus=='Withdrawn'" >{{schedule.visitStatus}}</span>
                  <span class="badge badge-blue w-100" v-if="schedule.visitStatus=='Planned'">{{schedule.visitStatus}}</span>                
                </td>
                <td v-if="roleprivileges.includes('Change Schedule Template Edit')" class="text-center"> 
                   <span v-if="schedule.formId === null && schedule.visitStatus !== 'Withdrawn'"
                    ><i
                      class="fa fa-edit editicon"
                      @click.prevent="openmodal(schedule.sheduleId)"
                    ></i
                  >
                  </span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mx-0 mt-2">
            <div class="col-lg-12 mx-0 px-0">
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPage"
                  :range-size="0"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="visit-modal-container" v-if="visitModal">
      <div class="visit-modal-contents">
      <div class="titleclass d-flex justify-content-between py-2 px-3">
        <h4 class="my-0"> {{ $t('navigations.assign form') }}</h4>
        <div class="tooltip-wrapper pointer"  @click.prevent="closemodal()" style="float: right; cursor: pointer;">
            <i class="fa fa-times close-button-popups"></i>
            <span class="custom-tooltip-popups">Close</span>
        </div>
      </div>
      <div class="form-group row mx-0">
          <div class="d-flex justify-content-center p-4">
            <div class="col-lg-10 pl-0">
              <select
                class="form-control my-2 my-lg-0 py-1"
                placeholder="Search by Status"
                v-model="selectedTemplate"
              >
                <option value="" disabled selected>{{ $t('navigations.select form') }}</option>
                <option
                  v-for="template in templatelist"
                  :key="template.templateId"
                  :value="template"
                >{{template.templateName}} ({{  template.version }})
                </option>
              </select>
            </div>
            <div class="col-md-2 px-0">
                <button
                class="save_btn py-1 w-100"
                @click.prevent="assignTemplate()"
              >
                {{ $t('navigations.assign') }}
              </button>
            </div>        
        </div>
      </div>
      </div>      
    </div>
  </div> 
</template>
<script src="./visit_template.js"></script>
<style scoped>
.site-block select {
	font-size: 1rem;
	border: 0px;
	padding-left: 0.2rem;
	padding-right: 0.7rem;
	background-color: transparent;
	outline: none;
}

.options {
	border: 0px;
}
.visit-modal-container{
  position: fixed;
  background: rgb(0 0 0 / 18%);
  inset: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.visit-modal-contents{
  background: #fff;
  width: min(600px,90%);
  border-radius: 4px;
  position: fixed;
  transition: all .3s ease-in-out;
  overflow:hidden
}

.fixed-height{
  height:60%;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
.badge-genre{
  background-color: #b6bdbf;
  color: #FFFFFF;
}
.badge-grn {
background-color: #1ab394;
color: #FFFFFF;
}
.height-170{
  height:170px;
}
.bgcolor3 {
  background-color: #d0f1f1;
}
</style>