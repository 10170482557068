<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{
              $t("navigations.role privilege list")
            }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content px-3 py-2">
      <div class="form-group row mb-0">
        <label class="mb-0">{{
              $t("navigations.role")
            }}</label>
        <div
          class="col-lg-6 d-flex flex-column flex-md-row justify-content-around align-items-lg-center my-2 m-lg-0"
        >
          <select
            class="form-select"
            name="account"
            @change="onChange($event)"
            placeholder="Select Role"
            v-model="RoleScreen.role_Name"
          >
            <option selected value="">All</option>
            <option
              v-for="options in roles"
              :value="options.id"
              :key="options.id"
            >
              {{ options.role_Name }}
            </option>
          </select>
        </div>
        <span class="errormsg" v-if="errors[0] == false"
          >{{
              $t("navigations.please select a role")
            }}</span
        >
      </div>
    </div>

    <div class="ibox-content px-3 py-2">
      <div class="table-responsive h-400">
        <table
          class="table table-striped table-bordered fixed-column-table dataTables"
        >
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  id="selectall"
                  @change="selectallcontrols()"
                />
              </th>
              <th>{{
              $t("navigations.screens")
            }}</th>
              <th>{{
              $t("navigations.privileges")
            }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(options, index) in screens" :key="options.id">
              <td>
                <input
                  type="checkbox"
                  :value="options.name"
                  v-model="selectedscreens"
                  :id="options.id"
                  @change="setcontrols(options.id, index, options.controls)"
                  :checked="selectedscreens.includes(options.name)"
                />
              </td>
              <td>{{ options.name }}</td>
              <td>
                <label v-for="option in options.controls" :key="option">
                  <input
                    type="checkbox"
                    :value="option"
                    :class="options.id"
                    v-model="screensds[index].controls"
                    @change="testcheck(options.id, options.name)"
                    :checked="
                      screenNames.includes(options.name) &&
                      controlName.includes(option)
                    "
                  />
                  {{ option }} &nbsp; </label
                >&nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="rollsprivilages.includes('Role Privileges Update')"
        class="d-flex justify-content-end text-right mt-2"
      >
        <button
          class="btn btn-primary save_btn px-5 mx-1"
          type="submit"
          @click.prevent="Save"
        >
          {{
              $t("navigations.save")
            }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
export default {
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      localurl: process.env.VUE_APP_redirect,
      selectedscreens: [],
      screensds: [],
      roles: "",
      RoleScreen: {
        role_Name: "",
      },
      screens: "",
      existingdata: [],
      screenNames: [],
      controlName: [],
      screenPrivileges: [],
      validations: [false],
      errors: [],
      rollsprivilages: "",
    };
  },

  async mounted() {
    this.getallroles();
    this.getallscreens();
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
  },
  methods: {
    async onChange(event) {
      await this.clearall();
      console.log(event.target.value);
      await this.getscreenandpriv(event.target.value);
    },
    async clearall() {
      this.selectedscreens = [];
      document.getElementById("selectall").checked = false;
      this.screensds = [];
      await this.screens.forEach((element) => {
        document.getElementById(element.id).checked = false;
        let savescreen = {
          name: "",
          controls: [],
        };
        savescreen.name = element.name;
        this.screensds.push(savescreen);
      });
    },
    async selectallcontrols() {
      // if (this.RoleScreen.role_Name == "All") {
      //   this.RoleScreen.role_Name = "";
      // }
      this.selectedscreens = [];
      let selectall = document.getElementById("selectall");
      if (selectall.checked == true) {
        this.screensds = [];
        await this.screens.forEach((element) => {
          // document.getElementById(element.id).checked = true;
          this.selectedscreens.push(element.name);
          //initialisation
          let savescreen = {
            name: "",
            controls: [],
          };
          //initialisation completed

          savescreen.name = element.name;
          savescreen.controls = element.controls;
          this.screensds.push(savescreen);
        });
      } else {
        this.screensds = [];
        await this.screens.forEach((element) => {
          document.getElementById(element.id).checked = false;
          let savescreen = {
            name: "",
            controls: [],
          };
          savescreen.name = element.name;
          this.screensds.push(savescreen);
        });
      }
    },

    async setcontrols(elementid, index, allcontrols) {
      let checkBox = document.getElementById(elementid);
      if (checkBox.checked == true) {
        this.screensds[index].controls = allcontrols;
      } else {
        this.screensds[index].controls = [];
      }
      console.log(this.screensds[index]);
    },
    async testcheck(optid, optname) {
      // this.selectedscreens = [];
      let filteredAry = this.selectedscreens.filter((e) => e !== optname);
      this.selectedscreens = filteredAry;
      document.getElementById(optid).checked = false;
      let isallselected = document.getElementsByClassName(optid);
      isallselected.forEach((checkBoxelement) => {
        if (checkBoxelement.checked == true) {
          this.selectedscreens.push(optname);
          // document.getElementById(optid).checked = true;
        }
      });
    },
    async getallroles() {
      const idtoken = store.getters.getIdToken;
      console.log("screens are listed");
      await axios
        .get(
          `${this.baseurl}/account-core/roles/search?status=ACTIVE&sortProperty=role_Name`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Got Res After listed");

          this.roles = res.data.data;
          console.log("Role Listttttttttt", this.roles);
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },
    async getscreenandpriv(id) {
      await axios
        .get(
          `${this.baseurl}/account-core/roles/getscreenroleprivilegebyid?guid=` +
            id
        )
        .then((res) => {
          console.log("Got existing screens and controls");
          this.existingdata =
            res.data == "No ScreenRolePrivilege found" ? null : res.data;
          console.log("existing data");
          this.screenPrivileges = this.existingdata.screens;
          // console.log("Screen Privileges are", this.screenPrivileges);
          // this.getScreensandNames();
          this.existingcontrols();
        })
        .catch((err) => {
          console.log("Have errorrrr After listed existingdata");
          console.log(err);
        });
    },
    async existingcontrols() {
      // console.log(this.screenPrivileges);
      await this.screenPrivileges.forEach((element) => {
        this.screensds.forEach((checkelement, index) => {
          if (element.name == checkelement.name) {
            this.selectedscreens.push(element.name);
            this.screensds[index].controls = element.controls;
          }
        });
      });
      console.log("selected screens are " + this.selectedscreens);
    },
    async getScreensandNames() {
      this.screenNames = [];
      this.controlName = [];
      this.screenPrivileges.filter((item) => {
        if (item.name) {
          this.screenNames.push(item.name);
        }
        item.controls.filter((subitems) => {
          this.controlName.push(subitems);
        });
      });
      console.log("The controls are", this.controlName);
      console.log("The screen names are", this.screenNames);
    },
    async getallscreens() {
      await axios
        .get(`${this.baseurl}/account-core/roles/getallscreens`)
        .then((res) => {
          console.log("Got Resss After listed");

          this.screens = res.data;
          console.log("screens");
          console.log(this.screens);
        })
        .catch((err) => {
          console.log("Have errorrrr After listed");
          console.log(err);
        });
      await this.declarescreen();
    },
    async declarescreen() {
      this.screensds = [];
      console.log("declarescreen function");
      await this.screens.forEach((element) => {
        //initialisation
        let savescreen = {
          name: "",
          controls: [],
        };
        //initialisation completed

        savescreen.name = element.name;
        this.screensds.push(savescreen);
      });
      console.log("screends are");
      console.log(this.screensds);
    },

    async validate() {
      if (this.RoleScreen.role_Name.length < 3) {
        this.validations[0] = false;
      } else {
        this.validations[0] = true;
      }
    },

    //save role priv

    async Save() {
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        this.screenNames = [];
        this.controlName = [];
        let resultarray = [];

        await this.screensds.forEach((element) => {
          if (element.controls.length != 0) {
            console.log("array is not empty");
            resultarray.push(element);
          }
        });
        if (this.existingdata == null) {
          console.log("in save fun");
          console.log(this.screensds);
          await axios
            .post(
              `${this.baseurl}/account-core/roles/createscreenroleprivilege`,
              {
                roleId: this.RoleScreen.role_Name,
                screens: resultarray,
              }
            )
            .then((res) => {
              console.log(res);
              console.log("consoleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
              alert("Role screen privilege saved successfully");
              this.$router.push(`/RoleScreen`);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          await this.updaterolepriv(resultarray);
        }
      }
    },
    //update

    async updaterolepriv(data) {
      console.log("in update fun");
      await axios
        .put(`${this.baseurl}/account-core/roles/updatescreenroleprivilege`, {
          roleId: this.RoleScreen.role_Name,
          screens: data,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      // alert("Role screen privilege updated successfully");
        alert(this.$t('navigations.role screen privilege updated successfully'))
      this.$router.push(`/RoleScreen`);
    },
  },
};
</script> 

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";

.h-400 {
  height: 400px;
}
.fixed-column-table td:first-child,
.fixed-column-table th:first-child,
.fixed-column-table td:nth-child(2),
.fixed-column-table th:nth-child(2) {
  position: sticky;
  z-index: 1;
  background-color: #f2f2f2;
}
.fixed-column-table td:first-child,
.fixed-column-table th:first-child {
  left: -1px;
}
.fixed-column-table td:nth-child(2),
.fixed-column-table th:nth-child(2) {
  left: 28px;
}

.ibox-form {
  background-color: #ffffff;
  border-color: #e7eaec;
  border-style: solid solid none;
  border-width: 1px;
  margin-bottom: 1px;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.errormsg {
  color: #ff4747;
  font-size: 15px;
  float: right;
}
</style>
