<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-6 align-self-center">
            <h2 class="mb-0">{{ $t("navigations.treatment emergent ae") }}</h2>
        </div>
        <div class="col-lg-2">
            <i class="fa fa-times close" @click="$router.push(`/analyticalDashboard`)"></i>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-md-12 pl-1 mb-2">
                <historytab :historyarray="historykeys" @gotohistorylevel="gotohistorylevel" @exporttopdf="exporttopdf"
                @exporttoexcell="exporttoexcell"/>
            </div>
            <div ref="captureArea1" class="col-12 pl-1 mb-2">
                <D3LineChart :data="data" :graphwidth="graphwidth" :loader="loader" :templateId="templateId" :chartlabel="'TEAE Trend Over Time'" @drilldowncurrentchart="drilldowncurrentchartforbar" @opendrillpopup="drillpopupopened" @exporttoexcell="exporttoexcell"/>
            </div>
            <div ref="captureArea2" class="col-12 mb-1 pl-1">  
                <sunburst 
                    :loader="loader"
                    :data="data"
                    title="TEAE Distribution"
                    fromPage="descriptiveStatistics"
                    labelOne="Possible to Retain"
                    labelTwo="Possible to Dropout"
                    @drilldowncurrentchart="drilldowncurrentchartforbar"
                    @opendrillpopup="drillpopupopened" 
                    @exporttoexcell="exporttoexcell"
                    /> 
            </div>
            <div class="col-lg-12 pl-1 mb-1">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                        <span>Patient Distribution</span>
                    </div>
                    <div class="stat-body-white">
                        <advtable :loader="loader" fileheadding="Treatment emergent AE" :data="data" fromPage="patientRetention"
                            @drilldowncurrentchart="drilldowncurrentchartforbar" />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <chartdrill v-if="drillpopup" fileheadding="Treatment emergent AE" :drilldata="drilldata" :templateId="templateId" :fromPage="'adverseEventsPop'"
    @closepopup="drillpopup=false" />
</template>
<script src="./treatmentEmergentAE.js"></script>
<style scoped>
.page-break {
  page-break-before: always; /* Ensures new page starts before this element */
}
</style>