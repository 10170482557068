/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import { useStore } from "vuex";
import Chart from "chart.js";
import jwt_decode from "jwt-decode";
import store from "@/store";
import moment from "moment";
// let testarray= [];
export default {
	name: "sitesummary",
	async mounted() {
		this.defaultdate = localStorage.getItem("dateformat");
		window.addEventListener("studyIdChanged", async (event) => {
			this.defaultdashboard = store.getters.getdefaultdashboard;
			this.roleprivileges = store.getters.getRolesprivilegeData;
			// window.location.reload();
			await this.loadstudy();
			await this.setdefaultstudy();
			await this.getcount();		
			this.sndcharts.update();
		});
		this.roleprivileges = await store.getters.getRolesprivilegeData;
		this.defaultdashboard = store.getters.getdefaultdashboard;
		this.sndcharts = null;
		await this.loadstudy();
		await this.setdefaultstudy();
		await this.getcount();
		this.filter();
		
		const snd = document.getElementById("lineChart");
		this.sndcharts = new Chart(snd, {
			type: "bar",
			data: this.sendData,
			options: this.options,
		});
		this.sndcharts;
	},
	data() {
		return {
			defaultdate: "",
			defaultdashboard: "",
			roleprivileges:"",
			//horizontalbarchart first starts
			firstchart: {
				charttype: "pie",
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
						id: "vuechart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories:[this.$t('navigations.open'), this.$t('navigations.closed')],
					},
				},
				series: [
					{
						name: this.$t('navigations.forms'),
						data: [3, 4],
					},
				],
				dataseries: [3, 4, 3, 2, 5],
				piechartOptions: {
					chart: {
						width: "100%",
						type: "pie",
					},
					labels: [this.$t('navigations.open'), this.$t('navigations.closed')],
					responsive: [
						{
							breakpoint: 480,
							options: {
								pie: {
									size: 200,
								},
								chart: {
									toolbar: {
										show: false,
									},
									width: 200,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
				piedataseries: [0, 0],
			},

			formchart: {
				charttype: "line",
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories:[this.$t('navigations.new'),this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
					},
				},
				series: [
					{
						name: this.$t('navigations.series-1'),
						data: [0,0,0,0],
					},
				],
				dataseries: [],
				piechartOptions: {					
					chart: {
						customScale: 0.8,
						width: "100%",
						type: "pie",
					},
					labels: [this.$t('navigations.new'),this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
					responsive: [
						{
							breakpoint: 480,
							options: {
								pie: {
									size: 200,
								},
								chart: {
									toolbar: {
										show: false,
									},
									width: 200,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
				piedataseries: [2, 3, 2, 1],
			},
			patientchart: {
				charttype: "pie",
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
						id: "vuechart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories:[this.$t('navigations.new'), this.$t('navigations.enrolled'), this.$t('navigations.withdrawn'), this.$t('navigations.completed'),this.$t('navigations.visit ongoing')],
					},
				},
				series: [
					{
						name: this.$t('navigations.forms'),
						data: [0, 0, 0, 0],
					},
				],
				dataseries: [],
				piechartOptions: {
					chart: {
						width: "100%",
						type: "pie",
					},
					labels: [this.$t('navigations.new'), this.$t('navigations.enrolled'), this.$t('navigations.withdrawn'), this.$t('navigations.completed'),this.$t('navigations.visit ongoing')],
					responsive: [
						{
							breakpoint: 480,
							options: {
								pie: {
									size: 200,
								},
								chart: {
									toolbar: {
										show: false,
									},
									width: 200,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
				piedataseries: [0,0,0,0],
			},	
			summarychart: {
				charttype: "bar",
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
						id: "vuechart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: true,
						},
					},
					xaxis: {
						categories:[this.$t('navigations.users'),this.$t('navigations.patients'),this.$t('navigations.forms')],
					},
				},
				series: [
					{
						name:this.$t('navigations.counts'),
						data: [0, 0, 0, 0],
					},
				],
				dataseries: [],
				piechartOptions: {
					chart: {
						width: "100%",
						type: "pie",
					},
					labels: [this.$t('navigations.users'),this.$t('navigations.patients'),this.$t('navigations.forms')],
					responsive: [
						{
							breakpoint: 480,
							options: {
								pie: {
									size: 200,
								},
								chart: {
									toolbar: {
										show: false,
									},
									width: 200,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
				piedataseries: [2, 3, 2, 1],
			},			
			showFilter: false,
			dropdownOpen: false,
			selectedcolumnobj:{},
			showFormFilter: false,
			dropdownFormOpen: false,
			selectedFormcolumnobj:{},
			activeformcount: "",
			patientscountstd: "",
			sites: [],
			sitecountstd: "",
			userscountstd: "",
			formcountstd: "",
			querycountstd: "",
			totalquerycount: 0,
			siteCode: "",
			formlists: [],
			queryArray: [],
			querylist:[],
			formlist:[],
			pageNumber: 1,
			userCount:0,
			patientCount:0,
			formsCount:0,
			currentStudy: "",
			userId: "",
			baseUrl: `${process.env.VUE_APP_Service_URL}/management/study/`,
			baseapi: process.env.VUE_APP_Service_URL,
			studies: "",
			valnow: "",
			sndcharts: "",
			secndcharts: "",
			senddatacoppy: "",
			seconsdatacoppy: "",
			sndoptions: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
			options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
		};
	},
	methods: {
		initializeColumns() {
			if (this.querylist.length > 0) {
			  const allowedKeys = [         
				"queryId",
				"query_Subject",
				"messages[0].raisedBy",
				"messages[0].raisedDate",			  ]
			  this.getColumns= Object.keys(this.querylist[0])
			  .filter(column => allowedKeys.includes(column));   
			  const fieldLabels = {
				"queryId":"Query ID",
				"query_Subject":"Subject",
				"messages[0].raisedBy":"Raised By",
				"messages[0].raisedDate":"Raised Date"
			  };
	  
			  const readableData = allowedKeys.map(key => fieldLabels[key] || key);
			   
			  this.getColumns=readableData
			  this.selectedColumns = [...this.getColumns];
			  this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
				obj[key] = true;
				return obj;
			  }, {});
			 
			}
		  },
		async initializeFormColumns() {
		if (this.formlist.length > 0) {

			const allowedKeys = [         
				"patient",
				"visit",
				"formName",
				"status",
				"date"	  
			]
			this.getFormColumns= Object.keys(this.formlist[0])
			.filter(column => allowedKeys.includes(column));   
			const fieldLabels = {
				patient:"Subject ID",
				visit:"Visit Name",
				formName:"Form Name",
				status:"Status",
				date:"Date",
			};
	
			const readableData = allowedKeys.map(key => fieldLabels[key] || key);
			
			this.getFormColumns=readableData
			this.selectedFormColumns = [...this.getFormColumns];
			this.selectedFormcolumnobj = this.selectedFormColumns.reduce((obj, key) => {
			obj[key] = true;
			return obj;
			}, {});
			
		}
		},
		async setasdefault(){
			// alert("set as default");
			  const idtoken = store.getters.getIdToken;
			  let userIdfordefault = {
				"userId": this.jwtDecrypt(idtoken).sub,
				"email": jwt_decode(idtoken).email,
				"defaultDashBoard": "sitesummary"
				};
			  await axios
				.put(`${this.baseapi}/account-core/settings/userdashboard`,
				userIdfordefault, 
				{
				  headers: {
					Authorization: "Bearer " + idtoken,
					"Content-Type": "application/json",
				  },
				})
				.then((res) => {
					store.dispatch("setdefaultdashboard", "sitesummary");
					alert(this.$t('navigations.default dashboard updated successfully'));
					this.defaultdashboard = store.getters.getdefaultdashboard;
				})
				.catch((err) => {
				  console.log(err);
				});
		  },
		filter(data) {
			if (data) {
			  return moment(data).format(this.defaultdate);
			}
		  },
		async firstchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchfirstbar(true);
				this.firstchart.charttype = "bar";
			} else {
				await this.switchfirstbar(false);
				this.firstchart.charttype = val;
			}
		},
		async secondchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchsecondbar(true);
				this.formchart.charttype = "bar";
			} else {
				await this.switchsecondbar(false);
				this.formchart.charttype = val;
			}
		},
		async thirdchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchthirdbar(true);
				this.patientchart.charttype = "bar";
			} else {
				await this.switchthirdbar(false);
				this.patientchart.charttype = val;
			}
		},
		async fourthchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchfourthbar(true);
				this.summarychart.charttype = "bar";
			} else {
				await this.switchfourthbar(false);
				this.summarychart.charttype = val;
			}
		},
		async switchfirstbar(res) {
			this.firstchart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.firstchart.charttype = "";
		},
		async switchsecondbar(res) {
			this.formchart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.formchart.charttype = "";
		},
		async switchthirdbar(res) {
			this.patientchart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.patientchart.charttype = "";
		},
		async switchfourthbar(res) {
			this.summarychart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.summarychart.charttype = "";
		},
		async raisequery() {
			this.$router.push(`/generalQuery`);
		},
		async querychartData(siteId) {
			if(siteId=='')
				{
					this.firstchart.piedataseries=[]
					this.firstchart.series.data=[]
				}
			else{
				const idtoken = store.getters.getIdToken;
				console.log("query chart data");
				await axios
					.get(
						`${this.baseapi}/forms/query/openclosedcountinsite?SiteID=${siteId}`,
						{
							headers: {
								Authorization: "Bearer " + idtoken,
								"Content-Type": "application/json",
							},
						}
					)
					.then(async (res) => {
						console.log(res);
						this.firstchart.series = [
							{
								name: this.$t('navigations.queries'),
								data: [
									res.data.open,
									res.data.closed 
								],
							},
						];
						this.firstchart.piedataseries = [
							res.data.open,
							res.data.closed 
						];
					})
					.catch((err) => {					
						console.log(err);
					});
					
					this.firstchart.charttype="pie" 
			}
		
		},
		async formchartdata(siteId) {
			const idtoken = store.getters.getIdToken;
			let studyid = await store.getters.getStudyIs;
			console.log("The site is changed", siteId);
			await axios
				.get(
					`${this.baseapi}/forms/forms/formcountinsite?studyID=${studyid}&siteID=${siteId}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log(res);
					let formValues=[]
					let formCategories=[]
					for (const [key, value] of Object.entries(res.data)) {
						if(key!='Total')
						{
							formValues.push(res.data[key])
							console.log("key value is",res.data[key])
						}
					  }
					
					console.log("forms value is ",formValues);
					console.log("forms data is ",formCategories);
					this.formchart.dataseries = [
						{
							'name':this.$t('navigations.forms'),
							'data': [
								res.data.New == null ? 0 : res.data.New,
								res.data.Active == null ? 0 : res.data.Active,
								res.data.Verified == null ? 0 : res.data.Verified ,
								res.data.Unverified == null ? 0 : res.data.Unverified,
							]
						}
					];
					this.formchart.piedataseries = this.formchart.dataseries[0].data;
				})
				.catch((err) => {
					console.log(err);
				});
				this.formchart.charttype = 'bar';
		},
		async patientchartdata(siteId) {
			if(siteId==''){
				this.patientchart.piedataseries=[]
				this.patientchart.dataseries=[]
			}
			else{
				const idtoken = store.getters.getIdToken;
				console.log("Funciton entered")
				await axios
					.get(
						`${this.baseapi}/management/patient/patientstatuscount?siteId=${siteId}`,
						{
							headers: {
								Authorization: "Bearer " + idtoken,
								"Content-Type": "application/json",
							},
						}
					)
					.then((res) => {
						console.log(res);
						this.patientchart.dataseries = [
							{
								name: this.$t('navigations.subjects'),
								data: [
									res.data.New == null ? 0 : res.data.New,
									res.data.Enrolled == null ? 0 : res.data.Enrolled,
									res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
									res.data.Completed == null ? 0 : res.data.Completed,
									res.data["Visit Ongoing"] == null ? 0 : res.data["Visit Ongoing"],
								],
							},
						];
						this.patientchart.piedataseries = this.patientchart.dataseries[0].data;
					})
					.catch((err) => {
					
						console.log(err);
					});
					this.patientchart.charttype="pie"
			}
			
		},
		async usercount(siteId)
		{
			const idtoken = store.getters.getIdToken;
			await axios
				.get(
					`${this.baseapi}/management/site/noofusers?SiteID=${siteId}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log("The user count is", res.data)
					this.userCount=res.data

				})
				.catch((err) => {
					console.log(err);
				});
		},
		async patientcount(siteId)
		{
			const idtoken = store.getters.getIdToken;
			await axios
				.get(
					`${this.baseapi}/management/patient/getpatientcount?Siteid=${siteId}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log("The patient count is", res.data)
					this.patientCount=res.data

				})
				.catch((err) => {
					console.log(err);
				});
		},
		async formcount(siteId)
		{
			const idtoken = store.getters.getIdToken;
			let studyid = await store.getters.getStudyIs;
			await axios
				.get(
					`${this.baseapi}/forms/forms/formcountinsite?studyID=${studyid}&siteID=${siteId}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log("The form count is", res.data.Total)
					this.formsCount=res.data.Total
					this.summarychartdata(siteId);

				})
				.catch((err) => {
					console.log(err);
				});
		},
		summarychartdata:function(siteId)
		{
			
			console.log("Counts are",this.userCount,
			this.patientCount,
			this.formsCount)
			this.summarychart.dataseries = [
				{
					name: this.$t('navigations.summary'),
					data: [
						this.userCount,
						this.patientCount,
						this.formsCount
					],
				},
			];
			this.summarychart.piedataseries = this.summarychart.dataseries[0].data;
		},
		async querytabledata(siteId){			
			const idtoken = store.getters.getIdToken;
			await axios
				.get(
					`${this.baseapi}/forms/query/getlatestquerydetails?SiteID=${siteId}&Limit=15`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					
					this.querylist=res.data
				})
				.catch((err) => {
					this.querylist=[]
					console.log(err);
				});
		},
		async formstabledata(siteId){			
			const idtoken = store.getters.getIdToken;
			await axios
				.get(
					`${this.baseapi}/forms/forms/formdetailsinsite?siteID=${siteId}&Limit=15`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.formlist=res.data
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async viewquery(id) {
			this.$router.push(`/query/sitesummary/${id}`);
		  },
		async viewform(id) {
			const fromArchive = false
			this.$router.push(`/showform/sitesummary/${id}/${fromArchive}`);
		},
		async sitedata(currentstudyid, idtoken) {
			this.sites = [];
			await axios
				.get(
					`${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&sortProperty=siteCode&IsDes=false&getAll=false`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					console.log("The sites are", res.data);
					res.data.filter((item) => {
						this.sites.push(item);
					});
					console.log("Site is", this.sites);
					await this.setinitialdata();
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async setinitialdata(){
			if(this.sites.length==0) 
			{
				this.siteCode=''
			}
			else{
				this.siteCode = this.sites[0].siteID;
			}
				await this.querychartData(this.siteCode);
				await this.formchartdata(this.siteCode);
				await this.patientchartdata(this.siteCode);
				await this.querytabledata(this.siteCode);
				await this.formstabledata(this.siteCode);
				await this.usercount(this.siteCode);
				await this.patientcount(this.siteCode);
				await this.formcount(this.siteCode);
				this.initializeColumns()
				this.initializeFormColumns()
		
		},
		async getcount() {
			const idtoken = store.getters.getIdToken;
			this.valnow = await store.getters.getStudyIs;
			this.sitedata(this.valnow, idtoken);
		},
		jwtDecrypt(token) {
			const base64Url = token.split(".")[1];
			const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function(c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			return JSON.parse(jsonPayload);
		},
		async setdefaultstudy() {
			if (this.valnow == "" || this.valnow == null) {
				const idtoken = store.getters.getIdToken;
				this.userId = await this.jwtDecrypt(idtoken).sub;
				await axios
					.get(this.baseUrl + "getdefaultstudy?id=" + this.userId, {
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					})
					.then((res) => {
						if (res.statusText == "No Content") {
							console.log("no default study");
						} else {
							this.currentStudy = res.data.studyID;
							store.dispatch("setStudyID", res.data.studyID);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
			this.valnow = store.getters.getStudyIs;
		},
		// loadstudy function
		/* filter(data){
            if(data){
                return moment(data).format('DD/MM/YYYY hh:mm')
            }
        },*/
		// loadstudy function
		async loadstudy() {
			const idtoken = store.getters.getIdToken;
			const usermail = jwt_decode(idtoken).email;
			await axios
				.get(`${this.baseapi}/management/study/getallstudy?user=${usermail}`, {
					headers: {
						Authorization: "Bearer " + idtoken,
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					this.studies = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
			this.valnow = store.getters.getStudyIs;
		},
	},
};
