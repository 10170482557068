/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import html2pdf from 'html2pdf.js';
import * as XLSX from "xlsx";
import groupbarchart from '../../custom_lib/AiCharts/groupbarchart.vue';
import stackedbarchart from '../../custom_lib/AiCharts/StackedBarchart.vue';
import barchart from '../../custom_lib/AiCharts/adbarchart.vue';
import histogram from '../../custom_lib/AiCharts/histogram.vue';
import piechart from '../../custom_lib/AiCharts/D3PieChart.vue';
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import aepiechart from '../../custom_lib/AiCharts/aepiechart.vue';
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
export default {
  name: "labresultsreport",
  components: {
    groupbarchart,
    barchart,
    histogram,
    piechart,
    advtable,
    aepiechart,
    historytab,
    stackedbarchart,
    chartdrill
  },
  props: {
    templateId: {
      type: String,
      default: ''
    },
    resultCount: {
      type: Object,
      required: true,
      default: () => ({
        noOfSubjects: 0,
        noOfSubjectsEnrolledByAI: 0,
        noOfSubjectsRejectedByAI: 0
      }),
    },
  },
  async mounted() {
    await this.getdata();
    this.targetValue=this.resultCount.noOfSubjects
    this.startRolling();
  },
  data() {
    return {
      arrayofkeys:[
        "SITEID",
        "AGE",
        "SEX",
        "RACE",
        "SMKSTAT",
        "ALCOHOL",
        "EXERCISE",
        "DIET",
        "COMORB",
        "BMI",
        "BPSTRES",
        "CHOLEST",
        "RECENTILL",
        "MENTALHIST",
        "BLHEALTH",
        "AEDECOD",
        "AETERM",
        "AESTDTC",
        "AEONGO",
        "AEENDTC",
        "AEOUT",
        "CMTXT",
        "CMDOSAGE",
        "CMSTDTC",
        "CMENDTC",
        "CMFREQ",
        "CMRESPONSE",
        "RSTERM",
        "RSDTC",
        "RSOUT",
        "AETERM.1",
        "AEREACT",
        "AESER",
        "FHTERM",
        "FHREL",
        "FHAGE",
        "VACCIN",
        "VACCTERM",
        "VACCDTC",
        "AEDECOD.1",
        "AETERM.2",
        "AESTDTC.1",
        "AEOUT.1"
    ],
    groupchartkeys:{
      "xaxiskey":"SEX",
    "groupkey":"SMKSTAT",
    },
      xaxiskey:"ALCOHOL",
      maximizedcomp:"",
      drilldata:{},
      loader:true,
      expandBlock:false,
      data:[],
      datahistory:[],
      historykeys:[],
      historydata:{},
      drilldowndata:[],
      currentValue: 0, // Start value
      targetValue: 0, // The target value you want to roll to
      duration: 2000, // Duration of the animation in milliseconds
      displayValue: 0,
      basetestUrl: process.env.VUE_APP_Service_URL,
      baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/patientenroll/",
      selectedChartType: 'Comparitive Bar chart',
      chartTypes: ['Comparitive Bar chart', 'Pie Chart', 'Bar Chart', 'Line Chart',],
      drillpopup: false
    };
  },
  methods: {
    async exporttoexcell() {
      const worksheet = XLSX.utils.json_to_sheet(this.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Medical History Summary.xlsx");
    },
    exporttopdf() {
      // Create a temporary container
      const pdfContainer = document.createElement('div');

      const captureArea1 = this.$refs.captureArea1.cloneNode(true);
      const captureArea2 = this.$refs.captureArea2.cloneNode(true);
      const captureArea3 = this.$refs.captureArea3.cloneNode(true);
      const captureArea4 = this.$refs.captureArea4.cloneNode(true);

      captureArea2.classList.add('page-break');
      captureArea3.classList.add('page-break');
      captureArea4.classList.add('page-break');
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea1);
      pdfContainer.appendChild(captureArea2);
      pdfContainer.appendChild(captureArea3);
      pdfContainer.appendChild(captureArea4);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '150%';
      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: 'Medical History Summary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    startRolling() {
      const start = this.currentValue;
      const end = this.targetValue;
      const duration = this.duration;
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        if (elapsedTime < duration) {
          const progress = elapsedTime / duration;
          this.displayValue = Math.floor(start + progress * (end - start));
          requestAnimationFrame(animate);
        } else {
          this.displayValue = end;
        }
      };

      requestAnimationFrame(animate);
    },
    async undobutton(){
      if(this.datahistory.length > 1){
      this.datahistory.pop();
      this.data=this.datahistory[this.datahistory.length-1];
      }
    },
    changetomaxcomponent(compval){
      this.maximizedcomp = compval;
    },
    changetominomponent(){
      this.maximizedcomp = "";
    },
    async retainxaxiskey(xaxiskey){
      if(xaxiskey!=""){
      this.xaxiskey = xaxiskey;
      }
    },
    async retaingroupchartvars(groupvars){
      if(groupvars.xaxiskey!="" && groupvars.groupkey!=""){
      this.groupchartkeys = groupvars;
      }
    },
    async onclosed(){
      this.drillpopup = false;
    },
    async drillpopupopened(datafromchild) {
      this.drilldata = datafromchild;
      this.drillpopup = true;

    },
    async drilldowncurrentchart(datafromchild){
      this.data = datafromchild;
      this.datahistory.push(this.data);
    },
    async drilldowncurrentchartforbar(datafromchild){
      console.log("sorteddataforbarchart",datafromchild);
      const lastkey = this.historykeys[this.historykeys.length - 1];
      // this.data = datafromchild.sortedData;
      if(this.historydata[lastkey].length != datafromchild.sortedData.length){
      // if(!this.historykeys.includes(datafromchild.filterkeyword)){
       this.historykeys.push(datafromchild.filterkeyword);
      this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
      this.data = this.historydata[datafromchild.filterkeyword];
      }
    },
    async gotohistorylevel(history){
      this.deleteAfterKeyvaluearrays(history);
      this.deleteAfterKeyarrays(history);
      this.data = this.historydata[history];
    },
    async deleteAfterKeyvaluearrays(selectedKey) {
      const entries = Object.entries(this.historydata); // Convert the object to an array of entries
      const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
    
      // If the key is found, slice the array to keep only the entries up to the selected key
      const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
    
      // Convert the array of entries back into an object
      return Object.fromEntries(newEntries);
    },
    async deleteAfterKeyarrays(selectedKey) {

      let index = this.historykeys.indexOf(selectedKey);

if (index !== -1) {
    // Slice the array up to the element (including the element)
    this.historykeys = this.historykeys.slice(0, index + 1);
}
    },
    async headderforbarcomparitive(repfieldname) {
      this.comparitivebarheader = repfieldname;
    },
    async getdata() {
      this.loader = true;
      await axios.post(
        `${this.basetestUrl}/forms-ml/report/getfilteredreports?table_name=MedicalHistory`)
        .then((res) => {
          this.data = res.data;
          this.loader = false;
          this.datahistory.push(this.data);
          this.historydata['Home'] = this.data;
          this.historykeys.push('Home');
        })
        .catch((err) => console.log("error in screen list", err));
    },
    async pageclosed(){
      this.$router.push(`/analyticalDashboard`);
  }
  },
};