<template>
    <div class="stat-card-white p-0" ref="captureArea">
      <div class="stat-header-white">
        <span>Statistical Summary</span>
        <div class="d-flex" v-if="!loader">
          <select class="graph-select py-0" v-model="selectedPercentageAttribute" id="attribute-select" @change="updateChart">
            <option v-for="option in attributeOptions" :key="option" :value="option">{{ option.toUpperCase() }}</option>
          </select>
          <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exportPDF()">
              Export to PDF
            </div>
          </div>
        </div>
        </div>
      </div>
  
      <div class="stat-body-white">
        <div v-if="loader" class="loader"></div>
        <div v-if="averagesItem && !loader" class="average-container">
          <div class="average-stats d-flex">
            <div class="stat-item" v-for="(value, key) in averagesItem" :key="key">
              <h3>{{ key }}</h3>
              <h4>{{ value != null ? value.toFixed(2) : 0 }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import html2pdf from 'html2pdf.js';
  import store from "../../store/index";
  import { mean, median, standardDeviation, mode, quantile } from 'simple-statistics';
  import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
  export default {
    name: 'AttributePercentageBar',
    props: {
      data: {
        type: Array,
        required: true,
        default: () => [],
      },
      templateId: {
        type: String,
        default: ''
      },
      fromPage: {
        type: String,
        default: ""
      },
      fromTable: {
        type: String,
        default: ""
      },
      loader: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showVisitPopup: false,
        idtoken: "",
        baseApi: process.env.VUE_APP_Service_URL,
        selectedPercentageAttribute: '',
        attributeOptions: [],
        averagesItem: {},
        expandBlock: false,
      };
    },
    watch: {
      data: {
        handler() {
          this.loadAttributes();
          this.updateAverages();
        },
        deep: true,
      },
    },
    async beforeMount() {
      this.idtoken = store.getters.getIdToken;
      this.loadAttributes();
      this.updateAverages();
    },
    methods: {
      async exporttoexcell() {
        this.$emit("exporttoexcell");
    },
    exportPDF() {
      // Create a temporary container
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);

      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting

      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: 'Statistical Summary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
      loadAttributes() {
        if (this.data.length > 0) {
          // Get all the possible numeric attributes to show in the dropdown
          this.attributeOptions = Object.keys(this.data[0]).filter(key => 
            typeof this.data[0][key] === 'number'
          );
          if (this.attributeOptions.length > 0) {
            this.selectedPercentageAttribute = this.attributeOptions[0];
          }
        }
      },
      updateChart() {
        this.updateAverages();
      },
      updateAverages() {
        if (!this.selectedPercentageAttribute) return;
        
        // Get the selected attribute's data from all entries
        const values = this.data
          .map(item => item[this.selectedPercentageAttribute])
          .filter(value => value != null); // Filter out null or undefined values
  
        if (values.length > 0) {
          const count = values.length;
          const avg = mean(values);
          const stdDev = standardDeviation(values);
          const minValue = Math.min(...values);
          const maxValue = Math.max(...values);
          const p25 = quantile(values, 0.25);
          const p50 = median(values); // Same as 50th percentile
          const p75 = quantile(values, 0.75);
          const mostFreq = mode(values);
  
          this.averagesItem = {
            "Count": count,
            "Mean": avg,
            "Standard Deviation": stdDev,
            "Minimum": minValue,
            "25th Percentile": p25,
            "50th Percentile (Median)": p50,
            "75th Percentile": p75,
            "Maximum": maxValue,
            "Most Frequent Value": mostFreq
          };
        } else {
          this.averagesItem = {}; // Clear if no values available
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import "../AiCharts/charts.css";
  .stat-card-white {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
  }
  
  .stat-header-white {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .average-container {
    display: flex;
    gap: 20px;
    overflow: auto;
  }
  
  .average-stats {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .stat-item h3 {
    font-size: 1rem;
    color: #888;
  }
  
  .stat-item h4 {
    font-size: 1.2rem;
    color: #333;
  }
  .stat-item-block {
    border: 1px solid #a7aaae;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  </style>
  