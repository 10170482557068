<template>
  <!-- modal starts -->
  <div class="patient__login__modal__wrapper">
    <div class="patient__login__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ modalText }}</h4>
        <div class="cursor-pointer" title="Close" @click.prevent="onclosed()">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="px-5 py-3">
        <div v-if="modalText == `Sign`">
          <h7> On signing, this is equivalent to the wet ink signature </h7>
        </div>
        <div v-if="emailValidation" class="form-group mb-4">
          <label class="col-form-label">
            {{ $t("navigations.email") }}
            <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <input type="text" class="form-control" v-model="emailId" />
          <!-- Only show the error message when emailError is present -->
          <span v-if="emailValidation" class="errmsg">{{ emailError }}</span>
        </div>
        <div v-else class="form-group ">
          <label class="col-form-label">
            {{ $t("navigations.email") }}
            <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <input type="text" class="form-control" v-model="emailId" />
          <!-- Only show the error message when emailError is present -->
          <span v-if="emailValidation" class="errmsg">{{ emailError }}</span>
        </div>
        <div class="form-group position-relative">
          <label class="col-form-label">{{ $t("navigations.digi sign password")
            }}<sup><i class="fa fa-asterisk required"></i></sup></label>
          <input :type="inputType" class="form-control" v-model="password" />
          <i class="password-sign-visible-block cursor-pointer position-absolute"
            :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']" @click.prevent="passwordVisible()"></i>
          <div>
            <span v-if="passwordValidation" class="errmsg">{{ passwordError }}</span>
          </div>
        </div>
        <div class="text-right">
          <button class="save_btn px-5 mt-3" @click="submitClick()">
            {{ $t("navigations.confirm") }}
          </button>
        </div>
      </div>
      <!-- test -->
    </div>
  </div>
  <!-- modal ends -->
</template>
<script>
import axios from "axios";
import store from "../../store/index";
export default {
  name: "signaturePopUp",
  props: {
    status: {
      type: String,
      default: "",
    },
    formId: {
      type: String,
      default: "",
    },
    modalText: {
      type: String,
      default: "",
    },
    fromSrcUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputType: "password",
      visibleOn: false,
      emailId: "",
      password: "",
      err: "",
      emailError: "", // Email-specific error
      passwordError: "", // Password-specific error
      emailValidation: false, // Email validation flag
      passwordValidation: false, // Password validation flag
      id: "",
      state: "",
      baseurl: process.env.VUE_APP_Service_URL,
    };
  },
  async mounted() {
    await this.assignValues();
  },
  methods: {
    async passwordVisible() {
      this.visibleOn = !this.visibleOn;
      if (this.visibleOn === true) {
        this.inputType = "text";
      } else this.inputType = "password";
    },
    async assignValues() {
      this.id = this.formId;
      this.state = this.status;
      console.log("FormID=>", this.id + "&" + "Status=>", this.state);
    },
    async onclosed() {
      this.$emit("signatureclosemodel");
      this.emailId = "";
      this.password = "";
    },

    async submitClick() {
      this.emailError = ""; // Reset email error
      this.passwordError = ""; // Reset password error
      this.emailValidation = false;
      this.passwordValidation = false;

      if (this.emailId == "") {
        this.emailError = this.$t("navigations.please enter a valid email id"); // Set specific email error
        this.emailValidation = true; // Trigger email error
      }

      if (this.password == "") {
        this.passwordError = this.$t("navigations.please enter your password");// Set specific password error
        this.passwordValidation = true; // Trigger password error
      }

      if (!this.emailValidation && !this.passwordValidation) {
        await this.updateSign();
      }
    },
    async updateSign(status) {
      const idtoken = store.getters.getIdToken;

      if (this.fromSrcUrl === "digitalForm") {
        await axios
          .put(
            `${this.baseurl}/forms/template/signtemplate?TempId=${this.id}&status=${this.state}&email=${this.emailId}&password=${this.password}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.refreshForm("test");
            this.$emit("signatureclosemodel");
            if (this.status == "Signed") {
              this.$emit("docSigned");
              localStorage.removeItem("setId");
              localStorage.removeItem("showSign");
            }
          })
          .catch((err) => {
            console.log(err);
            const errorMsg = err.response.data.errors.DomainValidations[0];

            if (errorMsg.includes("Email id entered differs from the logged-in user's email id")) {
              this.emailError =this.$t("navigations.email id entered differs from the logged-in user's email id")
              console.log("Email issue",this.emailError)
              this.emailValidation = true;
            }
            if (errorMsg.includes("Invalid password")) {  
              this.passwordError = this.$t("navigations.invalid password"); 
              console.log("Password issue",this.passwordError)
              this.passwordValidation = true; 
            }
          });
      } else {
        await axios
          .put(
            `${this.baseurl}/forms/forms/signform?formId=${this.id}&status=${this.state}&email=${this.emailId}&password=${this.password}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.refreshForm("test");
            this.$emit("signatureclosemodel");
          })
          .catch((err) => {
            const errorMsg = err.response.data.errors.DomainValidations[0];
            if (errorMsg.includes("Email id entered differs from the logged-in user's email id")) {
              this.emailError = this.$t("navigations.email id entered differs from the logged-in user's email id");
              console.log("Email issue",this.emailError)
              this.emailValidation = true;
            }
            if (errorMsg.includes("Invalid password")) {  
              this.passwordError = this.$t("navigations.invalid password"); 
              console.log("Password issue",this.passwordError)
              this.passwordValidation = true; 
            }
          });
      }
    },
    async refreshForm(test) {
      await store.dispatch("setFormRefresh", true);
      window.dispatchEvent(
        new CustomEvent("FormDigitalSignature", {
          detail: {
            storage: store.getters.getFormRefresh,
          },
        })
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";

.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}

.page-links {
  border-radius: 0px;
}

/*pagination styles*/
.pagination {
  margin: 0;
}

.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}

.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}

.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}

.modelbody {
  overflow: unset !important;
  background-color: #ffffff !important;
}

.modelbody {
  /* background-color: aquamarine; */
  margin: 0;
  padding: 5%;
}

.form-control {
  padding: 0.2rem 0.75rem !important;
}

.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}

.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}

.patient__login__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}

.patient__login__modal__container {
  position: fixed;
  width: min(500px, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}

.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}

.errmsg {
  font-size: 14px;
  color: red;
  float: left;
}

.password-sign-visible-block {
  right: 11px;
  top: 41px;
}
</style>
