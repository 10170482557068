<template>
  <div v-if="loader" class="loader"></div>
  <div v-if="!loader" class="col-md-12 overflow-auto">
    <label class="mr-2" v-if="enrolledornot && !loader">
      <input type="checkbox" v-model="aiPredictionTrue" @change="updateTable"> Enrolled
    </label>
    <label v-if="enrolledornot && !loader">
      <input type="checkbox" v-model="aiPredictionFalse" @change="updateTable"> Non-enrolled
    </label>
    
    <table>
      <thead>
        <tr v-if="fromPage=='patientScreening'">
          <th>
            <select class="graph-select py-0" v-model="selectedXValue" id="x-value-select" @change="updateTable">
              <option v-for="option in xValueOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </th>
          <th>
            <select class="graph-select py-0" v-model="selectedYValue1" id="y-value-select-1" @change="updateTable">
              <option v-for="option in yValueOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </th>
          <th>
            <select class="graph-select py-0" v-model="selectedYValue2" id="y-value-select-2" @change="updateTable">
              <option v-for="option in yValueOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </th>
          <th>
            <select class="graph-select py-0" v-model="selectedYValue3" id="y-value-select-3" @change="updateTable">
              <option v-for="option in yValueOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </th>
        </tr>
        <tr v-if="fromPage=='descriptiveStatistics'">
          <th></th>
          <th>
           Frequency
          </th>
          <th>
           Probability of Occurance
          </th>
        </tr>
      </thead>
      <tbody v-if="fromPage=='patientScreening'">
        <tr v-for="item in tableData" :key="item[selectedXValue]">
          <td>{{ getMappedValue(item[selectedXValue], selectedXValue) }}</td>
          <td>{{ item[selectedYValue1].toFixed(2) }}</td>
          <td>{{ item[selectedYValue2].toFixed(2) }}</td>
          <td>{{ item[selectedYValue3].toFixed(2) }}</td>
        </tr>
      </tbody>
      <tbody v-if="fromPage=='descriptiveStatistics'">
        <template v-for="(item,index) in tableData" :key="index">
          <tr v-for="(itemData,dataIndex) in item.frequencies" :key="dataIndex">
            <td>{{ dataIndex }}</td>
            <td>{{ itemData }}</td>
            <td>{{ item.probabilities[dataIndex] }}</td>
          </tr>
          
        </template>
      </tbody>
    </table>
  </div>
</template> 

<script>
import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";

export default {
  name: 'AttributeTable',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    dataObject:{
      type: Object,
      default: () => ({})
    },
    templateId: {
      type: String,
      default: ''
    },
    selectedValue: {
      type: String,
      default: ''
    },
    enrolledornot: {
      type: Boolean,
      default: true
    },
    fromPage:{
      type: String,
      default: ''
    },
    loader: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      selectedXValue: '',
      selectedYValue1: '',
      selectedYValue2: '',
      selectedYValue3: '',
      xValueOptions: [],
      yValueOptions: [],
      tableData: [],
      frequenciesArray:[],
      probabilitiesArray:[],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
      uniqueValues:[]
    };
  },
  watch: {
    data: {
      handler() {
      if(this.fromPage=='patientScreening' && this.data!=null)
      {
        this.loadAttributes();
        this.updateTable();
      }
      
      },
      deep: true,
    },
    selectedValue: {      
      handler() {
        if(this.fromPage=='descriptiveStatistics')
      {
          this.tableData=[
          {
            frequencies:'',
            probabilities:''
          }
        ]
    
        this.tableData[0].frequencies=this.dataObject[this.selectedValue].frequencies
        this.tableData[0].probabilities = this.dataObject[this.selectedValue].probabilities
       }
      },
      deep: true,
    },
  },
  async beforeMount() {
   
    if(this.fromPage=='patientScreening')
    {
    await this.loadAttributes();
    }
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;

    if (this.templateId != '' && this.templateId != null) {
      await this.createmapping();
    }
    if(this.fromPage=='patientScreening')
    {
    this.updateTable();
    }
    if(this.fromPage=='descriptiveStatistics'){
      this.tableData=[
        {
          frequencies:'',
          probabilities:''
        }
      ]
  
  
    }
  },
  methods: {
    async loadAttributes() {
      if(this.fromPage=='patientScreening')
      {
        const sampleData = (this.data && this.data.length > 0 && this.data[0].features) ? this.data[0].features : {};
        let xValues = [];
      const yValues = [];
 
      Object.keys(sampleData).forEach(key => {
        const uniqueValues = new Set(this.data.map(item => item.features[key])).size;
        xValues = [...this.uniqueValues]
        if (uniqueValues > 6) {
          yValues.push(key);
        }
      });
 
      this.xValueOptions = xValues;
      this.yValueOptions = yValues;
 
 
      this.selectedXValue = xValues[0] || '';
      this.selectedYValue1 = yValues[0] || '';
      this.selectedYValue2 = yValues[1] || '';
      this.selectedYValue3 = yValues[2] || '';
      }
   
    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.uniqueValues.push(field.fieldName);
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    updateTable() {
      const groupedData = this.groupDataByXValue();
      this.tableData = groupedData.map(group => ({
        [this.selectedXValue]: group.key,
        [this.selectedYValue1]: group.averages[this.selectedYValue1],
        [this.selectedYValue2]: group.averages[this.selectedYValue2],
        [this.selectedYValue3]: group.averages[this.selectedYValue3],
      }));
      console.log("table data", this.tableData)
      if(this.tableData.length != 0){
      }
    },
    groupDataByXValue() {
      console.log("this.data...", this.data)
      const validData = this.validateData(this.data.map(d => d.features))

        const groupedData = d3.groups(validData, d => d[this.selectedXValue]);

        return groupedData.map(([key, values]) => {
          const averages = {
            [this.selectedYValue1]: d3.mean(values, v => v[this.selectedYValue1]),
            [this.selectedYValue2]: d3.mean(values, v => v[this.selectedYValue2]),
            [this.selectedYValue3]: d3.mean(values, v => v[this.selectedYValue3]),
          };

          return { key, averages };
        });
      
    },
    validateData(data) {
      if(this.fromPage=='patientScreening')
      {
        return data.filter(d => {
          const aiPrediction = d.AIPrediction;
        if ((aiPrediction && this.aiPredictionTrue) || (!aiPrediction && this.aiPredictionFalse)) {
          return d[this.selectedXValue] !== undefined;
        }
        return false;        
      });
      }
      if(this.fromPage=='descriptiveStatistics')
      { 
        this.frequenciesArray=[]
        this.probabilitiesArray=[]
        if(this.data[this.selectedValue].frequencies!=null && this.data[this.selectedValue].probabilities!=null)
           {
           this.frequenciesArray.push(this.data[this.selectedValue].frequencies)
           this.probabilitiesArray.push(this.data[this.selectedValue].probabilities)
           }
   
      }
    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    }
  },
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>