<template>
    <!-- testwrapper -->
    <!-- <div v-if="defaultdashboard != 'studysummary'" class="row wrapper border-bottom white-bg page-heading">
<div class="col-lg-12 text-right">
<h2><button type="button" class="btn btn-outline-success fontprop" @click="setasdefault()">Set as default</button></h2>
</div>
</div> -->
<!-- testwrapper -->
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-6 col-md-10">
			<h2>{{$t('navigations.study summary')}}</h2>
		</div>
		<div class="col-6 col-md-2 text-end align-self-center" v-if="defaultdashboard != 'studysummary'">
			<button type="button" class="btn btn-setdefault fontprop" @click="setasdefault()">{{$t('navigations.set as default')}}</button>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-md-12 mb-2">
				<historytab :historyarray="historykeys" @gotohistorylevel="gotohistorylevel" @exporttopdf="exporttopdf"
				@exporttoexcell="exporttoexcell"/>
			</div>
			<div  ref="stackedbar" class="col-12 col-md-6 mb-2 pr-1">
				<stackedbarchart :availableColumns="[]" xAxisKey="STUDYREF" yAxisKey="count" segmentKey="SITESTATUS" :xAxisLabel="$t('navigations.studies')" :yAxisLabel="$t('navigations.sites count')"
				:loader="loader" :data="data" :templateId="''" :fromPage="'studySummary'"
				:chartlabel="$t('navigations.sites count')" @drilldowncurrentchart="drilldowncurrentchartforbar"
				@opendrillpopup="drillpopupopened" />
			</div>
			<div ref="stackedbarhorizontal" class="col-md-6 mb-2 pl-1">
				<stackedhorizontalbarchart :availableColumns="[]" xAxisKey="STUDYREF" yAxisKey="count" segmentKey="SUBJECTSTATUS" :xAxisLabel="$t('navigations.subjects count')" :yAxisLabel="$t('navigations.studies')"
				:loader="loader" :data="data" :templateId="''" :fromPage="'studySummary'"
				:chartlabel="$t('navigations.subjects count')" @drilldowncurrentchart="drilldowncurrentchartforbar"
				@opendrillpopup="drillpopupopened" />
			</div>
			<div ref="groupchartdiv" class="col-12 mb-2">
				<groupbarchart
				:availableColumns="[]"
				:loader="loader"
				:data="data"
				:templateId="templateId"
				:xAxisValue="groupchartkeys['xaxiskey']"
				yAxisValue=""
				:xAxisLabel="$t('navigations.studies')"
				:yAxisLabel="$t('navigations.query count')"
				:groupCategoryValue="groupchartkeys['groupkey']"
				:fromPage="'studySummary'"
				:chartLabel="$t('navigations.query count')"
				@drilldowncurrentchart="drilldowncurrentchartforbar"
				@opendrillpopup="drillpopupopened"
				@setgroupkeys="retaingroupchartvars"
				/>
			</div>
			<div ref="sunburst" class="col-12">
				<sunburst 
                    :loader="loader"
                    :data="data"
                    fromPage="studySummary"
                    labelOne="Possible to Retain"
                    labelTwo="Possible to dropout"
                    :title="$t('navigations.forms count')"
                    @drilldowncurrentchart="drilldowncurrentchartforbar"
                    @opendrillpopup="drillpopupopened"
                    /> 
			</div>
		</div>
	</div>
	<chartdrill
    v-if="drillpopup"
   :fromPage="'adverseEventsPop'"
    :drilldata="drilldata"
    :templateId="templateId"
    @closepopup="drillpopup=false"
  >
  </chartdrill>
</template>
<script src="./study_summary.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.widget {
	margin-top: 0rem;
	margin-bottom: 0rem;
}
.widgetmargin {
	margin-top: -13px !important;
}
.ibox-body {
	background-color: #ffffff;
	margin: 3rem, 0;
}

.style1 {
	background-color: black !important;
	color: #ffffff;
}
.btn-view {
	color: #fff !important;
	background-color: #bb458f !important;
	border-color: #bb458f !important;
}
.btn-view:hover {
	color: #fff;
	background-color: #666ccb;
	border-color: #666ccb;
}
.btn-view:active {
	color: rgb(255, 255, 255) !important;
	background-color: #dda2c7 !important;
	border-color: #dda2c7 !important;
}
.ibox-title {
	color: inherit;
	margin-bottom: 0;
	padding: 1rem;
	position: relative;
	clear: both;
	right: 0;
	margin-right: 0px;
	width: unset !important;
	margin-left: 0px !important;
	margin-top: 0px !important;
}
.graph-block__title {
	display: flex;
	justify-content: space-between;
	padding: 0.7rem 1.7rem;
	clear: both;
	right: 0;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 0;
}
.padding_bottom {
	padding-bottom: 2rem;
}
.ibox-title h5 {
	height: 0rem;
	padding: 0rem;
}
.ibox-title h5:hover {
	background-color: inherit;
	padding: 0;
	cursor: not-allowed;
}
.titlemsg {
	font-size: 15px;
	font-weight: bold;
	color: var(--primary-color);
}
.switchbtn {
	background-color: #bb458f !important;
	height: max-content;
	border-color: #bb458f !important;
	color: white !important;
	float: right;
}
.switchbtn:active {
	height: max-content;
	border-color: #bb458f !important;
	color: white !important;
	float: right;
	background-color: #bb458f;
}
.switchbtn:hover {
	height: max-content;
	border-color: #bb458f !important;
	color: white !important;
	float: right;
	background-color: #bb458f;
}
.queryhover:hover {
	color: #3c4192 !important;
	-webkit-transition: color ease-in 400ms;
	transition: color ease-in 400ms;
	cursor: pointer;
}

.widgetminheight {
	min-height: 102px;
}
.graphbutton {
	width: fit-content;
	padding: 3px;
}
.graphbutton:hover {
	cursor: pointer;
	border-color: #3c4192;
}
.graphbtncontainer {
	display: flex;
	gap: 5px;
}
img {
	margin-left: 0px !important;
}
.graph-block {
	background-color:#ffffff;
	border-radius: 7px;
	box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
}
.graph-block__content {
	padding: 0.5rem 1.3rem;
}

.site-block {
	position: relative;
}
.site-block select {
	font-size: 1rem;
	padding-left: 0.2rem;
	padding-right: 0.7rem;
	background-color: transparent;
	outline: none;
}
.select-border {
	display: block;
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 0;
	background-color: red;
	height: 1px;
	width: 0%;
	transition: width 0.4s ease-in-out;
	z-index: 2;
}
.selectdefault{
  left: 0;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding:0.2rem 0.4rem;
  text-align: center;
  font-size:0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius:3px;
}
.sitecodelabel {
     align-content: center;
    padding-top: 6px;
    margin-left: 104px;
}
.forms-control{
	display: block;
	width: 100%;
	padding: .125rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #676a6c;
	background-color: #fff;
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
	border:1px solid #e7eaec;
    -moz-appearance: none;
    appearance: none;
	-webkit-appearance: none;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.forms-control:focus, .forms-control:focus-visible,
.forms-control:focus-within{
	outline: none;
	border-color:#145faf!important
}
.width-40{
	width:40%;
}
.ibox-content h1{
  font-weight:400;
  color:#676a6c
}
</style>
