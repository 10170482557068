<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>ML Criteria Capture</h2>
    </div>
    <div class="col-lg-2 align-self-center" v-if="previousRouteName == 'studyProfile' || 'formTemplate'">
      <i class="fa fa-times close my-1" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-md-3">
        <div class="ibox">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h5 class="my-0 align-self-center">Forms</h5>
            <!-- <div
                v-if="rollsprivilages.includes('Designer Tool Create Form')"
                class="position-relative align-self-center"
                aria-label="Create form"
                tabindex="0"
              >
                <img
                  class="cursor-pointer"
                  src="../../assets/img/create.png"
                  alt="Create form"
                  width="20"
                  @click="createForm()"
                />
                <div class="tool-tip">{{ $t("navigations.create form") }}</div>
              </div> -->
          </div>
          <div class="ibox-content p-2">
            <div class="template-body">
              <div class="panel-group" id="accordion">
                <div class="panel panel-default" v-for="form in listforms" :key="form.id">
                  <div class="panel-heading d-flex justify-content-between py-1">
                    <h5 class="panel-title cursor-pointer" tabindex="0" :aria-collapsed="collapseDiv[form.templateId]"
                      :aria-label="form.templateName" :id="form.id" @click.prevent="
                      (collapseDiv[form.templateId] = !collapseDiv[
                        form.templateId
                      ]),
                        getPages(
                          form.templateFormId,
                          form.sourceTemplateId,
                          form.version,
                          form.templateName
                        )
                        ">
                      {{ form.templateName }}
                    </h5>
                    <div class="expand-delete-block cursor-pointer align-self-center position-relative px-2"
                      tabindex="0" aria-label="Expand options" @click.prevent="
                        getPages(
                          form.templateFormId,
                          form.sourceTemplateId,
                          form.version,
                          form.templateName
                        ),
                        (showSideModal[form.templateId] = !showSideModal[
                          form.templateId
                        ])
                        " v-on:clickout="showSideModal[form.templateId] = false">
                      <i class="fa fa-ellipsis-v"></i>
                      <div class="side-popup position-absolute py-2" v-if="
                        showSideModal[form.templateId] &&
                        this.rollsprivilages.includes(
                          'Designer Tool Create Form'
                        )
                      ">
                        <div class="d-flex px-3" @click.prevent="
                          createnewversion(
                            form.templateFormId,
                            form.version,
                            form.sourceTemplateId,
                            form.templateName
                          )
                          ">
                          <i class="fa fa-code-fork edit-delete align-self-center pr-2"></i>
                          <span>Create new version</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="panel-collapse">
                    <div class="template-body">
                      <ul class="template-list px-0 mb-0">
                        <!-- {{ pages }} -->
                        <!-- <li v-for="page in pages[form.id]" :key="page.pageNo" class="px-3 cursor-pointer" :id="page.pageName" @click="changepage(form.id,page.pageNo,page.pageName)" -->
                        <li v-for="page in pages[form.sourceTemplateId]" :key="page.pageNo" class="px-3 cursor-pointer"
                          :id="page.pageName + form.sourceTemplateId" @click="
                            changepage(
                              form.templateId,
                              pages[form.sourceTemplateId],
                              page,
                              form.sourceTemplateId,
                              form.version,
                              form.templateName
                            )
                            " :class="{
                              'py-2': collapseDiv[form.templateId],
                              'max-height-30': collapseDiv[form.templateId],
                              'max-height-0': !collapseDiv[form.templateId],
                            }">
                          {{ page.pageName }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-10 maincontents py-3" v-if="showForm == true">
            <div class="ibox ibox-content-edit-toolbar d-flex justify-content-between align-items-center"
              @click.prevent="columnselected = false">
              <h4 class="my-0">{{ formname }}</h4>
              <div class="text-right d-flex" v-if="rollsprivilages.includes('Designer Tool Create Form')">
                <div class="d-flex">
                  <span class="mr-2">Version</span>
                  <select v-model="version" class="form-control mr-2 version" @change="handleDropdownChange()">
                    <option v-for="versions in versionhistoryarray" :key="versions.version" :value="versions.version">
                      {{ versions.version }}
                    </option>
                  </select>
                  <span id="validationtooltip" class="pointer">
                    Maximum character limit has been reached
                  </span>
                </div>
                <div v-if="temparray.length != 0 && !isformlocked" class="position-relative">
                  <img class="cursor-pointer me-3" src="../../assets/save_fd.svg" alt="Save template"
                    @click.prevent="savetestfun()" />
                  <div class="tool-tip">Save Form</div>
                </div>
                <div v-if="temparray.length != 0 && !isformlocked" class="position-relative">
                  <img class="cursor-pointer me-3" src="../../assets/delete_fd.svg" alt="Delete Page"
                    @click.prevent="deletepagefun()" />
                  <div class="tool-tip">Delete Page</div>
                </div>
                <div v-if="temparray.length != 0 && !isformlocked" class="position-relative">
                  <img class="cursor-pointer me-3" src="../../assets/delete_col_fd.svg" alt="Delete Column"
                    @click.prevent="deletegroupfun()" />
                  <div class="tool-tip">Delete Column</div>
                </div>
                <!-- view template -->
                <div class="position-relative" v-if="
                  (viewtempicon && temparray.length != 0 && !isformlocked) ||
                  newversionViewTemp
                ">
                  <img class="cursor-pointer me-3" src="../../assets/view_temp_fd.svg" alt="View Template"
                    @click.prevent="viewtemplatefun()" />
                  <div class="tool-tip">View Form</div>
                </div>
                <div class="position-relative" v-if="status == `New` || !isformlocked">
                  <img class="cursor-pointer" src="../../assets/design_complete_fd.svg" alt="Design Complete"
                    @click.prevent="designcompletepopupfun()" />
                  <div class="tool-tip">Design Complete</div>
                </div>

                <!-- <a class="me-2 savebutton" href="#" @click.prevent="updatefun('update')"
                  data-toggle="tooltip" data-placement="top" title="Update Template"><img src="../../assets/update.png" alt="" /></a> -->
              </div>
            </div>
            <div class="maincontainer" @drop="drop($event, elementUsed)" @dragenter.prevent @dragover.prevent>
              <div v-for="input in temparray" :key="input">
                <div class="mainbgchange getcontents" v-for="(item, key) in input.pages" :key="key">
                  <form>
                    <div class="form-group">
                      <div>
                        <div v-if="item.pageNo == pageno" class="pageContainer mt-2">
                          <div :class="{
                            elements_container: item.Group.length > 2,
                          }">
                            <div class="groupsdiv mainbgchange" v-for="(groupno, key) in item.Group" :key="key">
                              <div class="flexContainer mainbgchange">
                                <template v-for="(newfield, key) in groupno.field" :key="key">
                                  <div v-if="newfield" class="flex-sub-container mainbgchange">
                                    <!-- headder starts -->
                                    <div :class="highlitedcolumn == newfield.fieldName
                                        ? 'dummydata dummydataenter'
                                        : 'dummydata'
                                      " tabindex="0" v-if="newfield.type == 'blankdata'"
                                      :data-selected="columnselected" @dragenter="selectspot(newfield, groupno)"
                                      @click.prevent="
                                        selectspot(newfield, groupno)
                                        "></div>
                                    <!-- headder ends -->
                                    <!-- headder starts -->
                                    <div v-if="newfield.type == 'header'" class="headerdiv" @click.prevent="
                                      inputfieldedit(newfield, groupno)
                                      ">

                                      <div class="text-center">
                                        <h4>{{ newfield.label }}</h4>
                                      </div>
                                    </div>
                                    <!-- headder ends -->
                                    <!-- input starts -->
                                    <div v-else-if="inputElements.includes(newfield.type)" class="flex-item bgblue">
                                      <div class="labelclass">
                                        {{ newfield.label }}
                                        <sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')"><i
                                            class="fa fa-asterisk imp"></i>
                                        </sup>
                                      </div>
                                      <input class="form-control" :label="newfield.label" type="text"
                                        @click.prevent="inputfieldedit(newfield, groupno)" />
                                    </div>
                                    <!-- input ends -->
                                    <!-- image starts -->
                                    <div v-else-if="newfield.type == 'image'" class="flex-item displayflex"
                                      @click.prevent="
                                        inputfieldedit(newfield, groupno)
                                        ">
                                      <div>
                                        <div class="labelclass marginauto"></div>
                                      </div>
                                      <img class="templatepreview" :src="`${fileuploadurl}/file/${newfield.imagePath.split(',')[0]
                                        }?versionid=${newfield.imagePath.split(',')[1]
                                        }`
                                        " />
                                    </div>
                                    <!-- image ends -->
                                    <!-- scales starts -->
                                    <div v-else-if="newfield.type == 'scales'" class="flex-item">
                                      <div>
                                        <div class="labelclass">
                                          {{ newfield.label }}
                                          <sup v-if="
                                            newfield.validation.includes('required') ||
                                            newfield.validation.includes('Required')">
                                            <i class="fa fa-asterisk imp"></i>
                                          </sup>
                                        </div>
                                        <input class="form-control scaleval" type="number"
                                          :min="parseInt(newfield.limit[0].min)" :max="parseInt(newfield.limit[0].max)"
                                          v-model="newfield.value" @click.prevent="
                                            inputfieldedit(newfield, groupno)
                                            " />
                                        <input type="range" class="rangeclass" :min="parseInt(newfield.limit[0].min)"
                                          :max="parseInt(newfield.limit[0].max)" step="1" v-model="newfield.value"
                                          @click.prevent="
                                            inputfieldedit(newfield, groupno)
                                            " />

                                        <span v-text="total"></span>
                                      </div>
                                    </div>
                                    <!-- scales ends -->
                                    <!-- time element starts -->
                                    <div v-else-if="newfield.type == 'time'" class="flex-item bgblue">
                                      <div class="labelclass">{{ newfield.label }}
                                        <sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')"><i
                                            class="fa fa-asterisk imp"></i>
                                        </sup>
                                      </div>
                                      <input class="form-control" classname="time" :label="newfield.label" type="time"
                                        @click.prevent="inputfieldedit(newfield, groupno)" />
                                    </div>
                                    <!-- time element ends -->
                                    <!-- date element starts -->
                                    <div v-else-if="newfield.type == 'date'" class="flex-item bgblue">
                                      <div class="labelclass">
                                        {{ newfield.label
                                        }}<sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')
                                        "><i class="fa fa-asterisk imp"></i></sup>
                                      </div>
                                      <input class="form-control" classname="date" :label="newfield.label" type="date"
                                        @click.prevent="
                                          inputfieldedit(newfield, groupno)
                                          " />
                                    </div>
                                    <!-- date element ends -->
                                    <!-- dateandtime element starts -->
                                    <div v-else-if="newfield.type == 'dateandtime'" class="flex-item bgblue">
                                      <div class="labelclass">
                                        {{ newfield.label
                                        }}<sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')
                                        "><i class="fa fa-asterisk imp"></i></sup>
                                      </div>
                                      <input class="form-control" classname="date" :label="newfield.label"
                                        type="datetime-local" @click.prevent="
                                          inputfieldedit(newfield, groupno)
                                          " />
                                    </div>
                                    <!-- date element ends -->
                                    <!-- text area starts -->
                                    <div class="elem" v-else-if="
                                      (newfield.type == 'description') |
                                      (newfield.type == 'textarea')
                                    ">
                                      <div class="txtarealabel">
                                        <label :for="newfield.fieldName" class="textsize mb-0">{{ newfield.label
                                          }}<sup v-if="
                                            newfield.validation.includes('required') ||
                                            newfield.validation.includes('Required')
                                          "><i class="fa fa-asterisk imp"></i></sup></label>
                                      </div>
                                      <div class="txtar">
                                        <textarea :id="newfield.fieldName" :name="newfield.fieldName" class="textbox"
                                          @click.prevent="
                                            inputfieldedit(newfield, groupno)
                                            " />
                                      </div>
                                    </div>
                                    <!-- text area ends -->
                                    <!-- checkboxes starts -->
                                    <div v-else-if="newfield.type == 'checkbox'"
                                      class="form-group sticktobottom checkboxcontainer" @click.prevent="
                                        inputfieldedit(newfield, groupno)
                                        ">
                                      <div class="pb-2">
                                        <input type="checkbox" :id="newfield.fieldName"
                                          :name="newfield.fieldName" /><span class="labelclass ml-2">
                                          {{ newfield.label }}

                                        </span>
                                      </div>
                                    </div>
                                    <!-- checkboxes ends -->
                                    <!-- radio button starts -->
                                    <div v-else-if="newfield.type == 'radio'"
                                      class="form-group labelclass sticktobottom" @click.prevent="
                                        inputfieldedit(newfield, groupno)
                                        ">
                                      <div class="radiogroupclass">
                                        {{ newfield.label
                                        }}<sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')
                                        "><i class="fa fa-asterisk imp"></i></sup>
                                        <div class="radiogroupclass" v-for="options in newfield.options" :key="options">
                                          <input type="radio" class="mr-2" :name="newfield.fieldName"
                                            :id="newfield.fieldName" />{{ options.optionname }}
                                        </div>
                                      </div>
                                    </div>
                                    <!-- radio button ends -->
                                    <!-- radio button horizondal starts -->
                                    <div v-else-if="
                                      newfield.type == 'radiohorizondal'
                                    " class="form-group labelclass sticktobottom" @click.prevent="
                                          inputfieldedit(newfield, groupno)
                                          ">
                                      <div class="radiohorizondalclass">
                                        <div class="radiolabelclass">
                                          {{ newfield.label }}
                                          <sup v-if="
                                            newfield.validation.includes('required') ||
                                            newfield.validation.includes('Required')"><i
                                              class="fa fa-asterisk imp"></i>
                                          </sup>
                                        </div>
                                        <div class="radiogroupclass" v-for="options in newfield.options" :key="options">
                                          <input type="radio" class="mr-2 ml-2" :name="newfield.fieldName"
                                            :id="newfield.fieldName" />{{ options.optionname }}
                                        </div>
                                      </div>
                                    </div>
                                    <!-- radio button ends -->
                                    <!-- dropdown menu starts -->
                                    <div v-else-if="newfield.type == 'dropdown'" class="flex-item bgblue"
                                      @click.prevent="
                                        inputfieldedit(newfield, groupno)
                                        ">
                                      <div class="labelclass">
                                        {{ newfield.label
                                        }}<sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')
                                        "><i class="fa fa-asterisk imp"></i></sup>
                                      </div>
                                      <div @click.prevent="
                                        inputfieldedit(newfield, groupno)
                                        " class="baseselect mainbgchange selectzindex">
                                        <input class="form-control" :label="newfield.label" :value="newfield.options[0].optionname
                                          " type="text" @click.prevent="
                                              inputfieldedit(newfield, groupno)
                                              " readonly />
                                        <!-- <select
                                            class="form-control dropdownbackground"
                                            disabled="true;"
                                            
                                          >
                                            <option
                                              
                                            >
                                              {{ newfield.options[0] }}
                                            </option>
                                          </select> -->
                                      </div>
                                    </div>
                                    <!-- dropdown menu ends -->
                                    <!-- notes starts -->
                                    <div class="ml-2 pb-2" v-else-if="newfield.type == 'notes'" @click.prevent="
                                      inputfieldedit(newfield, groupno)
                                      ">
                                      <div class="font-15">
                                        <i>{{ newfield.label }}</i>
                                      </div>
                                    </div>
                                    <!-- notes ends -->
                                    <!-- file upload starts -->
                                    <div v-else-if="newfield.type == 'uploadfile'" class="flex-item">
                                      <div class="labelclass">
                                        {{ newfield.label
                                        }}<sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')
                                        "><i class="fa fa-asterisk imp"></i></sup>
                                      </div>
                                      <div class="twoitemflex">
                                        <input class="form-control" :label="newfield.label" type="file" @click.prevent="
                                          inputfieldedit(newfield, groupno)
                                          " />
                                      </div>
                                    </div>
                                    <!-- file upload ends -->
                                    <!-- year starts -->
                                    <div v-else-if="newfield.type == 'year'" class="flex-item">
                                      <div class="labelclass">
                                        {{ newfield.label
                                        }}<sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')
                                        "><i class="fa fa-asterisk imp"></i></sup>
                                      </div>
                                      <input class="form-control" :label="newfield.label" type="number" min="1900"
                                        max="2099" step="1" @click.prevent="
                                          inputfieldedit(newfield, groupno)
                                          " />
                                    </div>
                                    <!-- year ends -->
                                    <!-- calculation starts -->
                                    <div v-else-if="newfield.type == 'calculation'" class="flex-item" @click.prevent="
                                      inputfieldedit(newfield, groupno)
                                      ">
                                      <div class="labelclass">{{ newfield.label }} </div>
                                    </div>
                                    <!-- calculation ends -->
                                    <!-- summery starts -->
                                    <div class="ml-2 pb-2 font-15" v-else-if="newfield.type == 'summary'"
                                      @click.prevent="inputfieldedit(newfield, groupno)">
                                      <i>
                                        {{ newfield.label }}
                                      </i>
                                    </div>
                                    <!-- summery ends -->
                                    <!-- qr code starts -->
                                    <div class="elem" v-else-if="
                                      newfield.type == 'qrcode'
                                    " @click.prevent="
                                                inputfieldedit(newfield, groupno)
                                                ">
                                      <div hidden>
                                        {{
                                          qrcodefun(
                                            newfield.fieldName,
                                            newfield.qrData
                                          )
                                        }}
                                      </div>
                                      <div class="position-relative">
                                        <img class="qrcodeframe" @click.prevent="inputfieldedit(newfield, groupno)"
                                          :src="qrcodetxt[newfield.fieldName]
                                            " title="qr code" />
                                        <div class="overlay" @click.prevent="inputfieldedit(newfield, groupno)"></div>
                                      </div>
                                    </div>
                                    <!-- qr code ends -->
                                    <!-- Grid starts -->
                                    <div class="elem" v-else-if="newfield.type == 'grid'" @click.prevent="
                                      inputfieldedit(newfield, groupno)
                                      ">
                                      <div v-if="
                                        gridoptarray[newfield.fieldName] ==
                                        undefined
                                      " hidden>
                                        {{
                                          gridoptfun(
                                            newfield.fieldName,
                                            newfield.options[0]
                                          )
                                        }}
                                      </div>
                                      <div class="notesstyle gridbackground gridelem">
                                        <div class="ibox-content-edit flextable gridbackground">
                                          <div class="addfieldtable">
                                            <div class="rowcolmn" v-for="(gridrow,
                                              index) in gridoptarray[
                                                newfield.fieldName
                                              ][0].Rows" :key="gridrow">
                                              <div class="rowlabelgrid">
                                                {{ gridrow }}
                                              </div>
                                              <div class="rowcolmnc" v-for="gridcolumn in gridoptarray[
                                                newfield.fieldName
                                              ][0].Columns" :key="gridcolumn">
                                                <div v-if="index == 0">
                                                  {{ gridcolumn }}
                                                </div>
                                                <input class="form-control" v-model="griddata[0].Columns[
                                                  gridcolumn - 1
                                                  ]
                                                  " type="text" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Grid ends -->
                                    <!-- add survey starts -->
                                    <div class="elem" v-else-if="newfield.type == 'addsurvey'">
                                      <div class="survey">
                                        <button type="button" class="btn btn-outline-primary"
                                          @click.prevent="addsurvey()">
                                          {{ newfield.label }}
                                        </button>
                                      </div>
                                    </div>
                                    <!-- add survey ends -->
                                    <!-- numberanddate starts -->
                                    <div v-else-if="
                                      newfield.type == 'numberanddate'
                                    " class="flex-item">
                                      <div class="labelclass">
                                        {{ newfield.label }}<sup v-if="
                                          newfield.validation.includes('required') ||
                                          newfield.validation.includes('Required')
                                        "><i class="fa fa-asterisk imp"></i></sup>
                                      </div>
                                      <div class="d-flex">
                                        <input class="form-control" :label="newfield.label" type="number"
                                          @click.prevent="
                                            inputfieldedit(newfield, groupno)
                                            " />
                                        <input class="form-control" :label="newfield.label" type="date" @click.prevent="
                                          inputfieldedit(newfield, groupno)
                                          " />
                                      </div>
                                    </div>
                                    <!-- numberanddate ends -->
                                    <!-- subHeader starts -->
                                    <div v-else-if="newfield.type == 'subHeader'" class="subheadder" @click.prevent="
                                      inputfieldedit(newfield, groupno)
                                      ">
                                      <h4>{{ newfield.label }}</h4>
                                    </div>
                                    <!-- repeatedmeasure starts -->
                                    <div v-else-if="newfield.type == 'repeatingfield'" :key="newfield">
                                      <h6 class="headerdiv"> {{ newfield.label }} </h6>
                                      <div>
                                        <div class="mainbgchange getcontents"
                                          @click.prevent="editrepeatedmeasure(newfield)">
                                          <form>
                                            <div class="form-group repeatContainer">
                                              <div>
                                                <div class="pageContainer mt-2">
                                                  <div>
                                                    <div>
                                                      <div>
                                                        <div class="flexContainer mainbgchange"
                                                          v-for="(repgroupno, key) in repeatedgroupgroupnos[newfield.fieldName]"
                                                          :key="key">
                                                          <template
                                                            v-for="(newrepeatingFields, newrepkey) in newfield.repeatingFields"
                                                            :key="newrepkey">
                                                            <div v-if="newrepeatingFields.groupOrderNo == repgroupno"
                                                              class="flex-sub-container mainbgchange">
                                                              <!-- headder starts -->
                                                              <div v-if="newrepeatingFields.type == 'header'"
                                                                class="headerdiv">
                                                                <div class="text-center">
                                                                  <h4>{{ newrepeatingFields.label }}</h4>
                                                                </div>
                                                              </div>
                                                              <!-- headder ends -->
                                                              <!-- input starts -->
                                                              <div v-else-if="
                                                                inputElements.includes(newrepeatingFields.type)
                                                              " class="flex-item bgblue">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <input class="form-control"
                                                                  :label="newrepeatingFields.label" type="text" />
                                                              </div>
                                                              <!-- input ends -->
                                                              <!-- image starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'image'"
                                                                class="flex-item displayflex">
                                                                <div>
                                                                  <div class="labelclass marginauto"></div>
                                                                </div>
                                                                <img class="templatepreview" :src="`${fileuploadurl}/file/${newrepeatingFields.imagePath.split(',')[0]
                                                                  }?versionid=${newrepeatingFields.imagePath.split(',')[1]
                                                                  }`
                                                                  " />
                                                              </div>
                                                              <!-- image ends -->
                                                              <!-- scales starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'scales'"
                                                                class="flex-item">

                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label }}
                                                                  <sup
                                                                    v-if="
                                                                      newrepeatingFields.validation.includes('required') ||
                                                                      newrepeatingFields.validation.includes('Required')">
                                                                    <i class="fa fa-asterisk imp"></i>
                                                                  </sup>
                                                                </div>
                                                                <div class="d-flex">
                                                                  <h6>{{ newrepeatingFields.limit[0].min }}</h6>
                                                                  <input type="range" class="rangeclass"
                                                                    :min="parseInt(newrepeatingFields.limit[0].min)"
                                                                    :max="parseInt(newrepeatingFields.limit[0].max)"
                                                                    step="1" v-model="newrepeatingFields.value" />
                                                                  <h6>{{ newrepeatingFields.limit[0].max }}</h6>

                                                                  <div>

                                                                    <input class="form-control scaleval" type="number"
                                                                      :min="parseInt(newrepeatingFields.limit[0].min)"
                                                                      :max="parseInt(newrepeatingFields.limit[0].max)"
                                                                      v-model="newrepeatingFields.value" />

                                                                    <span v-text="total"></span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <!-- scales ends -->
                                                              <!-- time element starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'time'"
                                                                class="flex-item bgblue">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <input class="form-control" classname="time"
                                                                  :label="newrepeatingFields.label" type="time" />
                                                              </div>
                                                              <!-- time element ends -->
                                                              <!-- date element starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'date'"
                                                                class="flex-item bgblue">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <input class="form-control" classname="date"
                                                                  :label="newrepeatingFields.label" type="date" />
                                                              </div>
                                                              <!-- date element ends -->
                                                              <!-- dateandtime element starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'dateandtime'"
                                                                class="flex-item bgblue">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <input class="form-control" classname="date"
                                                                  :label="newrepeatingFields.label"
                                                                  type="datetime-local" />
                                                              </div>
                                                              <!-- date element ends -->
                                                              <!-- text area starts -->
                                                              <div class="elem" v-else-if="
                                                                (newrepeatingFields.type == 'description') |
                                                                (newrepeatingFields.type == 'textarea')
                                                              ">
                                                                <div class="txtarealabel">
                                                                  <label :for="newrepeatingFields.fieldName"
                                                                    class="textsize mb-0">{{ newrepeatingFields.label
                                                                    }}<sup v-if="
                                                                      newrepeatingFields.validation.includes('required') ||
                                                                      newrepeatingFields.validation.includes('Required')
                                                                    "><i
                                                                        class="fa fa-asterisk imp"></i></sup></label>
                                                                </div>
                                                                <div class="txtar">
                                                                  <textarea :id="newrepeatingFields.fieldName"
                                                                    :name="newrepeatingFields.fieldName"
                                                                    class="textbox" />
                                                                </div>
                                                              </div>
                                                              <!-- text area ends -->
                                                              <!-- checkboxes starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'checkbox'"
                                                                class="form-group sticktobottom checkboxcontainer">
                                                                <div class="pb-2">
                                                                  <input type="checkbox"
                                                                    :id="newrepeatingFields.fieldName"
                                                                    :name="newrepeatingFields.fieldName" /><span
                                                                    class="labelclass ml-2">
                                                                    {{ newrepeatingFields.label }}
                                                                    <sup
                                                                      v-if="
                                                                        newrepeatingFields.validation.includes('required') ||
                                                                        newrepeatingFields.validation.includes('Required')"><i
                                                                        class="fa fa-asterisk imp"></i></sup>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <!-- checkboxes ends -->
                                                              <!-- radio button starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'radio'"
                                                                class="form-group labelclass sticktobottom">
                                                                <div class="radiogroupclass">
                                                                  {{ newrepeatingFields.label }}
                                                                  <sup
                                                                    v-if="
                                                                      newrepeatingFields.validation.includes('required') ||
                                                                      newrepeatingFields.validation.includes('Required')"><i
                                                                      class="fa fa-asterisk imp"></i></sup>
                                                                  <div class="radiogroupclass"
                                                                    v-for="options in newrepeatingFields.options"
                                                                    :key="options">
                                                                    <input type="radio" class="mr-2"
                                                                      :name="newrepeatingFields.fieldName"
                                                                      :id="newrepeatingFields.fieldName" />{{
                                                                    options.optionname }}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <!-- radio button ends -->
                                                              <!-- radio button horizondal starts -->
                                                              <div v-else-if="
                                                                newrepeatingFields.type == 'radiohorizondal'
                                                              " class="form-group labelclass sticktobottom">
                                                                <div class="radiohorizondalclass">
                                                                  <div class="radiolabelclass">
                                                                    {{ newrepeatingFields.label }}
                                                                  </div>
                                                                  <div class="radiogroupclass"
                                                                    v-for="options in newrepeatingFields.options"
                                                                    :key="options">
                                                                    <input type="radio" class="mr-2 ml-2"
                                                                      :name="newrepeatingFields.fieldName"
                                                                      :id="newrepeatingFields.fieldName" />{{
                                                                    options.optionname }}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <!-- radio button ends -->
                                                              <!-- dropdown menu starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'dropdown'"
                                                                class="flex-item bgblue">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <div class="baseselect mainbgchange selectzindex">
                                                                  <input class="form-control"
                                                                    :label="newrepeatingFields.label" :value="newrepeatingFields.options[0].optionname
                                                                      " type="text" readonly />
                                                                </div>
                                                              </div>
                                                              <!-- dropdown menu ends -->
                                                              <!-- notes starts -->
                                                              <div class="ml-2 pb-2"
                                                                v-else-if="newrepeatingFields.type == 'notes'">
                                                                <div class="notesstyle">
                                                                  {{ newrepeatingFields.label }}
                                                                </div>
                                                              </div>
                                                              <!-- notes ends -->
                                                              <!-- file upload starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'uploadfile'"
                                                                class="flex-item">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <div class="twoitemflex">
                                                                  <input class="form-control"
                                                                    :label="newrepeatingFields.label" type="file" />
                                                                </div>
                                                              </div>
                                                              <!-- file upload ends -->
                                                              <!-- year starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'year'"
                                                                class="flex-item">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <input class="form-control"
                                                                  :label="newrepeatingFields.label" type="number"
                                                                  min="1900" max="2099" step="1" />
                                                              </div>
                                                              <!-- year ends -->
                                                              <!-- calculation starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'calculation'"
                                                                class="flex-item">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label }}
                                                                </div>
                                                                {{}}
                                                              </div>
                                                              <!-- calculation ends -->
                                                              <!-- summery starts -->
                                                              <div class="ml-2 pb-2 font-15"
                                                                v-else-if="newrepeatingFields.type == 'summary'">
                                                                <i> {{ newrepeatingFields.label }} </i>
                                                              </div>
                                                              <!-- summery ends -->
                                                              <!-- qr code starts -->
                                                              <div class="elem"
                                                                v-else-if="newrepeatingFields.type == 'qrcode'">
                                                                <div hidden>
                                                                  {{
                                                                    qrcodefun(
                                                                      newrepeatingFields.fieldName,
                                                                      newrepeatingFields.qrData
                                                                    )
                                                                  }}
                                                                </div>
                                                                <div class="position-relative">
                                                                  <img class="qrcodeframe" :src="qrcodetxt[newrepeatingFields.fieldName]
                                                                    " title="qr code" />

                                                                  <div class="overlay"
                                                                    @click.prevent="editrepeatedmeasure(newfield)">
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <!-- qr code ends -->
                                                              <!-- Grid starts -->
                                                              <div class="elem"
                                                                v-else-if="newrepeatingFields.type == 'grid'">
                                                                <div v-if="
                                                                  gridoptarray[newrepeatingFields.fieldName] ==
                                                                  undefined
                                                                " hidden>
                                                                  {{
                                                                    gridoptfun(
                                                                      newrepeatingFields.fieldName,
                                                                      newrepeatingFields.options[0]
                                                                    )
                                                                  }}
                                                                </div>
                                                                <div class="notesstyle gridbackground gridelem">
                                                                  <div
                                                                    class="ibox-content-edit flextable gridbackground">
                                                                    <div class="addfieldtable">
                                                                      <div class="rowcolmn" v-for="(gridrow,
                                                                        index) in gridoptarray[
                                                                          newrepeatingFields.fieldName
                                                                        ][0].Rows" :key="gridrow">
                                                                        <div class="rowlabelgrid">
                                                                          {{ gridrow }}
                                                                        </div>
                                                                        <div class="rowcolmnc" v-for="gridcolumn in gridoptarray[
                                                                          newrepeatingFields.fieldName
                                                                        ][0].Columns" :key="gridcolumn">
                                                                          <div v-if="index == 0">
                                                                            {{ gridcolumn }}
                                                                          </div>
                                                                          <input class="form-control" v-model="griddata[0].Columns[
                                                                            gridcolumn - 1
                                                                            ]
                                                                            " type="text" />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <!-- Grid ends -->
                                                              <!-- numberanddate starts -->
                                                              <div v-else-if="
                                                                newrepeatingFields.type == 'numberanddate'
                                                              " class="flex-item">
                                                                <div class="labelclass">
                                                                  {{ newrepeatingFields.label
                                                                  }}<sup v-if="
                                                                    newrepeatingFields.validation.includes('required') ||
                                                                    newrepeatingFields.validation.includes('Required')
                                                                  "><i class="fa fa-asterisk imp"></i></sup>
                                                                </div>
                                                                <div class="d-flex">
                                                                  <input class="form-control"
                                                                    :label="newrepeatingFields.label" type="number" />
                                                                  <input class="form-control"
                                                                    :label="newrepeatingFields.label" type="date" />
                                                                </div>
                                                              </div>
                                                              <!-- numberanddate ends -->
                                                              <!-- subHeader starts -->
                                                              <div v-else-if="newrepeatingFields.type == 'subHeader'"
                                                                class="subheadder">
                                                                <h4>{{ newrepeatingFields.label }}</h4>
                                                              </div>
                                                            </div>
                                                          </template>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- repeatedmeasure ends -->
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="drag-component-block font-weight-bold text-center py-3" v-if="!isformlocked">
                              Drag elements here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!-- test pdf ends -->
            </div>
            <!------ pagination -------->
            <div class="row mx-0">
              <div class="col-lg-12 d-flex justify-content-lg-end px-0 mt-2">
                <v-pagination v-model="pageno" :pages="totalpages" :range-size="1" active-color="#f4f9ff" />
              </div>
            </div>
            <!-- pagination ends -->
          </div>
          <div class="col-md-2" v-if="
            showForm === true &&
            !isformlocked &&
            rollsprivilages.includes('Designer Tool Create Form')
          ">
            <!-- <div class="ibox-title-elements text-center p-1">
                <h5>ELEMENTS</h5>
              </div> -->
            <!-- <div class="ibox-content-elements p-1">
                <div class="elements_container">
                  <div class="elements-margins p-1">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        @click.prevent="openModel()"
                      >
                        <img
                          class="pr-1"
                          src="../../assets/add_page.svg"
                          alt=""
                        />
                        <h5 class="font-bold">New page</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class=" ">
                    <h5 class="px-1 w-100 text-center mt-1">
                      <b>FIELD ALIGNMENT</b>
                    </h5>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'addone')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/img/one_col.svg"
                          alt=""
                        />
                        <h5 class="font-bold">One</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'addtwo')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/img/two_col.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Two</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'addthree')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/img/three_col.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Three</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'addfour')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/img/four_col.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Four</h5>
                      </div>
                    </a>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'addsix')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/img/six_col.svg"
                          alt="Six column"
                        />
                        <h5 class="font-bold">Six</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="">
                    <h5 class="mx-2 text-center mt-1"><b>LABELS</b></h5>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'header')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/head.svg" alt="" />
                        <h5 class="font-bold">Heading</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'subHeader')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/sub_hed.svg" alt="" />
                        <h5 class="font-bold">Sub heading</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'summary')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
  
                        <h5 class="font-bold">Notes/Summary</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="">
                    <h5 class="mx-2 text-center mt-1"><b>CONTROLS</b></h5>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'textbox')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/text_box.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Text box</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'dropdown')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/drop_down.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Drop down</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'radio')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/radio_button.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Radio button</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'checkbox')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/chek_box.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Check box</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'date')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/date.svg" alt="" />
                        <h5 class="font-bold">Date</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'time')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/time.svg" alt="" />
                        <h5 class="font-bold">Time</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'textarea')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/text_area.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Text area</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'dateandtime')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/date_time.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Date and time</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'grid')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/grid.svg" alt="" />
                        <h5 class="font-bold">Grid</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'image')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <input
                          id="fileInput"
                          type="file"
                          class="hiddenfile"
                          accept="image/png, image/jpeg"
                          @change="helloButton"
                        />
                        <img
                          class="px-2"
                          src="../../assets/img_upload.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Image</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'uploadfile')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/upload_file.svg"
                          alt=""
                        />
                        <h5 class="font-bold">File Uploader</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'scales')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/scales.svg" alt="" />
                        <h5 class="font-bold">Scales</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'year')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/year.svg" alt="" />
                        <h5 class="font-bold">Year</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'qrcode')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img class="px-2" src="../../assets/qr_code.svg" alt="" />
                        <h5 class="font-bold">QR Code</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'calculation')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <i class="fa fa-calculator" aria-hidden="true"></i>
                        <h5 class="font-bold">Calculation</h5>
                      </div>
                    </a>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'repeatingfield')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <i
                          class="fa fa-thermometer-quarter"
                          aria-hidden="true"
                        ></i>
                        <h5 class="font-bold">Repeated measures</h5>
                      </div>
                    </a>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'model')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <i class="fa fa-building-o" aria-hidden="true"></i>
  
                        <h5 class="font-bold">Option Group</h5>
                      </div>
                    </a>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'numberanddate')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <img
                          class="px-2"
                          src="../../assets/number_date.svg"
                          alt=""
                        />
                        <h5 class="font-bold">Number and date</h5>
                      </div>
                    </a>
                  </div>
  
                  <div class="">
                    <h5 class="mx-2 text-center mt-1"><b>REPEATED FIELDS</b></h5>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        @click.prevent="createrepeatedfieldpopup()"
                      >
                        <i class="fa fa-repeat" aria-hidden="true"></i>
  
                        <h5 class="font-bold">Create Repeated Fields</h5>
                      </div>
                    </a>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        draggable="true"
                        @dragstart="drag($event, 'repeatedmodel')"
                        @dragenter.prevent
                        @dragover.prevent
                      >
                        <i class="fa fa-files-o" aria-hidden="true"></i>
                        <h5 class="font-bold">Repeated Fields</h5>
                      </div>
                    </a>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        @click="managerepeatedfieldmodel()"
                      >
                        <i class="fa fa-desktop" aria-hidden="true"></i>
  
                        <h5 class="font-bold">Manage Repeated Fields</h5>
                      </div>
                    </a>
                  </div>
                  <div class="">
                    <h5 class="mx-2 text-center mt-1"><b>MODELS</b></h5>
                  </div>
                  
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        @click="openoptmodel()"
                      >
                        <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        <h5 class="font-bold">Create Option Group</h5>
                      </div>
                    </a>
                  </div>
                  <div class="elements-margins">
                    <a>
                      <div
                        class="widget text-center elements-tools-bg p-1 m-auto"
                        @click="manageoptiongroupmodel()"
                      >
                        <i class="fa fa-briefcase" aria-hidden="true"></i>
                        <h5 class="font-bold">Manage Option Group</h5>
                      </div>
                    </a>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal starts -->
  <!-- design complete starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="designcompletepopup">
      <div class="option_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Design Complete</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closedesigncompletemodal()"></i>
          </a>
        </div>

        <div class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete">
          <div class="mt-4 form-check">
            <label class="form-check-label ml-4">
              On completing the design means ,the form is locked and there is no
              further amendment.
            </label>
          </div>
          <div class="mt-4 form-check">
            <input type="checkbox" v-model="reviewandapprovalvar" id="reviewandapproval" class="form-check-input"
              @change="handleCheckboxChange()" />
            <label class="form-check-label ml-4" for="reviewandapproval">
              Send the form to review and approval
            </label>
          </div>

          <div v-if="this.versionhistoryarray.length != 1" class="mt-4 form-check">
            <input type="checkbox" v-model="replacetemplatevar" id="replacetemplate" class="form-check-input"
              :disabled="reviewandapprovalvar" />
            <label class="form-check-label ml-4" for="replacetemplate">
              Replace the existing template on the visit schedules of the
              ongoing patients
            </label>
          </div>
          <div v-if="this.versionhistoryarray.length != 1" class="mt-4 form-check">
            <input type="checkbox" v-model="exisitingvisitsvar" id="exisitingvisits" class="form-check-input"
              :disabled="reviewandapprovalvar" />
            <label class="form-check-label ml-4" for="exisitingvisits">
              Replace the template on the existing study visits
            </label>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <button type="button" class="btn btn-primary mr-2" @click.prevent="closedesigncompletemodal()">
              Cancel
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="designcomplete()">
              Design Complete
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- design complete ends -->
  <!-- repeat measures starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="repeatedmeasurepopup">
      <div class="option_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Manage Repeated Measures</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closerepeatedmeasuremodal()"></i>
          </a>
        </div>

        <div class="form-group col-lg-10 textgroup">
          <label for="optionscheckbox">ID</label>
          <div class="optgroup">
            <input type="text" class="form-control" id="optionscheckbox" v-model="repratmeasureid"
              placeholder="options" />
          </div>
        </div>
        <div class="form-group col-lg-10 mx-auto" v-if="!repeatedmeasurelistpopup">
          <div v-for="measureopts in repeatedoptions" :key="measureopts" class="form-group col-lg-10 textgroup">
            <label for="optionscheckbox">{{ measureopts.fieldName }}</label>
            <div class="optgroup">
              <input type="text" class="form-control" v-model="temprepeatedmeasureval[measureopts.fieldName]"
                id="optionscheckbox" placeholder="options" />
            </div>
          </div>

          <div class="form-group col-lg-10 textgroup">
            <button type="button" class="btn btn-primary float-right ml-1" @click.prevent="addrepeatemesureentries()">
              Add
            </button>
            <button type="button" class="btn btn btn btn-secondary float-right" @click.prevent="repeatedmeasurlist()">
              Back
            </button>
          </div>
        </div>
        <div class="form-group d-flex flex-column col-lg-10 mx-auto maxheight" v-if="repeatedmeasurelistpopup">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>Repeated Measure Name</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in repeatedmeasurevalarray" :key="item.ID">
                  <td>{{ item.ID }}</td>
                  <td class="text-center">
                    <i class="fa fa-trash edit-delete" aria-hidden="true" @click="deleterepeatedmeasure(item)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group col-lg-10 mt-auto">
            <button type="button" class="btn btn-primary mr-2" @click.prevent="repeatedmeasurdatapopup()">
              Add Measures
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="saverepeatedmeasurdata()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- repeat measures ends -->
  <!-- repeat modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="createrepeatedmodel">
      <div class="option_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Manage Repeated Model</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closerepeatingmodel()"></i>
          </a>
        </div>

        <div class="modal-body" v-if="!addrepeatedoptfield">
          <label>Repeated Group Name</label>

          <input id="repeatingGroup" v-model="repeatedtemplatename" name="repeatingGroup" type="text"
            class="form-control required" aria-required="true" />

          <button type="button" class="btn btn-default btn-xs mt-3 px-4 w-100"
            @click.prevent="addrepeatedoptionfield()">
            + Add Field
          </button>

          <table class="table table-bordered mt-3 mb-3">
            <thead>
              <tr>
                <th width="80%">Field Name</th>

                <th width="10%" class="text-center">Delete</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in repeatedtemplatefieldarray" :key="item.formId">
                <td>{{ item.fieldName }}</td>
                <td class="text-center">
                  <i class="fa fa-trash edit-delete" aria-hidden="true" @click="removedrepeatedoptions(index)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer" v-if="!addrepeatedoptfield">
          <button type="button" class="btn btn-primary btn-xs px-4 float-right mr-3"
            @click.prevent="saverepeatedoptions()">
            Save
          </button>
        </div>
        <div class="modal-body" v-if="addrepeatedoptfield">
          <label class="mt-2">Field Name</label>
          <input v-model="repeatingfieldname" id="fieldName" name="fieldName" type="text" class="form-control required"
            aria-required="true" />
          <label class="mt-2">Field type</label>
          <select class="form-control" v-model="repeatingtype" name="fieldtype" id="fieldType">
            <option value="text">text</option>
            <option value="number">number</option>
            <option value="time">time</option>
            <option value="date">date</option>
            <option value="radio">radio</option>
            <option value="dropdown">dropdown</option>
          </select>
          <div v-if="
            repeatingtype == 'radio' ||
            repeatingtype == 'checkbox' ||
            repeatingtype == 'dropdown'
          " class="form-group col-lg-12 mx-auto textgroup">
            <label for="optionscheckbox">Add Options</label>
            <div class="optgroup">
              <input type="text" class="form-control opttext" v-model="repeatoptionss" placeholder="options" />
              <button type="button" class="btn btn-primary" @click.prevent="addrepeatedopts()">
                Add
              </button>
            </div>
          </div>
          <div v-if="
            repeatingtype == 'radio' ||
            repeatingtype == 'checkbox' ||
            repeatingtype == 'dropdown'
          " class="form-group col-lg-12 mx-auto">
            <label for="optionslistcheckbox">Options</label>
            <select multiple class="form-control horizondalscroll">
              <option v-for="options in repeatedoptions" :key="options" disabled>
                {{ options }}
              </option>
            </select>
          </div>
        </div>

        <div class="modal-footer" v-if="addrepeatedoptfield">
          <div class="float-right">
            <button type="button" class="btn btn-default btn-xs px-4 mr-2" @click.prevent="cancelrepeatedoptionfield()">
              Back
            </button>
            <button type="button" class="btn btn-primary btn-xs px-4" @click.prevent="addrepeatedoptions()">
              Save Field
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- repeat modal ends -->
  <!-- modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="managerepeatfieldmodel">
      <div class="option_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Manage Repeated Group</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemanagerepeatmodel()"></i>
          </a>
        </div>

        <div class="form-group col-lg-10 pt-4">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>Repeated Group</th>
                  <!-- <th>Edit</th> -->
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in presavedrepeatedmodel" :key="item">
                  <td>{{ item.templateName }}</td>
                  <!-- <td class="text-center">
                      <i
                        class="fa fa-edit edit-delete"
                        @click.prevent="viewoptiongroup(item.id)"
                      ></i>
                    </td> -->
                  <td class="text-center">
                    <i class="fa fa-trash edit-delete" aria-hidden="true" @click="deleterepeatmodel(item.id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->
  <!-- modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="manageoptoptmodel">
      <div class="option_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Manage Option Group</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemanageoptmodel()"></i>
          </a>
        </div>

        <div class="form-group col-lg-12 textgroup">
          <label for="optionscheckbox">Search</label>
          <div class="optgroup">
            <input type="text" class="form-control opttext" v-model="optiongroupsearch" id="optionscheckbox"
              placeholder="Options" />
            <button type="button" class="btn btn-primary" @click.prevent="searchoptiongroups()">
              Search
            </button>
          </div>
        </div>
        <div class="form-group col-lg-12 mx-auto">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>Option Group</th>
                  <th class="text-center">Edit</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in optiongroups.results" :key="item.formId">
                  <td>{{ item.label }}</td>
                  <td class="text-center">
                    <i class="fa fa-edit edit-delete" @click.prevent="viewoptiongroup(item.id)"></i>
                  </td>
                  <td class="text-center">
                    <i class="fa fa-trash edit-delete" aria-hidden="true" @click="deleteoptiongroup(item.id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->
  <!-- modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="showoptmodel">
      <div class="option_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Create Option Group</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closeoptmodel()"></i>
          </a>
        </div>
        <div class="p-3">
          <!-- <div class="form-group col-lg-10 mx-auto textgroup"> -->
          <div class="form-group col-lg-12">
            <label for="instructionsdropdown">Option group name</label>
            <input type="text" class="form-control" v-model="optiongroup" maxlength="30" id="instructionsdropdown"
              placeholder="Field name" />
          </div>

          <div class="form-group col-lg-12">
            <label for="instructionsdropdown" class="mr-4">Default type</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" value="radio" v-model="optiontype" type="radio" name="flexRadioDefault"
                id="defaultoptiongroup" />
              <label class="form-check-label" for="flexRadioDefault1">
                Radio
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" value="dropdown" v-model="optiontype" type="radio" name="flexRadioDefault"
                id="defaultoptiongroup" />
              <label class="form-check-label" for="flexRadioDefault2">
                Dropdown
              </label>
            </div>
          </div>

          <!--  new code starts-->
          <div class="form-group col-lg-12 textgroup">
            <label for="optionscheckbox">Add Options</label>
            <div class="row mb-3">
              <div class="col-lg-3">
                <!-- radiovalues changed to singlemodelvalues below-->
                <label class="mb-0">Code Value</label>
              </div>
              <div class="col-lg-9 pl-0 mb-2">
                <input ref="codeValueInput" type="text" class="form-control" v-model="singlemodelvalues" maxlength="50"
                  style="width:100%" />
              </div>

              <div class="col-lg-3 pr-0">
                <label class="mb-0">Option Name</label>
              </div>
              <div class="col-lg-9 pl-0">
                <!-- radiooptions changed to optiongroupopts below-->
                <input ref="optionNameInput" type="text" class="form-control" v-model="optiongroupopts"
                  id="optionscheckbox" maxlength="50" style="width:100%" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-5">
                <!--addradiooptions() changed to addroptiongroupopts() -->
                <div style="display: flex;">
                  <button type="button" class="save_btn px-3 py-1" @click.prevent="addroptiongroupopts()"
                    v-if="editingOption === null" :disabled="singlemodelvalues == '' ||
                      singlemodelvalues == null ||
                      optiongroupopts == '' ||
                      optiongroupopts == null
                      ">
                    Add
                  </button>
                  <!-- Update button -->
                  <button type="button" class="save_btn px-3 py-1" @click.prevent="UpdateOGoptions()" v-else>
                    Update
                  </button>
                  <div style="margin-left: 10px;"></div>
                  <!-- Cancel button -->
                  <button type="button" class="save_btn px-3 py-1" @click.prevent="cancelEditDropdown()"
                    v-if="editingOption !== null" style="background-color: red; color: white;">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- new codde ends here -->

          <!-- Old add options starts here -->

          <!-- <div class="form-group col-lg-10 mx-auto textgroup">
              <label for="optionscheckbox">Add Options</label>
              <div class="row">
                <div class="col-lg-5">
                  <input
                    type="text"
                    class="form-control"
                    v-model="singlemodelvalues"
                    placeholder="Code value"
                  />
                </div>
                <div class="col-lg-5">
                  <input
                    type="text"
                    class="form-control"
                    v-model="optiongroupopts"
                    id="optionscheckbox"
                    placeholder="Option name"
                  />
                </div>
                <div class="col-lg-2">
                  <button
                    type="button"
                    class="save_btn px-3 py-1"
                    @click.prevent="addroptiongroupopts()"
                    :disabled="
                      singlemodelvalues == '' ||
                        singlemodelvalues == null ||
                        optiongroupopts == '' ||
                        optiongroupopts == null
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </div> -->
          <!--Old add options end here -->
          <!-- new tablestructure below -->
          <div class="form-group col-lg-12 textgroup" style="overflow-y: auto; max-height: 250px;">
            <!-- old val of table -->
            <!-- v-for="option in radiooptionsarray"
                            :key="option.codevalue" -->
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Code Value</th>
                  <th>Option Name</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="options in groupoptions" :key="options">
                  <td>{{ options.codevalue }}</td>
                  <td>{{ options.optionname }}</td>
                  <td>
                    <i class="fa fa-edit edit-delete" style="color: #a13b7c" @click="
                      editOGOptions(options.codevalue, options.optionname);
                    focusOnEdit();
                    "></i>
                  </td>
                  <td>
                    <i class="fa fa-trash edit-delete" style="color: #a13b7c"
                      @click="deleteOGoptions(options.codevalue)"></i>
                  </td>
                </tr>
              </tbody>
            </table>

            <span class="error-message" v-if="dropdownError">Please add options for the dropdown</span>
          </div>

          <!-- new table strcut ends here -->
          <!--Old options select area start here -->
          <!-- <div class="form-group col-lg-10 mx-auto">
              <label for="optionslistcheckbox">Options</label>
              <select
                multiple
                class="form-control horizondalscroll"
                id="exampleFormControlSelect2"
              >
                <option v-for="options in groupoptions" :key="options" disabled>
                  ({{ options.codevalue }}){{ options.optionname }}
                </option>
              </select>
            </div> -->
          <!--Old options select area end here -->
          <div class="form-group col-lg-10 mx-auto text-center">
            <button v-if="optiongroupeditid != '' && optiongroupeditid != null" type="button" class="save_btn mb-3"
              @click.prevent="editoptiongroup()">
              Submit
            </button>
            <button v-if="optiongroupeditid == '' || optiongroupeditid == null" type="button" class="save_btn mb-3"
              @click.prevent="creatoptiongroup()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->
  <!-- modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="showModalll == 1">
      <div class="modal-dialog w-100" style="top: 50px">
        <div class="modal-content">
          <div class="modal-header add-block-title px-3 py-2">
            <h4 class="my-0">Add Page</h4>
            <a class="close-link">
              <i class="fa fa-times" @click.prevent="closemodall()"></i>
            </a>
          </div>
          <div class="modal-body">
            <input type="text" class="form-control" v-model="pagename" placeholder="Enter page name"
              aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <div class="modal-footer py-1">
            <button type="button" class="btn btn-xs py-0 float-right" style="color: #ffffff; background-color: #768dd3"
              @click.prevent="createpage()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->
  <!-- modal 2 starts -->
  <teleport to="#app">
    <div class="modal__wrapper__component" v-if="componentModal">
      <div class="component__modal__containers">
        <div class="titleclass d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Add Component</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemodaltwo()"></i>
          </a>
        </div>
        <div id="modalcontents" class="modalcontents">
          <div class="row">
            <div class="col-lg-12">
              <!-- textbox starts -->
              <!-- <div v-if="selectedmenu == 'textbox' || selectedmenu == 'text'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Textbox</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textboxtext">Text Box Label</label>
                    <input type="text" class="form-control" v-model="labeltext" id="textboxtext"
                      placeholder="Enter textbox label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextbox">Field Name</label>
                    <input type="text" class="form-control" v-model="fieldname" maxlength="30" id="instructionstextbox"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" @change="valuesChanged()"
                      :options="options" :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="form-group textgroup" v-if="values.length != 0 && values.includes('lengthrange')">
                    <div class="optgroup">
                      <div>
                        <label>min length</label>
                        <input v-model="minlength" type="number" class="form-control" placeholder="min length" />
                        <span v-if="rangeErrors[0] == true" class="errormsg"> Please enter a value</span>
                      </div>
                      <div>
                        <label>max length</label>
                        <input v-model="maxlength" type="number" class="form-control" placeholder="max length" />
                        <span v-if="rangeErrors[1] == true" class="errormsg"> Please enter a value</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group textgroup" v-if="values.length != 0 && values.includes('valuerange')">
                    <div class="optgroup">
                      <div>
                        <label>min value</label>
                        <input v-model="minvalue" type="number" class="form-control" placeholder="min value" />
                        <span v-if="rangeErrors[2] == true" class="errormsg"> Please enter a value</span>
                      </div>
                      <div>
                        <label>max value</label>
                        <input v-model="maxvalue" type="number" class="form-control" placeholder="max value" />
                        <span v-if="rangeErrors[3] == true" class="errormsg"> Please enter a value</span>
                      </div>
                    </div>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('textbox')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- textbox ends dropdown -->
              <!-- Grid starts -->
              <div v-if="selectedmenu == 'grid'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Grid</h5>
                  <nav class="nav nav-pills flex-column flex-sm-row">
                    <a :class="gridgeneral" aria-current="page" @click.prevent="selectconf('general')">General</a>
                    <a :class="gridconfiguration" @click.prevent="selectconf('configuration')">Configuration</a>
                  </nav>
                </div>
                <div v-if="gridoption == 'general'" class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Grid Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter grid label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                </div>
                <div v-if="gridoption == 'configuration'">
                  <div class="ibox-content-edit flextable">
                    <div class="addfieldtable">
                      <div>Row Label</div>
                      <!-- <div v-for="repeatingdatas in repeatingdata" :key="repeatingdatas" class="col-md-12 d-flex justify-content-end distancebetween"> -->
                      <div class="rowcolmn" v-for="(gridrow, index) in gridrows" :key="gridrow">
                        <input class="form-control" v-model="griddata[0].Rows[index]" type="text" />
                        <div v-if="gridrow == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementrows()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removerowsrows(index)"></i>
                        </div>
                      </div>
                    </div>
                    <div class="addfieldtable">
                      <div>Column Label</div>
                      <div class="rowcolmn" v-for="(gridcolumn, index) in gridcolumns" :key="gridcolumn">
                        <input class="form-control" v-model="griddata[0].Columns[index]" type="text" />
                        <div v-if="gridcolumn == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementcolumns()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removecolumns(index)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-10 rowfieldtype" v-for="(gridrow, index) in gridrows" :key="gridrow">
                    <label>Field Type of Row {{ gridrow }}</label>
                    <select class="col-md-10 form-control" v-model="griddata[0].Fieldtypes[index]" name="fieldtype">
                      <option value="text">text</option>
                      <option value="number">number</option>
                      <option value="time">time</option>
                      <option value="date">date</option>
                    </select>
                  </div>
                </div>
                <div class="my-4 row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <div class="position-relative" v-if="!isedit">
                      <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                        @click.prevent="addfield('grid')" />
                      <div class="tool-tip">Add</div>
                    </div>
                    <div class="position-relative" v-if="isedit">
                      <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                        @click="editfield()" />
                      <div class="tool-tip">Save</div>
                    </div>
                    <div class="position-relative mr-4">
                      <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                        @click.prevent="deletecomponent()" />
                      <div class="tool-tip">Delete</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid ends  -->
              <!-- model starts -->
              <div v-if="selectedmenu == 'model'" class="fieldentry">
                <!-- <div class="ibox-modal-title">
                    <h5>Model</h5>
                  </div> -->
                <div class="ibox-content-edit">
                  <div class="form-group textgroup">
                    <label class="col-form-label" for="instructionstext">Select model</label>
                    <select class="form-control horizondalscroll" id="exampleFormControlSelect2" v-model="selectedmodel"
                      @change.prevent="modelchange()">
                      <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                        {{ models.label }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group textgroup">
                    <label for="optionslistcheckbox">Options</label>
                    <select multiple class="form-control horizondalscroll" id="exampleFormControlSelect2">
                      <option v-for="options in selectedmodel.groupoptions" :key="options" disabled>
                        ({{ options.codevalue }}){{ options.optionname }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row">
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodelvalues" placeholder="Code value"
                          maxlength="50" />
                      </div>
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodeloptions" id="optionscheckbox"
                          placeholder="Option name" maxlength="50" />
                      </div>
                      <div class="col-lg-2">
                        <button type="button" class="save_btn px-3 py-1" @click.prevent="addmodeloptions()" :disabled="singlemodelvalues == '' ||
                          singlemodelvalues == null ||
                          singlemodeloptions == '' ||
                          singlemodeloptions == null
                          ">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label" for="modelelem">Select Control Type</label>
                    <div class="col-md-12">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="radio" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Radio
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="dropdown" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault2" />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Dropdown
                        </label>
                      </div>
                      <div class="option-error-block" v-if="controlSelectError">
                        Please select a control type
                      </div>
                    </div>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addmodel()" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- model ends -->
              <!-- headding starts -->
              <div v-if="selectedmenu == 'header'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-form-label" for="headdingtext">Add Heading</label>
                      <input type="text" v-model="labeltext" class="form-control" id="headdingtext"
                        placeholder="Enter Heading" />
                    </div>
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label class="col-form-label" for="instructionstext"
                        >Field Name</label
                      >
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('header')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- headding ends -->
              <!-- subheading starts -->
              <div v-if="selectedmenu == 'subHeader'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Sub Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="subheaddingtext">Add Sub heading</label>
                    <input type="text" v-model="labeltext" class="form-control" id="subheaddingtext"
                      placeholder="Enter Heading" />
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label for="instructionstext">Field Name</label>
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('subHeader')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- subheading ends -->

              <!-- summary starts -->
              <div v-if="selectedmenu == 'summary'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Summary</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textboxtext">Summary</label>
                    <textarea class="form-control" v-model="labeltext" id="textboxtext"
                      placeholder="Enter summary label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextbox">Field Name</label>
                    <input type="text" class="form-control" v-model="fieldname" id="instructionstextbox"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('summary')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- summary ends  -->
              <!-- date starts -->
              <div v-if="selectedmenu == 'date'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Date Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('date')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- date ends dropdown -->
              <!-- scales starts -->
              <div v-if="selectedmenu == 'scales'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Scale</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textboxtext">Scale Label</label>
                    <input type="text" class="form-control" v-model="labeltext" id="textboxtext"
                      placeholder="Enter scale label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextbox">Field Name</label>
                    <input type="text" class="form-control" v-model="fieldname" maxlength="30" id="instructionstextbox"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>

                  <div class="form-group textgroup">
                    <div class="optgroup">
                      <div>
                        <label>Min Value</label>
                        <input v-model="scaleminval" type="number" class="form-control" placeholder="Minimum value"
                          @input="clearInput()" />
                        <span class="error-message" v-if="scaleError[2]">Only accepts digits and '-' (minus
                          sign).</span>
                      </div>
                      <div>
                        <label>Max Value</label>
                        <input v-model="scalemaxval" type="number" class="form-control" placeholder="Maximum value"
                          @input="clearInput()" />
                        <span class="error-message" v-if="scaleError[3]">Only accepts digits and '-' (minus
                          sign).</span>
                      </div>
                    </div>
                    <div>
                      <span class="error-message" v-if="scaleError[0]">Values entered on min value and max value fields
                        cannot
                        be same.</span>
                      <span class="error-message" v-if="scaleError[1]">Value entered on min value must be lower than
                        value
                        entered on max value field.</span>
                    </div>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('scales')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- scale ends  -->
              <!-- calculation starts -->
              <div v-if="selectedmenu == 'calculation'" class="fieldentry">
                <div class="ibox-title">
                  <h5>calculation</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="calculatedfieldtext">Calculation Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="calculatedfieldtext"
                      placeholder="Enter calculated field label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionscalculated">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionscalculated"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label for="timetext">First field</label>

                    <select class="form-control dropdownbackground" v-model="calculate.firstfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Second field</label>
                    <select class="form-control dropdownbackground" v-model="calculate.secondfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Operation</label>
                    <select class="form-control dropdownbackground" v-model="calculate.operations">
                      <option value="+">Add</option>
                      <option value="_">Substract</option>
                      <option value="/">Divide</option>
                      <option value="*">Multiply</option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('calculation')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- time starts -->
              <div v-if="selectedmenu == 'time'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect mode="multiple" v-model="values" :options="commonvalidations" :hideSelected="false"
                      :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('time')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- number and date starts -->
              <div v-if="selectedmenu == 'numberanddate'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Number and Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="numberanddatetext">Number and Date Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="numberanddatetext"
                      placeholder="Enter number and date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsnumberanddate">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsnumberanddate" placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('numberanddate')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- number and date ends  -->
              <!--uploadfile  starts -->
              <div v-if="selectedmenu == 'uploadfile'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Upload File</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Upload File Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter upload file label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('uploadfile')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- uploadfile ends  -->
              <!-- qrcode starts qrcode -->
              <div v-if="selectedmenu == 'qrcode'" class="fieldentry">
                <div class="ibox-title">
                  <h5>QR Code</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="qrcodetext">QR code Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="qrcodetext"
                      placeholder="Enter QR code label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name<sup><i class="fa fa-asterisk imp"></i></sup></label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>QR Code data <sup><i class="fa fa-asterisk imp"></i></sup></label>
                    <textarea class="form-control" v-model="qrcodecontents" id="qrcodecontents"
                      placeholder="Enter QR Code data" />
                    <span v-if="emptyField" class="error-message">Please fill the qr data.</span>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('qrcode')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- qrcode ends  -->
              <!-- radiobutton starts -->
              <div v-if="selectedmenu == 'radio'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Radio</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label for="radiotext">Radio Label</label>
                      <input type="text" v-model="labeltext" class="form-control" id="radiotext"
                        placeholder="Enter radio label" />
                    </div>
                    <div class="col-lg-6">
                      <label for="dropdowntext">Import from Option Group</label>
                      <select class="form-select pb-0 horizondalscroll" id="exampleFormControlSelect2"
                        v-model="selectedmodel" @change.prevent="dropdownmodelchange()">
                        <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                          {{ models.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsradio">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsradio"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Alignment</label>
                    <select v-model="radioorientation" @change="handleChange" class="form-control"
                      aria-label="Default select example">
                      <option value="horizondal">Horizontal</option>
                      <option value="vertical">Vertical</option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row mb-3">
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Code Value</label>
                      </div>
                      <div class="col-lg-9 mb-2 pl-0">
                        <input ref="codeValueInput" type="text" class="form-control" v-model="radiovalues"
                          maxlength="50" />
                      </div>
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Option Name</label>
                      </div>
                      <div class="col-lg-9 pl-0">
                        <input type="text" ref="optionNameInput" class="form-control" v-model="radiooptions"
                          id="optionscheckbox" maxlength="50" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-5">
                        <!-- Add button  -->
                        <div style="display: flex;">
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="addradiooptions()"
                            v-if="editingOption === null" :disabled="radiovalues == '' ||
                              radiovalues == null ||
                              radiooptions == '' ||
                              radiooptions == null
                              ">
                            Add
                          </button>
                          <!-- Update button -->
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="UpdateDropdownoptions()"
                            v-else>
                            Update
                          </button>
                          <div style="margin-left: 10px;"></div>
                          <!-- Cancel button -->
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="cancelEditDropdown()"
                            v-if="editingOption !== null" style="background-color: red; color: white;">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group textgroup" style="overflow-y: auto; max-height: 250px;">
                      <!-- <label for="optionslistcheckbox">Options</label> -->
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Code Value</th>
                            <th>Option Name</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="option in radiooptionsarray" :key="option.codevalue">
                            <td>{{ option.codevalue }}</td>
                            <td>{{ option.optionname }}</td>
                            <td>
                              <i class="fa fa-edit edit-delete" style="color: #a13b7c" @click="
                                editOptionsfordropdown(
                                  option.codevalue,
                                  option.optionname
                                );
                              focusOnEdit();
                              "></i>
                            </td>
                            <td>
                              <i class="fa fa-trash edit-delete" style="color: #a13b7c"
                                @click="deleteoptions(option.codevalue)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <span class="error-message" v-if="dropdownError">Please add options for the radio</span>
                    </div>
                    <div class="form-group textgroup">
                      <label>Validations</label>
                      <Multiselect class="multiselect-theme" mode="multiple" v-model="values"
                        :options="commonvalidations" :hideSelected="false" :closeOnSelect="false" />
                    </div>
                    <div class="my-4 row">
                      <div class="col-md-12 d-flex justify-content-end">
                        <div class="position-relative" v-if="!isedit">
                          <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                            @click.prevent="addfield('radio')" />
                          <div class="tool-tip">Add</div>
                        </div>
                        <div class="position-relative" v-if="isedit">
                          <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                            @click="editfield()" />
                          <div class="tool-tip">Save</div>
                        </div>
                        <div class="position-relative">
                          <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                            @click.prevent="deletecomponent()" />
                          <div class="tool-tip">Delete</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- radiobutton ends  -->
              <!-- add survey starts -->
              <div v-if="selectedmenu == 'addsurvey'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Add Survey</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Add survey Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter survey label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('addsurvey')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- add survey ends  -->
              <!-- repeatedmeasure starts  -->
              <div v-if="selectedmenu == 'repeatedmeasure'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Repeated Measure</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="repeatedmeasuretext">Repeated Measure Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="repeatedmeasuretext"
                      placeholder="Enter repeated measure label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsrepeatedmeasure">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsrepeatedmeasure" placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedmeasure()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmeasure ends  -->
              <!-- repeatedmodel starts  -->
              <div v-if="selectedmenu == 'repeatedmodel'" class="fieldentry">
                <div class="ibox-title">
                  <h5>repeatedmodel</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedfield()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmodel ends  -->
              <!-- year starts  -->
              <div v-if="selectedmenu == 'year'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Year</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="yeartext">Year Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="yeartext"
                      placeholder="Enter year label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('year')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- year ends  -->
              <!-- dateandtime starts -->
              <div v-if="selectedmenu == 'dateandtime'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Date and Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Date and Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter date and time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('dateandtime')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- dateandtime ends  -->
              <!-- image starts -->
              <div v-if="selectedmenu == 'image'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Image</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Image Label</label>
                    <textarea rows="4" cols="50" type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter image label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup displayimage">
                    <div class="image-preview">
                      <img @click="onFileeditchange()" v-if="!isedit" class="preview" :src="imageData" />
                      <img @click="onFileeditchange()" v-if="isedit" class="templatepreview" :src="`${fileuploadurl}/file/${imagid.split(',')[0]
                        }?versionid=${imagid.split(',')[1]}`
                        " />
                    </div>
                  </div>
                  <hr />
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('image')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- image ends  -->
              <!-- checkbox starts -->
              <!-- <div v-if="selectedmenu == 'checkbox'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Checkbox</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="checkboxtext">Checkbox Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="checkboxtext"
                      placeholder="Enter checkbox label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionscheckbox">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionscheckbox"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('checkbox')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- checkbox ends  -->
              <!-- textarea starts -->
              <div v-if="selectedmenu == 'textarea'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Textarea</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Textarea Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter textarea label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('textarea')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- textarea ends  -->
              <!-- add values menu ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->

  <createrepeatedfield v-if="repeatedfieldpopup" :repfieldstoedit="repfieldstoedit" :popuptype="popuptype"
    :feildNames="fieldnamesarray" :templateId="templateid" @repeatedmodelclose="closerepeatedfield()"
    @send-object="handleReceivedObject">
  </createrepeatedfield>
  <createnewversion v-if="creatversionpopup == true" @closedialogebox="closecreatenewversion()"
    @createnewtempversion="newtemplateversion()" :currentversion="currentformversion" :formid="newversionformid">
  </createnewversion>
  <!-- modal 3 starts -->
  <teleport to="#app">
    <createFormModal v-if="showCreateFormModal" @closeModal="(showCreateFormModal = false), getForms()"
      @SubmitForm="createtemplate()" @sendIdToParent="handleIdFromChild" :studyId="$store.getters.getStudyIs" />
  </teleport>

  <!-- modal 4 starts -->
  <teleport to="#app">
    <div class="modal__wrapper__component" v-if="aitoolModal">
      <div class="component__modal__containers">
        <div class="titleclass d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Fill Form</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemodaltwo()"></i>
          </a>
        </div>
        <div id="modalcontents" class="modalcontents">
          <div class="row">
            <div class="col-lg-12">
              <!-- textbox starts -->
              <!-- <div v-if="selectedmenu == 'textbox' || selectedmenu == 'text'" class="fieldentry">

                <div class="row" style="padding-bottom:20px;">
                  <div class="col-sm-6"> <label class="form-check-label" style="float: left; padding-right: 30px;"
                      for="flexCheckDefault">Feature Field? </label><input class="form-check-input" style="float:right"
                      type="checkbox" value="" v-model="templateFieldProperties.featureField"></div>
                  <div class="col-sm-8"><input class="form-check-input" type="checkbox" value=""
                      v-model="templateFieldProperties.featureField"></div>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <div v-for="(item, index) in templateFieldProperties.fieldsToConsider" :key="index"
                      class="repeatstring">
                      <div class="row" style="padding-bottom: 20px">
                        <div class="col-sm-8">
                          <textarea class="form-control" rows="2" placeholder="Text Field"
                            v-model="item.considerableFields[0]"></textarea>
                        </div>

                        <div class="col-sm-4">
                          <input type="text" class="form-control" placeholder="%" aria-label="Recipient's username"
                            v-model="item.considerationRate">

                        </div>
                      </div>

                    </div>

                    <div class="row">
                      <div class="col-sm-4 ml-auto">
                        <button class="btn btn-outline-secondary" type="button" @click="addstringItem()">+</button>
                      </div>
                    </div>
                    <div class="text-right">
                      <button type="button" class="btn btn-primary" @click="savepredictionbutton">Save</button>
                    </div>
                  </div>


                </div>
              </div> -->
              <!-- textbox ends dropdown -->
              <!-- Grid starts -->
              <div v-if="selectedmenu == 'grid'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Grid</h5>
                  <nav class="nav nav-pills flex-column flex-sm-row">
                    <a :class="gridgeneral" aria-current="page" @click.prevent="selectconf('general')">General</a>
                    <a :class="gridconfiguration" @click.prevent="selectconf('configuration')">Configuration</a>
                  </nav>
                </div>
                <div v-if="gridoption == 'general'" class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Grid Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter grid label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                </div>
                <div v-if="gridoption == 'configuration'">
                  <div class="ibox-content-edit flextable">
                    <div class="addfieldtable">
                      <div>Row Label</div>
                      <!-- <div v-for="repeatingdatas in repeatingdata" :key="repeatingdatas" class="col-md-12 d-flex justify-content-end distancebetween"> -->
                      <div class="rowcolmn" v-for="(gridrow, index) in gridrows" :key="gridrow">
                        <input class="form-control" v-model="griddata[0].Rows[index]" type="text" />
                        <div v-if="gridrow == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementrows()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removerowsrows(index)"></i>
                        </div>
                      </div>
                    </div>
                    <div class="addfieldtable">
                      <div>Column Label</div>
                      <div class="rowcolmn" v-for="(gridcolumn, index) in gridcolumns" :key="gridcolumn">
                        <input class="form-control" v-model="griddata[0].Columns[index]" type="text" />
                        <div v-if="gridcolumn == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementcolumns()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removecolumns(index)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-10 rowfieldtype" v-for="(gridrow, index) in gridrows" :key="gridrow">
                    <label>Field Type of Row {{ gridrow }}</label>
                    <select class="col-md-10 form-control" v-model="griddata[0].Fieldtypes[index]" name="fieldtype">
                      <option value="text">text</option>
                      <option value="number">number</option>
                      <option value="time">time</option>
                      <option value="date">date</option>
                    </select>
                  </div>
                </div>
                <div class="my-4 row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <div class="position-relative" v-if="!isedit">
                      <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                        @click.prevent="addfield('grid')" />
                      <div class="tool-tip">Add</div>
                    </div>
                    <div class="position-relative" v-if="isedit">
                      <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                        @click="editfield()" />
                      <div class="tool-tip">Save</div>
                    </div>
                    <div class="position-relative mr-4">
                      <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                        @click.prevent="deletecomponent()" />
                      <div class="tool-tip">Delete</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid ends  -->
              <!-- model starts -->
              <div v-if="selectedmenu == 'model'" class="fieldentry">
                <!-- <div class="ibox-modal-title">
                    <h5>Model</h5>
                  </div> -->
                <div class="ibox-content-edit">
                  <div class="form-group textgroup">
                    <label class="col-form-label" for="instructionstext">Select model</label>
                    <select class="form-control horizondalscroll" id="exampleFormControlSelect2" v-model="selectedmodel"
                      @change.prevent="modelchange()">
                      <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                        {{ models.label }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group textgroup">
                    <label for="optionslistcheckbox">Options</label>
                    <select multiple class="form-control horizondalscroll" id="exampleFormControlSelect2">
                      <option v-for="options in selectedmodel.groupoptions" :key="options" disabled>
                        ({{ options.codevalue }}){{ options.optionname }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row">
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodelvalues" placeholder="Code value"
                          maxlength="50" />
                      </div>
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodeloptions" id="optionscheckbox"
                          placeholder="Option name" maxlength="50" />
                      </div>
                      <div class="col-lg-2">
                        <button type="button" class="save_btn px-3 py-1" @click.prevent="addmodeloptions()" :disabled="singlemodelvalues == '' ||
                          singlemodelvalues == null ||
                          singlemodeloptions == '' ||
                          singlemodeloptions == null
                          ">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label" for="modelelem">Select Control Type</label>
                    <div class="col-md-12">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="radio" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Radio
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="dropdown" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault2" />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Dropdown
                        </label>
                      </div>
                      <div class="option-error-block" v-if="controlSelectError">
                        Please select a control type
                      </div>
                    </div>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addmodel()" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- model ends -->
              <!-- headding starts -->
              <div v-if="selectedmenu == 'header'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-form-label" for="headdingtext">Add Heading</label>
                      <input type="text" v-model="labeltext" class="form-control" id="headdingtext"
                        placeholder="Enter Heading" />
                    </div>
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label class="col-form-label" for="instructionstext"
                        >Field Name</label
                      >
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('header')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- headding ends -->
              <!-- subheading starts -->
              <div v-if="selectedmenu == 'subHeader'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Sub Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="subheaddingtext">Add Sub heading</label>
                    <input type="text" v-model="labeltext" class="form-control" id="subheaddingtext"
                      placeholder="Enter Heading" />
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label for="instructionstext">Field Name</label>
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('subHeader')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- subheading ends -->
              <!-- summary starts -->
              <div v-if="selectedmenu == 'summary'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Summary</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textboxtext">Summary</label>
                    <textarea class="form-control" v-model="labeltext" id="textboxtext"
                      placeholder="Enter summary label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextbox">Field Name</label>
                    <input type="text" class="form-control" v-model="fieldname" id="instructionstextbox"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('summary')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- summary ends  -->
              <!-- date starts -->
              <div v-if="selectedmenu == 'date'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Date Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('date')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- date ends dropdown -->
              <!-- scales starts -->
              <div v-if="selectedmenu == 'scales'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Scale</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textboxtext">Scale Label</label>
                    <input type="text" class="form-control" v-model="labeltext" id="textboxtext"
                      placeholder="Enter scale label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextbox">Field Name</label>
                    <input type="text" class="form-control" v-model="fieldname" maxlength="30" id="instructionstextbox"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>

                  <div class="form-group textgroup">
                    <div class="optgroup">
                      <div>
                        <label>Min Value</label>
                        <input v-model="scaleminval" type="number" class="form-control" placeholder="Minimum value"
                          @input="clearInput()" />
                        <span class="error-message" v-if="scaleError[2]">Only accepts digits and '-' (minus
                          sign).</span>
                      </div>
                      <div>
                        <label>Max Value</label>
                        <input v-model="scalemaxval" type="number" class="form-control" placeholder="Maximum value"
                          @input="clearInput()" />
                        <span class="error-message" v-if="scaleError[3]">Only accepts digits and '-' (minus
                          sign).</span>
                      </div>
                    </div>
                    <div>
                      <span class="error-message" v-if="scaleError[0]">Values entered on min value and max value fields
                        cannot
                        be same.</span>
                      <span class="error-message" v-if="scaleError[1]">Value entered on min value must be lower than
                        value
                        entered on max value field.</span>
                    </div>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('scales')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- scale ends  -->
              <!-- calculation starts -->
              <div v-if="selectedmenu == 'calculation'" class="fieldentry">
                <div class="ibox-title">
                  <h5>calculation</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="calculatedfieldtext">Calculation Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="calculatedfieldtext"
                      placeholder="Enter calculated field label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionscalculated">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionscalculated"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label for="timetext">First field</label>

                    <select class="form-control dropdownbackground" v-model="calculate.firstfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Second field</label>
                    <select class="form-control dropdownbackground" v-model="calculate.secondfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Operation</label>
                    <select class="form-control dropdownbackground" v-model="calculate.operations">
                      <option value="+">Add</option>
                      <option value="_">Substract</option>
                      <option value="/">Divide</option>
                      <option value="*">Multiply</option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('calculation')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- time starts -->
              <div v-if="selectedmenu == 'time'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect mode="multiple" v-model="values" :options="commonvalidations" :hideSelected="false"
                      :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('time')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- number and date starts -->
              <div v-if="selectedmenu == 'numberanddate'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Number and Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="numberanddatetext">Number and date label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="numberanddatetext"
                      placeholder="Enter number and date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsnumberanddate">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsnumberanddate" placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('numberanddate')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- number and date ends  -->
              <!--uploadfile  starts -->
              <div v-if="selectedmenu == 'uploadfile'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Upload File</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Upload File Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter upload file label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('uploadfile')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- uploadfile ends  -->
              <!-- qrcode starts qrcode -->
              <div v-if="selectedmenu == 'qrcode'" class="fieldentry">
                <div class="ibox-title">
                  <h5>QR Code</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="qrcodetext">QR code Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="qrcodetext"
                      placeholder="Enter QR code label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name<sup><i class="fa fa-asterisk imp"></i></sup></label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>QR Code data <sup><i class="fa fa-asterisk imp"></i></sup></label>
                    <textarea class="form-control" v-model="qrcodecontents" id="qrcodecontents"
                      placeholder="Enter QR Code data" />
                    <span v-if="emptyField" class="error-message">Please fill the qr data.</span>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('qrcode')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- qrcode ends  -->
              <!-- radiobutton starts -->
              <div v-if="selectedmenu == 'radio'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Radio</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label for="radiotext">Radio Label</label>
                      <input type="text" v-model="labeltext" class="form-control" id="radiotext"
                        placeholder="Enter radio label" />
                    </div>
                    <div class="col-lg-6">
                      <label for="dropdowntext">Import from Option group</label>
                      <select class="form-select pb-0 horizondalscroll" id="exampleFormControlSelect2"
                        v-model="selectedmodel" @change.prevent="dropdownmodelchange()">
                        <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                          {{ models.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsradio">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsradio"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Alignment</label>
                    <select v-model="radioorientation" @change="handleChange" class="form-control"
                      aria-label="Default select example">
                      <option value="horizondal">Horizontal</option>
                      <option value="vertical">Vertical</option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row mb-3">
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Code Value</label>
                      </div>
                      <div class="col-lg-9 mb-2 pl-0">
                        <input ref="codeValueInput" type="text" class="form-control" v-model="radiovalues"
                          maxlength="50" />
                      </div>
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Option Name</label>
                      </div>
                      <div class="col-lg-9 pl-0">
                        <input type="text" ref="optionNameInput" class="form-control" v-model="radiooptions"
                          id="optionscheckbox" maxlength="50" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-5">
                        <!-- Add button  -->
                        <div style="display: flex;">
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="addradiooptions()"
                            v-if="editingOption === null" :disabled="radiovalues == '' ||
                              radiovalues == null ||
                              radiooptions == '' ||
                              radiooptions == null
                              ">
                            Add
                          </button>
                          <!-- Update button -->
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="UpdateDropdownoptions()"
                            v-else>
                            Update
                          </button>
                          <div style="margin-left: 10px;"></div>
                          <!-- Cancel button -->
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="cancelEditDropdown()"
                            v-if="editingOption !== null" style="background-color: red; color: white;">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group textgroup" style="overflow-y: auto; max-height: 250px;">
                      <!-- <label for="optionslistcheckbox">Options</label> -->
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Code Value</th>
                            <th>Option Name</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="option in radiooptionsarray" :key="option.codevalue">
                            <td>{{ option.codevalue }}</td>
                            <td>{{ option.optionname }}</td>
                            <td>
                              <i class="fa fa-edit edit-delete" style="color: #a13b7c" @click="
                                editOptionsfordropdown(
                                  option.codevalue,
                                  option.optionname
                                );
                              focusOnEdit();
                              "></i>
                            </td>
                            <td>
                              <i class="fa fa-trash edit-delete" style="color: #a13b7c"
                                @click="deleteoptions(option.codevalue)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <span class="error-message" v-if="dropdownError">Please add options for the radio</span>
                    </div>
                    <div class="form-group textgroup">
                      <label>Validations</label>
                      <Multiselect class="multiselect-theme" mode="multiple" v-model="values"
                        :options="commonvalidations" :hideSelected="false" :closeOnSelect="false" />
                    </div>
                    <div class="my-4 row">
                      <div class="col-md-12 d-flex justify-content-end">
                        <div class="position-relative" v-if="!isedit">
                          <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                            @click.prevent="addfield('radio')" />
                          <div class="tool-tip">Add</div>
                        </div>
                        <div class="position-relative" v-if="isedit">
                          <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                            @click="editfield()" />
                          <div class="tool-tip">Save</div>
                        </div>
                        <div class="position-relative">
                          <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                            @click.prevent="deletecomponent()" />
                          <div class="tool-tip">Delete</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- radiobutton ends  -->
              <!-- add survey starts -->
              <div v-if="selectedmenu == 'addsurvey'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Add Survey</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Add survey Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter survey label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('addsurvey')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- add survey ends  -->
              <!-- repeatedmeasure starts  -->
              <div v-if="selectedmenu == 'repeatedmeasure'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Repeated Measure</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="repeatedmeasuretext">Repeated Measure Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="repeatedmeasuretext"
                      placeholder="Enter repeated measure label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsrepeatedmeasure">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsrepeatedmeasure" placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedmeasure()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmeasure ends  -->
              <!-- repeatedmodel starts  -->
              <div v-if="selectedmenu == 'repeatedmodel'" class="fieldentry">
                <div class="ibox-title">
                  <h5>repeatedmodel</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedfield()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmodel ends  -->
              <!-- year starts  -->
              <div v-if="selectedmenu == 'year'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Year</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="yeartext">Year Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="yeartext"
                      placeholder="Enter year label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('year')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- year ends  -->
              <!-- dateandtime starts -->
              <div v-if="selectedmenu == 'dateandtime'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Date and Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Date and Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter date and time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('dateandtime')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- dateandtime ends  -->
              <!-- image starts -->
              <div v-if="selectedmenu == 'image'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Image</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Image Label</label>
                    <textarea rows="4" cols="50" type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter image label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup displayimage">
                    <div class="image-preview">
                      <img @click="onFileeditchange()" v-if="!isedit" class="preview" :src="imageData" />
                      <img @click="onFileeditchange()" v-if="isedit" class="templatepreview" :src="`${fileuploadurl}/file/${imagid.split(',')[0]
                        }?versionid=${imagid.split(',')[1]}`
                        " />
                    </div>
                  </div>
                  <hr />
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('image')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- image ends  -->
              <!-- checkbox starts -->
              <!-- <div v-if="selectedmenu == 'checkbox'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Checkbox</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="checkboxtext">Checkbox Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="checkboxtext"
                      placeholder="Enter checkbox label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionscheckbox">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionscheckbox"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('checkbox')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- checkbox ends  -->
              <!-- textarea starts -->
              <div v-if="selectedmenu == 'textarea'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Textarea</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Textarea Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter textarea label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('textarea')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- textarea ends  -->
              <!-- add values menu ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->

  <!-- modal 5 starts -->
  <teleport to="#app">
    <div class="modal__wrapper__component" v-if="aitoolModal">
      <div class="component__modal__containers">
        <div class="titleclass d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Fill Form</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemodaltwo()"></i>
          </a>
        </div>
        <div id="modalcontents" class="modalcontents">
          <div class="row">
            <div class="col-lg-12">
              <!-- textbox starts -->
              <!-- <div v-if="selectedmenu == 'textbox' || selectedmenu == 'text'" class="fieldentry">


                <div class="row" style="padding-bottom:10px;">
                  <div class="col-sm-6"> <label class="form-check-label" style="float: left; padding-right: 30px;"
                      for="flexCheckDefault">Feature Field? </label><input class="form-check-input" style="float:right"
                      type="checkbox" value="" v-model="templateFieldProperties.featureField"></div>
                  <div class="col-sm-8"><input class="form-check-input" type="checkbox" value=""
                      v-model="templateFieldProperties.featureField"></div>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">

                    <div v-for="(item, index) in templateFieldProperties.fieldsToConsider" :key="index"
                      class="repeatstring">
                      <div class="row" style="padding-bottom: 20px">
                        <div class="col-sm-8">
                          <textarea class="form-control" rows="2" placeholder="Text Field"
                            v-model="item.considerableFields[0]"></textarea>
                        </div>

                        <div class="col-sm-4">
                          <input type="text" class="form-control" placeholder="%" aria-label="Recipient's username"
                            v-model="item.considerationRate">

                        </div>
                      </div>

                    </div>

                    <div class="row">
                      <div class="col-sm-4 ml-auto">
                        <button class="btn btn-outline-secondary" type="button" @click="addstringItem()">+</button>
                      </div>
                    </div>
                    <div class="text-right">
                      <button type="button" class="btn btn-primary" @click="savepredictionbutton">Save</button>
                    </div>
                  </div>


                </div>
              </div> -->
              <!-- textbox ends dropdown -->
              <!-- Grid starts -->
              <div v-if="selectedmenu == 'grid'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Grid</h5>
                  <nav class="nav nav-pills flex-column flex-sm-row">
                    <a :class="gridgeneral" aria-current="page" @click.prevent="selectconf('general')">General</a>
                    <a :class="gridconfiguration" @click.prevent="selectconf('configuration')">Configuration</a>
                  </nav>
                </div>
                <div v-if="gridoption == 'general'" class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Grid Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter grid label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                </div>
                <div v-if="gridoption == 'configuration'">
                  <div class="ibox-content-edit flextable">
                    <div class="addfieldtable">
                      <div>Row Label</div>
                      <!-- <div v-for="repeatingdatas in repeatingdata" :key="repeatingdatas" class="col-md-12 d-flex justify-content-end distancebetween"> -->
                      <div class="rowcolmn" v-for="(gridrow, index) in gridrows" :key="gridrow">
                        <input class="form-control" v-model="griddata[0].Rows[index]" type="text" />
                        <div v-if="gridrow == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementrows()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removerowsrows(index)"></i>
                        </div>
                      </div>
                    </div>
                    <div class="addfieldtable">
                      <div>Column Label</div>
                      <div class="rowcolmn" v-for="(gridcolumn, index) in gridcolumns" :key="gridcolumn">
                        <input class="form-control" v-model="griddata[0].Columns[index]" type="text" />
                        <div v-if="gridcolumn == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementcolumns()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removecolumns(index)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-10 rowfieldtype" v-for="(gridrow, index) in gridrows" :key="gridrow">
                    <label>Field Type of Row {{ gridrow }}</label>
                    <select class="col-md-10 form-control" v-model="griddata[0].Fieldtypes[index]" name="fieldtype">
                      <option value="text">text</option>
                      <option value="number">number</option>
                      <option value="time">time</option>
                      <option value="date">date</option>
                    </select>
                  </div>
                </div>
                <div class="my-4 row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <div class="position-relative" v-if="!isedit">
                      <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                        @click.prevent="addfield('grid')" />
                      <div class="tool-tip">Add</div>
                    </div>
                    <div class="position-relative" v-if="isedit">
                      <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                        @click="editfield()" />
                      <div class="tool-tip">Save</div>
                    </div>
                    <div class="position-relative mr-4">
                      <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                        @click.prevent="deletecomponent()" />
                      <div class="tool-tip">Delete</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid ends  -->
              <!-- model starts -->
              <div v-if="selectedmenu == 'model'" class="fieldentry">
                <!-- <div class="ibox-modal-title">
                    <h5>Model</h5>
                  </div> -->
                <div class="ibox-content-edit">
                  <div class="form-group textgroup">
                    <label class="col-form-label" for="instructionstext">Select model</label>
                    <select class="form-control horizondalscroll" id="exampleFormControlSelect2" v-model="selectedmodel"
                      @change.prevent="modelchange()">
                      <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                        {{ models.label }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group textgroup">
                    <label for="optionslistcheckbox">Options</label>
                    <select multiple class="form-control horizondalscroll" id="exampleFormControlSelect2">
                      <option v-for="options in selectedmodel.groupoptions" :key="options" disabled>
                        ({{ options.codevalue }}){{ options.optionname }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row">
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodelvalues" placeholder="Code value"
                          maxlength="50" />
                      </div>
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodeloptions" id="optionscheckbox"
                          placeholder="Option name" maxlength="50" />
                      </div>
                      <div class="col-lg-2">
                        <button type="button" class="save_btn px-3 py-1" @click.prevent="addmodeloptions()" :disabled="singlemodelvalues == '' ||
                          singlemodelvalues == null ||
                          singlemodeloptions == '' ||
                          singlemodeloptions == null
                          ">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label" for="modelelem">Select Control Type</label>
                    <div class="col-md-12">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="radio" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Radio
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="dropdown" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault2" />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Dropdown
                        </label>
                      </div>
                      <div class="option-error-block" v-if="controlSelectError">
                        Please select a control type
                      </div>
                    </div>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addmodel()" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- model ends -->
              <!-- headding starts -->
              <div v-if="selectedmenu == 'header'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-form-label" for="headdingtext">Add Heading</label>
                      <input type="text" v-model="labeltext" class="form-control" id="headdingtext"
                        placeholder="Enter Heading" />
                    </div>
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label class="col-form-label" for="instructionstext"
                        >Field Name</label
                      >
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('header')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- headding ends -->
              <!-- subheading starts -->
              <div v-if="selectedmenu == 'subHeader'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Sub Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="subheaddingtext">Add Sub heading</label>
                    <input type="text" v-model="labeltext" class="form-control" id="subheaddingtext"
                      placeholder="Enter Heading" />
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label for="instructionstext">Field Name</label>
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('subHeader')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- subheading ends -->
              <!-- dropdown starts -->
              <!-- <div v-if="selectedmenu == 'dropdown'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Dropdown</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label for="dropdowntext">Dropdown Label</label>
                      <input type="text" class="form-control" v-model="labeltext" id="dropdowntext"
                        placeholder="Enter dropdown label" />
                    </div>
                    <div class="col-lg-6">
                      <label for="dropdowntext">Import from Option group</label>
                      <select class="form-select pb-0 horizondalscroll" id="exampleFormControlSelect2"
                        v-model="selectedmodel" @change.prevent="dropdownmodelchange()">
                        <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                          {{ models.label }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group textgroup">
                    <label for="instructionsdropdown">Field name</label>
                    <input type="text" class="form-control" v-model="fieldname" maxlength="30" id="instructionsdropdown"
                      placeholder="Field name" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row mb-3">
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Code Value</label>
                      </div>
                      <div class="col-lg-9 pl-0 mb-2">
                        <input ref="codeValueInput" type="text" class="form-control" v-model="radiovalues"
                          maxlength="50" style="width:100%" />
                      </div>
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Option Name</label>
                      </div>
                      <div class="col-lg-9 pl-0 mb-2">
                        <input ref="optionNameInput" type="text" class="form-control" v-model="radiooptions"
                          id="optionscheckbox" maxlength="50" style="width:100%" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-5">
                
                        <div style="display: flex;">
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="addradiooptions()"
                            v-if="editingOption === null" :disabled="radiovalues == '' || radiovalues == null ||
                              radiooptions == '' || radiooptions == null">
                            Add
                          </button>
        
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="UpdateDropdownoptions()"
                            v-else>
                            Update
                          </button>
                          <div style="margin-left: 10px;"></div>
                
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="cancelEditDropdown()"
                            v-if="editingOption !== null" style="background-color: red; color: white;">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group textgroup" style="overflow-y: auto; max-height: 250px;">
               
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Code Value</th>
                          <th>Option Name</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="option in radiooptionsarray" :key="option.codevalue">
                          <td>{{ option.codevalue }}</td>
                          <td>{{ option.optionname }}</td>
                          <td>
                            <i class="fa fa-edit edit-delete" style="color: #a13b7c" @click="
                              editOptionsfordropdown(
                                option.codevalue,
                                option.optionname
                              );
                            focusOnEdit();
                            "></i>
                          </td>
                          <td>
                            <i class="fa fa-trash edit-delete" style="color: #a13b7c"
                              @click="deleteoptions(option.codevalue)"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <span class="error-message" v-if="dropdownError">Please add options for the dropdown</span>
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('dropdown')" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Update</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- dropdown ends dropdown -->
              <!-- summary starts -->
              <div v-if="selectedmenu == 'summary'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Summary</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textboxtext">Summary</label>
                    <textarea class="form-control" v-model="labeltext" id="textboxtext"
                      placeholder="Enter summary label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextbox">Field Name</label>
                    <input type="text" class="form-control" v-model="fieldname" id="instructionstextbox"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('summary')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- summary ends  -->
              <!-- date starts -->
              <div v-if="selectedmenu == 'date'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Date Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('date')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- date ends dropdown -->
              <!-- scales starts -->
              <div v-if="selectedmenu == 'scales'" class="fieldentry">
                <!-- <div class="ibox-title">
                    <h5>Scale</h5>
                  </div> -->
                <div class="ibox-content-edit">
                  <!-- <div class="form-group">
                      <label for="textboxtext">Scale Label</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="labeltext"
                        id="textboxtext"
                        placeholder="Enter scale label"
                      />
                    </div> -->
                  <!-- <div class="form-group textgroup">
                      <label for="instructionstextbox">Field Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="fieldname"
                        maxlength="30"
                        id="instructionstextbox"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <!-- <div class="form-group textgroup">
                      <label>Validations</label>
                      <Multiselect
                        class="multiselect-theme"
                        mode="multiple"
                        v-model="values"
                        :options="commonvalidations"
                        :hideSelected="false"
                        :closeOnSelect="false"
                      />
                    </div> -->
                  <div class="row" style="padding-bottom:10px;">
                    <div class="col-sm-6"> <label class="form-check-label" style="float: left; padding-right: 30px;"
                        for="flexCheckDefault">Feature Field? </label><input class="form-check-input"
                        style="float:right" type="checkbox" value="" v-model="templateFieldProperties.featureField">
                    </div>
                    <div class="col-sm-6"></div>
                  </div>

                  <div class="row" style="padding-bottom: 20px">
                    <div class="col-sm-12">
                      <div v-for="(item, index) in templateFieldProperties.rangesToConsider" :key="index"
                        class="row repeatstring">

                        <div class="col-sm-4">


                          <label>Min Value</label>
                          <input v-model="item.min" type="number" class="form-control" placeholder="Minimum value"
                            @input="clearInput()" />
                          <span class="error-message" v-if="scaleError[2]">Only accepts digits and '-' (minus
                            sign).</span>
                        </div>
                        <div class="col-sm-4">
                          <label>Max Value</label>
                          <input v-model="item.max" type="number" class="form-control" placeholder="Maximum value"
                            @input="clearInput()" />
                          <span class="error-message" v-if="scaleError[3]">Only accepts digits and '-' (minus
                            sign).</span>
                        </div>
                        <div class="col-sm-4">
                          <label>consideration Rate</label>
                          <input v-model="item.considerationRate" type="number" class="form-control"
                            placeholder="Consideration value" @input="clearInput()" />
                          <span class="error-message" v-if="scaleError[4]">Only accepts digits and '-' (minus
                            sign).</span>
                        </div>
                      </div>
                      <div>
                        <span class="error-message" v-if="scaleError[0]">Values entered on min value and max value
                          fields
                          cannot
                          be same.</span>
                        <span class="error-message" v-if="scaleError[1]">Value entered on min value must be lower than
                          value
                          entered on max value field.</span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 ml-auto">
                      <button class="btn btn-outline-secondary" type="button" @click="saverangebutton()">+</button>
                    </div>
                    <div class="text-right">
                      <button type="button" class="btn btn-primary" @click="savepredictionbutton">Save</button>
                    </div>
                  </div>
                  <!-- <div class="my-4 row">
                      <div class="col-md-12 d-flex justify-content-end">
                        <div class="position-relative" v-if="!isedit">
                          <img
                            class="cursor-pointer me-3"
                            src="../../assets/add_doc.png"
                            alt="Add field"
                            @click.prevent="addfield('scales')"
                          />
                          <div class="tool-tip">Add</div>
                        </div>
                        <div class="position-relative" v-if="isedit">
                          <img
                            class="cursor-pointer me-3"
                            src="../../assets/save.png"
                            alt="Save field"
                            @click="editfield()"
                          />
                          <div class="tool-tip">Save</div>
                        </div>
                        <div class="position-relative">
                          <img
                            class="cursor-pointer"
                            src="../../assets/delete_doc.png"
                            alt="Delete field"
                            @click.prevent="deletecomponent()"
                          />
                          <div class="tool-tip">Delete</div>
                        </div>
                      </div>
                    </div> -->
                </div>
              </div>
              <!-- scale ends  -->
              <!-- calculation starts -->
              <div v-if="selectedmenu == 'calculation'" class="fieldentry">
                <div class="ibox-title">
                  <h5>calculation</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="calculatedfieldtext">Calculation Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="calculatedfieldtext"
                      placeholder="Enter calculated field label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionscalculated">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionscalculated"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label for="timetext">First field</label>

                    <select class="form-control dropdownbackground" v-model="calculate.firstfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Second field</label>
                    <select class="form-control dropdownbackground" v-model="calculate.secondfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Operation</label>
                    <select class="form-control dropdownbackground" v-model="calculate.operations">
                      <option value="+">Add</option>
                      <option value="_">Substract</option>
                      <option value="/">Divide</option>
                      <option value="*">Multiply</option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('calculation')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- time starts -->
              <div v-if="selectedmenu == 'time'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect mode="multiple" v-model="values" :options="commonvalidations" :hideSelected="false"
                      :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('time')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- number and date starts -->
              <div v-if="selectedmenu == 'numberanddate'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Number and Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="numberanddatetext">Number and date label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="numberanddatetext"
                      placeholder="Enter number and date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsnumberanddate">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsnumberanddate" placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('numberanddate')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- number and date ends  -->
              <!--uploadfile  starts -->
              <div v-if="selectedmenu == 'uploadfile'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Upload File</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Upload File Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter upload file label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('uploadfile')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- uploadfile ends  -->
              <!-- qrcode starts qrcode -->
              <div v-if="selectedmenu == 'qrcode'" class="fieldentry">
                <div class="ibox-title">
                  <h5>QR Code</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="qrcodetext">QR code Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="qrcodetext"
                      placeholder="Enter QR code label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name<sup><i class="fa fa-asterisk imp"></i></sup></label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>QR Code data <sup><i class="fa fa-asterisk imp"></i></sup></label>
                    <textarea class="form-control" v-model="qrcodecontents" id="qrcodecontents"
                      placeholder="Enter QR Code data" />
                    <span v-if="emptyField" class="error-message">Please fill the qr data.</span>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('qrcode')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- qrcode ends  -->
              <!-- radiobutton starts -->
              <div v-if="selectedmenu == 'radio'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Radio</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label for="radiotext">Radio Label</label>
                      <input type="text" v-model="labeltext" class="form-control" id="radiotext"
                        placeholder="Enter radio label" />
                    </div>
                    <div class="col-lg-6">
                      <label for="dropdowntext">Import from Option group</label>
                      <select class="form-select pb-0 horizondalscroll" id="exampleFormControlSelect2"
                        v-model="selectedmodel" @change.prevent="dropdownmodelchange()">
                        <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                          {{ models.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsradio">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsradio"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Alignment</label>
                    <select v-model="radioorientation" @change="handleChange" class="form-control"
                      aria-label="Default select example">
                      <option value="horizondal">Horizontal</option>
                      <option value="vertical">Vertical</option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row mb-3">
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Code Value</label>
                      </div>
                      <div class="col-lg-9 mb-2 pl-0">
                        <input ref="codeValueInput" type="text" class="form-control" v-model="radiovalues"
                          maxlength="50" />
                      </div>
                      <div class="col-lg-3 pr-0">
                        <label class="mb-0">Option Name</label>
                      </div>
                      <div class="col-lg-9 pl-0">
                        <input type="text" ref="optionNameInput" class="form-control" v-model="radiooptions"
                          id="optionscheckbox" maxlength="50" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-5">
                        <!-- Add button  -->
                        <div style="display: flex;">
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="addradiooptions()"
                            v-if="editingOption === null" :disabled="radiovalues == '' ||
                              radiovalues == null ||
                              radiooptions == '' ||
                              radiooptions == null
                              ">
                            Add
                          </button>
                          <!-- Update button -->
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="UpdateDropdownoptions()"
                            v-else>
                            Update
                          </button>
                          <div style="margin-left: 10px;"></div>
                          <!-- Cancel button -->
                          <button type="button" class="save_btn px-3 py-1" @click.prevent="cancelEditDropdown()"
                            v-if="editingOption !== null" style="background-color: red; color: white;">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group textgroup" style="overflow-y: auto; max-height: 250px;">
                      <!-- <label for="optionslistcheckbox">Options</label> -->
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Code Value</th>
                            <th>Option Name</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="option in radiooptionsarray" :key="option.codevalue">
                            <td>{{ option.codevalue }}</td>
                            <td>{{ option.optionname }}</td>
                            <td>
                              <i class="fa fa-edit edit-delete" style="color: #a13b7c" @click="
                                editOptionsfordropdown(
                                  option.codevalue,
                                  option.optionname
                                );
                              focusOnEdit();
                              "></i>
                            </td>
                            <td>
                              <i class="fa fa-trash edit-delete" style="color: #a13b7c"
                                @click="deleteoptions(option.codevalue)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <span class="error-message" v-if="dropdownError">Please add options for the radio</span>
                    </div>
                    <div class="form-group textgroup">
                      <label>Validations</label>
                      <Multiselect class="multiselect-theme" mode="multiple" v-model="values"
                        :options="commonvalidations" :hideSelected="false" :closeOnSelect="false" />
                    </div>
                    <div class="my-4 row">
                      <div class="col-md-12 d-flex justify-content-end">
                        <div class="position-relative" v-if="!isedit">
                          <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                            @click.prevent="addfield('radio')" />
                          <div class="tool-tip">Add</div>
                        </div>
                        <div class="position-relative" v-if="isedit">
                          <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                            @click="editfield()" />
                          <div class="tool-tip">Save</div>
                        </div>
                        <div class="position-relative">
                          <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                            @click.prevent="deletecomponent()" />
                          <div class="tool-tip">Delete</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- radiobutton ends  -->
              <!-- add survey starts -->
              <div v-if="selectedmenu == 'addsurvey'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Add Survey</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Add survey Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter survey label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('addsurvey')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- add survey ends  -->
              <!-- repeatedmeasure starts  -->
              <div v-if="selectedmenu == 'repeatedmeasure'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Repeated Measure</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="repeatedmeasuretext">Repeated Measure Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="repeatedmeasuretext"
                      placeholder="Enter repeated measure label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsrepeatedmeasure">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsrepeatedmeasure" placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedmeasure()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmeasure ends  -->
              <!-- repeatedmodel starts  -->
              <div v-if="selectedmenu == 'repeatedmodel'" class="fieldentry">
                <div class="ibox-title">
                  <h5>repeatedmodel</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedfield()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmodel ends  -->
              <!-- year starts  -->
              <div v-if="selectedmenu == 'year'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Year</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="yeartext">Year Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="yeartext"
                      placeholder="Enter year label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('year')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- year ends  -->
              <!-- dateandtime starts -->
              <div v-if="selectedmenu == 'dateandtime'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Date and Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Date and Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter date and time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('dateandtime')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- dateandtime ends  -->
              <!-- image starts -->
              <div v-if="selectedmenu == 'image'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Image</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Image Label</label>
                    <textarea rows="4" cols="50" type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter image label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup displayimage">
                    <div class="image-preview">
                      <img @click="onFileeditchange()" v-if="!isedit" class="preview" :src="imageData" />
                      <img @click="onFileeditchange()" v-if="isedit" class="templatepreview" :src="`${fileuploadurl}/file/${imagid.split(',')[0]
                        }?versionid=${imagid.split(',')[1]}`
                        " />
                    </div>
                  </div>
                  <hr />
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('image')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- image ends  -->
              <!-- checkbox starts -->
              <div v-if="selectedmenu == 'checkbox'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Checkbox 123123</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="checkboxtext">Checkbox Label ML</label>
                    <input type="text" v-model="labeltext" class="form-control" id="checkboxtext"
                      placeholder="Enter checkbox label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionscheckbox">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionscheckbox"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('checkbox')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- checkbox ends  -->
              <!-- textarea starts -->
              <div v-if="selectedmenu == 'textarea'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Textarea</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Textarea Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter textarea label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('textarea')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- textarea ends  -->
              <!-- add values menu ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->
  <!-- modal 6 starts -->
  <teleport to="#app">
    <div class="modal__wrapper__component" v-if="aitoolModal">
      <div class="component__modal__containers">
        <div class="titleclass d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Fill Form</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemodaltwo()"></i>
          </a>
        </div>
        <div id="modalcontents" class="modalcontents">
          <div class="row">
            <div class="col-lg-12">
              <!-- textbox starts -->
              <div v-if="selectedmenu == 'textbox' || selectedmenu == 'text'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Textbox</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                  <div class="align-content-center">
                    <input class="mr-1"
                      type="checkbox" id="flexCheckDefault" value="" v-model="templateFieldProperties.featureField"/>
                    <label
                       for="flexCheckDefault">Feature Field&nbsp;?</label>   
                  </div>
                
                       <div class="row mb-2">
                        <div class="col-8">
                          <input type="text" class="form-control" placeholder="Text Field"
                            v-model="fieldstoConsiderText[0].considerableFields[0]" :disabled="!templateFieldProperties.featureField"/>
                        </div>
                        <div class="col-4">
                          <input type="text" class="form-control" placeholder="%" aria-label="Recipient's username"
                            v-model="fieldstoConsiderText[0].considerationRate" :disabled="!templateFieldProperties.featureField"/>
                        </div>   
                       </div>
                                   
                    <div v-for="(item, index) in templateFieldProperties.fieldsToConsider" :key="index"
                      class="repeatstring">
                      <div class="row mb-2">
                        <div class="col-8">
                          <input type="text" class="form-control" placeholder="Text Field"
                            v-model="item.considerableFields[0]" :disabled="!templateFieldProperties.featureField"/>
                        </div>
                        <div class="col-4">
                          <input type="text" class="form-control" placeholder="%" aria-label="Recipient's username"
                            v-model="item.considerationRate" :disabled="!templateFieldProperties.featureField">
                        </div>
                        </div>
                      </div>

                    </div> 

                    <div class="text-right">
                        <button class="btn btn-outline-secondary mr-2" type="button" @click="addItem('text')">+</button>                 
                      <button type="button" class="btn btn-primary" @click="savepredictions('text')">Save</button>
                    </div>
                  </div>


                </div>
              <!-- textbox ends -->
              <!-- Grid starts -->
              <div v-if="selectedmenu == 'grid'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Grid</h5>
                  <nav class="nav nav-pills flex-column flex-sm-row">
                    <a :class="gridgeneral" aria-current="page" @click.prevent="selectconf('general')">General</a>
                    <a :class="gridconfiguration" @click.prevent="selectconf('configuration')">Configuration</a>
                  </nav>
                </div>
                <div v-if="gridoption == 'general'" class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Grid Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter grid label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                </div>
                <div v-if="gridoption == 'configuration'">
                  <div class="ibox-content-edit flextable">
                    <div class="addfieldtable">
                      <div>Row Label</div>
                      <!-- <div v-for="repeatingdatas in repeatingdata" :key="repeatingdatas" class="col-md-12 d-flex justify-content-end distancebetween"> -->
                      <div class="rowcolmn" v-for="(gridrow, index) in gridrows" :key="gridrow">
                        <input class="form-control" v-model="griddata[0].Rows[index]" type="text" />
                        <div v-if="gridrow == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementrows()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removerowsrows(index)"></i>
                        </div>
                      </div>
                    </div>
                    <div class="addfieldtable">
                      <div>Column Label</div>
                      <div class="rowcolmn" v-for="(gridcolumn, index) in gridcolumns" :key="gridcolumn">
                        <input class="form-control" v-model="griddata[0].Columns[index]" type="text" />
                        <div v-if="gridcolumn == 1">
                          <i class="fa fa-plus-square-o iconstyle" aria-hidden="true"
                            @click.prevent="incrementcolumns()"></i>
                        </div>
                        <div v-else>
                          <i class="fa fa-times-circle-o closeiconstyle" aria-hidden="true"
                            @click.prevent="removecolumns(index)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-10 rowfieldtype" v-for="(gridrow, index) in gridrows" :key="gridrow">
                    <label>Field Type of Row {{ gridrow }}</label>
                    <select class="col-md-10 form-control" v-model="griddata[0].Fieldtypes[index]" name="fieldtype">
                      <option value="text">text</option>
                      <option value="number">number</option>
                      <option value="time">time</option>
                      <option value="date">date</option>
                    </select>
                  </div>
                </div>
                <div class="my-4 row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <div class="position-relative" v-if="!isedit">
                      <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                        @click.prevent="addfield('grid')" />
                      <div class="tool-tip">Add</div>
                    </div>
                    <div class="position-relative" v-if="isedit">
                      <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                        @click="editfield()" />
                      <div class="tool-tip">Save</div>
                    </div>
                    <div class="position-relative mr-4">
                      <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                        @click.prevent="deletecomponent()" />
                      <div class="tool-tip">Delete</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid ends  -->
              <!-- model starts -->
              <div v-if="selectedmenu == 'model'" class="fieldentry">
                <!-- <div class="ibox-modal-title">
                    <h5>Model</h5>
                  </div> -->
                <div class="ibox-content-edit">
                  <div class="form-group textgroup">
                    <label class="col-form-label" for="instructionstext">Select model</label>
                    <select class="form-control horizondalscroll" id="exampleFormControlSelect2" v-model="selectedmodel"
                      @change.prevent="modelchange()">
                      <option v-for="models in optiongroups.results" :value="models" :key="models.id">
                        {{ models.label }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group textgroup">
                    <label for="optionslistcheckbox">Options</label>
                    <select multiple class="form-control horizondalscroll" id="exampleFormControlSelect2">
                      <option v-for="options in selectedmodel.groupoptions" :key="options" disabled>
                        ({{ options.codevalue }}){{ options.optionname }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="optionscheckbox">Add Options</label>
                    <div class="row">
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodelvalues" placeholder="Code value"
                          maxlength="50" />
                      </div>
                      <div class="col-lg-5">
                        <input type="text" class="form-control" v-model="singlemodeloptions" id="optionscheckbox"
                          placeholder="Option name" maxlength="50" />
                      </div>
                      <div class="col-lg-2">
                        <button type="button" class="save_btn px-3 py-1" @click.prevent="addmodeloptions()" :disabled="singlemodelvalues == '' ||
                          singlemodelvalues == null ||
                          singlemodeloptions == '' ||
                          singlemodeloptions == null
                          ">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label" for="modelelem">Select Control Type</label>
                    <div class="col-md-12">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="radio" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Radio
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" value="dropdown" v-model="modeloptiontype" type="radio"
                          name="flexRadioDefault" id="flexRadioDefault2" />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Dropdown
                        </label>
                      </div>
                      <div class="option-error-block" v-if="controlSelectError">
                        Please select a control type
                      </div>
                    </div>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addmodel()" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- model ends -->
              <!-- headding starts -->
              <div v-if="selectedmenu == 'header'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-form-label" for="headdingtext">Add Heading</label>
                      <input type="text" v-model="labeltext" class="form-control" id="headdingtext"
                        placeholder="Enter Heading" />
                    </div>
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label class="col-form-label" for="instructionstext"
                        >Field Name</label
                      >
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('header')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative me-3" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- headding ends -->
              <!-- subheading starts -->
              <div v-if="selectedmenu == 'subHeader'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Sub Heading</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="subheaddingtext">Add Sub heading</label>
                    <input type="text" v-model="labeltext" class="form-control" id="subheaddingtext"
                      placeholder="Enter Heading" />
                  </div>
                  <!-- <div class="form-group textgroup">
                      <label for="instructionstext">Field Name</label>
                      <input
                        type="text"
                        v-model="fieldname"
                        maxlength="30"
                        class="form-control"
                        id="instructionstext"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <div class="mt-auto row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('subHeader')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- subheading ends -->
              <!-- dropdown starts -->
              <div v-if="selectedmenu == 'dropdown'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Dropdown</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">                    
                      <div class="col-md-12 py-1">
                        <label class="col-form-label">Accepted Fields</label>
                        <Multiselect
                          v-model="selectedDropdown"
                          mode="multiple"
                          :valueProp="'codevalue'"
                          placeholder="Select a value"
                          :label="'optionname'"
                          :options="filteredOptions1"
                          :hideSelected="false"
                          :closeOnSelect="false"
                          class="multiselect-theme ml-1 mr-0"
                        >
                        </Multiselect>
                      </div>
                      <div class="col-md-12 py-1">
                        <label class="col-form-label">Rejected Fields</label>
                        <Multiselect                        
                          v-model="selectedDropdown2"
                          mode="multiple"
                          :valueProp="'codevalue'"
                          placeholder="Select a value"
                          :label="'optionname'"
                          :options="filteredOptions2"
                          :hideSelected="false"
                          :closeOnSelect="false"
                          class="multiselect-theme ml-1 mr-0"
                        >
                        </Multiselect>
                      </div>
                      <div class="row mx-0 px-0">
                        <label class="col-form-label">Fields to consider</label> 
                      <div v-for="(item, index) in fieldsConsider" :key="index"> 
                        <div class="row mx-0">
                      <div class="col-md-8 py-1 pl-0">                       
                        <Multiselect                 
                          v-model="item.considerableFields"
                          mode="multiple"
                          valueProp="codevalue"
                          placeholder="Select a value"
                          label="optionname"
                          :options="getFilteredOptions(index)"
                          :hideSelected="false"
                          :closeOnSelect="false"
                          class="multiselect-theme ml-1 mr-0"
                        >
                        </Multiselect>
                      </div>
                      <div class="col-md-4 py-1 pr-0">
                        <input type="text" class="form-control" v-model="item.considerationRate" placeholder="%">
                      </div>
                      </div>
                    </div> 
                  </div>
                    <div class="text-right">
                      <button class="btn btn-outline-secondary mr-2" type="button" @click="addropItem()">+</button>
                      <button type="button" class="btn btn-primary" @click="savepredictions('dropdown')">Save</button>
                  </div>                
                </div>

                     
                    </div>
              </div>
              <!-- dropdown ends dropdown -->
              <!-- summary starts -->
              <div v-if="selectedmenu == 'summary'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Summary</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textboxtext">Summary</label>
                    <textarea class="form-control" v-model="labeltext" id="textboxtext"
                      placeholder="Enter summary label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextbox">Field Name</label>
                    <input type="text" class="form-control" v-model="fieldname" id="instructionstextbox"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('summary')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- summary ends  -->
              <!-- date starts -->
              <div v-if="selectedmenu == 'date'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Date Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('date')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- date ends dropdown -->
              <!-- scales starts -->
              <div v-if="selectedmenu == 'scales'" class="fieldentry">
                <!-- <div class="ibox-title">
                    <h5>Scale</h5>
                  </div> -->
                <div class="ibox-content-edit">
                  <!-- <div class="form-group">
                      <label for="textboxtext">Scale Label</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="labeltext"
                        id="textboxtext"
                        placeholder="Enter scale label"
                      />
                    </div> -->
                  <!-- <div class="form-group textgroup">
                      <label for="instructionstextbox">Field Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="fieldname"
                        maxlength="30"
                        id="instructionstextbox"
                        placeholder="Field Name"
                      />
                    </div> -->
                  <!-- <div class="form-group textgroup">
                      <label>Validations</label>
                      <Multiselect
                        class="multiselect-theme"
                        mode="multiple"
                        v-model="values"
                        :options="commonvalidations"
                        :hideSelected="false"
                        :closeOnSelect="false"
                      />
                    </div> -->
                  <div class="row" style="padding-bottom:10px;">
                    <div class="col-sm-6"> <label class="form-check-label" style="float: left; padding-right: 30px;"
                        for="flexCheckDefault">Feature Field? </label><input class="form-check-input"
                        style="float:right" type="checkbox" value="" v-model="templateFieldProperties.featureField">
                    </div>
                    <div class="col-sm-6"></div>
                  </div>

                  <div class="row" style="padding-bottom: 20px">
                    <div class="col-sm-12">
                      <div v-for="(item, index) in templateFieldProperties.rangesToConsider" :key="index"
                        class="row repeatstring">

                        <div class="col-sm-4">


                          <label>Min Value</label>
                          <input v-model="item.min" type="number" class="form-control" placeholder="Minimum value"
                            @input="clearInput()" />
                          <span class="error-message" v-if="scaleError[2]">Only accepts digits and '-' (minus
                            sign).</span>
                        </div>
                        <div class="col-sm-4">
                          <label>Max Value</label>
                          <input v-model="item.max" type="number" class="form-control" placeholder="Maximum value"
                            @input="clearInput()" />
                          <span class="error-message" v-if="scaleError[3]">Only accepts digits and '-' (minus
                            sign).</span>
                        </div>
                        <div class="col-sm-4">
                          <label>consideration Rate</label>
                          <input v-model="item.considerationRate" type="number" class="form-control"
                            placeholder="Consideration value" @input="clearInput()" />
                          <span class="error-message" v-if="scaleError[4]">Only accepts digits and '-' (minus
                            sign).</span>
                        </div>
                      </div>
                      <div>
                        <span class="error-message" v-if="scaleError[0]">Values entered on min value and max value
                          fields
                          cannot
                          be same.</span>
                        <span class="error-message" v-if="scaleError[1]">Value entered on min value must be lower than
                          value
                          entered on max value field.</span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 ml-auto">
                      <button class="btn btn-outline-secondary" type="button" @click="saverangebutton()">+</button>
                    </div>
                    <div class="text-right">
                      <button type="button" class="btn btn-primary" @click="savepredictionbutton">Save</button>
                    </div>
                  </div>
                  <!-- <div class="my-4 row">
                      <div class="col-md-12 d-flex justify-content-end">
                        <div class="position-relative" v-if="!isedit">
                          <img
                            class="cursor-pointer me-3"
                            src="../../assets/add_doc.png"
                            alt="Add field"
                            @click.prevent="addfield('scales')"
                          />
                          <div class="tool-tip">Add</div>
                        </div>
                        <div class="position-relative" v-if="isedit">
                          <img
                            class="cursor-pointer me-3"
                            src="../../assets/save.png"
                            alt="Save field"
                            @click="editfield()"
                          />
                          <div class="tool-tip">Save</div>
                        </div>
                        <div class="position-relative">
                          <img
                            class="cursor-pointer"
                            src="../../assets/delete_doc.png"
                            alt="Delete field"
                            @click.prevent="deletecomponent()"
                          />
                          <div class="tool-tip">Delete</div>
                        </div>
                      </div>
                    </div> -->
                </div>
              </div>
              <!-- scale ends  -->
              <!-- calculation starts -->
              <div v-if="selectedmenu == 'calculation'" class="fieldentry">
                <div class="ibox-title">
                  <h5>calculation</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="calculatedfieldtext">Calculation Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="calculatedfieldtext"
                      placeholder="Enter calculated field label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionscalculated">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionscalculated"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label for="timetext">First field</label>

                    <select class="form-control dropdownbackground" v-model="calculate.firstfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Second field</label>
                    <select class="form-control dropdownbackground" v-model="calculate.secondfield">
                      <option v-for="fieldnames in textFields" :key="fieldnames.fieldName"
                        :value="fieldnames.fieldName">
                        {{ fieldnames.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Operation</label>
                    <select class="form-control dropdownbackground" v-model="calculate.operations">
                      <option value="+">Add</option>
                      <option value="_">Substract</option>
                      <option value="/">Divide</option>
                      <option value="*">Multiply</option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('calculation')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- time starts -->
              <div v-if="selectedmenu == 'time'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect mode="multiple" v-model="values" :options="commonvalidations" :hideSelected="false"
                      :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('time')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- time ends  -->
              <!-- number and date starts -->
              <div v-if="selectedmenu == 'numberanddate'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Number and Date</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="numberanddatetext">Number and date label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="numberanddatetext"
                      placeholder="Enter number and date label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsnumberanddate">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsnumberanddate" placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('numberanddate')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- number and date ends  -->
              <!--uploadfile  starts -->
              <div v-if="selectedmenu == 'uploadfile'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Upload File</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="datetext">Upload File Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="datetext"
                      placeholder="Enter upload file label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsdate">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsdate"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('uploadfile')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- uploadfile ends  -->
              <!-- qrcode starts qrcode -->
              <div v-if="selectedmenu == 'qrcode'" class="fieldentry">
                <div class="ibox-title">
                  <h5>QR Code</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="qrcodetext">QR code Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="qrcodetext"
                      placeholder="Enter QR code label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name<sup><i class="fa fa-asterisk imp"></i></sup></label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>QR Code data <sup><i class="fa fa-asterisk imp"></i></sup></label>
                    <textarea class="form-control" v-model="qrcodecontents" id="qrcodecontents"
                      placeholder="Enter QR Code data" />
                    <span v-if="emptyField" class="error-message">Please fill the qr data.</span>
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('qrcode')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- qrcode ends  -->
              <!-- radiobutton starts -->
              <div v-if="selectedmenu == 'radio'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Radio</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">  
                    <div class="form-check">  
                      <input type="checkbox" class="form-check-input mr-1" v-model="featureValue" id="checkboxtext" />               
                      <label for="checkboxtext">Is Feature field&nbsp;?</label>      
                    </div>              
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input mr-1" v-model="inclusiveLabel"  @change="handleLabelChange($event,selectedmenu)" id="inclusiveRadio" :disabled="!featureValue"/>               
                          <label for="inclusiveRadio">Inclusive</label>      
                        </div>
                    </div>    
                    <div class="col-md-3">
                      <div class="form-check">
                          <input type="checkbox" class="form-check-input mr-1" v-model="exclusiveLabel"  @change="handleLabelChange($event,selectedmenu)" id="exclusiveRadio" value="exclusive" :disabled="!featureValue"/>               
                            <label for="exclusiveRadio">Exclusive</label>  
                        </div>    
                    </div>   
                  </div>  
                  <div class="form-group row">
                    <div class="col-md-12 py-1">
                      <label class="col-form-label">Accepted Fields</label>
                      <Multiselect
                        v-model="selectedDropdown"
                        mode="multiple"
                        :valueProp="'codevalue'"
                        placeholder="Select a value"
                        :label="'optionname'"
                        :disabled="(featureValue==false) || (exclusiveLabel && (inclusiveLabel==false)) || (!exclusiveLabel && !inclusiveLabel)"
                        :options="filteredOptions1"
                        :hideSelected="false"
                        :closeOnSelect="false"
                        class="multiselect-theme ml-1 mr-0"
                      >
                      </Multiselect>
                    </div>
                    <div class="col-md-12 py-1">
                      <label class="col-form-label">Rejected Fields</label>
                      <Multiselect                        
                        v-model="selectedDropdown2"
                        mode="multiple"
                        :disabled="(featureValue==false) || (inclusiveLabel && (exclusiveLabel==false)) || (!exclusiveLabel && !inclusiveLabel)"
                        :valueProp="'codevalue'"
                        placeholder="Select a value"
                        :label="'optionname'"
                        :options="filteredOptions2"
                        :hideSelected="false"
                        :closeOnSelect="false"
                        class="multiselect-theme ml-1 mr-0"
                      >
                      </Multiselect>
                    </div>    
                    <div class="row mx-0 px-0">
                        <label class="col-form-label">Fields to consider</label> 
                      <div v-for="(item, index) in fieldsConsider" :key="index"> 
                        <div class="row mx-0">
                          <div class="col-md-8 py-1 pl-0">                       
                            <Multiselect                 
                              v-model="item.considerableFields"
                              mode="multiple"
                              valueProp="codevalue"
                              :disabled="(inclusiveLabel && exclusiveLabel) || !featureValue || (inclusiveLabel || exclusiveLabel)"
                              placeholder="Select a value"
                              label="optionname"
                              :options="getFilteredOptions(index)"
                              :hideSelected="false"
                              :closeOnSelect="false"
                              class="multiselect-theme ml-1 mr-0"
                            >
                            </Multiselect>
                          </div>
                          <div class="col-md-4 py-1 pr-0">
                            <input type="text" class="form-control" v-model="item.considerationRate" 
                            :disabled="(inclusiveLabel && exclusiveLabel) || !featureValue || (inclusiveLabel || exclusiveLabel)" placeholder="%">
                          </div>
                        </div>
                      </div>
                    </div>   
                    <div class="text-right">
                      <button class="btn btn-outline-secondary mr-2" type="button" @click="addropItem()">+</button>
                      <button type="button" class="btn btn-primary" @click="savepredictions('radio')">Save</button>
                  </div>                       
                  </div>
                </div>
              </div>
              <!-- radiobutton ends  -->
              <!-- add survey starts -->
              <div v-if="selectedmenu == 'addsurvey'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Add Survey</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Add survey Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter survey label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('addsurvey')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- add survey ends  -->
              <!-- repeatedmeasure starts  -->
              <div v-if="selectedmenu == 'repeatedmeasure'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Repeated Measure</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="repeatedmeasuretext">Repeated Measure Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="repeatedmeasuretext"
                      placeholder="Enter repeated measure label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsrepeatedmeasure">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control"
                      id="instructionsrepeatedmeasure" placeholder="Field Name" />
                  </div>
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedmeasure()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmeasure ends  -->
              <!-- repeatedmodel starts  -->
              <div v-if="selectedmenu == 'repeatedmodel'" class="fieldentry">
                <div class="ibox-title">
                  <h5>repeatedmodel</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label>Select Repeated Model</label>

                    <select class="col-md-10 form-control" v-model="selectedrepeatedtemp" name="fieldtype">
                      <option v-for="repeatedoption in presavedrepeatedmodel" :key="repeatedoption.id"
                        :value="repeatedoption.field">
                        {{ repeatedoption.templateName }}
                      </option>
                    </select>
                  </div>

                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addrepeatedfield()" />
                        <div class="tool-tip">Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- repeatedmodel ends  -->
              <!-- year starts  -->
              <div v-if="selectedmenu == 'year'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Year</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="yeartext">Year Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="yeartext"
                      placeholder="Enter year label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionsyear">Field Name</label>

                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionsyear"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('year')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- year ends  -->
              <!-- dateandtime starts -->
              <div v-if="selectedmenu == 'dateandtime'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Date and Time</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="timetext">Date and Time Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="timetext"
                      placeholder="Enter date and time label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstime">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstime"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('dateandtime')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- dateandtime ends  -->
              <!-- image starts -->
              <div v-if="selectedmenu == 'image'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Image</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Image Label</label>
                    <textarea rows="4" cols="50" type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter image label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup displayimage">
                    <div class="image-preview">
                      <img @click="onFileeditchange()" v-if="!isedit" class="preview" :src="imageData" />
                      <img @click="onFileeditchange()" v-if="isedit" class="templatepreview" :src="`${fileuploadurl}/file/${imagid.split(',')[0]
                        }?versionid=${imagid.split(',')[1]}`
                        " />
                    </div>
                  </div>
                  <hr />
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('image')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- image ends  -->
              <!-- checkbox starts -->
              <div v-if="selectedmenu == 'checkbox'" class="fieldentry">
                <div class="ibox-title">
                  <h5>Checkbox</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">  
                    <div class="form-check">  
                      <input type="checkbox" class="form-check-input mr-1" v-model="featureValue" id="checkboxtext" />               
                      <label for="checkboxtext">Is Feature field</label>      
                    </div>              
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input mr-1" v-model="inclusiveLabel"  @change="handleLabelChange($event,selectedmenu)" id="inclusiveRadio" :disabled="!featureValue"/>               
                          <label for="inclusiveRadio">Inclusive</label>      
                        </div>
                    </div>    
                    <div class="col-md-3">
                      <div class="form-check">
                          <input type="checkbox" class="form-check-input mr-1" v-model="exclusiveLabel"  @change="handleLabelChange($event,selectedmenu)" id="exclusiveRadio" value="exclusive" :disabled="!featureValue"/>               
                            <label for="exclusiveRadio">Exclusive</label>  
                        </div>    
                    </div>   
                  </div>  
                  <div class="form-group row">
                    <div class="col-md-12 mb-3">
                      <div class="d-flex">
                        <label for="truetext" class="mr-2 mb-0">If true consideration rate</label>     
                        <input type="text" placeholder="Enter a value" class="mr-1" v-model="trueConsideration" id="truetext" :disabled="!featureValue || !considerationRate"/>               
                          
                        </div>
                    </div>    
                    <div class="col-md-12">
                      <div class="d-flex">
                        <label for="falsetext" class="mr-2 mb-0">If false consideration rate</label>     
                        <input type="text" placeholder="Enter a value" class="mr-1" v-model="falseConsideration" id="falsetext" :disabled="!featureValue || !considerationRate"/>               
                          
                        </div>   
                    </div>   
                  </div>        
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('checkbox')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- checkbox ends  -->
              <!-- textarea starts -->
              <div v-if="selectedmenu == 'textarea'" class="fieldentry">
                <div class="ibox-modal-title">
                  <h5>Textarea</h5>
                </div>
                <div class="ibox-content-edit">
                  <div class="form-group">
                    <label for="textareatext">Textarea Label</label>
                    <input type="text" v-model="labeltext" class="form-control" id="textareatext"
                      placeholder="Enter textarea label" />
                  </div>
                  <div class="form-group textgroup">
                    <label for="instructionstextarea">Field Name</label>
                    <input type="text" v-model="fieldname" maxlength="30" class="form-control" id="instructionstextarea"
                      placeholder="Field Name" />
                  </div>
                  <div class="form-group textgroup">
                    <label>Validations</label>
                    <Multiselect class="multiselect-theme" mode="multiple" v-model="values" :options="commonvalidations"
                      :hideSelected="false" :closeOnSelect="false" />
                  </div>
                  <div class="my-4 row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <div class="position-relative" v-if="!isedit">
                        <img class="cursor-pointer me-3" src="../../assets/add_doc.png" alt="Add field"
                          @click.prevent="addfield('textarea')" />
                        <div class="tool-tip">Add</div>
                      </div>
                      <div class="position-relative" v-if="isedit">
                        <img class="cursor-pointer me-3" src="../../assets/save.png" alt="Save field"
                          @click="editfield()" />
                        <div class="tool-tip">Save</div>
                      </div>
                      <div class="position-relative">
                        <img class="cursor-pointer" src="../../assets/delete_doc.png" alt="Delete field"
                          @click.prevent="deletecomponent()" />
                        <div class="tool-tip">Delete</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- textarea ends  -->
              <!-- add values menu ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->

</template>

<script src="./mlCriteriaCapture.js"></script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
@import "./style1.css";
@import "./modal.css";

.error-message {
  color: #ff0000;
}

.makeitbottom {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.gridelem {
  overflow: auto;
}

.element-block {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.drag-component-block {
  border: 1px dashed #c2b6b6;
  font-size: 20px;
  color: #ada9a9;
  background-color: #ffffff;
}

.multiselect-theme {
  --ms-radius: 0;
  --ms-py: 0.2rem;
  --ms-option-font-size: 0.8rem;
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}

.form-control {
  font-size: 0.8rem;
}

.activeBackground {
  background-color: #dddddd;
}

.bgblue {
  background-color: #f0f0ff;
}

.max-height-500 {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.side-popup {
  text-align: left;
  left: 14px;
  width: 170px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  -moz-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  -webkit-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.side-popup>*:is(:hover, :focus) {
  background-color: #e4eaf9;
}

.expand-delete-block:is(:hover, :focus) {
  border-radius: 50%;
  background-color: #dddddd;
}

.max-height-0 {
  max-height: 0;
}

.max-height-30 {
  max-height: 300px;
}

.template-list li {
  overflow: hidden;
  transition: max-height 0.2s ease-out, padding-block 0.2s ease-out;
}

.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100pc;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}

.add_page {
  width: min(400px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  height: 150px;
}

.option_modal__containers {
  width: min(600px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  min-height: 316px;
  position: fixed;
  margin-top: 100px;
  max-height: 400px;
}

.component__modal__containers {
  width: min(500px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  height: 370px;
  margin-top: 100px;
}

.maincontainer {
  min-height: 50px;
}

.ibox-content-elements {
  background-color: #c4cfff;
  padding: 0px;
  border-color: #dedede;
  border-image: none;
  border-radius: 0px;
  border-style: solid;
  border-width: 0px;
  padding-top: 3px;
  padding-bottom: 5px;
}

.ibox-modal-title {
  background-color: #eeedff;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  padding: 15px 15px 8px 15px;
  min-height: 40px;
  position: relative;
  clear: both;
  width: unset;
}

.ibox-modal-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  color: #676a6c;
}

.ibox-content-elements h5 {
  font-size: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.updatebutton {
  width: 100%;
  padding: 20px;
}

.updatebtn {
  float: right;
}

.deletepagebtn {
  float: left;
}

.btncenter {
  margin: auto;
}

.groupcontents {
  width: 100%;
  padding: 20px;
}

.opttext {
  width: 80%;
}

.optgroup {
  display: flex;
  gap: 5px;
}

.selectzindex {
  z-index: 10000;
}

.addcontainer {
  width: 100%;
}

.outline-add {
  color: green;
  font-size: 14px;
  font-weight: bold;
  margin-left: 25px;
}

.outline-add:hover,
.outline-delete:hover {
  cursor: pointer;
}

.placebuttons {
  display: flex;
  margin-right: 0px;
  margin-left: 220px;
}

.textflex {
  display: flex;
}

.outline-delete {
  color: red;
  font-size: 14px;
  font-weight: bold;
}

.fieldentry {
  background-color: #ffffff;
  min-height: 320px;
}

.textgroup {
  margin-top: 10px;
}

.settingsmenu {
  width: 300px;
  background-color: #ffffff;
}

.mainmenuitem {
  margin-bottom: 1px;
  font-weight: 600;
  padding: 0px 20px 0px 20px;
}

.submenuitem {
  border-top: 0px solid #ffffff;
  background-color: #eeedff;
  width: 100%;
  border-bottom: 1px solid #d0d4df;
}

.submenuitem:hover {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.dropdown {
  height: 100%;
}

.maincontents {
  background-color: white;
  height: 100%;
}

.home {
  background-color: #f3f3f4;
  min-height: 100vh;
  display: flex;
}

.navbar-static-side {
  background-color: #e6e5ea;
}

.metismenu {
  width: 220px;
}

.text-right {
  text-align: end;
}

.ibox-content-edit-toolbar {
  position: relative;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  border-color: #dedede;
  border-image: none;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
}

.dropdownbackground {
  background-color: #fff !important;
}

.ibox-content-edit {
  background-color: #ffffff;
  padding: 15px 20px 0px 20px;
  border-color: #80ced7;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 0px;
  display: flex;
  flex-direction: column;
}

.icon-layout {
  float: right;
  line-height: 1.5;
}

.nav-label {
  font-weight: 600;
}

.checkboxcontainer {
  width: fit-content;
  min-width: 15%;
}

.labelclass {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
}

.dummydata:is(:active, :focus) {
  background-color: #cbd2eb;
}

.columtable {
  border: 1px solid #c2b6b6;
  border-collapse: collapse;
  height: 30px;
  margin-bottom: 10px;
}

.btnwidth {
  min-width: 50px;
  background-color: #5f64a3;
  border-color: #5f64a3;
}

.btnwidth:hover {
  min-width: 50px;
  background-color: #5f64a3;
  border-color: #5f64a3;
}

.rowcolmnc {
  min-width: 60px;
}

.titleclass {
  color: #ffffff;
  background: #768dd3;
  z-index: 2000;
}

.add-block-title {
  color: #ffffff;
  background: #768dd3;
}

.firstmodalheight {
  height: 38% !important;
}

.ibox-title {
  background-color: #eeedff;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  padding: 12px 15px 8px 15px;
  min-height: 40px;
  position: relative;
  clear: both;
  width: unset;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  color: #676a6c;
}

.savebutton {
  font-size: 20px;
  color: black !important;
  margin-right: 16px !important;
}

.savebutton :hover {
  color: #7aa6ff !important;
}

.deletebutton {
  font-size: 20px;
  color: black !important;
  margin-right: 16px !important;
}

.deletebutton :hover {
  color: red !important;
}

.version {
  width: 85px;
  border-color: #dedede;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
}

.resizemodel {
  width: min(200px, 90%);
}

.addpage {
  width: 100% !important;
}

.addpagewrapper {
  margin-top: 30px !important;
}

.headerheight {
  min-height: 50px;
}

.tooldisplay {
  display: flex !important;
}

.versionbox {
  margin-top: 5px;
}

.pointer {
  visibility: hidden;
  width: max-content;
  background-color: #ec4758;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.rowcolmn {
  margin-top: 0px;
  display: flex;
  gap: 5px;
  margin: 10px;
}

.rowlabelgrid {
  margin-top: auto;
}

.ibox-title-elements {
  color: #ffffff !important;
  text-transform: uppercase;
  padding-block: 0.56rem;
  background-color: #768dd3;
  border-color: #dedede;
  border-image: none;
  border-style: solid solid none;
  border-width: 0px;
  margin-bottom: 0;
  min-height: 30px;
  position: relative;
  clear: both;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 0px;
}

.ibox-title-elements h5 {
  font-size: 0.8rem !important;
}

.elements-tools-bg {
  width: 100px;
  background-color: #ffffff;
  color: #676a6c;
  border: 1px solid #e7eaec;
  cursor: pointer;
}

.element-tools-title {
  font-size: 0.75rem;
}

.elements-margins {
  margin-top: 3px;
  margin-bottom: 3px;
}

.elements-margins-large {
  margin: 0px -9px 0px 1px !important;
  padding: 0px 12px 0px 8px !important;
}

.close-link {
  color: white;
}

.horizondalscroll {
  overflow-x: auto;
}

.save_btn {
  background-color: #6a97e7 !important;
  /* border-color: #1ab394; */
  border: none;
}

.cancel_btn {
  /* border-color: #1ab394; */
  border-radius: 10%;
  margin-left: 10px;
  padding: 2px !important;
}

.tabactive {
  background-color: #80ced7;
}

.forsecondtab {
  margin-left: auto;
}

.modalcontents {
  overflow-x: hidden;
  overflow-y: auto;
  height: 320px;
}

.rangeclass {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.box {
  min-width: 100%;
  height: 43px;
  line-height: 100px;
  margin-top: 10px;
  opacity: 1;
  /* transition: opacity 0.5s; */
  border: solid;
  border-width: 1px;
  border-color: #c7c7c8;
  display: flex;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

img {
  max-width: 100%;
}

.navpadding {
  padding-bottom: 15px;
}

.elementsmenu {
  margin-left: 5px;
  width: 100px;
}

.elements_container {
  height: 460px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.elements_container {
  overflow-y: scroll;
  /* add a vertical scroll bar */
  scrollbar-width: thin;
  /* set the width of the scrollbar */
  scrollbar-color: #333 #fff;
  /* set the color of the scrollbar */
}

/* Customize the appearance of the scrollbar */
.rowcolmn {
  margin-top: 0px;
  display: flex;
  gap: 5px;
  margin: 10px;
}

.elements_container::-webkit-scrollbar {
  width: 8px;
}

.elements_container::-webkit-scrollbar-thumb {
  background-color: #85a0d1;
  border-radius: 8px;
}

.dummydataenter {
  background-color: #c5c5e2;
}

.hiddenfile {
  display: none;
}

/* .twoitemflex{
    display:flex;
  } */
.elements_container::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .md-block>* {
    display: block;
    margin: auto;
  }

  .elements-margins {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .elements-margins-large {
    margin: 0px !important;
    padding: 0px 5px 0px 8px !important;
  }

  .addcontainer {
    width: 79%;
  }

  .totaldiv {
    min-height: 300px;
  }

  .navbar.navbar-static-top {
    padding: 0 1rem !important;
  }

  iframe {
    position: absolute;
    float: left;
    clear: both;
    width: 100%;
    height: 350px;
    z-index: 0;
    left: no-repeat;
  }

  .navbar-header {
    align-self: center;
  }

  .flex-item {
    background-color: #f0f0ff !important;
  }

  .minimalize-styl-2 {
    margin: 0 !important;
  }

  .nav>li>a {
    font-size: 1.2em;
    padding: 1rem;
  }

  .nav>li>a i {
    font-size: 1em;
  }

  #wrapper {
    position: relative !important;
  }

  .pace-done #page-wrapper,
  .body.mini-navbar #page-wrapper {
    width: 100% !important;
  }

  .mini-navbar #mobilemenu>div {
    width: 0px;
    transition: width ease-in-out 400ms !important;
  }
}

.tab-pane {
  padding: 10px;
  background-color: #c4cfff;
}

.radiohorizondalclass {
  display: flex;
  gap: 2;
  flex-wrap: wrap;
}

.maxheight {
  height: 86%;
}

.maxheightdesigncomplete {
  height: 270px;
}

.option-error-block {
  color: hsl(0, 100%, 50%);
}

.font-15 {
  font-size: 15px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999;
  /* Make sure overlay is above the iframe */
}

.repeatContainer {
  border: groove;
}

</style>