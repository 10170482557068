<template>
  <div  class="stat-card-white" id="piechartcomp" ref="captureArea">
    <div class="stat-header-white">
      <span>{{ computedChartLabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <select
          class="graph-select py-0"
          v-model="selectedAttribute"
          id="attribute-select"
          @change="updateChart"
        >
          <option
            v-for="option in attributeOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <!-- <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="backtooriginal()">
              <img  src="../../assets//img/undo.png" alt="Undo block">
          </div> -->
        <div v-if="!loader" class="expand-block px-2 mb-1 position-relative">
          <img
            class="cursor-pointer"
            @click="showtable()"
            src="../../assets//img/table_view.png"
            alt="More block"
          />
          <div class="more-expand">
            <span>{{$t("navigations.data overview")}}</span>
          </div>
        </div>
        <div
          v-if="!loader"
          class="expand-block px-2 mb-1 cursor-pointer"
          @click.prevent="expandBlock = true"
        >
          <img src="../../assets//img/expand.png" alt="Expand block" />
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="stat-body-white chart-container d-flex flex-column"
      ref="chartContainerBlock"
    >
      <div v-if="loader" class="loader"></div>
      <div
        v-if="!loader && selectedGroups.length != 0"
        class="selectdrill d-flex flex-row-reverse gap-2 w-100"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_cancel.png"
          @click.prevent="cancelselection()"
          alt="Save profile"
        />
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_submit.png"
          @click.prevent="recreatechart()"
          alt="Save profile"
        />
      </div>
      <div v-if="!loader">
        <label
          class="mr-2"
          v-if="
            (enrolledornot && fromPage == 'patientScreening') ||
              fromPage == 'patientRetention'
          "
        >
          <input
            type="checkbox"
            v-model="aiPredictionTrue"
            @change="updateChart"
          />
          {{ labelOne }}
        </label>
        <label
          v-if="
            (enrolledornot && fromPage == 'patientScreening') ||
              fromPage == 'patientRetention'
          "
        >
          <input
            type="checkbox"
            v-model="aiPredictionFalse"
            @change="updateChart"
          />
          {{ labelTwo }}
        </label>
      </div>
      <svg ref="chartContainer" viewBox="0 0 500 500"></svg>
      <div ref="tooltip" class="tooltip"></div>
    </div>
  </div>
  <enlargerpopup
    v-if="expandBlock == true"
    @closeModal="(expandBlock = false), updateChart()"
  >
    <div class="stat-body-white d-flex flex-column w-100">
      <div
        v-if="!loader && selectedGroups.length != 0"
        class="selectdrill d-flex flex-row-reverse gap-2 w-100"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_cancel.png"
          @click.prevent="cancelselection()"
          alt="Save profile"
        />
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_submit.png"
          @click.prevent="recreatechart()"
          alt="Save profile"
        />
      </div>
      <svg ref="popupchartContainer"></svg>
    </div>
  </enlargerpopup>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';

export default {
  name: "PieChart",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
    enrolledornot: {
      type: Boolean,
      default: true,
    },
    labelOne: {
      type: String,
      default: "Enrolled",
    },
    labelTwo: {
      type: String,
      default: "Non-enrolled",
    },
    chartlabel: {
      type: String,
      default: "Interactive Analysis",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    displayValue: {
      type: Boolean,
      default: true,
    },
    percentage: {
      type: Boolean,
      default: true,
    },
    xaxiskey: {
      type: String,
      default: "",
    },
    xaxisvariables: {
      type: Array,
      required: true,
      default: () => [],
    },
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    enlargerpopup,
  },
  computed: {
    computedChartLabel() {
      return this.chartlabel.replace("$$", this.selectedAttribute || "");
    },
  },
  data() {
    return {
      showVisitPopup: false,
      displaydrillpopup: false,
      expandBlock: false,
      showMore: false,
      graphdata: [],
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      selectedAttribute: "",
      attributeOptions: [],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
      singlecolor: "",
      resizeObserver: null,
      barselectedarray: [],
      sortdata: [],
      filterkeyword: "",
      filterarray: [],
      selectedGroups: [],
      chartContainer: null,
      mouseX: 0,
      mouseY: 0,
      colormapping: {},
    };
  },
  watch: {
    data: {
      handler() {
        this.graphdata = this.data;
        this.loadQuantitativeAttributes();
        this.cancelselection();
        this.updateChart();
      },
      deep: true,
    },
    selectedAttribute() {
      this.cancelselection();
      this.updateChart();
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  async mounted() {
    window.addEventListener("mousemove", this.updateMousePosition);
    this.graphdata = this.data;
    this.idtoken = store.getters.getIdToken;
    if (this.fromPage == "patientScreening") {
      await this.createmapping();
    }
    this.tooltip = this.$refs.tooltip;
    this.createChart();

    // Add resize observer to make SVG responsive
    this.resizeObserver = new ResizeObserver(() => this.updateChart());
    this.resizeObserver.observe(this.$refs.chartContainerBlock);
  },
  beforeUnmount() {
    window.removeEventListener("mousemove", this.updateMousePosition);
    // Disconnect the observer on component destruction
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
},
exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      let filename = this.computedChartLabel || "chart_data";
      const opt = {
        margin: 0,
        filename: `${filename}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      const rect = this.$refs.chartContainerBlock.getBoundingClientRect();
      // Calculate the mouse X and Y positions relative to the component
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    async cancelselection() {
      this.selectedGroups = [];
      this.sortdata = [];
      this.updateChart();
    },
    showtable() {
      this.showMore = false;
      this.$emit("opendrillpopup", this.data);
    },
    async loadQuantitativeAttributes() {
      if (
        this.fromPage == "patientScreening" ||
        this.fromPage == "patientRetention"
      ) {
        const sampleData =
          this.graphdata &&
          this.graphdata.length > 0 &&
          this.graphdata[0].features
            ? this.graphdata[0].features
            : {};
        const qualitativeAttributes = [];

        Object.keys(sampleData).forEach((key) => {
          const uniqueValues = new Set(
            this.graphdata.map((item) => item.features[key])
          ).size;
          if (uniqueValues < 6) {
            qualitativeAttributes.push(key);
          }
        });
        this.attributeOptions = qualitativeAttributes;
        if (this.xaxiskey != "") {
          this.selectedAttribute = this.xaxiskey;
        } else {
          if (this.selectedAttribute == "") {
            this.selectedAttribute = qualitativeAttributes[0] || "";
          }
        }
      }
      if (
        this.fromPage == "descriptiveStatistics" ||
        this.fromPage == "deathSummary" ||
        this.fromPage == "statusReport"
      ) {
        if (this.xaxisvariables.length == 0) {
          const qualitativeAttributes = [];
          const sampleData =
            this.graphdata && this.graphdata.length > 0
              ? this.graphdata[0]
              : {};

          if (this.graphdata[0]) {
            const keys = Object.keys(this.graphdata[0]).filter(
              (key) =>
                key !== "_id" &&
                key !== "Participant ID" &&
                key !== "SubjectID" &&
                key != "USUBJID" &&
                key != "STUDYID" &&
                key != "SITEID" &&
                key != "DSSTDTC" &&
                key != "CMSCAT" &&
                key != "CMINDC" &&
                key != "RPDTC" &&
                key != "NATION" &&
                key != "CMCAT" &&
                key != "CMSCAT" &&
                key != "CMCOND" &&
                key != "RPNSDONE" &&
                key != "EDUCLVL" &&
                key != "DSREAS"
            );

            keys.forEach((key) => {
              const uniqueValues = new Set(
                this.graphdata.map((item) => item[key])
              ).size;
              qualitativeAttributes.push(key);
            });
            this.attributeOptions = qualitativeAttributes;
            if (this.xaxiskey != "") {
              this.selectedAttribute = this.xaxiskey;
            } else {
              this.selectedAttribute = qualitativeAttributes[0] || "";
            }
          }
        } else {
          this.attributeOptions = this.xaxisvariables;
          if (this.xaxiskey != "") {
            this.selectedAttribute = this.xaxiskey;
          } else {
            this.selectedAttribute = this.xaxisvariables[0] || "";
          }
        }
      }
    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach((page) => {
            page.field.forEach((field) => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce(
                  (map, option) => {
                    map[option.codevalue] = option.optionname;
                    return map;
                  },
                  {}
                );
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    validateData(data) {
      if (
        this.fromPage == "patientScreening" ||
        this.fromPage == "patientRetention"
      ) {
        return data.filter((d) => {
          const aiPrediction = d.features.AIPrediction;
          if (
            (aiPrediction && this.aiPredictionTrue) ||
            (!aiPrediction && this.aiPredictionFalse)
          ) {
            return (
              d.features[this.selectedAttribute] !== undefined ||
              d[this.selectedAttribute] !== undefined
            );
          }
          return false;
        });
      }
      if (
        this.fromPage == "descriptiveStatistics" ||
        this.fromPage == "deathSummary" ||
        this.fromPage == "statusReport"
      ) {
        return data.filter(
          (d) =>
            d[this.selectedAttribute] !== undefined ||
            d[this.selectedAttribute] !== undefined ||
            d[this.selectedAttribute] !== null
        );
      }
    },
    createChart() {
      this.chartContainer = this.expandBlock
        ? this.$refs.popupchartContainer
        : this.$refs.chartContainer;
      try {
        const { graphdata } = this;
        let data = graphdata;
        if (!Array.isArray(data) || data.length === 0) {
          console.error("Data is invalid or empty:", data);
          return;
        }

        const validData = this.validateData(data);
        if (validData.length === 0) {
          console.error("Valid data is empty");
          return;
        }

        // Get the container dimensions
        const containerWidth = this.$refs.chartContainerBlock.clientWidth;
        const containerHeight = 280;
        const radius = Math.min(containerWidth, containerHeight) / 2 - 20;

        if (
          this.fromPage == "patientScreening" ||
          this.fromPage == "patientRetention"
        ) {
          const aggregatedData = d3
            .rollups(
              validData,
              (v) => ({
                count: v.length,
                subarray: v,
              }),
              (d) => {
                const value =
                  d.features[this.selectedAttribute] !== undefined
                    ? d.features[this.selectedAttribute]
                    : d[this.selectedAttribute];
                return this.mappings[this.selectedAttribute]
                  ? this.mappings[this.selectedAttribute][value] || value
                  : value;
              }
            )
            .map(([key, { count, subarray }]) => ({
              key,
              value: count,
              subarray,
            }));

          if (aggregatedData.length === 0) {
            console.error("Aggregated data is empty");
            return;
          }
          const totalValue = d3.sum(aggregatedData, (d) => d.value);
          // Define a function to generate random colors excluding specific colors
          const customColors = [
            "#28C0FF",
            "#FF98BD",
            "#8C82FF",
            "#FFB44F",
            "#57D9A5",
          ];

          // Function to get a color based on index, cycling through customColors

          // Assign colors to segments ensuring cycling through customColors
          aggregatedData.forEach((d, i) => {
            // Ensure colormapping exists for the selected attribute
            if (!this.colormapping[this.selectedAttribute]) {
              this.colormapping[this.selectedAttribute] = {}; // Initialize if not present
            }

            // Check if the current key has a color already assigned
            if (!this.colormapping[this.selectedAttribute][d.key]) {
              const usedColors = Object.values(
                this.colormapping[this.selectedAttribute]
              );

              // Use customColors for the first five entries, then generate random colors
              const newColor =
                i < customColors.length
                  ? customColors[i] // Use the default custom colors for the first five
                  : this.generateRandomColor(usedColors); // Generate a random color for others

              // Assign the new unique color to the current key in the selectedAttribute's colormapping
              this.colormapping[this.selectedAttribute][d.key] = newColor;
            }
          });
          const color = d3
            .scaleOrdinal()
            .domain(aggregatedData.map((d) => d.key))
            .range([
              ...customColors,
              ...d3.schemeCategory10.slice(customColors.length),
            ]);

          this.svg = d3
            .select(this.chartContainer)
            .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
            .append("g")
            .attr(
              "transform",
              `translate(${containerWidth / 2}, ${containerHeight / 2})`
            );

          const pie = d3
            .pie()
            .sort(null)
            .value((d) => d.value);

          const arc = d3
            .arc()
            .innerRadius(0)
            .outerRadius(radius);

          const arcs = this.svg
            .selectAll(".arc")
            .data(pie(aggregatedData))
            .enter()
            .append("g")
            .attr("class", "arc")
            .on("mouseover", (event, d) => {
              const percentage = ((d.value / totalValue) * 100).toFixed(2);
              d3.select(this.tooltip)
                .style("opacity", 1)
                .html(`Category: ${d.data.key}<br/>Value: ${d.value}<br/>Percentage: ${percentage}%`)
                .style("left", this.mouseX + "px")
                .style("top", this.mouseY + "px");
            })
            .on("mouseout", () => {
              d3.select(this.tooltip).style("opacity", 0);
            })
            .on("click", () => {
              d3.select(this.tooltip).style("opacity", 0);
            })
            .on("mousemove", (event) => {
              d3.select(this.tooltip)
                .style("left", this.mouseX + "px")
                .style("top", this.mouseY + "px");
            });

          arcs
            .append("path")
            .attr("d", arc)
            .attr(
              "fill",
              (d) => this.colormapping[this.selectedAttribute][d.data.key]
            )
            .attr("style", (d) =>
              this.selectedGroups.length != 0
                ? this.selectedGroups.includes(d.data.key)
                  ? ""
                  : "opacity: 50%;"
                : ""
            )
            .on("click", (event, d) => {
              console.log("Clicked on:", d.data);
              // this.arcclicked(d.data, color(d.data.key));
              this.arcclicked(d.data.subarray, d.data.key);
            });

          // Add legends with values
          const legend = this.svg
            .append("g")
            .attr("class", "legend")
            .attr(
              "transform",
              `translate(${0 - containerWidth / 2}, ${-containerHeight / 2 +
                20})`
            );

          const legendItem = legend
            .selectAll(".legend-item")
            .data(aggregatedData)
            .enter()
            .append("g")
            .attr("class", "legend-item")
            .attr("transform", (d, i) => `translate(0, ${i * 20})`);

          legendItem
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 18)
            .attr("height", 18)
            .attr(
              "fill",
              (d) => this.colormapping[this.selectedAttribute][d.key]
            );

          legendItem
            .append("text")
            .attr("x", 24)
            .attr("y", 9)
            .attr("dy", "0.35em")
            .text((d) => `${d.key}: ${d.value}`);
        }

        if (
          this.fromPage == "descriptiveStatistics" ||
          this.fromPage == "deathSummary" ||
          this.fromPage == "statusReport"
        ) {
          const aggregatedData = d3
            .rollups(
              validData,
              (v) => ({
                count: v.length,
                subarray: v,
              }),
              (d) => {
                const value =
                  d[this.selectedAttribute] !== undefined ||
                  d[this.selectedAttribute] !== null
                    ? d[this.selectedAttribute]
                    : d[this.selectedAttribute];
                if (typeof d[this.selectedAttribute] === "number") {
                  const range = this.getRange(value);
                  return this.mappings[this.selectedAttribute]
                    ? this.mappings[this.selectedAttribute][range] || range
                    : range;
                } else {
                  return this.mappings[this.selectedAttribute]
                    ? this.mappings[this.selectedAttribute][value] || value
                    : value;
                }
              }
            )
            .map(([key, { count, subarray }]) => ({
              key,
              value: count,
              subarray,
            }));

          if (aggregatedData.length === 0) {
            console.error("Aggregated data is empty");
            return;
          }
          const totalValue = d3.sum(aggregatedData, (d) => d.value);
          // Define a function to generate random colors excluding specific colors

          const customColors = [
            "#28C0FF",
            "#FF98BD",
            "#8C82FF",
            "#FFB44F",
            "#57D9A5",
          ];

          // Function to get a color based on index, cycling through customColors

          // Assign colors to segments ensuring cycling through customColors
          aggregatedData.forEach((d, i) => {
            // Ensure colormapping exists for the selected attribute
            if (!this.colormapping[this.selectedAttribute]) {
              this.colormapping[this.selectedAttribute] = {}; // Initialize if not present
            }

            // Check if the current key has a color already assigned
            if (!this.colormapping[this.selectedAttribute][d.key]) {
              const usedColors = Object.values(
                this.colormapping[this.selectedAttribute]
              );

              // Use customColors for the first five entries, then generate random colors
              const newColor =
                i < customColors.length
                  ? customColors[i] // Use the default custom colors for the first five
                  : this.generateRandomColor(usedColors); // Generate a random color for others

              // Assign the new unique color to the current key in the selectedAttribute's colormapping
              this.colormapping[this.selectedAttribute][d.key] = newColor;
            }
          });
          const color = d3
            .scaleOrdinal()
            .domain(aggregatedData.map((d) => d.key))
            .range([
              ...customColors,
              ...d3.schemeCategory10.slice(customColors.length),
            ]);

          this.svg = d3
            .select(this.chartContainer)
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
            .append("g")
            .attr(
              "transform",
              `translate(${containerWidth / 2 + 92}, ${containerHeight / 2})`
            );
          this.svg
            .append("defs")
            .append("clipPath")
            .attr("id", "textClip")
            .append("rect")
            .attr("width", "450")
            .attr("height", "600")
            .attr(
              "transform",
              `translate(${0 - containerWidth / 2}, ${-containerHeight / 2 +
                20})`
            );
          const pie = d3
            .pie()
            .sort(null)
            .value((d) => d.value);

          const arc = d3
            .arc()
            .innerRadius(0)
            .outerRadius(radius);

          const arcs = this.svg
            .selectAll(".arc")
            .data(pie(aggregatedData))
            .enter()
            .append("g")
            .attr("class", "arc")
            .on("mouseover", (event, d) => {
              const percentage = ((d.value / totalValue) * 100).toFixed(2);
              d3.select(this.tooltip)
                .style("opacity", 1)
                .html(`Category: ${d.data.key}<br/>Value: ${d.value}<br/>Percentage: ${percentage}%`)
                .style("left", this.mouseX + "px")
                .style(
                  "top",
                  (this.fromPage == "descriptiveStatistics"
                    ? this.mouseY
                    : containerHeight / 2) + "px"
                );
            })
            .on("mouseout", () => {
              d3.select(this.tooltip).style("opacity", 0);
            })
            .on("click", () => {
              d3.select(this.tooltip).style("opacity", 0);
            })
            .on("mousemove", (event) => {
              d3.select(this.tooltip)
                .style("left", this.mouseX + "px")
                .style(
                  "top",
                  (this.fromPage == "descriptiveStatistics"
                    ? this.mouseY
                    : containerHeight / 2) + "px"
                );
            });

          arcs
            .append("path")
            .attr("d", arc)
            .attr(
              "fill",
              (d) => this.colormapping[this.selectedAttribute][d.data.key]
            )
            .attr("style", (d) =>
              this.selectedGroups.length != 0
                ? this.selectedGroups.includes(d.data.key)
                  ? ""
                  : "opacity: 50%;"
                : ""
            )
            .on("click", (event, d) => {
              this.arcclicked(d.data.subarray, d.data.key);
            });

          // Add legends with values
          const legend = this.svg
            .append("g")
            .attr("class", "legend")
            .attr(
              "transform",
              `translate(${0 - containerWidth / 2 - 84} , ${-containerHeight /
                2 +
                20})`
            );

          const legendItem = legend
            .selectAll(".legend-item")
            .data(aggregatedData)
            .enter()
            .append("g")
            .attr("class", "legend-item")
            .attr("clip-path", "url(#textClip)")
            .attr("transform", (d, i) => `translate(0, ${i * 20})`);

          legendItem
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 18)
            .attr("height", 18)
            .attr(
              "fill",
              (d) => this.colormapping[this.selectedAttribute][d.key]
            );

            legendItem
  .append("text")
  .attr("x", 24)
  .attr("y", 9)
  .attr("dy", "0.35em")
  .text((d) => {
    const percentage = ((d.value / totalValue) * 100).toFixed(2); // Calculate percentage
    return `${
      d.key == undefined || d.key == "null" || d.key == null
        ? "Null"
        : d.key
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    }: ${d.value} (${percentage}%)`; // Add value and percentage
  });
        }
      } catch (error) {
        console.error("Error creating pie chart:", error);
      }
    },
    arcclicked(filteredData, filteredkeyword) {
      if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter(
          (k) => k !== filteredkeyword
        );
        let result = this.sortdata.filter(
          (item) => !filteredData.includes(item)
        );
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(
          (item) => `(${filteredkeyword})` != item
        );
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedAttribute}(${this.selectedGroups})`;
      } else {
        this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`);
        this.filterkeyword = `${this.selectedAttribute}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.updateChart();
    },
    generateRandomColor(excludedColors) {
      const letters = "0123456789ABCDEF";
      let color;

      // Keep generating colors until we get one that is not in the excluded list
      do {
        color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
      } while (excludedColors.includes(color)); // Regenerate if color is in excludedColors

      return color;
    },
    recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
        sortedData: this.sortdata,
        filterkeyword: this.filterkeyword,
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },

    updateChart() {
      this.chartContainer = this.expandBlock
        ? this.$refs.popupchartContainer
        : this.$refs.chartContainer;
      this.$emit("setxaxiskey", this.selectedAttribute);
      d3.select(this.chartContainer)
        .selectAll("*")
        .remove();
      this.svg = null;
      this.createChart();
    },
    // Function to determine the range
    getRange(value) {
      const ranges = [0, 10, 20, 30, 40, 50]; // Define your ranges
      for (let i = 0; i < ranges.length - 1; i++) {
        if (value >= ranges[i] && value < ranges[i + 1]) {
          return `${ranges[i]}-${ranges[i + 1]}`;
        }
      }
      return `${ranges[ranges.length - 1]}+`; // Last range
    },
  },
};
</script>
<style></style>
<style scoped>
@import "../AiCharts/charts.css";
.pieminheight {
  min-height: 486px;
}
.chart-container {
  overflow-x: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.controls {
  margin-bottom: 10px;
}

.controls label {
  margin-right: 5px;
}

.controls select {
  margin-right: 15px;
}

.pieheight {
  height: 400px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.moving-box {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: lightblue;
  border: 2px solid #000;
  transition: top 0.1s, left 0.1s;
}
</style>
