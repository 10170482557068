/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import store from "../../store/index";
import axios from "axios";
import store from "../../../store/index";
const baseurl = process.env.VUE_APP_Service_URL;

async function apicall(data) {
  console.log("api call function");
  const idtoken = store.getters.getIdToken;
  const result = await axios
   .post(`${baseurl}/forms/forms/create?api-version=1.0`, data,   
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      console.log(err);
    });
  if (result) {
    return result.data;
  }
}

async function getdatafromapi(id) {
  const idtoken = store.getters.getIdToken;
  const result = await axios
    .get(
      `${baseurl}/forms/forms/getconsnetform/${id}`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
    )
    .catch((err) => {
      console.log("failed" + baseurl);
      console.log(err);
    });
  if (result) {
    console.log("form data is ");
    console.log(result.data);
    return result.data;
  }
}

async function getformbyid() {
  const idtoken = store.getters.getIdToken;
console.log("service url call");
  await axios
  .get(
    `${baseurl}/forms/template/get/ba25cd82-3c51-408f-b9a9-b283cbfea405?api-version=1.0`,
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    }
  )
  .then((res) => {
    console.log(res.data);
    return(res.data);
  })
  .catch((err) => {
    console.log(err);
  });
  }

async function editdata(data, isnextreview, saveid) {
  const idtoken = store.getters.getIdToken;
  console.log(data);
  await axios
    .put(`${baseurl}/forms/forms/formpagesave?api-version=1.0`, data,
    //.put(`https://localhost:5002/forms/formpagesave?api-version=1.0`, data,
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    })
    .then(()=>{
      console.log("success");
      if(isnextreview){
      this.isreviewstatus(saveid);
      }
    })
    .catch(console.log);
}

async function updateform(data) {
  const idtoken = store.getters.getIdToken;
  console.log(data);
  await axios
    .put(`${baseurl}/forms/forms/updateform`, data,
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    })
    .then(console.log)
    .catch(console.log);
}

async function isreviewstatus(formId){
  const idtoken = store.getters.getIdToken;
  await axios.put(`${baseurl}/forms/forms/isreviewstatus`, { "formId": formId},
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) =>{ 
       console.log("review status changed", res);
      })
      .catch((err) => console.log("error in review status change" , err));
}


export { apicall, editdata, getformbyid, getdatafromapi, updateform, isreviewstatus };
