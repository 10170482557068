<template>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-6 align-self-center">
        <h2>{{ $t("navigations.subject status report") }}</h2>
      </div>
      <div class="col-lg-2">
        <i class="fa fa-times close" @click="pageclosed()"></i>
    </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-md-12 pl-1 mb-2">
                <historytab
                :historyarray="historykeys"
                @gotohistorylevel="gotohistorylevel"
                @exporttopdf="exporttopdf"
                @exporttoexcell="exporttoexcell"
                />
            </div>
            <span class="row mr-0 pr-0" ref="captureArea">
            <div ref="captureArea1" class="col-12 col-md-12 mb-2 pl-1 pr-0">
                <barchart
                    :availableColumns="subjectKeys"
                    :loader="loader"
                    :data="data"
                    :templateId="''"
                    xAxisKey="DSDECOD"
                     :tableData="data"
                    yAxisKey="count"
                    :chartlabel="$t('navigations.study of medication categories and frequency')"
                    :fromPage="'statusReport'"
                    @drilldowncurrentchart="drilldowncurrentchartforbar"
                    @opendrillpopup="drillpopupopened"
                    @exporttoexcell="exporttoexcell"
                    />
            </div>
            <div ref="captureArea2" class="col-12 col-md-6 mb-2 pl-1 pr-0">
                <stackedbarchart
                xAxisKey="EMPSTAT"
                yAxisKey=""
                :availableColumns="subjectKeys"
                segmentKey="EMPSTAT"
                :loader="loader"
                :data="data"
                :templateId="''"
                :fromPage="'statusReport'"
                :chartlabel="$t('navigations.distribution of subjects by employment status (empstat)')"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"    
                @exporttoexcell="exporttoexcell"         
                />
            </div>
            <div ref="captureArea3" class="col-md-6 mb-2 pl-1 pr-0">
                <div></div>
                <piechart
                :loader="loader"
                :data="data"
                :tableData="data"
                :templateId="''"
                :fromPage="'statusReport'"
                :displayValue="false"
                :chartlabel="$t('navigations.distribution of marital status')"
                :xaxisvariables="subjectKeys"
                :xaxiskey="xaxiskeys['piechart']"
                @setxaxiskey="retainxaxiskey"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
                @exporttoexcell="exporttoexcell"
            />
            </div>
        </span>
            <div class="col-lg-12 mb-1 pl-0">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                    <span>{{ $t("navigations.subject status") }}</span>
                    </div>
                    <div class="stat-body-white">
                    <advtable 
                    fileheadding="Subject status report" 
                        :loader="loader"
                        :data="data"
                        fromPage="patientRetention"
                        @drilldowncurrentchart="drilldowncurrentchartforbar"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <chartdrill
      v-if="drillpopup"
      fileheadding="Subject status report"
      :drilldata="drilldata"
      :templateId="''"
      :fromPage="'adverseEventsPop'"
      @closepopup="onclosed()"
    />
</template>
<script src="./status_report.js"></script>
<style scoped>
.page-break {
  page-break-before: always; /* Ensures new page starts before this element */
}
</style>