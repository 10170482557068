/* eslint-disable  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import store from '@/store';
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: 'displayform',
    components: {
        VPagination,
    },
    data() {
        return {
            defaultdate: "",
            roleprivileges: "",
            baseapi: process.env.VUE_APP_Service_URL,
            searchItem: '',
            currentPageSize: 0,
            currentPageNumber: 1,
            pageSize: 0,
            pageNumber: 1,
            totalLists: 0,
            lists: [],
            filteredListing: [],
            show: false,
            showFilter: false,
            dropdownOpen: false,
            selectedcolumnobj:{},
            SortProperty: "",
            SortOrder :1,
        }
    },
    async mounted() {
        this.defaultdate = localStorage.getItem("dateformat");
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            // window.location.reload();
            this.getData();
            this.filter();
            this.initializeColumns()
        });
        await this.setroleprivileges(); 
        await this.getData();
        await this.filter();
        this.initializeColumns()
    },
    methods: {
        initializeColumns() {
            if (this.lists.length > 0) {
              const allowedKeys = [
              "visitName",
              "createdUserName",
              "createdAt",                            
              "formStatus",
              "version"
              ]
              this.getColumns= Object.keys(this.lists[0])
              .filter(column => allowedKeys.includes(column));       
              const fieldLabels = {
                visitName:"Form Name",
                createdUserName:"Uploaded By",
                createdAt:"Uploaded Date",                            
                formStatus:"Status",
                version:"Version"
              };
      
              const readableData = allowedKeys.map(key => fieldLabels[key] || key);
               
              this.getColumns=readableData
              this.selectedColumns = [...this.getColumns];
              this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
                obj[key] = true;
                return obj;
              }, {});
            }
          },
        async setroleprivileges(){
            this.roleprivileges = await store.getters.getRolesprivilegeData;
          },
        filter(data) {
            if (data) {
                return moment(data).format(this.defaultdate)
            }
        },
        async mapthisform(id) {
            this.$router.push(`/mapform/${id}`);
        },
        async getPrevious() {
            if (this.pageNumber > 1) {
                if (this.searchItem == '') {
                    this.pageNumber = this.pageNumber - 1;
                    this.getData();
                }
                else {
                    this.pageNumber = this.pageNumber - 1;
                    this.searchData();
                }
            }
        },
        async getNext() {
            if (this.pageNumber < this.totalLists) {
                if (this.searchItem == '') {
                    this.pageNumber = this.pageNumber + 1;
                    this.getData();
                }
                else {
                    this.pageNumber = this.pageNumber + 1;
                    this.searchData();
                }
            }
        },
        async getData() {
            const stdvalnow = store.getters.getStudyIs;
            const idtoken = store.getters.getIdToken;
            await axios
                .get(
                    `${this.baseapi}/forms/forms/unassignedform?StudyId=${stdvalnow}&formName=${this.searchItem}&pageindex=${this.pageNumber}&pagesize=10&SortOrder=${this.SortOrder}&api-version=1`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((response) => {
                    console.log(response)
                    if (response.data) {
                        this.totalLists = Math.ceil(response.data.totalCount / 10);
                        this.lists = response.data.results;
                        console.log(this.totalLists)
                    } else {
                        console.log("no study found");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.enableNext = false;
                    this.enablePrevious = false;
                });
        },
        async searchData() {
            const idtoken = store.getters.getIdToken;
            const stdvalnow = store.getters.getStudyIs;
            await axios
                .get(                   
                    `${this.baseapi}/forms/forms/unassignedform?StudyId=${stdvalnow}&formName=${this.searchItem}&pageindex=${this.pageNumber}&pagesize=10&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}&api-version=1`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((response) => {
                    console.log(response);
                    console.log('search')
                    if (response.data) {
                        this.totalLists = Math.ceil(response.data.totalCount / 10)
                        this.lists = response.data.results;
                        console.log(this.lists);
                    } else {
                        console.log("There are currently no list");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.enableNext = false;
                    this.enablePrevious = false;
                });
        },

        //filtering list
        filteredLists() {
            console.log('running')
            this.filteredListing = []
            this.lists.filter((list) => {
                if ((this.searchItem == list.visitName)) {
                    //push the filtered list to new array                     
                    this.filteredListing.push({
                        visitName: list.visitName,
                        updatedBy: list.createdUserName,
                        updatedDate: list.updatedDate,
                        formStatus: list.formStatus,
                        version: list.version,
                    })
                    // console.log(this.filteredListing) 
                    this.show = true
                    return (this.filteredListing)
                }
                else {
                    this.show = true
                    return (this.filteredListing)
                }
            });

        },
        async loadPage(page) {
            this.currentPageNumber = page;
            this.pageNumber = page;
            this.searchData();
        },
        SortSelected(sortProp,id){
            console.log('SortPropChanged');
            const selectedElement=document.getElementById(id)
            const selectedElementID=document.getElementById(id).id
            if(sortProp!=null && id==selectedElementID)
            {
              this.sortDesc=!this.sortDesc
              if(this.SortOrder == 0)
              {
                // if(selectedElement.classList.contains("sortDesc")){
                //   return
                // }
                 if(selectedElement.classList.contains("sortAsc"))
                {
                  selectedElement.classList.remove("sortAsc")
                  selectedElement.classList.add("sortDesc")
                }    
                else selectedElement.classList.add("sortDesc")
              }
              else
              {
                if(selectedElement.classList.contains("sortDesc")){
                  selectedElement.classList.remove("sortDesc")
                  selectedElement.classList.add("sortAsc")
                }
                // else if(selectedElement.classList.contains("sortAsc"))
                // {
                //   return
                // }          
                else selectedElement.classList.add("sortAsc")
              }
            }
            this.pageNumber=1;
            this.currentPageNumber=1;
            this.SortProperty=sortProp;
            this.SortOrder = this.SortOrder==0?1:0;
            this.searchData();
          }
      
    },

}