<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.subject document") }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-0" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form group col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.document name")
              }}<sup><i class="fa fa-asterisk required"></i></sup
            ></label>
            <input
              type="text"
              class="form-control my-2 my-lg-0"
              :maxlength="50"
              v-model="azuredata.DocumentTitle"
            />
            <span class="errorClass" v-if="errors[0] == false">{{
              $t("navigations.please enter document name")
            }}</span>
          </div>
          <div class="form group col-lg-8 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.document description")
              }}<sup><i class="fa fa-asterisk required"></i></sup
            ></label>
            <input
              type="text"
              class="form-control my-2 my-lg-0"
              :maxlength="300"
              v-model="azuredata.DocumentDescription"
            />
            <span class="errorClass" v-if="errors[1] == false">{{
              $t("navigations.please enter document description")
            }}</span>
          </div>
        </div>
      </div>
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form group col-lg-4 mb-1" style="margin-top: 20px">
            <label class="col-form-label"></label>
            <!-- <label class="mb-0" for="InPutFile">Upload File</label> -->

            <input
              accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf"
              id="InPutFile"
              type="file"
              class="form-control my-2 my-lg-0"
              @change="previewFiles"
            />
            <span class="errorClass" v-if="errors[2] == false">{{
              $t("navigations.please upload a file")
            }}</span>
            <!-- <div class="input-group">
              <div class="custom-file">
                <input id="inputGroupFile01" type="file" accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf" class="form-group mb-0 pl-0 pr-1" @change="previewFiles"/>
                  <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
              </div>
            </div> -->
          </div>

          <div class="col-lg-8 my-3" style="float: right">
            <span
              type="button"
              class="save_btn py-2 px-5"
              @click="submitFile()"
              >{{ $t("navigations.submit") }}</span
            >
          </div>
          <!-- <span
            type="button"
            class="  "
            @click="submitFile()"
          >
            Submit
          </span> -->
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.document list") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <!-- <th>Site Code</th> -->
                <th>{{ $t("navigations.subject no") }}</th>

                <th>{{ $t("navigations.document name") }}</th>
                <th>{{ $t("navigations.document description") }}</th>
                <th>{{ $t("navigations.created on") }}</th>
                <!-- <th>Edit</th> -->
                <th>{{ $t("navigations.delete") }}</th>

                <th>{{ $t("navigations.view file") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="customheight"
                v-for="document in documentList"
                :key="document.id"
              >
                <!-- <td>{{ document.siteCode }}</td> -->
                <td>{{ document.subjectNo }}</td>
                <td class="maximumwidth">{{ document.documentName }}</td>
                <td class="maximumwidth">{{ document.documentDescription }}</td>
                <td>{{ filter(document.CreatedAt) }}</td>
                <!-- pre visit -->

                <!-- visit date -->

                <!-- post visit -->

                <!--                     
                  <td class="text-center" v-if="!roleprivileges || roleprivileges.includes('Roles edit')">
                  <i
                    class="fa fa-edit edit-delete"
                    @click="editCreateRole(document.id)"
                  ></i>
                  </td> -->
                <td class="text-center">
                  <i
                    class="fa fa-trash edit-delete"
                    @click="
                      deleteDocument(document.documentReferenceId, document.id)
                    "
                  ></i>
                </td>

                <td class="text-center">
                  <i
                    class="fa fa-file-text-o edit-delete"
                    @click="
                      openModel(document.documentReferenceId, document.id)
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal__wrapper">
    <div class="modelbackground">
      <div class="modal__containers">
        <div class="titleclass">
          <h4 class="ml-lg-2">{{ $t("navigations.view files") }}</h4>
          <div class="modalqueryicons"></div>
          <div class="closebutton">
            <a style="color: white" class="close-link">
              <i class="fa fa-times" @click="closemodall()"></i>
            </a>
          </div>
        </div>
        <div id="wrapper">
          <div class="wrapper wrapper-content animated fadeInRight">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ibox-body p-3">
                  <div class="form-group row my-3">
                    <div class="col-md-12 my-4">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>{{ $t("navigations.file name") }}</th>
                            <th>{{ $t("navigations.download") }}</th>
                            <th>{{ $t("navigations.delete") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(filename, index) in fileslist"
                            :key="index"
                          >
                            <td>{{ filename }}</td>
                            <td class="text-center">
                              <i
                                class="fa fa-download edit-delete"
                                @click="downloadfile(filename)"
                              ></i>
                            </td>
                            <td class="text-center">
                              <i
                                class="fa fa-trash edit-delete"
                                @click="deletefile(filename)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";

export default {
  name: "patientdocument",
  props: { patientId: String },
  data() {
    return {
      defaultdate: "",
      azuredata: {
        DocumentTypeId: "61acae34176c410a626c5a02",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: "",
        OrganizationId: 2,
      },
      getdocumentslist: {
        SubjectID: this.patientId,
        StudyID: "",
        SiteID: "",
        SubjectNo: "",
        DocumentReferenceId: "",
        DocumentName: "",
        FileNames: "",
      },
      fileslist: [],
      currentdocId: "",
      eidsadocId: "",
      getpageproperty: {
        pageindex: 1,
        pagesize: 10,
        SortProperty: "",
        SortOrder: 1,
      },
      documentId: "",
      filename: [],
      //DocumentName:"",
      fileuploadurl: process.env.VUE_APP_File_upload_URL,
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patient/`,
      baseapi: process.env.VUE_APP_Service_URL,
      idToken: "",
      headerValues: {},
      sortorder: 0,

      documentList: [],
      validations: [false, false, false],
      errors: [],
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,

        "Content-Type": "application/json",
      },
    };
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      window.location.reload();
      this.getdocumentlist();
    });
    await this.getdocumentlist();
  },

  methods: {
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
      console.log("upload file is", this.azuredata.Document.name);
    },
    async submitFile() {
      await this.validateIsNull();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        var formData = new FormData();
        formData.append("Document", this.azuredata.Document);
        formData.append("DocumentTypeName", "Patientdocument");
        formData.append("DocumentTitle", this.azuredata.DocumentTitle);
        formData.append(
          "DocumentDescription",
          this.azuredata.DocumentDescription
        );
       
        console.log("final data", this.azuredata);
        //TODO Change here
        await axios
          .post(
            `${this.fileuploadurl}/create`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + this.idToken,

                "Content-Type": "multipart/form-data",
              },
            }
          )

          .then((response) => {
            console.log("retreieved data", response);
            this.documentId = response.data.data[0];
            this.filename = [];
            this.filename.push(response.data.data[1]);
            console.log("retreieved fileName", this.filename);
            console.log("retreieved docid", this.documentId);
            this.Createpatientdocument();
          })
          .catch((err) => {
            console.log("error", err);
            console.log(err.data);
          });
      }
    },
    async Createpatientdocument() {
      console.log("create patientdoc in db");
      await axios
        .post(
          `${this.baseapi}/management/patientdocument/createpatientdocument`,
          {
            documentReferenceId: this.documentId,
            subjectID: this.patientId,
            documentName: this.azuredata.DocumentTitle,
            fileNames: this.filename,
            documentDescription: this.azuredata.DocumentDescription,
          },
          this.headerValues
        )
        .then((response) => {
          alert(this.$t("navigations.subject document added successfully"));
          window.location.reload();
          console.log("retreieve data", response);
          // this.documentId = response.data.data;
        })
        .catch((err) => {
          console.log("error", err);
          console.log(err.data);
        });
    },
    async getdocumentlist() {
      console.log("doc list is", this.getdocumentslist);
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patientdocument/listpatientdocument?SubjectID=${this.getdocumentslist.SubjectID}`,
          this.headerValues
        )
        .then((response) => {
          console.log("doc list result is", response.data.results);
          this.documentList = response.data.results;
        })
        .catch((err) => {
          console.log("error from getdoc listing", err);
          console.log(err.data);
        });
    },
    async FileslistPage(patientId) {
      this.$router.push(`/patientdocument/${patientId}`);
    },
    async openModel(docId, eidsadocId) {
      this.eidsadocId = eidsadocId;
      console.log("status is is", docId);
      const popup = document.querySelector(".modal__wrapper");
      console.log(popup);
      popup.classList.add("active");
      await this.getfiles(docId);
    },
    async getfiles(docId) {
      this.currentdocId = docId;
      console.log("INDD", docId);
      await axios
        .get(
          `${this.baseapi}/management/patientdocument/getfilesbydocid/${docId}`,
          this.headerValues
        )
        .then((response) => {
          console.log("file list result is", response.data);
          this.fileslist = response.data;
        })
        .catch((err) => {
          console.log("error from file listing", err);
          console.log(err.data);
        });
    },
    async closemodall() {
      console.log("close modal");
      const popup = document.querySelector(".modal__wrapper");
      popup.classList.remove("active");
    },
    async downloadfile(filename) {
      console.log("get file to dwnld");
      console.log("file name", filename);
      console.log("get file to dwnld", this.currentdocId);
       var a = document.createElement("a");

      a.href = `${this.fileuploadurl}/file/${this.currentdocId}?filename=${filename}`;

      a.download = "FILENAME";

      a.click();
      // await axios
      //   .get(
      //     `${this.fileuploadurl}/file/${this.currentdocId}?filename=${filename}`,
      //     {
      //       headers: {
      //         Authorization: "Bearer " + this.idToken,

      //         "Content-Type": "application/json",
      //         responseType: "blob",
      //       },
      //     }
      //   )
      //   .then((response) => {
         
      //     fileDownload(response.data, filename);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    async deletefile(filename) {
      console.log("get doc to delete", this.currentdocId);
      if (
        confirm(
          this.$t("navigations.are you sure you want to delete this file?")
        )
      ) {
        await axios
          .delete(
            `${this.fileuploadurl}/deleteonefile?id=${this.currentdocId}&filename=${filename}`,
            this.headerValues
          )
          .then((response) => {
            console.log(response.data);
            console.log("yes");
          })
          .catch((err) => {
            console.log("nope", err);
          });
        await this.deletedbfile(filename);
      } else {
      }
    },
    async deletedbfile(filename) {
      console.log("delete eidsa doc", this.eidsadocId);
      console.log("file iss", filename);
      await axios
        .put(
          `${this.baseapi}/management/patientdocument/deletefile?documentId=${this.eidsadocId}&fileName=${filename}`,
          this.headerValues
        )
        .then((response) => {
          this.closemodall();
          this.openModel(this.currentdocId, this.eidsadocId);
          console.log(response.data);
          console.log("yes");
        })
        .catch((err) => {
          console.log("nope", err);
        });
    },
    async deleteDocument(dmsdocId, eidsadocId) {
      if (
        confirm(
          this.$t("navigations.are you sure you want to delete this document?")
        )
      ) {
        await axios
          .delete(
            `${this.fileuploadurl}/deletedocument?id=${dmsdocId}`,
            this.headerValues
          )
          .then((response) => {
            console.log(response.data);
            console.log("yes");
          })
          .catch((err) => {
            console.log("nope", err);
          });
        await this.deletedbDocument(eidsadocId);
      } else {
      }
    },
    async deletedbDocument(eidsadocId) {
      await axios
        .delete(
          `${this.baseapi}/management/patientdocument/deletedocument?id=${eidsadocId}`,
          this.headerValues
        )
        .then((response) => {
          console.log(response.data);
          console.log("yes");
          window.location.reload();
          this.getdocumentlist();
        })
        .catch((err) => {
          console.log("nope", err);
        });
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async onclosed() {
      this.$router.push(`/patientList`);
    },
    async validateIsNull() {
      if (this.azuredata.DocumentTitle != "") {
        this.validations[0] = true;
      }

      if (this.azuredata.DocumentDescription != "") {
        this.validations[1] = true;
      }

      if (this.azuredata.Document != "") {
        this.validations[2] = true;
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";

.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.form-control {
  padding: 0.2rem 0.75rem !important;
}
.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}
.input-file {
  /*opacity: 1; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.errorClass {
  color: red;
  font-size: 15px;
}
/* .tdbreak {
  word-break: break-all;
} */
/* table {
  table-layout: fixed;
  white-space: normal!important;
}

td {
  word-wrap: break-word;
} */
.maximumwidth {
  inline-size: 300px;
  overflow-wrap: break-word;
  max-width: 300px;
  white-space: inherit;
}
.customheight {
  height: auto;
}
</style>
