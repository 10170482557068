<template>
    <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-6 align-self-center">
      <h2>{{ $t("navigations.survival analysis") }}</h2>
    </div>
    <div class="text-end col-md-2" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="exporttopdfall()">
              Export to PDF
            </div>
          </div>
        </div>
  </div>
  <div ref="captureArea" class="wrapper wrapper-content" id="survivalanalysis">
    <div class="col-md-12">
    <div class="row">
      <div ref="captureArea1" class="col-lg-12 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white maxheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.kaplan-meier curve") }}</span>
                <button class="btn btn-primary py-0" @click="showData()">{{ $t("navigations.data") }}</button>
                <div class="text-center" v-on:clickout="showkaplerPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showkaplerPopup = !showkaplerPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showkaplerPopup">
            <div @click.prevent="exporttopdf('captureArea1')">
              Export to PDF
            </div>
          </div>
        </div>
              </div>
              <div class="stat-body-white">
                <kaplermodel />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div ref="captureArea2" class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.hazard ratios") }}</span>
                <div class="text-center" v-on:clickout="showkaca2Popup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showkaca2Popup = !showkaca2Popup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showkaca2Popup">
            <div @click.prevent="exporttopdf('captureArea2')">
              Export to PDF
            </div>
          </div>
        </div>
              </div>
              <div class="stat-body-white">
                <hazardus :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="captureArea3" class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.p-values of covariates") }}</span>
                <div class="text-center" v-on:clickout="showkaca3Popup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showkaca3Popup = !showkaca3Popup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showkaca3Popup">
            <div @click.prevent="exporttopdf('captureArea3')">
              Export to PDF
            </div>
          </div>
        </div>
              </div>
              <div class="stat-body-white">
                <survivalhorizondal :data="data" :fromPage="'survivalReport'" :chartLabel="'p-values of covariates'"/>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div ref="captureArea4" class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.coefficient of covariates") }}</span>
                <div class="text-center" v-on:clickout="showkaca4Popup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showkaca4Popup = !showkaca4Popup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showkaca4Popup">
            <div @click.prevent="exporttopdf('captureArea4')">
              Export to PDF
            </div>
          </div>
        </div>
              </div>
              <div class="stat-body-white">
                <Survivalreport :analysisdata="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="captureArea5" class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.confidence intervals of coefficient") }}</span>
                <div class="text-center" v-on:clickout="showkaca5Popup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showkaca5Popup = !showkaca5Popup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showkaca5Popup">
            <div @click.prevent="exporttopdf('captureArea5')">
              Export to PDF
            </div>
          </div>
        </div>
              </div>
              <div class="stat-body-white">
                <coifficientchart :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div ref="captureArea6" class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.forest plot") }}</span>
                <div class="text-center" v-on:clickout="showkaca6Popup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showkaca6Popup = !showkaca6Popup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showkaca6Popup">
            <div @click.prevent="exporttopdf('captureArea6')">
              Export to PDF
            </div>
          </div>
        </div>
              </div>
              <div class="stat-body-white">
                <forestploat :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="captureArea7" class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.significance plot") }}</span>
                <div class="text-center" v-on:clickout="showkaca7Popup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showkaca7Popup = !showkaca7Popup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showkaca7Popup">
            <div @click.prevent="exporttopdf('captureArea7')">
              Export to PDF
            </div>
          </div>
        </div>
              </div>
              <div class="stat-body-white">
                <SignificancePlot :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <survivaldata_popup
    v-if="popactive"
     @libactive="popactive = false"
  >
  </survivaldata_popup>
</template>

<script>
import Survivalreport from "./survivalreport.vue";
import kaplermodel from "./kaplermodel.vue";
import coifficientchart from "./coefficient_plot.vue";
import forestploat from "./forest_ploat.vue";
import SignificancePlot from "./significance_ploat.vue";
import survivalhorizondal from "./horizondalbarsurvival.vue";
import hazardus from "./hazardratiocoif.vue";
import survivaldata_popup from "../../components/survivaldata_popup/survivaldata_popup.vue";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import * as XLSX from "xlsx";
import html2pdf from 'html2pdf.js';
export default {
  name: "survivalchart",
  components: {
    kaplermodel,
    coifficientchart,
    forestploat,
    SignificancePlot,
    Survivalreport,
    survivalhorizondal,
    hazardus,
    survivaldata_popup
  },
  async mounted() {
    await this.getdata();
  },
  data() {
    return {
      showkaca7Popup: false,
      showkaca6Popup: false,
      showkaca5Popup: false,
      showkaca4Popup:false,
      showkaca3Popup: false,
      showkaca2Popup: false,
      showkaplerPopup: false,
      showVisitPopup: false,
      data: {},
      basetestUrl: process.env.VUE_APP_Service_URL,
      popactive: false,
    };
  },
  methods: {
    setallpopupfalse(){
      this.showkaca7Popup= false;
      this.showkaca6Popup= false;
      this.showkaca5Popup= false;
      this.showkaca4Popup=false;
      this.showkaca3Popup= false;
      this.showkaca2Popup= false;
      this.showkaplerPopup= false;
      this.showVisitPopup= false;
    },
    exporttopdf(refName) {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs[refName].cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: `chart.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    exporttopdfall() {
      this.showVisitPopup = false;
      const pdfContainer = document.createElement('div');
      const captureArea1 = this.$refs.captureArea1.cloneNode(true)
      const captureArea2 = this.$refs.captureArea2.cloneNode(true);
      const captureArea3 = this.$refs.captureArea3.cloneNode(true);
      const captureArea4 = this.$refs.captureArea4.cloneNode(true);
      const captureArea5 = this.$refs.captureArea5.cloneNode(true);
      const captureArea6 = this.$refs.captureArea6.cloneNode(true);
      const captureArea7 = this.$refs.captureArea7.cloneNode(true);

      captureArea2.classList.add('page-break');
      captureArea3.classList.add('page-break');
      captureArea4.classList.add('page-break');
      captureArea5.classList.add('page-break');
      captureArea6.classList.add('page-break');
      captureArea7.classList.add('page-break');
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea1);
      pdfContainer.appendChild(captureArea2);
      pdfContainer.appendChild(captureArea3);
      pdfContainer.appendChild(captureArea4);
      pdfContainer.appendChild(captureArea5);
      pdfContainer.appendChild(captureArea6);
      pdfContainer.appendChild(captureArea7);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';
      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: 'Survival report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    async getdata() {
      await axios
        .get(
          `${this.basetestUrl}/forms-ml/report/getsurvivalreports?table_name=SurvivalReport`
        )
        .then((res) => {
          this.data = res.data;
          console.log("data for main survival chart is", res.data);
        })
        .catch((err) => console.log("error in screen list", err));
    },
    showData(){
      this.popactive=true
    }
  },
};
</script>
<style scoped>
@import "../AiCharts/charts.css";
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}

.titlemsg {
  border-color: #e7eaec;
  margin-bottom: 0;
  padding: 20px 20px;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding-left: 10px;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}
.graphbtncontainer {
  display: flex;
  gap: 5px;
}
img {
  margin-left: 0px !important;
}
.selectdefault {
  left: 0;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-size: 0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius: 3px;
}
.ibox-content h1 {
  font-weight: 400;
  color: #676a6c;
}
.progress {
  display: flex;
  height: 5px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.progress-mini {
  flex-grow: 1;
  margin-right: 10px;
}

.progress-bar {
  background-color: #5bc0de;
  height: 20px;
}

.progress-value {
  color: #333;
  font-size: 1.2em;
}
.average-title {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #888;
}

.stat-item h3 {
  margin-bottom: 5px;
  font-size: 1em;
  color: #888;
}

.stat-item h2 {
  margin-bottom: 5px;
  font-size: 2em;
  color: #333;
}

.stat-item small {
  color: #888;
}

.stat-info h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info small {
  font-size: 1em;
}

.stat-icon img {
  width: 50px;
  height: 50px;
}


.stat-body-dflex {
  margin: 5px 15px !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 125px;
}
.stat-info-white h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info-white small {
  font-size: 1em;
}
.stat-dropdown {
  position: relative;
  display: inline-block;
}

.stat-dropbtn {
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.stat-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10001;
  right: 0;
}

.stat-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.stat-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.stat-dropdown:hover .stat-dropdown-content {
  display: block;
}

.stat-dropdown:hover .dropbtn {
  color: #000;
}
.adjustheight {
  height: 220px;
}
.minheight {
  height: 405px;
}
.maxheight {
  height: 100%;
}
.page-break {
  page-break-before: always;
}
</style>
