/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import { useStore } from "vuex";
import Chart from "chart.js";
import jwt_decode from "jwt-decode";
import store from "@/store";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// let testarray= [];
export default {
  name: "visitsummary",
  components: {
    VPagination
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    const idtoken = store.getters.getIdToken;
    this.headers = {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    };
    window.addEventListener("studyIdChanged", async (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.defaultdashboard = store.getters.getdefaultdashboard;
      // window.location.reload();
      this.getcount();
      this.getallcounts();
      //   this.GetFormData();
      this.queryrecieved();
      this.getoverduevisits();
      await this.getOverdueVisitslist();
      this.getplannedvisits();
      this.getreceivedquery();
      this.getvisits();
      this.getPlannedImminentCount();
      this.Getoverduecompletedcount();
      this.GetCompletedCount();
      await this.getImminetVisits();
      this.initializeColumns();
      this.initializeImminentColumns()
      this.sndcharts.update();
    });
    this.defaultdashboard = store.getters.getdefaultdashboard;
    this.sndcharts = null;
    this.getcount();
    this.getallcounts();
    // this.GetFormData();
    this.queryrecieved();
    this.getoverduevisits();
    await this.getOverdueVisitslist();
    this.getplannedvisits();
    this.getreceivedquery();
    this.getvisits();
    this.getPlannedImminentCount();
    this.Getoverduecompletedcount();
    this.GetCompletedCount();
    await this.getImminetVisits();
    this.initializeColumns();
    this.initializeImminentColumns()
    // this.setdefaultstudy();
    const snd = document.getElementById("lineChart");
    this.sndcharts = new Chart(snd, {
      type: "bar",
      data: this.sndoptions,
      options: this.options,
    });
    this.sndcharts;
  },
  data() {
    return {
      sohowFormModal: false,
      sohowFormModalImminent:false,
      formlist:[],
      sProp: "",
      IsDesc: true,
      defaultdate: "",
      headers: {},
      defaultdashboard: "",
      imminentVisitList: [],
      overdueVisitList: [],
      completedCount: 0,
      overdueCount: 0,
      plannedCount: 0,
      activeformcount: "",
      patientscountstd: "",
      sitecountstd: "",
      userscountstd: "",
      formcountstd: "",
      querycountstd: "",
      formlists: [],
      receivedquery: [],
      overduevisits: [],
      receivedquerylist: [],
      visits: [],
      dates: [],
      activeformcount: "",
      patientscountstd: "",
      sitecountstd: "",
      userscountstd: "",
      formcountstd: "",
      formlists: [],
      pageNumber: 1,
      currentStudy: "",
      userId: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/study/`,
      baseapi: process.env.VUE_APP_Service_URL,
      studies: "",
      valnow: "",
      sndcharts: "",
      secndcharts: "",
      senddatacoppy: "",
      seconsdatacoppy: "",
      totalCountOverdue: 0,
      currentoverduePage: 1,
      pageoverdueindex: 1,
      pageoverduesize: 10,
      totaloverduePages: 0,
      totalCountImminent: 0,
      currentimminentPage: 1,
      pageimminentindex: 1,
      pageimminentsize: 10,
      pageplannedindex: 1,
      pageplannedsize: 10,
      totalimminentPages: 0,
      totalplannedCount: "",
      visitscount: "",
      showFilter: false,
      dropdownOpen: false,
      selectedcolumnobj: {},
      showImminentFilter: false,
      dropdownImminentOpen: false,
      selectedImminentcolumnobj: {},
      sndoptions: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      //horizondalbarchart first ends
      upcommingpiechartoptions: {
        chart: {
          width: '100%',
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            pie: {
              size: 200
            },
            chart: {
              toolbar: {
                show: false
              },
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      upcommingvisitchart: {
        charttype: "bar",
        chartOptions: {
          stroke: {
            width: 2,
          },
          chart: {
            toolbar: {
              show: false,
            },
            id: "vuechart-example",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          xaxis: {
            categories: [],
          },
        },
        series: [
          {
            name: this.$t('navigations.visits'),
            data: [],
          },
        ],
        dataseries: "",
        piedataseries: [2, 3, 2, 1]
      },
      overduepiechartoptions: {
        chart: {
          width: '100%',
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            pie: {
              size: 200
            },
            chart: {
              toolbar: {
                show: false
              },
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      overduevisitchart: {
        charttype: "bar",
        chartOptions: {
          stroke: {
            width: 2,
          },
          chart: {
            toolbar: {
              show: false,
            },
            id: "vuechart-example",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          xaxis: {
            categories: [],
          },
        },
        series: [
          {
            name: this.$t('navigations.visits'),
            data: [],
          },
        ],
        dataseries: "",
        piedataseries: [2, 3, 2, 1]
      },

    };
  },
  methods: {
    initializeColumns() {
      if (this.overdueVisitList.length > 0) {
        const allowedKeys = [
          "patientNo",
          "siteCode",
          "visitNo",
          "visitDate"]
        this.getColumns = Object.keys(this.overdueVisitList[0])
          .filter(column => allowedKeys.includes(column));
        const fieldLabels = {
          patientNo: "Subject ID",
          siteCode: "Site ID",
          visitNo: "Visit No.",
          visitDate: "Date"
        };

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);

        this.getColumns = readableData
        this.selectedColumns = [...this.getColumns];
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});

      }
    },
    async openFormsModal(visitNo, patientId, studyId) {
      await this.listvisitForms(visitNo, patientId, studyId);
      this.sohowFormModal = true;
    },
    async closeFormsModal() {
      this.sohowFormModal = false;
    },
    async openImminentFormsModal(visitNo, patientId, studyId) {
      await this.listvisitForms(visitNo, patientId, studyId);
      this.sohowFormModalImminent = true;
    },
    async closeImminentFormsModal() {
      this.sohowFormModalImminent = false;
    },
    async listvisitForms(visitNo, patientId, studyId) {
      const idtoken = store.getters.getIdToken;
      await
        axios.get(`${this.baseapi}/management/patientschedule/getfromsbyvisit?studyId=${studyId}&patientID=${patientId}&visitNo=${visitNo}`, {          
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        )
        .then((res) => {
          console.log("Visit form list is...", res);
          this.formlist = res.data.data;
        })
          .catch(err => console.log(" error in getting visit forms...", err));
    },
    async initializeImminentColumns() {
      if (this.imminentVisitList.length > 0) {

        const allowedKeys = [
          "patientNo",
          "siteCode",
          "visitNo",
          "visitDate"
        ]
        this.getImminentColumns = Object.keys(this.imminentVisitList[0])
          .filter(column => allowedKeys.includes(column));
        const fieldLabels = {
          patientNo: "Subject ID",
          siteCode: "Site ID",
          visitNo: "Visit No.",
          visitDate: "Date"
        };

        const readableData = allowedKeys.map(key => fieldLabels[key] || key);

        this.getImminentColumns = readableData
        this.selectedImminentColumns = [...this.getImminentColumns];
        this.selectedImminentcolumnobj = this.selectedImminentColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});

      }
    },
    async upcommingchange(val) {
      if (val == "barhorizondal") {
        await this.switchfirstbar(true);
        this.upcommingvisitchart.charttype = "bar";
      } else {
        await this.switchfirstbar(false);
        this.upcommingvisitchart.charttype = val;
      }
      // chart.render();
    },
    async switchfirstbar(res) {
      this.upcommingvisitchart.chartOptions.plotOptions = { bar: { horizontal: res } };
      this.upcommingvisitchart.charttype = "";
    },

    async pastchange(val) {
      if (val == "barhorizondal") {
        await this.switchsecondbar(true);
        this.overduevisitchart.charttype = "bar";
      } else {
        await this.switchsecondbar(false);
        this.overduevisitchart.charttype = val;
      }
    },
    async switchsecondbar(res) {
      this.overduevisitchart.chartOptions.plotOptions = { bar: { horizontal: res } };
      this.overduevisitchart.charttype = "";
    },
    async getallcounts() {
      const idtoken = store.getters.getIdToken;
      const currentstuid = await store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/management/patientschedule/getallcounts?StudyId=${currentstuid}&Days=7`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          let upcommingArray = res.data.slice(7, 15);
          let overdueArray = res.data.slice(0, 8);
          console.log("get patient count for graph");
          console.log(res.data);

          let arrayoftotal = [];
          let arrayoverdue = [];
          let upcommingdatearray = [];
          let overduedatearray = [];
          await (upcommingArray).forEach(series => {
            upcommingdatearray.push(this.filter(series.date));
            arrayoftotal.push(series.data.Planned ? series.data.Planned : 0 || series.data.Imminent ? series.data.Imminent : 0);
          });

          await (overdueArray).forEach(series => {
            overduedatearray.push(this.filter(series.date));
            arrayoverdue.push(series.data.Overdue ? series.data.Overdue : 0);
          });
          this.upcommingvisitchart.chartOptions =
          {
            stroke: {
              width: 2,
            },
            chart: {
              toolbar: {
                show: false,
              },
              id: "vuechart-example",
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
              },
            },
            xaxis: {
              categories: upcommingdatearray,
            },
          }
          this.upcommingpiechartoptions.labels = upcommingdatearray;
          this.overduevisitchart.chartOptions =
          {
            stroke: {
              width: 2,
            },
            chart: {
              toolbar: {
                show: false,
              },
              id: "vuechart-example",
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
              },
            },
            xaxis: {
              categories: overduedatearray,
            },
          };
          this.overduepiechartoptions.labels = overduedatearray;
          this.upcommingvisitchart.series = [
            {
              name: this.$t('navigations.upcoming visits'),
              data: arrayoftotal,
            },
          ];
          this.upcommingvisitchart.piedataseries = arrayoftotal;
          this.overduevisitchart.series = [
            {
              name: this.$t('navigations.past visits'),
              data: arrayoverdue,
            },
          ];
          this.overduevisitchart.piedataseries = arrayoverdue;
        })
        .catch((err) => {
          console.log(err);
        });
      this.upcommingvisitchart.charttype = "bar"
      this.overduevisitchart.charttype = "bar"
    },
    async queryrecieved() {
      const idtoken = store.getters.getIdToken;
      const currentstuid = await store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/forms/query/queryrecivedlist?StudyId=${currentstuid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.receivedquery = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getcount() {
      const idtoken = store.getters.getIdToken;
      this.valnow = await store.getters.getStudyIs;
      this.getformcount(this.valnow, idtoken);
      this.getquerycount(this.valnow, idtoken);
      this.getsitecount(this.valnow, idtoken);
      this.getpatientscount(this.valnow, idtoken);
      this.getuserscount(this.valnow, idtoken);
      this.getactiveformcount(this.valnow, idtoken);
      //patientscountstd
    },
    async setasdefault() {
      // alert("set as default");
      const idtoken = store.getters.getIdToken;
      let userIdfordefault = {
        "userId": this.jwtDecrypt(idtoken).sub,
        "email": jwt_decode(idtoken).email,
        "defaultDashBoard": "visitsummary"
      };
      await axios
        .put(`${this.baseapi}/account-core/settings/userdashboard`,
          userIdfordefault,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          store.dispatch("setdefaultdashboard", "visitsummary");
          alert(this.$t('navigations.default dashboard updated successfully'));
          this.defaultdashboard = store.getters.getdefaultdashboard;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getpatientscount(currentstudyid, idtoken) {
      console.log("ID token is");
      await axios
        .get(
          `${this.baseapi}/management/patient/getpatientcountinstudy?StudyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getactiveformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/activecounts?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080',
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.activeformcount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.currentPage = 1;
      this.sProp = sortProp;
      this.IsDesc = this.IsDesc == true ? false : true;
      this.getOverdueVisitslist();
    },
    async search() {
      this.setSearchData();
      this.pageindex = 1;
      this.currentPage = 1;
      this.getAndSetData();
    },
    setSearchData() {
      if (this.Status == "All") {
        this.Status = "";
      }
      this.searchSiteCode = this.SiteCode;
      this.searchSiteName = this.SiteName;
      this.searchStatus = this.Status;
      this.totalPage = parseInt(this.totalCount / this.pagesize);

    },
    async getoverduevisits() {
      this.overduevisits = []
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/patientschedule/getoverduevisits?studyid=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.overduevisits = res.data.data;
          this.totaloverdueCount = res.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getplannedvisits() {
      // this.plannedvisits=[]
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/patientschedule/getplannedvisits?studyid=${currentstudy}&PageIndex=${this.pageplannedindex}&PageCount=${this.pageplannedsize}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // this.plannedvisits = res.data.data;
          // console.log("Planned visits are",this.plannedvisits)
          this.totalplannedCount = res.data.totalCount;
          // console.log(idtoken)
          // this.totalplannedPages = parseInt(res.data.totalCount / this.pageplannedsize);
          // if (this.totalplannedCount % this.pageplannedsize != 0) {
          //   this.totalplannedPages = this.totalplannedPages + 1;
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getvisits() {
      this.visits = []
      this.dates = []
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/visit/listvisit?StudyId=${currentstudy}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.visits = res.data.results;
          // console.log("Planned visits are",this.plannedvisits)
          this.visitscount = res.data.totalCount;
          console.log(idtoken)
          this.visits.filter((element) => {
            this.dates.push(element.template_Name);
          });

        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getquerycount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/query/getcounts?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "localhost:8080",
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.querycountstd = res.data;

        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getreceivedquery() {
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/forms/query/queryrecivedlist?StudyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "localhost:8080",
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.receivedquerylist = res.data.results;

        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsitecount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/site/getsitescount?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sitecountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getuserscount(studyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/study/getusercountinstudy?StudyId=${studyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080',
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/getcounts?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080',
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formcountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async raisequery() {
      this.$router.push(`/generalQuery`);
    },
    async GetqueryChartData() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/forms/query/getallcounts?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.secondchart.dataseries = [{
            name: this.$t('navigations.series-1'),
            data: [
              res.data.openQuries,
              res.data.closedQuries
            ]
          }];
          this.secondchart.piedataseries = this.secondchart.dataseries[0].data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jwtDecrypt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getcount() {
      const idtoken = store.getters.getIdToken;
      this.valnow = await store.getters.getStudyIs;
      console.log("current study value is " + this.valnow);
      this.getformcount(this.valnow, idtoken);
      this.getquerycount(this.valnow, idtoken);
      this.getsitecount(this.valnow, idtoken);
      this.getpatientscount(this.valnow, idtoken);
      this.getuserscount(this.valnow, idtoken);
      this.getactiveformcount(this.valnow, idtoken);
      //patientscountstd
    },
    async getpatientscount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/patient/getpatientcountinstudy?StudyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getactiveformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/activecounts?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.activeformcount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getsitecount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/site/getsitescount?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sitecountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getuserscount(studyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/study/getusercountinstudy?StudyId=${studyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getquerycount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/query/getcounts?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.querycountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/getcounts?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formcountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setdefaultstudy() {
      if (this.valnow == "" || this.valnow == null) {
        const idtoken = store.getters.getIdToken;
        this.userId = await this.jwtDecrypt(idtoken).sub;
        await axios
          .get(`${this.baseapi}/management/study/getdefaultstudy?id=${this.userId}`, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.statusText == "No Content") {
              console.log("no default study");
            } else {
              this.currentStudy = res.data.studyID;
              store.dispatch("setStudyID", res.data.studyID);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.valnow = store.getters.getStudyIs;
    },
    async studychange(val) {
      // store.commit(setStudy,val);
      await store.dispatch("setStudyID", val);
      await this.getcount();
      //   await this.GetFormData();
      await this.GetChartData();
      await this.GetqueryChartData();
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate)
      }
    },
    async viewforms(id) {
      const fromArchive = false
      this.$router.push(`/showform/${id}/${fromArchive}`);
    },
    // async GetFormData() {
    //   const idtoken = store.getters.getIdToken;
    //   const stdid = store.getters.getStudyIs;
    //   await axios
    //     .get(`${this.baseapi}/forms/forms/getrecentforms?studyId=${stdid}`, {
    //       headers: {
    //         Authorization: "Bearer " + idtoken,
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       if (response.data) {
    //         this.formlists = response.data;
    //         // console.log("table data", this.formlists);
    //       } else {
    //         console.log("no forms found");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.enableNext = false;
    //       this.enablePrevious = false;
    //     });
    // },
    // loadstudy function
    async loadstudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      await axios
        .get(`${this.baseapi}/management/study/getallstudy?user=${usermail}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.studies = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.valnow = store.getters.getStudyIs;
    },
    // getcompletedVist(){
    //   this.Getoverduecompletedcount("Completed");
    //   this.completed = this.overdueCount.Completed ;
    //   console.log("Completed count is ............", this.overdueCount,this.completed)
    // },
    // getOverdueVisit(){ 
    //   this.Getoverduecompletedcount("Overdue");
    //   this.overdueVist =this.overdueCount.Overdue;
    //   console.log("overdue count is......" , this.overdueVist);
    // },

    async Getoverduecompletedcount() {
      this.overdueCount = 0;
      const currentstuid = store.getters.getStudyIs;
      console.log("overdue visit count std id is " + currentstuid);
      let status = "Overdue";
      await axios.get(`${this.baseapi}/management/patientschedule/overduecompletedcount?StudyId=${currentstuid}&DaysBefore=30&Status=${status}`, this.headers)
        .then((res) => {
          this.overdueCount = res.data.Overdue;
        })
        .catch((err) => {
          console.log("error..", err);
        })
    },
    async GetCompletedCount() {
      const currentstuid = store.getters.getStudyIs;
      let status = "Completed";
      await axios.get(`${this.baseapi}/management/patientschedule/overduecompletedcount?StudyId=${currentstuid}&DaysBefore=30&Status=${status}`, this.headers)
        .then((res) => {
          this.completedCount = res.data.Completed;
        })
        .catch((err) => {
          console.log("error..", err);
        })
    },
    async getPlannedImminentCount() {
      const currentstuid = store.getters.getStudyIs;
      let status = "Planned,Imminent"
      await axios.get(`${this.baseapi}/management/patientschedule/plannedimminentcount?StudyId=${currentstuid}&DaysAfter=30&Status=${status}`, this.headers)
        .then((res) => {
          this.plannedCount = res.data.Imminent + res.data.Planned;
        })
        .catch((err) => {
          console.log("error..", err);
        })
    },
    async getOverdueVisitslist() {
      const currentstuid = store.getters.getStudyIs;
      let status = "Overdue"
      await axios.get(`${this.baseapi}/management/patientschedule/getshedules?StudyId=${currentstuid}&VisitStatus=${status}&PageNo=${this.pageoverdueindex}&ItemCount=${this.pageoverduesize}&sortprop=${this.sProp}&isDes=${this.IsDesc}`, this.headers)
        .then((res) => {
          this.overdueVisitList = res.data.data;
          this.totalCountOverdue = res.data.totalCount;
          this.totaloverduePages = parseInt(this.totalCountOverdue / this.pageoverduesize)
          if ((this.totalCountOverdue % this.pageoverduesize) != 0) {
            this.totaloverduePages = this.totaloverduePages + 1
          }
        })
        .catch((err) => {
          console.log("error..", err);
        })
    },
    SortSelectedIm(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.currentPage = 1;
      this.sProp = sortProp;
      this.IsDesc = this.IsDesc == true ? false : true;
      this.getImminetVisits();
    },
    async getImminetVisits() {
      const currentstuid = store.getters.getStudyIs;
      let status = "Imminent"
      await axios.get(`${this.baseapi}/management/patientschedule/getshedules?StudyId=${currentstuid}&VisitStatus=${status}&PageNo=${this.pageimminentindex}&ItemCount=${this.pageimminentsize}&sortprop=${this.sProp}&isDes=${this.IsDesc}`, this.headers)
        .then((res) => {
          this.imminentVisitList = res.data.data;
          this.totalCountImminent = res.data.totalCount;
          this.totalimminentPages = parseInt(this.totalCountImminent / this.pageimminentsize)
          if ((this.totalCountImminent % this.pageimminentsize) != 0) {
            this.totalimminentPages = this.totalimminentPages + 1
          }

        })
        .catch((err) => {
          console.log("error..", err);
        })
    },
    loadOverdue: function (page) {
      this.currentformPage = page;
      this.pageoverdueindex = this.currentoverduePage;
      this.getOverdueVisitslist();
    },
    loadImminent: function (page) {
      this.currentimminentPage = page;
      this.pageimminentindex = this.currentimminentPage;
      this.getImminetVisits();
    }
  },
};
