import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import RetentionDashboard from "../RetentionDashBoard/RetentionDashboard.vue"
export default {
  name: "patientRetention",
  components: {
    VPagination,
    RetentionDashboard
  },
  props: {},
  data() {
    return {
      baseUrl: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
      refreshFlags: {
        predictionReport: false,
        analyticalReport: true,
      },
      accuracy: "",
      precision: "",
      recall: "",
      f1_score: "",

      retentionDetails: {},
      totalCount: 0,
      trueCount: 0,
      falseCount: 0,
      retainedPercentage: 0,
      dropoutPercentage: 0,
      isLoading: false,
      searchData: {
        PageNo: 0,
        ItemCount: 500,
        SortBy: "",
        IsDescending: false,
        ML_confidenceMin: 0,
        ML_confidenceMax: 1,
        ML_prediction: null,
      },
      currentPage: 1,
      totalPage: 1,
      retentionCount: {},
      showFeatures: false,
      ML_confidence: 0,
      ML_prediction: 0,
      feature_interaction: {},
      tableloadingBlock : false,
      changeHeight: true,
      newfeaturesValue: {},

    };
  },
  async mounted() {
    await this.getPatientRetentionInfo();
    await this.getRetentionCount();
    await this.getModelInfo();
  },
  computed: {
    featuresWithShap() {
      return Object.keys(this.features)
        .filter(key => this.getShapValue(key) !== 0)
        .map(key => ({ key, value: this.features[key] }));
    },
    featuresWithoutShap() {
      return Object.keys(this.features)
        .filter(key => this.getShapValue(key) === 0)
        .map(key => ({ key, value: this.features[key] }));
    }
  },
  methods: {
    async getPatientRetentionInfo() {
      await axios
        .post(`${this.baseUrl}retention/getretentioninfo`, this.searchData)
        .then((res) => {
          this.retentionDetails = res.data.data;
          this.totalCount = res.data.count;
          this.totalPage = Math.ceil( this.totalCount / this.searchData.ItemCount);
        })
        .catch((err) => console.log("error in getting retention result", err));
    },
    async getRetentionCount(){
      await axios.get(`${this.baseUrl}retention/getretentioncounts`)
      .then((res) => {
        this.retentionCount = res.data;
        this.retainedPercentage = this.retentionCount.total_count !== 0
                                    ? ((this.retentionCount.true_count * 100) / this.retentionCount.total_count).toFixed(2)
                                    : null;
        this.dropoutPercentage = this.retentionCount.total_count !== 0
                                    ? ((this.retentionCount.false_count * 100) / this.retentionCount.total_count).toFixed(2)
                                    : null;
      })
      .catch(err => {
        console.log("error in get retention counts", err)
      });
    },
    async getModelInfo() {
      await axios
        .get(`${this.baseUrl}patientenroll/getmodelinfo?templateId=Retained`)
          .then((res) => {
            if(res.data){
              this.model = res.data[res.data.length-1];
            }
            this.accuracy = this.model.PerformanceMatrix.accuracy.toFixed(2);
            this.precision = this.model.PerformanceMatrix.precision.toFixed(2);
            this.recall = this.model.PerformanceMatrix.recall.toFixed(2);
            this.f1_score = this.model.PerformanceMatrix.f1_score.toFixed(2);
          })
          .catch((err) => console.log("error in getting model info...", err));
    },
    loadPage(page) {
      this.currentPage = page;
      this.searchData.PageNo = page;
      this.getPatientRetentionInfo();
      window.scrollTo(10, 10);
    },
    async closemodal() {
      this.showFeatures = false;
    },
    async ViewFeatures(features,feature_interaction,ML_confidence,ML_prediction) {
      this.feature_interaction = feature_interaction;
      this.ML_prediction = ML_prediction;
      this.ML_confidence = ML_confidence;
      this.showFeatures = true;
      this.tableloadingBlock = true;
      this.changeHeight = true;
      window.scroll(0, 0);
      this.newfeaturesValue = features;
      this.features = features
      //this.getPagesInfo();
      this.features.length != 0 ? this.tableloadingBlock = false : true;
    },
    getconfidenceBarColor() {
      const confidencePercentage = this.ML_confidence * 100;
  
      if (this.ML_prediction === true) {
        return `linear-gradient(to right, #8ce38f ${confidencePercentage}%, #FF8184 ${confidencePercentage}%)`;
      } else if (this.ML_prediction === false) {
        return `linear-gradient(to right, #FF8184 ${confidencePercentage}%, #8ce38f ${confidencePercentage}%)`;
      } else {
        return '#FF8184'; // Default to red if prediction is neither 'enrolled' nor 'rejected'
      }
    },
    getShapValue(label) {
      const fieldName = label
      if (fieldName && this.feature_interaction[fieldName]) {
        const shapValues = this.feature_interaction[fieldName];
        const sum = shapValues[0] + shapValues[1];
        const normalizedSum = this.normalizeSum(sum);
        return `${normalizedSum} `; 
      }
      return 0;
    },
    normalizeSum(sum) {
      const overallMin = Math.min(...Object.values(this.feature_interaction).map(val => val[0] + val[1]));
      const overallMax = Math.max(...Object.values(this.feature_interaction).map(val => val[0] + val[1]));
      
      const normalizedSum = ((sum - overallMin) / (overallMax - overallMin)) * 200 - 100;
      return normalizedSum;
    },
    getShapBarWidth(label) {
      const shapValue = this.getShapValue(label);
      return Math.abs(shapValue); 
    },
    getShapBarColor(label) {
      const shapValue = this.getShapValue(label);
      return shapValue >= 0 ? '#8ce38f' : '#FF8184'; 
    },
    handleTabClick(tab) {
      this.refreshFlags = [false, false];
      this.refreshFlags[tab] = true;
    },
  },
};
