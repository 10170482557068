/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  name: "AnalyticalDashboard",
  data(){
    return{
      refreshFlags: {
        DataAnalytics : true,
        OperationalAnalytics: false,
        idtoken : "",
        
      }
    }
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    console.log("roles",this.rollsprivilages)
  },
  methods:{
    handleTabClick(tab) {
      this.refreshFlags = [false, false];
      this.refreshFlags[tab] = true;
    },
  }
}
