/* eslint-disable */
import axios from "axios";
import moment from 'moment';
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import * as XLSX from "xlsx";
import html2pdf from 'html2pdf.js';
import stackedbarchart from '../../custom_lib/AiCharts/StackedBarchart.vue';
import piechart from '../../custom_lib/AiCharts/D3PieChart.vue';
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
import barchart from '../../custom_lib/AiCharts/adbarchart.vue';
// import from 'jstree'
export default {
    name: "subjectstatusreport",
    components: {
        stackedbarchart,
        piechart,
        historytab,
        advtable,
        chartdrill,
        barchart
      },
  data() {
    return{
        basetestUrl: process.env.VUE_APP_Service_URL,
        loader:true,
        drillpopup: false,
        data:[],
        datahistory:[],
        historykeys:[],
        historydata:{},
        drilldata:{},
        drilldowndata:[],
        subjectKeys:[
            "SITEID", "DSDECOD", "COCFL", "CMPRESFL", 
            "CMBIOLFL", "CMDISFL", "CMONGOFL", "CMIMMODFL", "CMDISIMMODFL", "CMONGOIMMODFL", 
            "CM5ASAF", "CMDIS5ASAF", "CMONGO5ASAF", "CMCORTFL", "CMDISCORTFL", "CMONGOCORTFL ", 
            "CMCONTFL", "CMDOSEFL", "CMNODOSEFL", "CMRELFL", "RPEVALFL", 
             "RPCHILDPOT", "RPNPST", "EMPSTAT", "INCOME", "MARTL", "NATION", 
            "MILSTAT", "EXPSMK", "TOBCNSLFL", "DSCAT", "DSEPOCH", 
            "DSCONTEPOCH", "DSNXTEPOCH"
          ],
        xaxiskeys:{"groupbarchart":"",
          "barchart":"",
          "histogram":"",
          "piechart":"",
          "advtable":"",
          "aepiechart":"",
          "historytab":"",
          "chartdrill":""}
        
    }
  },
  async mounted(){
    await this.getdata()
  },
  methods:{
    async exporttoexcell() {
      const worksheet = XLSX.utils.json_to_sheet(this.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Subject Status Report.xlsx");
    },
    // exporttopdf() {
    //   const chartElement = this.$refs.captureArea;
    
    //   const opt = {
    //     margin: 0,
    //     filename: 'chart.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
    //   };
    
    //   html2pdf().set(opt).from(chartElement).save();
    // },
    exporttopdf() {
      // Create a temporary container
      const pdfContainer = document.createElement('div');
      const pdfContainer1 = document.createElement('div');
      const pdfContainer2 = document.createElement('div');
      const pdfContainer3 = document.createElement('div');
      // Add the elements you want to include in the PDF
      const captureArea1 = this.$refs.captureArea1.cloneNode(true);
      const captureArea2 = this.$refs.captureArea2.cloneNode(true);
      const captureArea3 = this.$refs.captureArea3.cloneNode(true);

      captureArea2.classList.add('page-break');
      captureArea3.classList.add('page-break');
      // Append cloned elements to the container
      pdfContainer1.appendChild(captureArea1);
      pdfContainer2.appendChild(captureArea2);
      pdfContainer3.appendChild(captureArea3);

      pdfContainer2.style.width = '200%';
      pdfContainer3.style.width = '200%';

      pdfContainer.appendChild(pdfContainer1);
      pdfContainer.appendChild(pdfContainer2);
      pdfContainer.appendChild(pdfContainer3);
      
      // Optionally style the container for PDF formatting
      pdfContainer.style.display = 'block';
      pdfContainer.style.width = '100%';
      
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: 'Subject Status Report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    async retainxaxiskey(xaxiskey){
      this.xaxiskeys["piechart"] = xaxiskey;
    },
    async gotohistorylevel(history){
      this.deleteAfterKeyvaluearrays(history);
      this.deleteAfterKeyarrays(history);
      this.data = this.historydata[history];
    },
    async deleteAfterKeyvaluearrays(selectedKey) {
      const entries = Object.entries(this.historydata); // Convert the object to an array of entries
      const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
    
      // If the key is found, slice the array to keep only the entries up to the selected key
      const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
    
      // Convert the array of entries back into an object
      return Object.fromEntries(newEntries);
    },
    async deleteAfterKeyarrays(selectedKey) {

      let index = this.historykeys.indexOf(selectedKey);

      if (index !== -1) {
          // Slice the array up to the element (including the element)
          this.historykeys = this.historykeys.slice(0, index + 1);
      }
    },
    async retainxaxiskey(xaxiskey){
      this.xaxiskeys["piechart"] = xaxiskey;
    },
    async onclosed(){
      this.drillpopup = false;
    },
    async drilldowncurrentchartforbar(datafromchild){
      const lastkey = this.historykeys[this.historykeys.length - 1];
      if(this.historydata[lastkey].length != datafromchild.sortedData.length){
       this.historykeys.push(datafromchild.filterkeyword);
      this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
      this.data = this.historydata[datafromchild.filterkeyword];
      }
    },
    async drillpopupopened(datafromchild) {
      this.drilldata = datafromchild;
      this.drillpopup = true;

    },
    async getdata() {
        this.loader = true;
        await axios.get(
          `${this.basetestUrl}/forms-ml/report/getreports?table_name=SubjectStatus`)
          .then((res) => {
            this.data = res.data;
            this.loader = false;
            this.historydata['Home'] = this.data;
            this.historykeys.push('Home');
          })
          .catch((err) => console.log("error in screen list", err));
    },
    async pageclosed(){
        this.$router.push(`/analyticalDashboard`);
    }
  }
}