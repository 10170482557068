<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white">
      <span>{{ chartlabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <select class="graph-select py-0" v-model="selectedxaxis" id="fieldSelect">
          <option value="" disabled>Select X-Axis Field</option>
          <option v-for="(column, index) in attributeOptions" :key="index" :value="column">
            {{ column }}
          </option>
        </select>
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img  class="cursor-pointer" @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>{{$t("navigations.data overview")}}</span>
          </div>
        </div> 
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
     <div
      class="stat-body-white d-flex flex-column" :class="{'h-100':loader==true}">
      <div v-if="loader" class="loader"></div>
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div ref="chartContainerWidth" :class="{'overflow-x-auto':fromPage=='statusReport'}" v-show="!loader" class="w-100">
        
        <svg ref="chartContainer" ></svg>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,createBarChart()">
    <div
      class="stat-body-white d-flex flex-column">
    <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
    <div class="histogram-content overflow-x-scroll">
      <svg ref="popupChartContainer"></svg>
    </div>
  </div>
     
  </enlargerpopup>
</template>

<script>
import * as d3 from 'd3';
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: 'BarChart',
  components:{
    enlargerpopup
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      required: true,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: false,
    },
    xAxisKey: {
      type: String,
      default: 'AEOUT',
    },
    yAxisKey: {
      type: String,
      default: 'AEYN',
    },
    yAxisLabel: {
      type: String,
      default: 'Count',
    },
    chartlabel: {
      type: String,
      default: "Bar chart",
    },
    fromPage:{
      type:String,
      default:""
    },
    graphwidth: {
      type: Number,
      default: 1200,
    },
    graphheight: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      showVisitPopup: false,
      attributeOptions:[],
      selectedxaxis:"",
      chartdata: [],
      graphdata:[],
      selectedyaxis: "",
      chartContainer: null,
      resizeObserver: null,
      tooltip: null,
      expandBlock:false,
      barselectedarray:[],
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      color:{},
      tableArray:[],
      mouseX:0,
      mouseY:0
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.component) {
        console.error("Component ref is not defined.");
      }
    });
    this.extractAttributeOptions();
    window.addEventListener('mousemove', this.updateMousePosition)
    this.selectedxaxis = this.xAxisKey;
    this.selectedyaxis = this.yAxisKey;
    this.chartContainer = this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.createBarChart();
     // Add resize observer to make SVG responsive
    this.resizeObserver = new ResizeObserver(() => this.createBarChart());
    this.resizeObserver.observe(this.$refs.chartContainer);
  },
  watch: {
    data: {
      handler() {
        this.graphdata = this.data
        this.extractAttributeOptions();
        this.createBarChart();
        this.cancelselection();

      },
      deep: true,
    },
    selectedxaxis() {
      this.createBarChart();
      this.cancelselection();

    },
    selectedyaxis() {
      this.createBarChart();
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          console.log("Entered")
          this.createBarChart();
        });
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
    // Disconnect the observer on component destruction
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: `${this.chartlabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    extractAttributeOptions() {
      if (this.data.length > 0) {
        if(this.availableColumns.length == 0){
        this.attributeOptions = Object.keys(this.data[0]).filter((key)=>
          key!='_id' && key!='STUDYID' && key!='USUBJID'
        );
        }
        else{
          this.attributeOptions=this.availableColumns;
        }
      }
    },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      const rect = this.$refs.chartContainer.getBoundingClientRect();
      if (!rect) {
        console.error("Component reference is not defined or not yet mounted.");
        return;
      }
    
      // Calculate the mouse X and Y positions relative to the component
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
  showtable() {
    this.$emit("opendrillpopup", this.chartdata);
  },
  async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createBarChart();
},
  backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
  createBarChart() {
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    this.chartdata = this.data;
    if (!this.chartContainer) return;

    d3.select(this.chartContainer).selectAll('*').remove();
    if(this.fromPage=='statusReport')
    {
      const margin = { top: 30, right: 20, bottom: 100, left: 70 }; // Fixed margins
      const fixedBarWidth = 40; // Fixed width for each bar
      const gapBetweenBars = 10; // Gap between bars
      const dataCount = new Set(this.data.map(d => d[this.selectedxaxis])).size; // Unique bars

      // Calculate the total width based on the number of bars, the width of each bar, and the gap between them
      const totalBarsWidth = (dataCount * fixedBarWidth) + ((dataCount - 1) * gapBetweenBars); // Total width of all bars and gaps combined
      const svgWidth = totalBarsWidth + margin.left + margin.right; // SVG width includes the total width of bars and margins
      const height = this.graphheight - margin.top - margin.bottom; // Chart height

      const svg = d3
        .select(this.chartContainer)
        .attr('width', svgWidth)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      let groupedData;   
    
      if (this.selectedyaxis === 'AEYN') {
        groupedData = d3.rollups(
          this.data.map(d => ({
            ...d,
            [this.selectedxaxis]: d[this.selectedxaxis] || 'null'
          })),
          (v) => v.filter(d => d[this.selectedyaxis] === 'Yes').length,
          (d) => d[this.selectedxaxis]
        );
      } else if (this.selectedyaxis === 'count') {
        groupedData = d3.rollups(
          this.data.map(d => ({
            ...d,
            [this.selectedxaxis]: d[this.selectedxaxis] || 'null'
          })),
          (v) => v.length,
          (d) => d[this.selectedxaxis]
        );
      } else {
        groupedData = d3.rollups(
          this.data.map(d => ({
            ...d,
            [this.selectedxaxis]: d[this.selectedxaxis] || 'null'
          })).filter(d => d[this.selectedxaxis]),
          (v) => d3.sum(v, (d) => d[this.selectedyaxis]),
          (d) => d[this.selectedxaxis]
        );
      }
      
    // Check if this.color is a d3 ordinal scale by ensuring it has a domain method
if (!this.color || typeof this.color.domain !== 'function') {
    // Initialize this.color if it hasn't been already
    this.color = d3.scaleOrdinal()
        .range(['#62B2FD', '#9BDFC4', '#ABF0FF', '#C1D1FF', '#FBD164', '#FDAD73'].concat(d3.schemeCategory10));
}

// Get the current domain (if already initialized)
let currentDomain = this.color.domain();

// Get the new keys from groupedData
let newKeys = groupedData.map((d) => d[0]);

// Merge the new keys with the existing domain, adding only keys that don't already exist
let updatedDomain = [...currentDomain, ...newKeys.filter(key => !currentDomain.includes(key))];

// Update the color scale's domain with the merged domain
this.color.domain(updatedDomain);
    const x = d3
      .scaleBand()
      .domain(groupedData.map((d) => d[0]))
      .range([0,totalBarsWidth])
      .padding(0.2);

    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .text(function(d) {
        // Handle null or empty string cases, and truncate the label if needed
        const label = d === null || d === '' ? 'Null' : d;
        return label.length > 13 ? `${label.slice(0, 10)}...` : label;
      })
      .attr('transform', 'translate(-10,0)rotate(-45)')

      .style('text-anchor', 'end');

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(groupedData, (d) => d[1]) || 0])
      .nice()
      .range([height, 0]);

    svg.append('g').call(d3.axisLeft(y));

    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - (height / 2))
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text(this.yAxisLabel);  // Set y-axis label from prop

    svg
      .selectAll('rect')
      .data(groupedData)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d[0]) || 0) // Calculate the X position for each bar based on its index
      .attr('y', d => y(d[1]))
      .attr('width', x.bandwidth()) // Fixed width for each bar
      .attr('height', (d) => height - y(d[1]))
      .attr('fill', (d) => this.color(d[0]))
      .attr("stroke", "none")
      .attr("style", (d) =>
          this.selectedGroups.length!=0?(this.selectedGroups.includes(d[0]) ? "" : "opacity: 35%;"):""
        )
      .on('mouseover', (event, d) => {
        d3.select(this.tooltip)
        .style('opacity', 1)
        .html(`Category: ${d[0]}<br>Value: ${d[1]}`)
        .style("left",  this.mouseX + "px")
        .style("top", this.mouseY + "px");

      })
      .on("mouseout", () => {
        d3.select(this.tooltip).style("opacity", 0);
      })
      .on('mousemove', event => {
          d3.select(this.tooltip)
          .style("left",  this.mouseX + "px")
          .style("top", this.mouseY + "px");
        })
      .on('click', (event, d) => {
        d3.select(this.tooltip).style("opacity", 0);
        const filteredData = this.data.filter(item => (item[this.selectedxaxis] || 'null') === d[0]);
        this.clickbarinchart(filteredData, d[0]);
      });

    svg
      .selectAll('text.bar-label')
      .data(groupedData)
      .enter()
      .append('text')
      .attr('class', 'bar-label')
      .attr('x', (d) => (x(d[0]) || 0) + x.bandwidth() / 2)
      .attr('y', (d) => y(d[1]) - 5)
      .attr('text-anchor', 'middle')
      .text((d) => d[1]);
    
   
    }
    else{
      const margin = { top: 30, right: 300, bottom: 70, left: 60 };
      const width = 800 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;    

    const svg = d3
      .select(this.chartContainer)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    let groupedData;

    if (this.selectedyaxis === 'AEYN') {
      groupedData = d3.rollups(
        this.data.map(d => ({
          ...d,
          [this.selectedxaxis]: d[this.selectedxaxis] || 'null'
        })),
        (v) => v.filter(d => d[this.selectedyaxis] === 'Yes').length,
        (d) => d[this.selectedxaxis]
      );
    } else if (this.selectedyaxis === 'count') {
      groupedData = d3.rollups(
        this.data.map(d => ({
          ...d,
          [this.selectedxaxis]: d[this.selectedxaxis] || 'null'
        })),
        (v) => v.length,
        (d) => d[this.selectedxaxis]
      );
    } else {
      groupedData = d3.rollups(
        this.data.map(d => ({
          ...d,
          [this.selectedxaxis]: d[this.selectedxaxis] || 'null'
        })).filter(d => d[this.selectedxaxis]),
        (v) => d3.sum(v, (d) => d[this.selectedyaxis]),
        (d) => d[this.selectedxaxis]
      );
    }
    // Check if this.color is a d3 ordinal scale by ensuring it has a domain method
if (!this.color || typeof this.color.domain !== 'function') {
    // Initialize this.color if it hasn't been already
    this.color = d3.scaleOrdinal()
        .range(['#62B2FD', '#9BDFC4', '#ABF0FF', '#C1D1FF', '#FBD164', '#FDAD73'].concat(d3.schemeCategory10));
}

// Get the current domain (if already initialized)
let currentDomain = this.color.domain();

// Get the new keys from groupedData
let newKeys = groupedData.map((d) => d[0]);

// Merge the new keys with the existing domain, adding only keys that don't already exist
let updatedDomain = [...currentDomain, ...newKeys.filter(key => !currentDomain.includes(key))];

// Update the color scale's domain with the merged domain
this.color.domain(updatedDomain);
    const x = d3
      .scaleBand()
      .domain(groupedData.map((d) => d[0]))
      .range([0, width])
      .padding(0.2);

    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .text(function(d) {
        // Handle null or empty string cases, and truncate the label if needed
        const label = d === null || d === '' ? 'Null' : d;
        return label.length > 6 ? `${label.slice(0, 10)}...` : label;
      })
      .attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'end');

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(groupedData, (d) => d[1]) || 0])
      .nice()
      .range([height, 0]);

    svg.append('g').call(d3.axisLeft(y));

    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - (height / 2))
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text(this.yAxisLabel);  // Set y-axis label from prop

    svg
      .selectAll('rect')
      .data(groupedData)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d[0]) || 0)
      .attr('y', (d) => y(d[1]))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d[1]))
      .attr('fill', (d) => this.color(d[0]))
      .attr("stroke", "none")
      .attr("style", (d) =>
          this.selectedGroups.length!=0?(this.selectedGroups.includes(d[0]) ? "" : "opacity: 35%;"):""
        )
        .on('mouseover', (event, d) => {
        d3.select(this.tooltip)
        .style('opacity', 1)
        .html(`Category: ${d[0]}<br>Value: ${d[1]}`)
        .style("left",  this.mouseX + "px")
        .style("top", this.mouseY + "px");

      })
      .on("mouseout", () => {
        d3.select(this.tooltip).style("opacity", 0);
      })
      .on('mousemove', event => {
          d3.select(this.tooltip)
          .style("left",  this.mouseX + "px")
          .style("top", this.mouseY + "px");
        })
      .on('click', (event, d) => {
        d3.select(this.tooltip).style("opacity", 0);
        const filteredData = this.data.filter(item => (item[this.selectedxaxis] || 'null') === d[0]);
        this.clickbarinchart(filteredData, d[0]);
      });

    svg
      .selectAll('text.bar-label')
      .data(groupedData)
      .enter()
      .append('text')
      .attr('class', 'bar-label')
      .attr('x', (d) => (x(d[0]) || 0) + x.bandwidth() / 2)
      .attr('y', (d) => y(d[1]) - 5)
      .attr('text-anchor', 'middle')
      .text((d) => d[1]);
    }
   
  },
  clickbarinchart(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedxaxis}(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `${this.selectedxaxis}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createBarChart();
  },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
},
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";
.bar-chart-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
}
.selectedbargraph{
  opacity: 50%;
}
svg {
  font-family: sans-serif;
  font-size: 10px;
}

text.bar-label {
  fill: black;
  font-size: 12px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
.overflow-x-auto{
    overflow-x:auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.overflow-x-scroll{
  overflow-x: auto;
}
</style>
