`<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-6 col-lg-10">
      <h2>{{ $t("navigations.visit summary") }}</h2>
    </div>
    <div
      class="col-6 col-lg-2 text-end align-self-center"
      v-if="defaultdashboard != 'visitsummary'"
    >
      <button
        type="button"
        class="btn btn-setdefault fontprop"
        @click="setasdefault()"
      >
        {{ $t("navigations.set as default") }}
      </button>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div
          class="dash-widget cursor-pointer"
          @click.prevent="$router.push('/submitForm')"
        >
          <div class="row">
            <div class="col-8 text-left">
              <span>
                <h4>{{ $t("navigations.upcoming visits") }}</h4></span
              >
              <h1 class="font-bold count-1">{{ plannedCount }}</h1>
            </div>
            <div class="col-4 justify-content-center align-self-top">
              <span class="label label-success float-right">{{
                $t("navigations.next month")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/submitForm')">
                <div class="ibox-title style_2">
                  <span class="label label-success float-right">{{$t('navigations.next month')}}</span>
                  <h5>{{$t('navigations.upcoming visits')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{plannedCount}}</h1>
                    <small>{{$t('navigations.upcoming visits')}}</small>
                </div>
            </div>
        </div> -->
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div
          class="dash-widget cursor-pointer"
          @click.prevent="$router.push('/submitForm')"
        >
          <div class="row">
            <div class="col-8 text-left">
              <span>
                <h4>{{ $t("navigations.overdue visits") }}</h4></span
              >
              <h1 class="font-bold count-1">{{ overdueCount }}</h1>
            </div>
            <div class="col-4 justify-content-center align-self-top">
              <span class="label label-info float-right">{{
                $t("navigations.last month")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/submitForm')">
                <div class="ibox-title style_2">
                  <span class="label label-info float-right">{{$t('navigations.last month')}}</span>
                    <h5>{{$t('navigations.overdue visits')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{overdueCount}}</h1>
                    <small>{{$t('navigations.overdue visits')}}</small>
                </div>
            </div>
        </div> -->

      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div
          class="dash-widget cursor-pointer"
          @click.prevent="$router.push('/listform')"
        >
          <div class="row">
            <div class="col-8 text-left">
              <span>
                <h4>{{ $t("navigations.completed visits") }}</h4></span
              >
              <h1 class="font-bold count-1">{{ completedCount }}</h1>
            </div>
            <div class="col-4 justify-content-center align-self-top">
              <span class="label label-info float-right">{{
                $t("navigations.last month")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/listform')">
                <div class="ibox-title style_2">
                  <div class="label label-info float-right">{{$t('navigations.last month')}}</div>
                    <h5>{{$t('navigations.completed visits')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{completedCount}}</h1>
                    <small>{{$t('navigations.completed visits')}}</small>
                </div>
            </div>
        </div> -->
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div
          class="dash-widget cursor-pointer"
          @click.prevent="$router.push('/allQueryList')"
        >
          <div class="row">
            <div class="col-8 text-left">
              <span>
                <h4>{{ $t("navigations.open query") }}</h4></span
              >
              <h1 class="font-bold count-1">{{ querycountstd }}</h1>
            </div>
            <div class="col-4 justify-content-center align-self-top">
              <span class="label primary-bg float-right">{{
                $t("navigations.monthly")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/allQueryList')">
                <div class="ibox-title style_2">
                  <div class="ibox-tools">
                                <span class="label primary-bg float-right">{{$t('navigations.monthly')}}</span>
                            </div>
                    <h5>{{$t('navigations.open query')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{querycountstd}}</h1>
                    <small>{{$t('navigations.open query')}}</small>
                </div>
            </div>
        </div> -->
      <div class="col-lg-6 pr-0 pl-1 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between py-1">
            <h5 class="mt-1">{{ $t("navigations.upcoming visits") }}</h5>
            <div class="graphbtncontainer">
              <div class="graphbutton">
                <img
                  src="../../assets/images/bar_clr.png"
                  @click.prevent="upcommingchange('bar')"
                />
              </div>
              <div class="graphbutton">
                <img
                  src="../../assets/images/horiz_bar_clr.png"
                  @click.prevent="upcommingchange('barhorizondal')"
                />
              </div>
              <div class="graphbutton">
                <img
                  src="../../assets/images/Line_clr.png"
                  @click.prevent="upcommingchange('line')"
                />
              </div>
              <div class="graphbutton">
                <img
                  src="../../assets/images/Pie_clr.png"
                  @click.prevent="upcommingchange('pie')"
                />
              </div>
              <div class="graphbutton">
                <img
                  src="../../assets/images/bubble_clr.png"
                  @click.prevent="upcommingchange('scatter')"
                />
              </div>
            </div>
          </div>
          <div class="ibox-content">
            <div>
              <apexchart
                v-if="upcommingvisitchart.charttype != 'pie'"
                width="100%"
                height="250px"
                :type="upcommingvisitchart.charttype"
                :options="upcommingvisitchart.chartOptions"
                :series="upcommingvisitchart.series"
              ></apexchart>
              <apexchart
                v-if="upcommingvisitchart.charttype == 'pie'"
                width="100%"
                height="250px"
                :type="upcommingvisitchart.charttype"
                :options="upcommingpiechartoptions"
                :series="upcommingvisitchart.piedataseries"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 my-1 my-lg-2">
          <div class="titlemsg style_2">
             Received queries
          </div>     
          <div class="ibox-body minwidthdiv px-3">
            <div class="table-responsive strechtable">
                <table class="table table-striped table-bordered dataTables " >
                    <thead>
                    <tr>
                        <th>Query Id</th>
                        <th>Subject ID</th>
                        <th>Raised by</th>
                        <th>Date</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="list in receivedquerylist" :key="list.id">
                            <td>{{list.queryId}}</td>
                            <td>{{list.subjectId}}</td>
                            <td>{{list.createdUserEmail}}</td>
                            <td>{{list.createdAt}}</td>
                            </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div> -->

      <div class="col-lg-6 pr-0 pl-1 mb-1">
        <div class="ibox-title style_2 d-flex justify-content-between py-1">
          <h5 class="mt-1">{{ $t("navigations.past visits") }}</h5>
          <div class="graphbtncontainer">
            <div class="graphbutton">
              <img
                src="../../assets/images/bar_clr.png"
                @click.prevent="pastchange('bar')"
              />
            </div>
            <div class="graphbutton">
              <img
                src="../../assets/images/horiz_bar_clr.png"
                @click.prevent="pastchange('barhorizondal')"
              />
            </div>
            <div class="graphbutton">
              <img
                src="../../assets/images/Line_clr.png"
                @click.prevent="pastchange('line')"
              />
            </div>
            <div class="graphbutton">
              <img
                src="../../assets/images/Pie_clr.png"
                @click.prevent="pastchange('pie')"
              />
            </div>
            <div class="graphbutton">
              <img
                src="../../assets/images/bubble_clr.png"
                @click.prevent="pastchange('scatter')"
              />
            </div>
          </div>
        </div>

        <div class="ibox-content">
          <div>
            <apexchart
              v-if="overduevisitchart.charttype != 'pie'"
              width="100%"
              height="250px"
              :type="overduevisitchart.charttype"
              :options="overduevisitchart.chartOptions"
              :series="overduevisitchart.series"
            ></apexchart>
            <apexchart
              v-if="overduevisitchart.charttype == 'pie'"
              width="100%"
              height="250px"
              :type="overduevisitchart.charttype"
              :options="overduepiechartoptions"
              :series="overduevisitchart.piedataseries"
            ></apexchart>
          </div>
        </div>
        <!-- <div class="ibox-body minwidthdiv px-3">
            <div class="table-responsive strechtable">
                <table class="table table-striped table-bordered dataTables " >
                    <thead>
                    <tr>
                        <th>Query Id</th>
                        <th>Subject ID</th>
                        <th>Raised by</th>
                        <th>Date</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="list in receivedquerylist" :key="list.id">
                            <td>{{list.queryId}}</td>
                            <td>{{list.subjectId}}</td>
                            <td>{{list.createdUserEmail}}</td>
                            <td>{{list.createdAt}}</td>
                            </tr>
                    </tbody>
                </table>
            </div>
          </div> -->
      </div>
      <div class="col-lg-6 pr-0 pl-1">
        <div class="ibox-title style_2 d-flex justify-content-between py-1">
          <h5 class="mt-2">{{ $t("navigations.overdue visits") }}</h5>
          <div
            class="position-relative cursor-pointer"
            v-if="overdueVisitList.length!=0"
            @click.prevent="showFilter = !showFilter"
          >
            <img
              src="../../assets/img/filter.png"
              width="17"
              height="17"
              alt="filter table"
            />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
        </div>
        <div class="ibox-content">
          <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
            <div class="col-3 px-0" v-on:clickout="dropdownOpen = false">
              <div
                class="table-multi-select-dropdown"
                @click.stop="dropdownOpen = !dropdownOpen"
              >
                <div class="selected-options">
                  <span>Select Columns</span>
                </div>
                <div class="dropdown-arrow"></div>
              </div>
              <div
                v-if="dropdownOpen"
                class="table-dropdown-content"
                @click.stop
              >
                <label v-for="(column, index) in getColumns" :key="index">
                  <input
                    type="checkbox"
                    v-model="selectedcolumnobj[column]"
                    :value="true"
                  />
                  {{ column }}
                </label>
              </div>
            </div>
          </div>
          <div class="text-center" v-if="overdueVisitList.length===0">
            No data available
          </div>
          <div v-else class="table-responsive strechtable minwidthdiv">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th v-if="selectedcolumnobj['Subject ID']">
                    {{ $t("navigations.subject id") }}
                  </th>
                  <th v-if="selectedcolumnobj['Site ID']">
                    {{ $t("navigations.site id") }}
                  </th>
                  <th v-if="selectedcolumnobj['Visit No.']">
                    {{ $t("navigations.visit no") }}
                  </th>
                  <th
                    v-if="selectedcolumnobj['Date']"
                    class="sort_block"
                    @click="SortSelected('visitDate', 4)"
                    id="4"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.date") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th class="text-center">{{ $t("navigations.forms") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="list in overdueVisitList" :key="list.sheduleId">
                  <td v-if="selectedcolumnobj['Subject ID']">
                    {{ list.patientNo }}
                  </td>
                  <td v-if="selectedcolumnobj['Site ID']">
                    {{ list.siteCode }}
                  </td>
                  <td v-if="selectedcolumnobj['Visit No.']">
                    {{ list.visitNo }}
                  </td>
                  <td v-if="selectedcolumnobj['Date']">
                    {{ filter(list.visitDate) }}
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-file-text edit-delete"
                      @click.prevent="
                        openFormsModal(
                          list.visitNo,
                          list.patientId,
                          list.studyId
                        )
                      "
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mx-0" v-if="overdueVisitList.length!=0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination float-right"
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentoverduePage"
                    :pages="totaloverduePages"
                    active-color="#618bd7"
                    @update:modelValue="loadOverdue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pr-0 pl-1">
        <div class="ibox-title style_2 d-flex justify-content-between py-1">
          <h5 class="mt-2">{{ $t("navigations.imminent visits") }}</h5>
          <div
            class="position-relative cursor-pointer"
            v-if="imminentVisitList.length!=0"
            @click.prevent="showImminentFilter = !showImminentFilter"
          >
            <img
              src="../../assets/img/filter.png"
              width="17"
              height="17"
              alt="filter table"
            />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
        </div>
        <div class="ibox-content">
          <div
            class="d-flex justify-content-end px-0 mb-2"
            v-if="showImminentFilter"
          >
            <div
              class="col-3 px-0"
              v-on:clickout="dropdownImminentOpen = false"
            >
              <div
                class="table-multi-select-dropdown"
                @click.stop="dropdownImminentOpen = !dropdownImminentOpen"
              >
                <div class="selected-options">
                  <span>Select Columns</span>
                </div>
                <div class="dropdown-arrow"></div>
              </div>
              <div
                v-if="dropdownImminentOpen"
                class="table-dropdown-content"
                @click.stop
              >
                <label
                  v-for="(column, index) in getImminentColumns"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    v-model="selectedImminentcolumnobj[column]"
                    :value="true"
                  />
                  {{ column }}
                </label>
              </div>
            </div>
          </div>
          <div class="text-center" v-if="imminentVisitList.length===0">
            No data available
          </div>
          <div v-else class="table-responsive strechtable minwidthdiv">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th v-if="selectedImminentcolumnobj['Subject ID']">
                    {{ $t("navigations.subject id") }}
                  </th>
                  <th v-if="selectedImminentcolumnobj['Site ID']">
                    {{ $t("navigations.site id") }}
                  </th>
                  <th v-if="selectedImminentcolumnobj['Visit No.']">
                    {{ $t("navigations.visit no") }}
                  </th>
                  <th
                    v-if="selectedImminentcolumnobj['Date']"
                    class="sort_block"
                    @click="SortSelectedIm('visitDate', 4)"
                    id="4"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.date") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th>{{ $t("navigations.forms") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in imminentVisitList" :key="item.patientId">
                  <td v-if="selectedImminentcolumnobj['Subject ID']">
                    {{ item.patientNo }}
                  </td>
                  <td v-if="selectedImminentcolumnobj['Site ID']">
                    {{ item.siteCode }}
                  </td>
                  <td v-if="selectedImminentcolumnobj['Visit No.']">
                    {{ item.visitNo }}
                  </td>
                  <td v-if="selectedImminentcolumnobj['Date']">
                    {{ filter(item.visitDate) }}
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-file-text edit-delete"
                      @click.prevent="
                        openImminentFormsModal(
                          item.visitNo,
                          item.patientId,
                          item.studyId
                        )
                      "
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mx-0" v-if="imminentVisitList.length!=0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination float-right"
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentimminentPage"
                    :pages="totalimminentPages"
                    active-color="#618bd7"
                    @update:modelValue="loadImminent"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-12 my-4 my-lg-2">
            <div class="titlemsg style_2">
                <span class="titlemsg">Patient Count</span>
            </div>
            <div class="ibox-body">
              <div class="ibox-content patientcountchartdiv">
                  <div>
                    <apexchart width="100%" height='400px' :type="secondchart.charttype" :options="secondchart.chartOptions" :series="secondchart.series"></apexchart>
                  </div>
              </div>
            </div>
        </div> -->
      <!--new form table-->
      <!-- <div class="col-lg-12 my-1 my-lg-2">
        <div class="ibox-body p-2">
          <div class="ibox p-1">
            <div class="table-responsive">
                <table class="table table-striped table-bordered dataTables" >
                    <thead>
                    <tr>
                        <th>Site code</th>
                        <th> cursor-pointer" @click.prevent="$router.push('/listform')"</th>
                        <th>Visit</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr  v-for="list in formlists" :key="list.formId">
                            <td>{{list.siteId}}</td>
                            <td>{{list.subjectId}}</td>
                            <td>{{list.visitName}}</td>
                            <td>{{list.formStatus}}</td>
                            <td><i class="fa fa-eye editicon" @click.prevent="viewforms(list.formId)"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- Visit form list modal starts -->
  <div class="modal__schedule__wrapper" v-if="sohowFormModal">
    <div class="modal__schedule__container-forms">
      <div
        class="modal__schedule__title d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0">{{ $t("navigations.forms") }}</h4>
        <div class="cursor-pointer">
          <i
            class="fa fa-times"
            title="Close"
            @click.prevent="closeFormsModal()"
          >
          </i>
        </div>
      </div>
      <div class="modal-body">
        <div class="form-group row my-lg-2">
          <div class="d-flex flex-column flex-lg-row mb-lg-0">
            <div class="col-lg-12">
              <table class="table features-table">
                <tbody>
                  <p v-if="formlist.data == ''" class="mb-0 text-center">
                   {{ $t("navigations.no forms added") }}
                  </p>
                  <tr v-for="forms in formlist" :key="forms.sheduleId">
                    <td>{{ forms.templateName }}</td>
                    <td>
                      <span
                        class="badge badge-grn"
                        v-if="forms.visitStatus == 'Completed'"
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                        class="badge badge-danger"
                        v-if="forms.visitStatus == 'Overdue'"
                        >{{ forms.visitStatus }}
                      </span>
                      <span
                        class="badge badge-yellow"
                        v-if="forms.visitStatus == 'Imminent'"
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                        class="badge badge-gray"
                        v-if="forms.visitStatus == 'Withdrawn'"
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                        class="badge badge-blue"
                        v-if="
                          forms.visitStatus == 'Planned'
                        "
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                                  class="badge badge-orage"
                                  v-if="forms.visitStatus == 'Pending'"
                                  >{{ forms.visitStatus }}
                                </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Visit form list modal ends -->
    <!-- Visit form list modal starts -->
  <div class="modal__schedule__wrapper" v-if="sohowFormModalImminent">
    <div class="modal__schedule__container-forms">
      <div
        class="modal__schedule__title d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0">{{ $t("navigations.forms") }}</h4>
        <div class="cursor-pointer">
          <i
            class="fa fa-times"
            title="Close"
            @click.prevent="closeImminentFormsModal()"
          >
          </i>
        </div>
      </div>
      <div class="modal-body">
        <div class="form-group row my-lg-2">
          <div class="d-flex flex-column flex-lg-row mb-lg-0">
            <div class="col-lg-12">
              <table class="table features-table">
                <tbody>
                  <p v-if="formlist.data == ''" class="mb-0 text-center">
                     {{ $t("navigations.no forms added") }}
                  </p>
                  <tr v-for="forms in formlist" :key="forms.sheduleId">
                    <td>{{ forms.templateName }}</td>
                    <td>
                      <span
                        class="badge badge-grn"
                        v-if="forms.visitStatus == 'Completed'"
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                        class="badge badge-danger"
                        v-if="forms.visitStatus == 'Overdue'"
                        >{{ forms.visitStatus }}
                      </span>
                      <span
                        class="badge badge-yellow"
                        v-if="forms.visitStatus == 'Imminent'"
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                        class="badge badge-gray"
                        v-if="forms.visitStatus == 'Withdrawn'"
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                        class="badge badge-blue"
                        v-if="
                          forms.visitStatus == 'Planned'
                        "
                        >{{ forms.visitStatus }}</span
                      >
                      <span
                                  class="badge badge-orage"
                                  v-if="forms.visitStatus == 'Pending'"
                                  >{{ forms.visitStatus }}
                                </span>
                    </td>                  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Visit form list modal ends -->
</template>
<script src="./visit_summary.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}
.ibox-body {
  background-color: #ffffff;
  margin: 3rem, 0;
}

.style1 {
  background-color: #3c4192 !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}
.ibox-title {
  color: inherit;
  margin-bottom: 0;
  padding: 1rem;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  width: unset !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.titlemsg {
  padding: 20px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #145faf;
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}
.graphbtncontainer {
  display: flex;
  gap: 5px;
}
img {
  margin-left: 0px !important;
}
.minwidthdiv {
  height: 420px;
  overflow: hidden;
}
.ibox-title h5 {
  float: none;
}

.tableheight {
  height: 100%;
  padding: 7px;
}
.strechtable {
  height: 100%;
}
.mt-1,
.my-1 {
  margin-left: 0 !important;
}
.patientcountchartdiv {
  overflow: auto;
}
.btn-setdefault {
  position: relative;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-size: 0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius: 3px;
}
.ibox-content h1 {
  font-weight: 400;
  color: #676a6c;
}
</style>
