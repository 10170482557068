 <template>
    <div class="Retention_modal__wrapper">
      <div class="Retention_modal__container">
        <div class="titleclass d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">{{ $t("navigations.data") }}</h4>
          <div class="cursor-pointer">
            <i class="fa fa-times" title="Close" @click.prevent="closemodal()"></i>
          </div>
        </div>
        <div class="patientenrollment__modal__content p-3 table-responsive"
        :class="{
            'height-100': changeHeight == true,
            'max-height-500': changeHeight == false,
            'align-items-center': changeHeight == true,
          }">
          <!-- <div v-if="tableloadingBlock" class="text-center">
            <img
              src="../../assets/img/loading.webp"
              class="table-load"
              width="30"
              alt="loading"
            />
          </div> -->
          <div >
              <table class="table features-table ">
              <thead>
                <th>Participant ID</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Ethnicity</th>
                <th>Treatment Group</th>
                <th>BMI</th>
                <th>Smoking Status</th>
                <th>Alcohol Consumption</th>
                <th>Medication Adherence</th>
                <th>Comorbidities</th>
                <th>Baseline Health Status</th>
                <th>Side Effects</th>
                <th>Dose Levels</th>
                <th>Geographical Location</th>
                <th>Blood Pressure</th>
                <th>Cholesterol Levels</th>
                <th>Study Site</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Event Occurred</th>
                <th>Time to Event (Months)</th>
                <th>Censoring Indicator</th>
                <th>Adverse Events</th>
              </thead>
              <tr v-for="item in survivalData" :key="item.id">
                <td class="py-1">{{ item["Participant ID"] }}</td>
                <td class="py-1">{{ item["Age"] }}</td>
                <td class="py-1">{{ item["Gender"] }}</td>
                <td class="py-1">{{ item["Ethnicity"] }}</td>
                <td class="py-1">{{ item["Treatment Group"] }}</td>
                <td class="py-1">{{ item["BMI"] }}</td>
                <td class="py-1">{{ item["Smoking Status"] }}</td>
                <td class="py-1">{{ item["Alcohol Consumption"] }}</td>
                <td class="py-1">{{ item["Medication Adherence"] }}</td>
                <td class="py-1">{{ item["Comorbidities"] }}</td>
                <td class="py-1">{{ item["Baseline Health Status"] }}</td>
                <td class="py-1">{{ item["Side Effects"] }}</td>
                <td class="py-1">{{ item["Dose Levels"] }}</td>
                <td class="py-1">{{ item["Geographical Location"] }}</td>
                <td class="py-1">{{ item["Blood Pressure"] }}</td>
                <td class="py-1">{{ item["Cholesterol Levels"] }}</td>
                <td class="py-1">{{ item["Study Site"] }}</td>
                <td class="py-1">{{ item["Start Date"] }}</td>
                <td class="py-1">{{ item["End Date"] }}</td>
                <td class="py-1">{{ item["Event Occurred"] }}</td>
                <td class="py-1">{{ item["Time to Event (Months)"] }}</td>
                <td class="py-1">{{ item["Censoring Indicator"] }}</td>
                <td class="py-1">{{ item["Adverse Events"] }}</td>
              </tr>
            </table>
          </div>
         
        </div>
      </div>
    </div>
</template>
   <script src="./survivaldata_popup.js"></script>

   <style scoped>
.txt-blu {
  color: #1c84c6;
}

.height {
  height: 103px;
  overflow-y: scroll;
}

.activeTab {
  color: #495057;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff;
}

.align-end {
  position: absolute !important;
}

.max-height-500 {
  overflow-y: scroll;
  height: 500px;
}

.upload_btn {
  text-align: center;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.3s ease-in-out !important;
}

.upload_btn img {
  mix-blend-mode: color-dodge;
}

.height-100 {
  height: 535px;
}

.Retention_modal__container {
  width: min(45%, 90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

.titleclass {
  color: #ffffff;
  background: #768dd3;
  z-index: 2000;
}

.height-500 {
  max-height: 500px;
  overflow-y: scroll;
}

.screen-table thead th {
  background-color: hsl(0, 0%, 100%);
  border-top: 1px solid #e7eaec;
  position: sticky;
  top: 0;
}

.Retention_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}

.features-table thead th {
  width: 60%;
}

.table-load {
  mix-blend-mode: difference;
  filter: brightness(0.5);
}

.loading-img {
  mix-blend-mode: lighten;
}

.pr-content {
  min-height: 105px;
}

.txt-blu {
  color: #1c84c6;
}

.shap-bar-container {
  width: 100px;
  height: 16px;
  border: 1px solid #ccc;
  overflow: hidden;
  background-color: #fdfcfc;
  border-radius: 3px;
  padding: 3px 3px !important;
}

.shap-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  line-height: 20px;
}

.status-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.box-color {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  /* Optional: border for better visibility */
}

.confidence {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.confidence p {
  margin: 0;
}

.status-bar {
  width: 120px;
  height: 20px;
  border: 1px solid #ccc;
  margin-top: 3px;
}
</style>