/* eslint-disable */
import axios from "axios";
import html2pdf from 'html2pdf.js';
import * as XLSX from "xlsx"
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import { useStore } from "vuex";
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import stackedbarchart from '../../custom_lib/AiCharts/StackedBarchart.vue';
import stackedhorizontalbarchart from '../../custom_lib/AiCharts/StackedHorizontalBarchart.vue';
import D3PieChart from "../../custom_lib/AiCharts/D3PieChart.vue";
import sunburst from '../../custom_lib/AiCharts/sunburst.vue';
import groupbarchart from '../../custom_lib/AiCharts/groupbarchart.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue'
import jwt_decode from "jwt-decode";
import store from "@/store";
// let testarray= [];
export default {
	name: "studysummary",
	components:{
		historytab,
		stackedbarchart,
		stackedhorizontalbarchart,
		D3PieChart,
		sunburst,
		groupbarchart,
		chartdrill
	},
	async mounted() {
		this.defaultdashboard = store.getters.getdefaultdashboard;
		this.getcount();
	},
	data() {
		return {
			defaultdashboard: "",
			activeformcount: "",
			patientscountstd: "",
			sitecountstd: "",
			userscountstd: "",
			formcountstd: "",
			querycountstd: "",
			totalquerycount: 0,
			siteCode: "",
			formlists: [],
			queryArray: [],
			siteData: [],
			subjectList:[],
			data:[],
			pageNumber: 1,
			currentStudy: "",
			userId: "",
			baseapi: process.env.VUE_APP_Service_URL,
			studies: "",
			valnow: "",
			datahistory:[],
			historykeys:[],
			formsList:[],
			historydata:{},
			drilldata:{},
			drilldowndata:[],
			querylist:[],
			drillpopup:false,
			excludeKeywords:['STUDYID', 'QUERYOPENSTATUS','FORMID', 'QUERYID', 'FORMNAME', 'FORMSTATUS', 'SUBJECTID', 'SUBJECTSTATUS', 'VISITNAME', 'VERSION', 'ACTIVE'],
			excludeQueryKeywords:['STUDYID', 'SITENAME','FORMID', 'SITESTATUS', 'FORMNAME', 'FORMSTATUS', 'SUBJECTSTATUS', 'VISITNAME', 'VERSION', 'ACTIVE'],
			excludeSubjectKeywords:['STUDYID', 'FORMID','QUERYOPENSTATUS', 'QUERYID', 'FORMNAME', 'FORMSTATUS', 'VISITNAME', 'VERSION', 'ACTIVE', 'SITENAME', 'SITESTATUS'],
			excludeFormKeywords:['STUDYID', 'FORMID','QUERYOPENSTATUS', 'QUERYID', 'VISITNAME', 'VERSION', 'ACTIVE', 'SITENAME', 'SITESTATUS', 'SUBJECTSTATUS'],			
			arrayofkeys:['StudyId', 'SiteCode', 'SiteName', 'SiteContactName', 'SiteContactEmail', 'Status', 'SiteNotes', 'NumberOfPatient', 'Active'],
			  xaxiskeys:{"groupbarchart":"",
				"barchart":"",
				"histogram":"",
				"piechart":"STUDYREF",
				"advtable":"",
				"aepiechart":"",
				"historytab":"",
				"chartdrill":""},
			  groupchartkeys:{
				"xaxiskey":"STUDYREF",
			  "groupkey":"QUERYOPENSTATUS",
			  },
			  loader:true,
		};
	},
	methods: {
		exporttopdf() {
			// Create a temporary container
			const pdfContainer = document.createElement('div');
			const pdfContainer1 = document.createElement('div');
			const pdfContainer2 = document.createElement('div');
			const pdfContainer3 = document.createElement('div');
			const pdfContainer4 = document.createElement('div');
			const captureArea1 = this.$refs.stackedbar.cloneNode(true);
			const captureArea2 = this.$refs.stackedbarhorizontal.cloneNode(true);
			const captureArea3 = this.$refs.groupchartdiv.cloneNode(true);
			const captureArea4 = this.$refs.sunburst.cloneNode(true);
	  
			captureArea2.classList.add('page-break');
			captureArea3.classList.add('page-break');
			captureArea4.classList.add('page-break');
			// Append cloned elements to the container
			pdfContainer1.appendChild(captureArea1);
			pdfContainer2.appendChild(captureArea2);
			pdfContainer3.appendChild(captureArea3);
			pdfContainer4.appendChild(captureArea4);
			
			// Optionally style the container for PDF formatting
			pdfContainer1.style.width = '100%';
			pdfContainer2.style.width = '200%';
			pdfContainer3.style.width = '100%';
			pdfContainer4.style.width = '50%';
			
			pdfContainer.appendChild(pdfContainer1);
			pdfContainer.appendChild(pdfContainer2);
			pdfContainer.appendChild(pdfContainer3);
			pdfContainer.appendChild(pdfContainer4);
	  
			pdfContainer.style.display = 'block';
			// Set the options for html2pdf
			const opt = {
			  margin: 0,
			  filename: 'Study Summary.pdf',
			  image: { type: 'jpeg', quality: 0.98 },
			  html2canvas: { scale: 2 },
			  jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
			};
		  
			// Convert the temporary container to PDF
			html2pdf().set(opt).from(pdfContainer).save();
		  },
		  async exporttoexcell() {
			const worksheet = XLSX.utils.json_to_sheet(this.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			XLSX.writeFile(workbook, "Study Summary.xlsx");
		  },
		async retaingroupchartvars(groupvars){
			if(groupvars.xaxiskey!="" && groupvars.groupkey!=""){
			this.groupchartkeys = groupvars;
			}
		  },
		async setasdefault(){
			// alert("set as default");
			  const idtoken = store.getters.getIdToken;
			  let userIdfordefault = {
				"userId": this.jwtDecrypt(idtoken).sub,
				"email": jwt_decode(idtoken).email,
				"defaultDashBoard": "studysummary"
				};
			  await axios
				.put(`${this.baseapi}/account-core/settings/userdashboard`,
				userIdfordefault,
				{
				  headers: {
					Authorization: "Bearer " + idtoken,
					"Content-Type": "application/json",
				  },
				})
				.then((res) => {
					store.dispatch("setdefaultdashboard", "studysummary");
					alert("Default dashboard updated successfully");
					this.defaultdashboard = store.getters.getdefaultdashboard;
				})
				.catch((err) => {
				  console.log(err);
				});
		  },
		jwtDecrypt(token) {
			const base64Url = token.split(".")[1];
			const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function(c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			return JSON.parse(jsonPayload);
		},
		async getcount() {
			const idtoken = store.getters.getIdToken;
			this.getData(idtoken);
		},
		async getData(idtoken) {
			this.sites = [];
			await axios
				.get(
					`${this.baseapi}/management/site/getallstudysites?getAll=false`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.data=res.data.map(obj => {
							return Object.fromEntries(
								Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value]) // Capitalize keys
							);
						});
					this.datahistory.push(this.data);
					this.historydata['Home'] = this.data;
					this.historykeys.push('Home');
					this.totalCount = res.data.count;
					this.loader = false;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async drillpopupopened(datafromchild,table) {
			
			if(table=='site')
			{
				this.drilldata=datafromchild.map(item => {
				const newItem = { ...item }; // Create a shallow copy
				this.excludeKeywords.forEach(key => delete newItem[key]); // Delete specified keys
				return newItem;
				});
			}
			if(table=='query')
				{
					this.drilldata=datafromchild.map(item => {
					const newItem = { ...item }; // Create a shallow copy
					this.excludeQueryKeywords.forEach(key => delete newItem[key]); // Delete specified keys
					return newItem;
					});
				}
			if(table=='subjects')
			{
				this.drilldata=datafromchild.map(item => {
				const newItem = { ...item }; // Create a shallow copy
				this.excludeSubjectKeywords.forEach(key => delete newItem[key]); // Delete specified keys
				return newItem;
				});
			}
			if(table=='forms')
				{
					this.drilldata=datafromchild.map(item => {
					const newItem = { ...item }; // Create a shallow copy
					this.excludeFormKeywords.forEach(key => delete newItem[key]); // Delete specified keys
					return newItem;
					});
				}
			this.drillpopup = true;  
		},
		async drilldowncurrentchart(datafromchild){
		  this.data = datafromchild
		  this.datahistory.push(this.data);
		},
		async drilldowncurrentchartforbar(datafromchild){
		  const lastkey = this.historykeys[this.historykeys.length - 1];
		  if(this.historydata[lastkey].length != datafromchild.sortedData.length){
		   this.historykeys.push(datafromchild.filterkeyword);
		  this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
		  this.data = this.historydata[datafromchild.filterkeyword];
		  }
		},
		async gotohistorylevel(history){
		  this.deleteAfterKeyvaluearrays(history);
		  this.deleteAfterKeyarrays(history);
		  this.data = this.historydata[history];
		},
		async deleteAfterKeyvaluearrays(selectedKey) {
		  const entries = Object.entries(this.historydata); // Convert the object to an array of entries
		  const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
		
		  // If the key is found, slice the array to keep only the entries up to the selected key
		  const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
		
		  // Convert the array of entries back into an object
		  return Object.fromEntries(newEntries);
		},
		async deleteAfterKeyarrays(selectedKey) {
	
		  let index = this.historykeys.indexOf(selectedKey);
	
	if (index !== -1) {
		// Slice the array up to the element (including the element)
		this.historykeys = this.historykeys.slice(0, index + 1);
	}
		},
	},
};
