<!--eslint-disable @typescript-eslint/explicit-module-boundary-types-->
<template>
    <div class="register-block row bgwhite">
       <!--  <div class="row border-bottom" v-if="!getNavbarstatus">
          <nav
            class="navbar navbar-static-top"
            role="navigation"
            style="margin-bottom: 0"
           >
          </nav>
         
        </div> -->
      <div class="col-12 col-md-12 left-block d-flex justify-content-center">
        <div
          class="register-screen animated fadeInDown height-100 d-flex flex-column justify-content-center pb-5"
        >
          
          <div class="tab-section text-center mb-2">
            <h5 class="py-2">Sign In</h5>
          </div>
          <div class="px-2 py-4">
            <div class="login">
              <form role="form" autocomplete="on">
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    v-model="username"
                    placeholder="Email"
                    required
                  />
                </div>
                <div class="form-group position-relative">
                  <input
                    :type="inputType"
                    class="form-control mb-0"
                    v-model="password"
                    placeholder="Password"
                    required
                  />
                  <i
                    class="password-visible-block cursor-pointer position-absolute"
                    :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                    @click.prevent="passwordVisible()"
                  ></i>
                  <div class="text-right">
                    <a href="#">Forgot password?</a>
                  </div>
                </div>
                <div class="errmsg mb-2">{{ errr }}</div>
                <button
                  type="submit"
                  class="btn-block save_btn py-2 continue"
                  @click.prevent="login"
                >
                  <span v-if="loadingBlock && errr == ''"
                    ><img
                      src="../../assets/img/loading.webp"
                      width="20"
                      class="loading-img"
                      alt="Waiting for login"
                  /></span>
                  <span v-else>Login</span>
                </button>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </template>
  
  <script src="./subjectlogin.js"></script>
  
  <style>
  @media (max-width: 768px) {
    .loginscreen.middle-box {
      width: clamp(200px, calc(100vw - 100px), 430px);
    }
  }
  
  .img-block img {
    aspect-ratio: 1/1;
  }
  
  .register-screen {
    width: clamp(200px, calc(100vw - 100px), 400px);
  }
  
  .loading-img {
    mix-blend-mode: lighten;
  }
  
  .login-tab-panels {
    border: 1px solid #dcdfe6;
    background-color: #ffffff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    cursor: auto !important;
  }
  
  .patient-tab-panels {
    border-top-right-radius: 0.25rem;
  }
  
  .login-contents {
    border-left: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  .login-image > * {
    width: 100%;
  }
  
  .login-contents {
    background-color: #ffffff;
  }
  
  .height-100 {
    height: 100dvh;
  }
  
  .errmsg {
    font-size: 14px;
    color: red;
  }
  
  .form-background {
    margin-left: -15px;
  }
  
  .loginheader {
    font-size: 17px;
  }
  
  .password-visible-block {
    right: 11px;
    top: 7px;
  }
  
  .img-block {
    -webkit-background: linear-gradient(90.25deg, #80e2e7 4.35%, #059f8c 101.12%);
    -moz-background: linear-gradient(90.25deg, #80e2e7 4.35%, #059f8c 101.12%);
    background: linear-gradient(90.25deg, #80e2e7 4.35%, #059f8c 101.12%);
  }
  
  .register-block {
    background-color: #f3f3f4;
    color: #676a6c;
    width: 100vw;
  }
  
  .left-block {
    color: #676a6c;
  }
  
  .right-block {
    color: #676a6c;
  }
  
  .points-block {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.1rem;
    list-style-type: disc;
  }
  .bgwhite{
    background-color: white;
  }
  .continue{
  padding:initial;
}
  </style>
  