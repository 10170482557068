<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-6 align-self-center">
            <h2 class="mb-0">{{ $t("navigations.ae incidence table") }}</h2>
        </div>
        <div class="col-lg-2">
            <i class="fa fa-times close" @click="$router.push(`/analyticalDashboard`)"></i>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-12 pl-1 mb-2">
                <historytab
                :historyarray="historykeys"
                @gotohistorylevel="gotohistorylevel"
                @exporttopdf="exporttopdf"
            @exporttoexcell="exporttoexcell"
                />
            </div>
            <div ref="captureArea1" class="col-12 mb-2 pl-1">
                <heatmap
                :availableColumns=arrayofkeys
                :loader="loader"
                :data="data"
                :templateId="templateId"
                xAxisKey="TRT"
                yAxisKey="AETERM"
                valueKey="count"
                chartlabel="Adverse Event Incidence Heatmap"
                :fromPage="'descriptiveStatistics'"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
                @exporttoexcell="exporttoexcell"
            />
          </div>
          <div class="col-lg-12 mb-1 pl-1">
            <div class="stat-card-white ">
            <div class="stat-header-white">
                <span>Patient Distribution</span>
            </div>
            <div class="stat-body-white">
                <advtable fileheadding="AE incidence table" :loader="loader" :data="data" fromPage="patientRetention"
                @drilldowncurrentchart="drilldowncurrentchartforbar" />
            </div>
            </div>
        </div>
        </div>
    </div>
    <chartdrill v-if="drillpopup" fileheadding="AE incidence table" :drilldata="drilldata" :templateId="templateId" :fromPage="'adverseEventsPop'"
    @closepopup="drillpopup=false" />
</template>
<script src="./AEIncidenceTable.js">
</script>
<style>
.page-break {
  page-break-before: always; /* Ensures new page starts before this element */
}
</style>