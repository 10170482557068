<template>
  <div class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 v-if="selectedvisit == 0" class="my-0">
          {{ $t("navigations.create visit") }}
        </h4>
        <h4 v-else class="my-0">{{ $t("navigations.edit visit") }}</h4>
        <!-- <div class="cursor-pointer"  @click.prevent="closeModal">
          <i class="fa fa-times" title="Close"></i>
        </div> -->
        <div
          class="tooltip-wrapper pointer"
          style="float: right; cursor: pointer"
        >
          <i class="fa fa-times" @click.prevent="closeModal"></i>
          <span class="custom-tooltip">{{ $t('navigations.close') }}</span>
        </div>
      </div>
      <div class="ibox-content p-0">
        <div class="modal-body pb-0">
          <div class="form-group row">
            <div class="col-lg-4 my-0">
              <label class="col-form-label">
                {{ $t("navigations.visit no")
                }}<sup><i class="fa fa-asterisk imp"></i></sup>
              </label>
              <input
                type="text"
                class="form-control"
                v-model="values.visitNo"
                maxlength="3"
              />
              <span class="errormsg" v-if="errors[0] == false">
                {{ $t("navigations.please enter visit no") }}
              </span>
            </div>
            <div class="col-lg-4 my-0">
              <label class="col-form-label">
                {{ $t("navigations.visit name") }}
                <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                type="text"
                class="form-control"
                v-model="values.visitFormName"
                maxlength="500"
              />
              <span class="errormsg" v-if="errors[1] == false">{{
                $t("navigations.please enter visit form name")
              }}</span>
            </div>
            <div class="col-lg-4 my-0">
              <label class="col-form-label"
                >{{ $t("navigations.visit type")
                }}<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <select
                class="form-select"
                v-model="values.visitType"
                valueProp="VisitTypeName"
              >
                <option disabled value="">{{ $t("navigations.select") }}</option>
                <option v-for="options in visitList" :key="options.typename">
                  {{ options.typename }}
                </option>
              </select>
              <span class="errormsg" v-if="errors[2] == false">{{
                $t("navigations.please select a visit type")
              }}</span>
            </div>

            <div class="col-lg-12 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit notes")
              }}</label>
              <input
                type="text"
                class="form-control"
                v-model="values.visitNotes"
              />
            </div>

            <div class="col-lg-12 my-0 mt-3 mb-2">
              <span class="text-muted">{{
                $t(
                  "navigations.you have the option to either select from the existing templates or utilize templates available in the form library"
                )
              }}</span>
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label">{{
                $t("navigations.assign form")
              }}</label>
              <Multiselect
                v-model="assignTemplateId"
                mode="multiple"
                valueProp="templateId"
               :placeholder="$t('navigations.select form')"
                label="templateName"
                :options="templatelist"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-template-theme mr-0"
              >
                <template v-slot:option="{ option }">
                  {{ option.templateName }} ( {{ option.version }})
                </template>
              </Multiselect>
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label">
                {{ $t("navigations.import from form library") }}</label
              >
              <Multiselect
                mode="multiple"
                v-model="importTemplateId"
                placeholder="Select Form"
                valueProp="formLibraryId"
                label="templateName"
                :options="formlibrarylist"
                :disabled="assignTemplateSelected"
                :hideSelected="false"
                :closeOnSelect="false"
                @close="openPopup()"
                class="multiselect-template-theme mr-0"
              >
                <template v-slot:option="{ option }">
                  {{ option.formLibraryName }} ( {{ option.version }})
                </template>
              </Multiselect>
            </div>

            <div class="col-lg-12 my-0 mt-3 mb-2">
              <span class="text-muted">
                {{
                  $t(
                    "navigations.if you wish to establish a visit schedule based on the enrollment date test"
                  )
                }}</span
              >
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label">
                {{ $t("navigations.pre visit days") }}</label
              >
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.preVisit"
                maxlength="3"
                @input.prevent="limitInputLength('preVisit', 3)"
                @keydown="preventNonNumeric"
              />
              <span class="errormsg" v-if="numerrors['preVisit'] == false">{{
                $t("navigations.please enter a number")
              }}</span>
            </div>
            <div class="col-lg-6 my-0">
              <label class="col-form-label">{{$t("navigations.post-visit (days)")}}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.postVisit"
                maxlength="3"
                @input.prevent="limitInputLength('postVisit', 3)"
                @keydown="preventNonNumeric"
              />
              <span class="errormsg" v-if="numerrors['postVisit'] == false">{{
                $t("navigations.please enter a number")
              }}</span>
            </div>

            <div class="col-lg-3 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit period days")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitDayPeriod"
                @input.prevent="limitInputLength('visitDayPeriod', 4)"
                @keydown="preventNonNumeric"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitDayPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>
            <div class="col-lg-3 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit period weeks")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitWeekPeriod"
                @input.prevent="limitInputLength('visitWeekPeriod', 4)"
                @keydown="preventNonNumeric"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitWeekPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>
            <div class="col-lg-3 my-0 px-0">
              <label class="col-form-label">{{
                $t("navigations.visit period months")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitMonthPeriod"
                @input.prevent="limitInputLength('visitMonthPeriod', 4)"
                @keydown="preventNonNumeric"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitMonthPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>
            <div class="col-lg-3 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit period year")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitYearPeriod"
                @input.prevent="limitInputLength('visitYearPeriod', 4)"
                @keydown="preventNonNumeric"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitYearPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>

            <div class="mt-3 ml-3 form-check align-self-center d-flex">
              <input
                type="checkbox"
                :checked="values.visitType == 'Patient Visit'"
                v-model="values.isPatientVisit"
                :disabled="values.visitType == 'Patient Visit'"
                class="form-check-input"
              />
              <label class="form-check-label ml-2">
                {{
                  $t(
                    "navigations.select this to signify that the visit is completed by the subject"
                  )
                }}
              </label>
            </div>

            <div class="mt-1 ml-3 form-check align-self-center d-flex">
              <input
                type="checkbox"
                v-model="values.active"
                class="form-check-input"
              />
              <label class="mr-2">{{ $t("navigations.active") }}</label>
            </div>

            <div class="col-lg-12 mt-1 d-flex justify-content-lg-end">
              <button
                class="cancel_btn py-1 px-4 mx-3"
                type="button"
                @click.prevent="clear()"
              >
                {{ $t("navigations.clear") }}
              </button>
              <button
                class="save_btn py-1 px-4"
                type="button"
                @click.prevent="addVisit()"
              >
                {{ $t("navigations.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="simple-wrapper" v-if="showPopup == true">
    <div class="simple-popup">
      <p>
        {{ $t("navigations.are you sure you want to import the form(s)") }}
      </p>

      <div class="d-flex justify-content-around mt-3">
        <div class="col-lg-12 mt-1 d-flex justify-content-lg-end">
          <button
            class="cancel_btn py-1 px-4 mx-3"
            type="button"
            @click.prevent="handleCancel()"
          >
            {{ $t("navigations.cancel") }}
          </button>
          <button
            class="save_btn py-1 px-4"
            type="button"
            @click.prevent="ImportFun()"
          >
            {{ $t("navigations.confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    return { t, locale };
  },
  components: {
    Multiselect,
  },
  props: {
    visitId: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      this.studyId = store.getters.getStudyIs;
      this.selectedvisit = this.visitId;
      await this.retrieveinfo();
      await this.getVisitType();
      await this.gettemplatedata();
      await this.getformLibrarydata();
    });
    this.studyId = store.getters.getStudyIs;
    this.selectedvisit = this.visitId;
    await this.retrieveinfo();
    await this.getVisitType();
    await this.gettemplatedata();
    await this.getformLibrarydata();
    await this.getAllForms();
    if (this.formItems != null) {
      console.log("Inside map function");
      this.assignTemplateId = this.formItems.map((item) => item.template_ID);
      console.log("selected template ids...", this.assignTemplateId);
    }
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      selectedStudy: "",
      selectedvisit: "",
      values: {
        visitId: "",
        isPatientVisit: false,
        visitNo: "",
        visitFormName: "",
        visitNotes: "",
        active: true,
        preVisit: "",
        postVisit: "",
        visitType: "",
        folderId: "",
        studyId: "",
        isDeleted: false,
        visitDayPeriod: "",
        visitWeekPeriod: "",
        visitMonthPeriod: "",
        visitYearPeriod: "",
        templateId: "",
      },
      numerrors: {
        preVisit: true,
        postVisit: true,
        visitDayPeriod: true,
        visitWeekPeriod: true,
        visitMonthPeriod: true,
        visitYearPeriod: true,
      },
      validations: [false, false, false],
      errors: [],
      visitList: [],
      templatelist: [],
      studyId: "",
      assignTemplateId: [],
      importTemplateId: [],
      importvalue: {
        formLibraryIds: [],
        studyId: "",
      },
      formlibrarylist: [],
      showPopup: false,
      selectedFormLibraryName: [],
      formsArray: [],
      formItems: [],
    };
  },
  computed: {
    importFromLibrarySelected() {
      return this.importTemplateId !== null && this.importTemplateId !== "none";
    },
    assignTemplateSelected() {
      return this.assignTemplateId.length != [];
    },
    selectedmodeltemplate() {
      return this.assignTemplateId.map((id) => {
        return this.templatelist.find((template) => template.templateId === id);
      });
    },
  },
  methods: {
    preventNonNumeric(event) {
    // Allow only digits and control keys
    if (!/[0-9]/.test(event.key) && !["Backspace", "ArrowLeft", "ArrowRight","ArrowDown","ArrowUp", "Delete", "Tab"].includes(event.key)) {
      event.preventDefault();
    }
  },
    async updateSelectedFormLibraryName(name) {
      this.selectedFormLibraryName = name;
      console.log(name, "heyyyyy", this.selectedFormLibraryName);
    },
    async getAllForms() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/visit/getallforms?studyId=${this.studyId}&visitId=${this.visitId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formItems = res.data[0];
          console.log("visit form list is...", this.formItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async openPopup() {
      if(this.importTemplateId.length!=0){
      this.showPopup = true;
      }
    },
    async handleConfirm() {
      await this.ImportFun();
    },
    async handleCancel() {
      this.showPopup = false;
    },
    async mapTemplateIds() {
      if (this.assignTemplateId.length != 0) {
        this.values.templateId = this.assignTemplateId;
      }
    },
    async getVisitType() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/visittype/listvisittypes`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.visitList = res.data.entities;
          console.log("Result visit is", this.visitList);
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.detail);
        });
    },
    async clear() {
      console.log("inside clear function");
      this.values = {
        visitId: this.values.visitId,
        visitNo: "",
        visitFormName: "",
        visitNotes: "",
        active: true,
        preVisit: "",
        postVisit: "",
        visitDayPeriod: "",
        visitWeekPeriod: "",
        visitMonthPeriod: "",
        visitYearPeriod: "",
        isPatientVisit: false,
        templateId: null,
        visitType: "",
        folderId: this.values.folderId,
        studyId: this.values.studyId,
        accountId: this.values.accountId,
        isDeleted: this.values.isDeleted,
        // temp: this.values.temp,
      };
      (this.assignTemplateId = []), (this.importTemplateId = []);
    },
    async addVisit() {
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        if (this.selectedvisit == 0) {
          this.save();
        } else {
          this.edit();
        }
      }
    },
    async save() {
      this.values.preVisit = String(this.values.preVisit);
      this.values.postVisit = String(this.values.postVisit);
      this.values.visitMonthPeriod = String(this.values.visitMonthPeriod);
      this.values.visitWeekPeriod = String(this.values.visitWeekPeriod);
      this.values.visitYearPeriod = String(this.values.visitYearPeriod);
      this.values.visitDayPeriod = String(this.values.visitDayPeriod);
      this.values.studyId = store.getters.getStudyIs;
      this.values.folderId = store.getters.getStudyIs;
      console.log("this.forms arrry", this.selectedmodeltemplate);
      this.selectedmodeltemplate.forEach((item, index) => {
        (this.formsArray[index] = {
          template_ID: "",
          templateName: "",
          version: "",
        }),
          (this.formsArray[index].template_ID = item.templateId);
        this.formsArray[index].templateName = item.templateName;
        this.formsArray[index].version = item.version;
      });
      this.values.forms = this.formsArray;
      const idtoken = store.getters.getIdToken;
      console.log("token is", idtoken);
      console.log("passing data");
      await axios
        .post(`${this.baseurl}/management/visit/create`, this.values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.data === "No visit Found") {
            alert("No visit Found");
          }

          this.userList = res.data.data;
        alert(this.$t('navigations.visit added successfully'));
          this.closeModal();
          this.$emit("createVisit");
        })
        .catch((err) => {
          if(err.response.data.detail ==="Visit No already exists."){
             alert(this.$t('navigations.visit no already exists'))

          }
          else{
     alert(err.response.data.detail);
          }
         
          
        });
    },
    async edit() {
      this.values.preVisit = String(this.values.preVisit);
      this.values.postVisit = String(this.values.postVisit);
      this.values.visitMonthPeriod = String(this.values.visitMonthPeriod);
      this.values.visitWeekPeriod = String(this.values.visitWeekPeriod);
      this.values.visitYearPeriod = String(this.values.visitYearPeriod);
      this.values.visitDayPeriod = String(this.values.visitDayPeriod);
      this.values.studyId = store.getters.getStudyIs;
      this.selectedmodeltemplate.forEach((item, index) => {
        (this.formsArray[index] = {
          template_ID: "",
          templateName: "",
          version: "",
        }),
          (this.formsArray[index].template_ID = item.templateId);
        this.formsArray[index].templateName = item.templateName;
        this.formsArray[index].version = item.version;
      });
      this.values.forms = this.formsArray;
      console.log("edit function", this.values);
      const idtoken = store.getters.getIdToken;
      console.log(idtoken);
      await axios
        .put(`${this.baseurl}/management/visit/update`, this.values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.data === "No Visit Found") {
            // alert("No visit Found");
             alert(this.$t('navigations.no visit found'))
            //alert(this.$t('navigations.no visit found'));
          }
          this.userList = res.data.data;
          console.log("Result is", res.data.data);
          // alert("Visit updated successfully.");
           alert(this.$t('navigations.visit updated successfully'))
          this.closeModal();
          this.$emit("createVisit");
        })
        .catch((err) => {
          console.log(err);
            if (err.response.data.detail === "Visit No already exists.") {
               alert(this.$t('navigations.visit no already exists'))
              //  alert(this.$t('navigations.no study selected'))
            }
         
        });
    },
    async retrieveinfo() {
      this.values.studyId = store.getters.getVisitStudyIs;
      console.log(this.values.studyId);
      if (this.selectedvisit != 0) {
        // console.log("edit function " + this.selecteduser);
        const idtoken = store.getters.getIdToken;
        await axios
          .get(
            //`https://localhost:5001/visit/getbyid/`+this.selectedvisit,
            `${this.baseurl}/management/visit/getbyid/` + this.selectedvisit,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("get bindable data", res.data);
            if (res.data === "No Visit Found") {
              alert(this.$t('navigations.no visit found'));
              // alert("No visit Found");
            }
            this.values = res.data;
            if (res.data.template_ID != "" && res.data.template_ID != null) {
              this.values.templateId = ""; //res.data.template_ID;
              this.assignTemplateId = res.data.template_ID;
            } else {
              this.values.templateId = "";
              this.assignTemplateId = [];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async validate() {
      this.values.visitNo = this.values.visitNo.replace(/ +/g, " ").trim();

      this.values.visitFormName = this.values.visitFormName
        .replace(/ +/g, " ")
        .trim();
      this.values.visitFormName = this.values.visitFormName
        .replace(/ +/g, " ")
        .trim();
      if (this.values.visitNo == "") {
        this.validations[0] = false;
      } else {
        this.validations[0] = true;
      }
      if (this.values.visitFormName == "") {
        this.validations[1] = false;
      } else {
        this.validations[1] = true;
      }

      if (this.values.visitType != "") {
        this.validations[2] = true;
      }
    },
    // async numberonlyvalidation(fieldname) {
    //   let isInteger = true;
    //   this.values[fieldname] = this.values[fieldname].replace(/\s/g, "");
    //   let fieldval = this.values[fieldname];

    //   if (fieldval === "" || /^\s*$/.test(fieldval)) {
    //     // Field is empty or contains only whitespace
    //     this.numerrors[fieldname] = true;
    //     return;
    //   }

    //   if (fieldval % 1 !== 0) {
    //     isInteger = false;
    //   }

    //   if (!isInteger) {
    //     this.values[fieldname] = 0;
    //   } else {
    //     if (parseInt(this.values[fieldname]) >= 0) {
    //       this.numerrors[fieldname] = true;
    //     } else {
    //       this.values[fieldname] = 0;
    //     }
    //   }
    // },
    async closeModal() {
      this.$emit("closeModal");
      this.$emit("createVisit");
    },
    limitInputLength(field, maxLength) {
      const inputValue = this.values[field].toString();

      if (inputValue.length > maxLength) {
        this.values[field] = Number(inputValue.slice(0, maxLength));
      }
    },
    async gettemplatedata() {
      console.log("template data function");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.templatelist=[];
          console.log("template data is");
          console.log(res.data.results);
          // this.templatelist = res.data.results; sorted
          let currenttemplatelist = (res.data.results || []).sort((a, b) =>
            a.templateName.localeCompare(b.templateName)
          );
          this.templatelist =  Array.from(currenttemplatelist);
        })
        .catch((err) => {
          console.log("error in get template details ", err);
        });
    },
    async getformLibrarydata() {
      console.log("template data function");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/formslibrary/listformlibrary?Status=Approved`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("template data is");
          console.log(res.data);
          // this.formlibrarylist = res.data.results;sorted
          this.formlibrarylist = (res.data.results || []).sort((a, b) =>
            a.formLibraryName.localeCompare(b.formLibraryName)          
          );
        })
        .catch((err) => {
          console.log("error in get template details ", err);
        });
    },
    async ImportFun() {
      this.importvalue.formLibraryIds = [];
      this.importvalue.formLibraryIds = this.importTemplateId;
      this.importvalue.studyId = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .post(
          `${this.baseurl}/forms/template/copyfromlibrary`,
          this.importvalue,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res);
          this.showPopup = false;
          this.importTemplateId = [];
          this.assignTemplateId = res.data;
          alert(this.$t('navigations.form imported succesfully'));
          await this.gettemplatedata();
        })
        
    },
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "../../assets/savepages.css";
.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.study_profile_modal__container {
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}

.cancel_btn {
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
}
.import-popup {
  position: relative;
}
.import-popup .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -40px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.import-popup:hover .tool-tip,
.import-popup:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.custom-popup {
  text-align: center;
}

.simple-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 33px; /* Adjust the padding to increase the size */
  width: 29%; /* Adjust the width as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 4000; /* Increase the z-index value if needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.simple-wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.multiselect-template-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-radius: 0;
  --ms-line-height: 0.8;
  --ms-option-font-size: 0.8rem;
  --ms-font-size: 0.8rem;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  width: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -18%; /* Position the tooltip above the icon */
  left: -375%;
  margin-left: -30px; /* Use half of the width to center-align */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}
</style>